import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {TOOLTIP_CONTENT_DATA} from '../../../directive/tooltip/tooltip-content-token';
import {firstValueFrom} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from '../../../service/snackbar/snackbar.service';
import {ITooltipButton} from '../../../interface/ui/tooltip/ITooltipButton';
import {IItemValidated, ITooltipList} from '../../../interface/ui/tooltip/ITooltipList';

@Component({
  selector: 'app-tooltip-list',
  templateUrl: './tooltip-list.component.html',
  styleUrls: ['./tooltip-list.component.scss']
})
export class TooltipListComponent {
  @Output() public closeClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(@Inject(TOOLTIP_CONTENT_DATA) public data: ITooltipList<any>,
              private translateService: TranslateService, private snackbarService: SnackbarService) {
  }

  public onButtonClicked(event: Event, button: ITooltipButton<any, any>): void {
    if (button.preventRedirect === true) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (button.handlerFn) {
      button.handlerFn(button.handlerArg);
      this.closeClicked.emit();
    } else {
      this.showActionNotImplementedMsg();
    }
  }

  public async showActionNotImplementedMsg(): Promise<void> {
    const message = await firstValueFrom(this.translateService.get('error.actionNotImplemented'));
    this.snackbarService.openErrorSnackbar(message);
  }

  public areGeneralErrors(): boolean {
    return this.data.errors?.length > 0;
  }

  public areItemErrors(item: IItemValidated<any>): boolean {
    return item.errors?.length > 0;
  }

  public areGeneralButtons(): boolean {
    return this.data.buttons?.length > 0;
  }

  public areItemButtons(item: IItemValidated<any>): boolean {
    return item.buttons?.length > 0;
  }

  public getButton(button: any): ITooltipButton<any, any> {
    return button as ITooltipButton<any, any>;
  }
}
