import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NgControl} from '@angular/forms';
import {MatDateRangePicker} from '@angular/material/datepicker';
import {CalendarHeaderComponent} from '../calendar-header/calendar-header.component';
import {hasAnyError, hasRequiredError} from 'src/app/shared/util/form/form-utils';
import {faFloppyDiskCircleArrowRight, faTrash} from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-date-range-control',
  templateUrl: './date-range-control.component.html',
  styleUrls: ['./date-range-control.component.scss']
})
export class DateRangeControlComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  @ViewChild('picker') picker: MatDateRangePicker<any>;

  public dateRangeForm: FormGroup;

  @Input() isTableFilter = false;
  @Input() placeholder: string;

  public isDropdownOpen = false;
  public calendarHeader = CalendarHeaderComponent;

  public isDisabled = false;

  public faTrash = faTrash;
  public faDiskArrowRight = faFloppyDiskCircleArrowRight;

  public hasRequiredError: (control: NgControl) => boolean = hasRequiredError;
  public hasAnyError = hasAnyError;

  public onChange: any = () => {
  }
  public onTouched: any = () => {
  }

  constructor(public control: NgControl) {
    this.control.valueAccessor = this;
  }

  public ngOnInit(): void {
    this.dateRangeForm = new FormGroup<any>({
      start: new FormControl(null),
      end: new FormControl(null)
    });
  }

  public ngAfterViewInit(): void {
    this.picker.closedStream.subscribe(() => {
      if (this.isDropdownOpen) {
        this.picker._applyPendingSelection();
        this.onDropdownToggled(false);
      }
      this.updateDateValue();
    });
  }


  public onDropdownToggled(isOpen: boolean): void {
    this.isDropdownOpen = isOpen;
    if (isOpen) {
      this.onTouched();
      this.picker.open();
    }
  }

  private updateDateValue(): void {
    this.onChange(this.dateRangeForm.value);
    this.onTouched();
  }

  public isControlGreyedOut(): boolean {
    return !this.control?.value && !this.isDropdownOpen && !this.isTableFilter;
  }

  public onClearClicked(): void {
    this.dateRangeForm.reset();
    this.picker.select(null);
    this.isDropdownOpen = false;
    this.picker.close();
  }

  /* Control Value Accessor */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    if (!obj && this.picker && this.dateRangeForm) {
      this.onClearClicked();
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public hasValue(): boolean {
    return this.control.value && (this.control.value.start || this.control.value.end);
  }
}
