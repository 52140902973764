import {AbstractControl, FormArray, FormGroup, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {getControl} from './campaign-form.util';
import {ICreative} from '../../creative/interface/ICreative';
import {IAdUnitSelectForm} from '../../ad-unit/interface/IAdUnitSelectForm';

export function adUnitsRequired(formArray: FormArray<FormGroup<IAdUnitSelectForm>>): ValidationErrors | null {
  return formArray.length > 0 && isMinimumOneActiveAdUnit(formArray) ? null : {adUnitsRequiredError: true};
}

function isMinimumOneActiveAdUnit(formArray: FormArray<FormGroup<IAdUnitSelectForm>>): boolean {
  return formArray.controls.some((adUnit: FormGroup<IAdUnitSelectForm>) => adUnit.value.isActive === true);
}

export function endDateValidator(control: AbstractControl): ValidationErrors | null {
  const startDateValue = getControl('startDate', control.parent as UntypedFormGroup)?.value;
  return (startDateValue && control.value && startDateValue > control.value) ? {endDateError: true} : null;
}

export function startDateValidator(control: AbstractControl): ValidationErrors | null {
  const endDateValue = getControl('endDate', control.parent as UntypedFormGroup)?.value;
  return (endDateValue && control.value && endDateValue < control.value) ? {startDateError: true} : null;
}

export function creativesRequired(creativesControl: AbstractControl): ValidationErrors | null {
  return (creativesControl.value && (creativesControl.value as ICreative[]).length > 0) ? null : {creativesRequiredError: true};
}
