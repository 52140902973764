import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {DropdownComponent} from '../../../shared/component/common/dropdown/dropdown.component';
import {faBasketShoppingSimple, faCheck} from '@fortawesome/pro-light-svg-icons';
import {ProductsService} from '../../service/product/products.service';
import * as _ from 'lodash';
import {ProductDialogService} from '../../service/product-dialog/product-dialog.service';
import {firstValueFrom, Subject, Subscription} from 'rxjs';
import {
  getOrderedSearchItemsByName,
  itemIncludesSearch
} from '../../../shared/util/search.util';
import {ProductEndpointsEnum} from '../../../shared/enum/Endpoints';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {ProductSimple} from '../../interface/IProductSimple';
import {ProductSelectService} from '../../service/product-select.service';

@Component({
  selector: 'app-select-product-dropdown',
  templateUrl: './select-product-dropdown.component.html',
  styleUrls: ['./select-product-dropdown.component.scss']
})
export class SelectProductDropdownComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('multiItemsContainer') multiItemsSelectedContainer: ElementRef;

  @Input() isMultiSelect = true;
  @Input() dropdown: DropdownComponent;
  @Input() displayedItemsCount = 4;
  @Input() showAddProductButton = true;

  @Input() selectedItemsInitial: ProductSimple[] = [];
  @Input() selectedItemInitial: ProductSimple;

  @Input() dropdownOpenedSubject: Subject<void>;

  @Input() excludedIds: string[] = [];

  public selectedItems: ProductSimple[] = [];
  public selectedItem: ProductSimple;

  @Output() selectionChange: EventEmitter<ProductSimple | ProductSimple[]> = new EventEmitter();

  public allItems: ProductSimple[] = [];
  public displayedItems: ProductSimple[];
  public searchValue = '';

  public productsPromise: Promise<ProductSimple[]>;

  public faBasketShoppingSimple = faBasketShoppingSimple;
  public faCheck = faCheck;

  public isLoading = false;
  private loadingEndpointNames: string[] = [ProductEndpointsEnum.GET_PRODUCTS];
  private subscriptions: Subscription[] = [];

  constructor(private productsService: ProductsService, private productDialogService: ProductDialogService,
              private changeDetectorRef: ChangeDetectorRef, private loadingService: LoadingService,
              private productSelectService: ProductSelectService) {
  }

  private static shouldUpdateDisplayedItems(changes: SimpleChanges): boolean {
    return (changes.excludedIds?.previousValue !== changes.excludedIds?.currentValue)
      || changes.excludedIds?.firstChange;
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (SelectProductDropdownComponent.shouldUpdateDisplayedItems(changes)) {
        this.updateDisplayedItems();
      }

      if(this.shouldUpdateMultiSelectedItems(changes)) {
        this.selectedItems = _.cloneDeep(this.selectedItemsInitial);
        this.updateDisplayedItems();
      } else if (this.shouldUpdateSingleSelectedItem(changes)) {
        this.selectedItem = _.cloneDeep(this.selectedItemInitial);
        this.updateDisplayedItems();
      }
  }

  public shouldUpdateMultiSelectedItems(changes: SimpleChanges): boolean {
    return (changes.selectedItemsInitial?.previousValue !== changes.selectedItemsInitial?.currentValue)
      || changes.selectedItemsInitial?.firstChange;
  }

  public shouldUpdateSingleSelectedItem(changes: SimpleChanges): boolean {
    return (changes.selectedItemInitial?.previousValue !== changes.selectedItemInitial?.currentValue)
      || changes.selectedItemInitial?.firstChange;
  }

  ngOnInit(): void {
    this.observeLoading();
    this.updateProductsList();
    this.observeProductList();
  }

  private observeLoading(): void {
    const sub = this.loadingService.loadingEndpointsObs().subscribe((loadingEndpoints) =>{
      this.isLoading = this.loadingService.areEndpointsLoading(this.loadingEndpointNames, loadingEndpoints);
    });
    this.subscriptions.push(sub);
  }

  private updateProductsList(): void {
    this.productsPromise = firstValueFrom(this.productSelectService.getProducts());
    this.productsPromise.then((res) => {
      this.allItems = res;
      this.updateDisplayedItems();
    });
  }

  private observeProductList(): void {
    const sub = this.productsService.productChangedSubject.subscribe(() => {
      this.updateProductsList();
    });
    this.subscriptions.push(sub);
  }

  public onCleanClicked(): void {
    this.selectionChange.emit([]);
    this.dropdown.closed.emit();
  }

  public onSaveClicked(): void {
    this.selectionChange.emit(this.selectedItems);
    this.dropdown.closed.emit();
  }

  public saveSingleSelect(): void {
    this.selectionChange.emit(this.selectedItem);
    this.dropdown.closed.emit();
  }

  public async onSearchValueChanges(value: string): Promise<void> {
    await this.productsPromise;
    this.searchValue = value;
    this.updateDisplayedItems();
  }

  public updateDisplayedItems(): void {
    const availableItems = this.allItems.filter(item => !this.isItemSelected(item) && !this.excludedIds.includes(item.id));
    this.displayedItems = getOrderedSearchItemsByName(availableItems, this.searchValue);
  }

  public isItemSelected(item: ProductSimple): boolean {
    if (this.isMultiSelect) {
      return this.selectedItems.some(el => el.id === item.id);
    } else {
      return this.selectedItem?.id === item.id;
    }
  }

  public onItemSelected(item: ProductSimple): void {
    if (this.isMultiSelect && !this.isItemSelected(item)) {
      this.onItemSelectedMulti(item);
    } else if (!this.isMultiSelect && !this.isItemSelected(item)) {
      this.onItemSelectedSingle(item);
    }
  }

  public onItemSelectedMulti(item: ProductSimple): void {
    this.selectedItems.push(item);
    this.changeDetectorRef.detectChanges();
    this.scrollDownSelectedItemsContainer();
    this.updateDisplayedItems();
  }

  public onItemSelectedSingle(item: ProductSimple): void {
    this.selectedItem = item;
    this.updateDisplayedItems();
    this.saveSingleSelect();
  }

  public onItemDeselected(item: ProductSimple): void {
    if (this.isMultiSelect) {
      this.onItemDeselectedMulti(item);
    } else {
      this.onItemDeselectedSingle();
    }
  }

  public onItemDeselectedMulti(item: ProductSimple): void {
    this.selectedItems = this.selectedItems.filter(el => el.id !== item.id);
    this.updateDisplayedItems();
  }

  public onItemDeselectedSingle(): void {
    this.selectedItem = null;
    this.updateDisplayedItems();
    this.saveSingleSelect();
  }

  public openNewProductDialog(): void {
    this.productDialogService.openProductAddDialog(null, true);
    this.dropdown.closed.emit();
  }

  public shouldShowNoResultsError(): boolean {
    return this.displayedItems.length === 0 && !this.isLoading &&
      !this.selectedItems.some(item => itemIncludesSearch(item, this.searchValue));
  }

  private scrollDownSelectedItemsContainer(): void {
    if (this.multiItemsSelectedContainer?.nativeElement) {
      this.multiItemsSelectedContainer.nativeElement.scrollTop = this.multiItemsSelectedContainer.nativeElement.scrollHeight;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }
}
