import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {finalize, Observable} from 'rxjs';
import {LoadingService} from '../../service/loading/loading.service';
import {ILoadingEndpointsCount} from '../../interface/ui/loading/ILoading';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private count = 0;
  private countPerEndpoint: ILoadingEndpointsCount = {};

  constructor(private loadingService: LoadingService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.handleRequestStarted(request);

    return next.handle(request).pipe(finalize(() => this.handleRequestFinished(request)));
  }

  private handleRequestStarted(request: HttpRequest<any>): void {
    if(this.isCountZero(request.url)) {
      this.loadingService.setIsLoadingEndpoint(true, request.url);
    }
    this.incrementCount(request.url);

    if (this.count === 0) {
      this.loadingService.setIsLoading(true);
    }
    this.count++;
  }

  private handleRequestFinished(request: HttpRequest<any>): void {
    this.decrementCount(request.url);
    if(this.isCountZero(request.url)) {
      this.loadingService.setIsLoadingEndpoint(false, request.url);
    }

    this.count--;
    if (this.count === 0) {
      this.loadingService.setIsLoading(false);
    }
  }

  private isCountZero(url: string): boolean {
    return this.countPerEndpoint[url] === 0 || !this.countPerEndpoint[url];
  }

  private incrementCount(url: string): void {
    if (this.isCountZero(url)) {
      this.countPerEndpoint[url] = 1;
    } else {
      this.countPerEndpoint[url]++;
    }
  }

  private decrementCount(url: string): void {
    if (this.countPerEndpoint[url]) {
      this.countPerEndpoint[url]--;
    }
  }
}
