import { Injectable } from '@angular/core';
import {IPlatformSmallInfo} from '../../interface/platform/IPlatformSmallInfo';
import {ITableCell} from '../../../shared/interface/ui/table/ITableCell';
import {compareAlphabetical} from '../../../shared/util/table/sort-utils';
import {IconOriginType} from '../../../shared/enum/ui/icons/IconOriginType';
import {CustomIconName} from '../../../shared/enum/ui/icons/CustomIconName';
import {TooltipType} from '../../../shared/enum/ui/tooltip/TooltipType';
import {firstValueFrom} from 'rxjs';
import {Placement} from '../../../shared/enum/ui/tooltip/Placement';
import {TranslateService} from '@ngx-translate/core';
import { CellBasic } from 'src/app/shared/interface/ui/my-table/cell.model';

@Injectable({
  providedIn: 'root'
})
export class PlatformMapperService {

  constructor(private translateService: TranslateService) { }


  private static iconNameExists(iconName: string): boolean {
    return (Object as any).values(CustomIconName).includes(iconName);
  }

  public async getPlatformsCell(platforms: IPlatformSmallInfo[]): Promise<CellBasic> {
    if (!platforms || platforms?.length === 0) {
      return {
        value: '-'
      };
    } else {
      return {
        realValue: platforms,
        icons: await Promise.all(platforms.sort((a, b) => compareAlphabetical(a.type, b.type))
          .map(async platform => (
            {
              type: IconOriginType.CUSTOM, iconName: platform.type as unknown as CustomIconName,
              class: platform.type.toLowerCase() + '-icon-col',
              tooltip: {
                type: TooltipType.TEXT,
                text: await firstValueFrom(this.translateService.get('platformType.' + platform.type)),
                placement: Placement.TOP
              }
            }
          )))
      };
    }
  }

  public async getPlatformCellFromType(platformType: string): Promise<ITableCell> {
    if (!platformType) {
      return {
        value: '-'
      };
    } else {
      const platformTypeLowerCase = platformType.trim().toLowerCase();
      const platformName = await firstValueFrom(this.translateService.get('platformType.' + platformTypeLowerCase));
      const platformNameDefined = platformName !== 'platformType.' + platformTypeLowerCase;

      return {
        realValue: platformType,
        value: platformNameDefined ? platformName : platformType,
        icons: PlatformMapperService.iconNameExists(platformNameDefined ? platformTypeLowerCase : platformType) ? [
          {
            type: IconOriginType.CUSTOM, iconName: platformType as unknown as CustomIconName,
            class: platformType.toLowerCase() + '-icon-col--with-name',
          }] : ([] as any[])
      };
    }
  }
}
