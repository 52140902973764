import {IBackAction} from '../../interface/ui/dialog/IBackAction';
import {DialogType} from '../../enum/ui/dialog/Dialog';
import {IDialogConfig} from '../../interface/ui/dialog/IDialogConfig';

export function getSideDialogConfig(component: any, backAction: IBackAction, data: any): IDialogConfig {
  return {
    component,
    type: DialogType.side,
    backActionDialog: backAction?.backActionDialogConfig,
    data
  };
}
