<ng-template>
  <div [ngClass]="{'dropdown-content-padding': !showDropdownArrow,
   'dropdown-content-padding--arrow': showDropdownArrow,
   'dropdown-content-padding--left': placement === Placement.BOTTOM}">
  <div *ngIf="show" [style.width]="width ? width + 'px' : 'auto'"
       [ngClass]="{'dropdown-arrow': showDropdownArrow && placement === Placement.BOTTOM,
        'dropdown-arrow--left': showDropdownArrow && placement === Placement.RIGHT}"
       class="dropdown-content" #dropdownContainer>
    <ng-content></ng-content>
  </div>
  </div>
</ng-template>
