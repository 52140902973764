export enum TranslationPrefix {
  PRODUCT = 'product',
  PLATFORM = 'platform',
  EXECUTION_POINT_URL = 'executionPointUrl',
  EXECUTION_POINT_GOOGLE_PLAY = 'executionPointGooglePlay',
  EXECUTION_POINT_SAMSUNG = 'executionPointSamsung',
  EXECUTION_POINT_UTM_CAMPAIGN = 'executionPointUtmCampaign',
  EXECUTION_POINT_APP_STORE = 'executionPointAppStore',
  ASSET = 'asset',
  CREATIVE = 'creative',
  CAMPAIGN = 'campaign',
  WATERFALL = 'waterfall',
  MORE_GAMES = 'moreGames',
  WHITELIST = 'whitelist',
  CSV_HISTORY = 'csvHistory'
}
