import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {DropdownTriggerForDirective} from '../../../../directive/dropdown/dropdown-trigger-for.directive';
import {getOrderedSearchItems} from '../../../../util/search.util';
import {SearchControlComponent} from '../../../form/search/search-control/search-control.component';

@Component({
  selector: 'app-search-autocomplete',
  templateUrl: './search-autocomplete.component.html',
  styleUrls: ['./search-autocomplete.component.scss']
})
export class SearchAutocompleteComponent implements OnInit, OnChanges {
  @ViewChild(SearchControlComponent) searchControlCmp: SearchControlComponent;
  @Input() isNarrow = false;
  @ViewChild(DropdownTriggerForDirective) dropdownDirective: DropdownTriggerForDirective;
  @Input() items: string[] = [];
  public isDropDownOpen = false;

  public displayedItems: string[] = [];
  public searchString = '';

  @Output() valueChanges = new EventEmitter<{value: string, shouldSubmit: boolean}>();
  public valueChangesSubject = new Subject();

  constructor() {
  }

  private static isEventInitiatedByOptionSelect(event: any): boolean {
    return event.relatedTarget?.classList?.contains('option');
  }

  ngOnInit(): void {
    this.updateDisplayedItems();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ((changes.items?.previousValue !== changes.items?.currentValue) || changes.items?.firstChange) {
      this.updateDisplayedItems();
    }
  }

  public onFocusOut(event: any): void {
    if (!SearchAutocompleteComponent.isEventInitiatedByOptionSelect(event)) {
      this.dropdownDirective.destroyDropdown();
      this.emitIfClosedWithEmptyVal();
    }
  }

  private emitIfClosedWithEmptyVal(): void {
    setTimeout(() => {
      if (!this.searchString) {
        this.valueChanges.emit({value: '', shouldSubmit: true});
      }}, 50);
  }

  public onFocusIn(): void {
    this.isDropDownOpen = true;
    this.dropdownDirective.openDropdown();
  }

  public onValueChanged(update: {value: string, shouldSubmit: boolean}): void {
    this.searchString = update.value;
    this.valueChanges.emit({value: update.value, shouldSubmit: update.shouldSubmit});
    this.updateDisplayedItems();
  }

  public updateDisplayedItems(): void {
    this.displayedItems = getOrderedSearchItems(this.items, this.searchString);
  }

  public onItemClicked(item: string): void {
    this.closeDropdown();
    this.valueChanges.emit({value: item, shouldSubmit: true});
    this.valueChangesSubject.next(item);
  }

  public closeDropdown(): void {
    this.searchControlCmp.focusOutInput();
    this.isDropDownOpen = false;
    this.dropdownDirective.destroyDropdown();
  }
}
