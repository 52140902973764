import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Filter, FilterBasic} from '../../../interface/ui/my-table/filter.model';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {IDateRangeOption} from '../../../interface/ui/form/IBasicSelectOption';
import {FilterHandler} from '../../../interface/ui/my-table/filter-handler.interface';


@Component({
  selector: 'app-filter-date-range',
  templateUrl: './filter-date-range.component.html',
  styleUrl: './filter-date-range.component.scss'
})
export class FilterDateRangeComponent implements FilterHandler, OnInit, OnDestroy {
  @Input({required: true}) filter: FilterBasic;
  @Output() filterChanged: EventEmitter<Filter> = new EventEmitter<Filter>();
  public control: FormControl<IDateRangeOption>;
  public subscription: Subscription[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.control = new FormControl<IDateRangeOption>(null);
    const sub = this.control.valueChanges.subscribe(() => {
      this.filter.selectedItems = this.getSelectedItemsForDateRange();
      this.filterChanged.emit(this.filter);
      this.changeDetectorRef.detectChanges();
    });
    this.subscription.push(sub);
  }

  private getSelectedItemsForDateRange(): IDateRangeOption[] {
    const controlValue = this.control.value;
    return (controlValue && (controlValue.start || controlValue.end)) ?
      [{start: controlValue.start, end: controlValue.end} as IDateRangeOption] : [];
  }

  public getFilter(): Filter {
    return this.filter;
  }

  public clear(): void {
    this.control.setValue(null, {emitEvent: false});
    this.filter.selectedItems = [];
  }

  public ngOnDestroy(): void {
    this.subscription.forEach(sub => sub?.unsubscribe());
  }
}
