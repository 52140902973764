import {NativeDateAdapter} from '@angular/material/core';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}
