import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RequestType} from 'src/app/shared/enum/RequestType';
import {IWaterfall} from 'src/app/waterfall/interface/IWaterfall';
import {IWaterfallRaw} from 'src/app/waterfall/interface/IWaterfallRaw';
import {ApiGeneralService} from '../../shared/service/api/api-general.service';
import {Observable} from 'rxjs';
import {IWaterfallDetails} from '../interface/IWaterfallDetails';
import {WaterfallList} from '../interface/WaterfallList';
import {QueryService} from '../../shared/service/query.service';

@Injectable({
  providedIn: 'root'
})
export class ApiWaterfallService {

  constructor(
    private apiGeneralService: ApiGeneralService,
    private queryService: QueryService,
    private httpClient: HttpClient
  ) {
  }

  public getWaterfallsPaginated(query: any): Observable<WaterfallList> {
    const params = this.queryService.getHttpParams(query);
    const url = `Waterfalls/PaginatedAndFiltered`;

    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
      params
    });
  }

  public removeWaterfall(id: string): Observable<string> {
    const url = `Waterfalls/${id}`;

    return this.apiGeneralService.sendRequest({
      method: RequestType.delete,
      url,
    });
  }

  public createWaterfall(waterfall: IWaterfallRaw): Observable<void> {
    const url = 'Waterfalls';

    return this.apiGeneralService.sendRequest({
      method: RequestType.post,
      url,
      data: waterfall
    });
  }

  public updateWaterfall(waterfall: IWaterfallRaw): Observable<void> {
    const url = 'Waterfalls';

    return this.apiGeneralService.sendRequest({
      method: RequestType.put,
      url,
      data: waterfall
    });
  }

  public getWaterfallsByProductId(productId: string): Observable<IWaterfall[]> {
    const url = `Waterfalls/GetByProduct/${productId}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }

  public getWaterfall(waterfallId: string): Observable<IWaterfallDetails> {
    const url = `Waterfalls/GetByInternalId/${waterfallId}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }

  public getAutocomplete(): Observable<string[]> {
    const url = 'Waterfalls/Names';
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }

  /**
   * Initiates the export of waterfall data to a CSV file by making a request to the server.
   * @returns An observable that emits the CSV data as a string upon a successful response.
   */
  public exportCsv(): Observable<string> {
    const url = this.apiGeneralService.getUrl('Waterfalls/ExportCsv');
    return this.httpClient.get(url, { responseType: 'text' })
  }
}
