import {ChangeDetectorRef, Component, Inject, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {MatCalendar} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {takeUntil} from 'rxjs/operators';
import {faChevronLeft, faChevronRight} from '@fortawesome/pro-light-svg-icons';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss']
})
export class CalendarHeaderComponent<D> implements OnDestroy {
  public faChevronLeft = faChevronLeft;
  public faChevronRight = faChevronRight;

  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, private cdr: ChangeDetectorRef, private datePipe: DatePipe) {
    this._calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => {cdr.markForCheck();});
  }

  public ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public get periodLabel(): string {
    return this.datePipe.transform(this._calendar.activeDate as unknown as Date, 'MMMM YYYY');
  }

  public previousClicked(): void {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
  }

  public nextClicked(): void {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
  }
}
