export enum ActionIconType {
  DELETE = 'delete',
  DELETE_FROM_LIST = 'deleteFromList',
  EDIT = 'edit',
  PREVIEW = 'preview',
  PREVIEW_IN_NEW_TAB = 'previewInNewTab',
  DUPLICATE = 'duplicate',
  REGENERATE = 'regenerate',
  DOWNLOAD = 'download'
}
