import {ErrorHandler} from '@angular/core';
import {useLogRocket} from '../util/logrocket.util';
import LogRocket from 'logrocket';

export class MyErrorHandler implements ErrorHandler {
  handleError(error: Error): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const extendedChunkFailedMessage = /Loading chunk .*failed.*[.js\\)]/;

    if (chunkFailedMessage.test(error.message) || extendedChunkFailedMessage.test(error.message)) {
      window.location.reload();
    }

    console.error(error);
    if (useLogRocket() && !MyErrorHandler.shouldIgnore(error)) {
      LogRocket.captureException(error);
    }
  }
  private static shouldIgnore(error: any): boolean {
    return error?.toString()?.includes('"status":401');
  }
}
