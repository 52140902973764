import {ICampaignFormMsgHandler} from '../interface/ICampaignFormMsgHandler';
import {firstValueFrom} from 'rxjs';
import {IConfirmDialogData} from '../../shared/interface/ui/dialog/IConfirmDialogData';
import {TranslateService} from '@ngx-translate/core';
import {AppInjector} from '../../shared/util/app-injector';
import {DialogRef} from '../../shared/service/dialog/dialog/dialog-ref';
import {ConfirmDialogComponent} from '../../shared/component/common/confirm-dialog/confirm-dialog.component';
import {DialogType} from '../../shared/enum/ui/dialog/Dialog';
import {DialogService} from '../../shared/service/dialog/dialog/dialog.service';

export class CampaignFormMsgHandler implements ICampaignFormMsgHandler {
  private translateService: TranslateService;
  private dialogService: DialogService;

  constructor() {
    this.translateService = AppInjector.get(TranslateService);
    this.dialogService = AppInjector.get(DialogService);
  }

  public async confirmProductChange(oldProductName: string, newProductName: string): Promise<boolean> {
    const data = await this.getProductDialogData(oldProductName, newProductName);
    return await this.openAndGetConfirmation(data);
  }

  private async openAndGetConfirmation(dialogData: IConfirmDialogData): Promise<boolean> {
    const dialogRef: DialogRef = this.dialogService.open(ConfirmDialogComponent, {
      type: DialogType.center,
      data: dialogData
    });
    return new Promise(resolve => {
      dialogRef.afterClosed().subscribe(async (value) => {
        resolve(!!value);
      });
    });
  }

  /* Dialog data */

  private async getProductDialogData(previousProductName: string, newProductName: string): Promise<IConfirmDialogData> {
    return {
      text: await firstValueFrom(this.translateService.get('campaign.campaignForm.productChangeWarning',
        {previousProduct: previousProductName, newProduct: newProductName})),
      acceptButton: {
        name: await firstValueFrom(this.translateService.get('button.ok')),
      },
      closeButton: {
        name: await firstValueFrom(this.translateService.get('button.cancel'))
      }
    };
  }

}
