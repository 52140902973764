<div class="options-wrapper">
<ng-content></ng-content>
</div>
<div *ngIf="showActionButtons"  class="action-buttons">
  <button (click)="cleanClicked.emit()" [disabled]="buttonsDisabled" class="button-narrow" color="primary"
          mat-stroked-button mat-button>
    <fa-icon [icon]="faTrash"></fa-icon>
    {{'button.clear' | translate}}
  </button>
  <button (click)="saveClicked.emit()" [disabled]="buttonsDisabled" class="button-narrow" color="primary"
          mat-flat-button mat-button>
    <fa-icon [icon]="faFloppyDiskCircleArrowRight"></fa-icon>
    {{'button.choose' | translate}}
  </button>
</div>
