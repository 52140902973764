<div class="color-dark-grey waterfall-error-tooltip">
  <div class="list-inner-wrapper">
  <div [ngClass]="{' color-error': data.mode === 'error', 'color-warning-secondary': data.mode === 'warning'}"
    class="general-errors mat-body mat-caption text-left" *ngIf="areGeneralErrors()">
    {{data.errors.join(', ')}}
  </div>

  <ol *ngIf="data.items?.length > 0" class="mat-body mat-caption">
    <li *ngFor="let item of data.items; let i = index"
        [ngClass]="{'color-error': areItemErrors(item) && data.mode === 'error',
         'color-warning-secondary': areItemErrors(item) && data.mode === 'warning',
         'li--error': areItemErrors(item)}">
    <span>
      {{item?.value}}
      <ng-container *ngIf="areItemErrors(item)"> - {{item.errors.join(', ')}}
        <ng-container *ngIf="areItemButtons(item)">
          <ng-container *ngFor="let button of item.buttons">
            <ng-container *ngIf="button.routerLink">
              <a target="_blank" [routerLink]="button.routerLink" [queryParams]="button.queryParams ? button.queryParams : null">
              <ng-container *ngTemplateOutlet="actionButtonForItem;context: {button: button}"></ng-container>
              </a>
            </ng-container>
            <ng-container *ngIf="!button.routerLink">
              <ng-container *ngTemplateOutlet="actionButtonForItem;context: {button: button}"></ng-container>
            </ng-container>
            </ng-container>
    </ng-container>
      </ng-container>
    </span>
    </li>
  </ol>

  <ng-container *ngIf="areGeneralButtons()">
    <hr>
    <div class="align-center-gap-5 flex-wrap end-buttons">
      <ng-container *ngFor="let button of data.buttons">
        <ng-container *ngIf="button.routerLink">
          <a target="_blank" [routerLink]="button.routerLink" [queryParams]="button.queryParams ? button.queryParams : null">
            <ng-container *ngTemplateOutlet="actionButton;context: {button: button}"></ng-container>
          </a>
        </ng-container>
        <ng-container *ngIf="!button.routerLink">
          <ng-container *ngTemplateOutlet="actionButton;context: {button: button}"></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  </div>
</div>


<ng-template let-button="button" #actionButton>
  <button (click)="onButtonClicked($event, button)"
          mat-stroked-button class="button-narrow" color="primary">
    <fa-icon *ngIf="button.icon" [icon]="getButton(button).icon"></fa-icon>
    {{button.name}}
  </button>
</ng-template>


<ng-template let-button="button" #actionButtonForItem>
  <div style="margin-top: 5px;">
  <button class="cursor-pointer button-narrow" mat-stroked-button color="primary"
          (click)="onButtonClicked($event, button)">
  <fa-icon [icon]="getButton(button).icon">
  </fa-icon>
    {{getButton(button).name}}
  </button>
  </div>
</ng-template>
