<div>
    <div #searchControl [dropdownTriggerFor]="dropdownContent" [hasBackdrop]="false" [preventClick]="true"
         [preventClose]="true" [offsetY]="5">
      <app-search-control [isNarrow]="isNarrow" [valueChangesObservable]="valueChangesSubject.asObservable()"
                          (valueChanges)="onValueChanged($event)"
                          (focusIn)="onFocusIn()" (focusOut)="onFocusOut($event)">
      </app-search-control>
    </div>


    <app-dropdown [show]="isDropDownOpen && displayedItems.length > 0" [width]="searchControl.offsetWidth"
                  [showDropdownArrow]="false" #dropdownContent>
      <div class="dropdown-content" *ngIf="isDropDownOpen && displayedItems.length > 0">
        <div class="options-wrapper">
          <div tabindex="-1" (click)="$event.stopPropagation();onItemClicked(item)" class="mat-caption option"
               *ngFor="let item of displayedItems">
            {{item}}
          </div>
        </div>
      </div>
    </app-dropdown>
</div>
