import {Injectable} from '@angular/core';
import {ApiWaterfallCsvService} from '../api-waterfall-csv.service';
import {IWaterfallCSV} from '../../interface/IWaterfallCSV';
import {WaterfallService} from '../waterfall.service';
import {ProductsService} from '../../../product/service/product/products.service';
import {ApiErrorService} from '../../../shared/service/api/api-error.service';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WaterfallCsvService {

  constructor(private apiCsvUploadService: ApiWaterfallCsvService, private waterfallService: WaterfallService,
              private productService: ProductsService,
              private apiErrorService: ApiErrorService) {
  }

  public async uploadCsv(waterfalls: IWaterfallCSV[]): Promise<void> {
    try {
      await firstValueFrom(this.apiCsvUploadService.uploadCsv(waterfalls));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'waterfallImport.waterfallImportCreationErrorMessage');
      throw error;
    }

  }

  public async waterfallsDeleteCsv(file: File): Promise<void> {
    try {
      await firstValueFrom(this.apiCsvUploadService.waterfallsDeleteCsv(file));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'waterfall.waterfallDeleteCsv.deleteError');
      throw error;
    }

  }

  public async validateCsv(file: File): Promise<IWaterfallCSV[]> {
    try {
      return await firstValueFrom(this.apiCsvUploadService.validateCsv(file));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error,'import.fileUploadFailed');
      throw error;
    }
  }
}
