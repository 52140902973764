<div class="color-dark-grey tooltip-panel">
  <div class="list-inner-wrapper">
    <ng-container *ngFor="let section of data.sections" class="mat-body mat-caption">
      <ng-container *ngIf="section.items.length > 0">
        <div class="section-header title">{{ section.name }}</div>
        <div class="detail" *ngFor="let detail of section.items; let i = index">
          {{ i + 1 }}. {{ detail }}
        </div>
      </ng-container>
      <ng-container *ngIf="section.items.length === 0">
        <div class="section-header"><span class="title">{{ section.name }}</span>-</div>
      </ng-container>
    </ng-container>
  </div>
</div>
