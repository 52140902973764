import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {faAngleDown, faAngleUp, faCheck, faFloppyDiskCircleArrowRight, faTrash} from '@fortawesome/pro-light-svg-icons';
import * as _ from 'lodash';
import {IBasicSelectOption} from '../../../interface/ui/form/IBasicSelectOption';
import {DropdownComponent} from '../../common/dropdown/dropdown.component';
import {hasAnyError} from '../../../util/form/form-utils';
import {Subject} from 'rxjs';
import {getOrderedSearchItemsByLabel, includesSearchStringOrKeywords} from '../../../util/search.util';

@Component({
  selector: 'app-multi-select-input',
  templateUrl: './multi-select-input.component.html',
  styleUrls: ['./multi-select-input.component.scss']
})
export class MultiSelectInputComponent implements OnInit, ControlValueAccessor {
  @ViewChild('multiItemsContainer') multiItemsSelectedContainer: ElementRef;
  @ViewChild('dropdown') dropdownComponent: DropdownComponent;

  @Input() label: string;
  @Input() items: IBasicSelectOption[] = [];
  @Input() isTableFilter = false;

  @Input() withAutocomplete = false;
  public displayedItemsCount = 3;
  public searchValue = '';
  public displayedItems: IBasicSelectOption[];

  public isDropdownOpen = false;
  public dropdownOpenedSubject = new Subject<void>();
  public selectedItems: IBasicSelectOption[] = [];

  public faCheck = faCheck;
  public faFloppyDiskCircleArrowRight = faFloppyDiskCircleArrowRight;
  public faTrash = faTrash;
  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;

  public hasAnyError = hasAnyError;

  public onChange: any = () => {
  }
  public onTouched: any = () => {
  }


  constructor(public control: NgControl, private changeDetectorRef: ChangeDetectorRef) {
    this.control.valueAccessor = this;
  }

  ngOnInit(): void {
    if (this.withAutocomplete) {
      this.updateDisplayedItems();
    }
  }

  onItemClicked(item: IBasicSelectOption): void {
    if (this.containsItemWithName(item.name)) {
      this.unselectItem(item);
    } else {
      this.selectItem(item);
    }

    if (this.withAutocomplete) {
      this.updateDisplayedItems();
    }
  }

  private selectItem(item: IBasicSelectOption): void {
    this.selectedItems.push(item);
    this.changeDetectorRef.detectChanges();
    this.scrollDownSelectedItemsContainer();
  }

  private unselectItem(item: IBasicSelectOption): void {
    this.selectedItems = this.selectedItems.filter(el => el.name !== item.name);
  }

  public containsItemWithName(name: string): boolean {
    return this.selectedItems.some(item => item.name === name);
  }

  public onSaveClicked(): void {
    this.onChange(_.clone(this.selectedItems));
    this.onTouched();
    this.dropdownComponent.closed.emit();
  }

  public onCleanClicked(): void {
    this.selectedItems = [];
    this.onChange([]);
    this.onTouched();
    this.dropdownComponent.closed.emit();
  }

  public onDropdownToggled(isOpen: boolean): void {
    if (isOpen) {
      this.dropdownOpenedSubject.next();
    } else {
      this.handleDropdownClose();
    }
    this.isDropdownOpen = isOpen;
    this.updateDisplayedItems();
    this.changeDetectorRef.detectChanges();
  }

  private handleDropdownClose(): void {
    if(this.isClosedByBackdropClick()) {
      this.onChange(_.clone(this.selectedItems));
    }
  }

  private isClosedByBackdropClick(): boolean {
    return this.isArrayDiffByName(this.control.value, this.selectedItems);
  }

  private isArrayDiffByName(array1: IBasicSelectOption[], array2: IBasicSelectOption[]): boolean {
    const diff1 = array1.some(item1 => !array2.some(item2 => item2.name === item1.name));
    const diff2 = array2.some(item2 => !array1.some(item1 => item1.name === item2.name));
    return diff1 || diff2;
  }

  public getControlValue(): string {
    return this.control.value.map(el => el.label).join(', ');
  }

  public isControlGreyedOut(): boolean {
    return !this.isTableFilter && !this.getControlValue()?.length && !this.isDropdownOpen;
  }

  private scrollDownSelectedItemsContainer(): void {
    if (this.multiItemsSelectedContainer?.nativeElement) {
      this.multiItemsSelectedContainer.nativeElement.scrollTop = this.multiItemsSelectedContainer.nativeElement.scrollHeight;
    }
  }

  /* Autocomplete */
  public updateDisplayedItems(): void {
    const unselectedItems = this.items.filter(item => !this.containsItemWithName(item.name));
    this.displayedItems = getOrderedSearchItemsByLabel(unselectedItems, this.searchValue);
  }

  public async onSearchValueChanges(value: string): Promise<void> {
    this.searchValue = value;
    this.updateDisplayedItems();
  }

  public shouldShowNoResultsError(): boolean {
    return this.displayedItems.length === 0 &&
      !this.selectedItems.some(item => includesSearchStringOrKeywords(item, this.searchValue));
  }

  /* Tooltip*/
  public isEllipsisActive(e: HTMLElement): boolean {
    return e ? (e.offsetWidth < e.scrollWidth) : false;
  }

  /* ControlValueAccessor */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: any): void {
    this.selectedItems = _.clone(obj) || [];
  }
}
