/**
 * Enumerates the possible names for custom icons used in the application.
 * Each enum value corresponds to a specific type of custom icon.
 */
export enum CustomIconName {
  IOS = 'ios',
  ANDROID = 'android',
  SAMSUNG = 'samsung',
  WEB = 'web',
  HUAWEI = 'huawei',
  AMAZON = 'amazon',
  REWARDED= 'rewarded',
  INTERSTITIAL = 'interstitial',
  BANNER = 'banner',
  EMBEDDED_SQUARE = 'embedded_square',
  EMBEDDED = 'embedded',
  TOGGLE_DISABLED = 'toggle_disabled',
  MORE_GAMES = 'moreGames',
  AD_UNIT_DEFAULT = 'adUnitDefault'
}
