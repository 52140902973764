import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {IDropdownPanel} from '../../../interface/ui/form/select/IDropdownPanel';
import {Placement} from '../../../enum/ui/tooltip/Placement';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit, IDropdownPanel {
  @ViewChild('dropdownContainer') dropdownContainer: ElementRef;

  @Input() showDropdownArrow = true;
  @Input() width = null;
  @Input() show = true;
  @Input() placement = Placement.BOTTOM;

  public Placement = Placement;

  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;
  @Output() closed = new EventEmitter<void>();
  @Output() resized = new EventEmitter<void>();

  private resizeObserver: ResizeObserver;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.initResizeObserver();
  }

  private initResizeObserver(): void {
    this.resizeObserver = new ResizeObserver(() => {
      this.resized.emit();
    });
  }

  public onOpen(): void {
    this.changeDetectorRef.detectChanges();
    if (this.dropdownContainer?.nativeElement) {
      this.resizeObserver.disconnect();
      this.observeSize();
    }
  }

  private observeSize(): void {
    this.resizeObserver.observe(this.dropdownContainer?.nativeElement);
  }
}
