import { Injectable } from '@angular/core';
import { Observable, ReplaySubject} from 'rxjs';
import { ILoadingEndpoints} from '../../interface/ui/loading/ILoading';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _isLoading$ = new ReplaySubject<boolean>(1);
  private _loadingEndpoints$ = new ReplaySubject<ILoadingEndpoints>(10, 2000);

  private loadingEndpoints: { [key: string]: boolean } = {};

  constructor() { }

  public isLoadingObs(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  public loadingEndpointsObs(): Observable<ILoadingEndpoints> {
    return this._loadingEndpoints$.asObservable();
  }

  public setIsLoading(isLoading: boolean): void {
    this._isLoading$.next(isLoading);
  }

  public setIsLoadingEndpoint(isLoading: boolean, endpoint: string): void {
    if (!isLoading) {
      delete this.loadingEndpoints[endpoint];
    } else {
      this.loadingEndpoints[endpoint] = isLoading;
    }
    this._loadingEndpoints$.next(this.loadingEndpoints);
  }

  public areEndpointsLoading(endpointPartial: string[], loadingEndpoints: ILoadingEndpoints): boolean {
    for (const [key, value] of Object.entries(loadingEndpoints)) {
      if (endpointPartial.some(partial => key.includes(partial)) && value === true) {
        return true;
      }
    }
    return false;
  }

  public anyEndpointLoading(loadingEndpoints: ILoadingEndpoints): boolean {
    return !_.isEmpty(loadingEndpoints);
  }
}
