import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import {ConnectedPosition} from '@angular/cdk/overlay';
import {Subscription} from 'rxjs';
import {Filter, FilterBasic} from '../../../interface/ui/my-table/filter.model';
import {FilterHandler} from '../../../interface/ui/my-table/filter-handler.interface';
import {SelectOption} from '../../../interface/ui/my-table/select-option';
import {ITableFilterInput} from '../../../interface/ui/table/ITableFilterInput';
import {FilterType} from '../../../enum/ui/table/FilterType';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss']
})
export class FilterSelectComponent implements FilterHandler, OnInit, OnDestroy {
  @Input({required: true}) filter: FilterBasic;
  @Output() filterChanged: EventEmitter<Filter> = new EventEmitter<Filter>();
  public control: FormControl<SelectOption[]>;
  public faAngleDown = faAngleDown;
  public position: ConnectedPosition = {originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'top'};
  public subscription: Subscription[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }


  public ngOnInit(): void {
    this.control = new FormControl<SelectOption[]>(this.filter?.selectedItems || []);
    const sub = this.control.valueChanges.subscribe((value: SelectOption[]) => {
      this.filter.selectedItems = value;
      this.filterChanged.emit(this.filter);
      this.changeDetectorRef.detectChanges();
    });
    this.subscription.push(sub);
  }

  public getFilter(): Filter {
    return this.filter;
  }

  public clear(): void {
    this.control.setValue([], {emitEvent: false});
    this.filter.selectedItems = [];
  }

  public get selectedFilters(): string {
    return this.control?.value?.map((el: SelectOption) => el.label)?.join(', ');
  }

  public get areFiltersSelected(): boolean {
    return this.control?.value?.length > 0;
  }

  public ngOnDestroy(): void {
    this.subscription.forEach(sub => sub?.unsubscribe());
  }

  public isMultiSelectFilter(filter: ITableFilterInput): boolean {
    return !(this.isDateFilter(filter) || this.isCheckboxFilter(filter));
  }

  public isDateFilter(filter: ITableFilterInput): boolean {
    return filter.isDateRange || filter.isDate;
  }

  public isCheckboxFilter(filter: ITableFilterInput): boolean {
    return filter.type === FilterType.CHECKBOX;
  }
}


