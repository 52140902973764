import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiGeneralService} from '../../shared/service/api/api-general.service';
import { Observable} from 'rxjs';
import {IWaterfallCSV} from '../interface/IWaterfallCSV';
import {RequestType} from '../../shared/enum/RequestType';

@Injectable({
  providedIn: 'root'
})
export class ApiWaterfallCsvService {

  constructor(private httpClient: HttpClient, private apiGeneralService: ApiGeneralService) { }

  public uploadCsv(waterfalls: IWaterfallCSV[]): Observable<void> {
    const url = `CSV/Import`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.post,
      url,
      data: waterfalls
    });
  }

  public validateCsv(file: File): Observable<IWaterfallCSV[]> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<any>(this.apiGeneralService.getUrl('CSV/Validate'), formData);
  }

  public waterfallsDeleteCsv(file: File): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<any>(this.apiGeneralService.getUrl('CSV/DeleteWaterfalls'), formData);
  }
}
