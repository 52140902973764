import { getData, getName} from 'country-list';
import {IBasicSelectOption} from '../../interface/ui/form/IBasicSelectOption';
import countries2to3 from 'countries-list/dist/countries2to3.json';

const GLOBAL = 'Global';

export function getDataCustom(): {name: string, code: string}[] {
  const data: {name: string, code: string}[] = getData();
  return data.concat({name: GLOBAL, code: GLOBAL});
}

export function getNameCustom(code: string): string {
  return !!getName(code) ? getName(code) : code;
}

export function getRegionsSelect(): IBasicSelectOption[] {
  const countries: {name: string, code: string}[] = getDataCustom();
  return countries.map(country => ({
    name: country.code,
    label: getRegionNameShort(country.name),
    keywords: getCountryCodes(country.code)
  }));
}

function getCountryCodes(code2: string): string[] {
  if (code2 === 'GB') {
    return [code2, countries2to3[code2], 'UK'];
  }
  if (countries2to3[code2]) {
    return [code2, countries2to3[code2]];
  }
  return [code2];
}

export function mapRegionsToSelect(regions: string[]): IBasicSelectOption[] {
  return regions.map((region) => ({
    name: region,
    label: getRegionNameShort(getNameCustom(region)),
    keywords: getCountryCodes(region)
  }));
}

export function getRegionNameShort(name: string): string {
  return name?.split(',')[0];
}

export function getRegionsNameShort(codes: string[]): string[] {
  return codes.map(code => getRegionNameShort(getNameCustom(code)));
}
