<app-base-side-dialog [backAction]="backAction" [showBackButton]="!!backAction?.backActionDialogConfig" [dialogRef]="dialogRef">
  <app-spinner *ngIf="isLoading"></app-spinner>

  <div class="preview-content" *ngIf="product">
    <div class="preview-header text-light side-dialog--padding-horizontal">
      <p class="mat-body-2 color-dark-grey-fourth">{{'product.productPreview.productPreview' | translate}}</p>

      <div class="side-dialog-header-row">
        <h1>{{product.name}}</h1>
        <p>{{'product.productForm.productId' | translate}}: {{product.boombitId}}</p>

        <ng-container *ngIf="product?.adUnitTypes?.length">
          <div class="align-center-gap-10">
          <p>{{'adUnit.adUnit' | translate}}:</p>
            <app-ad-unit-icons [adUnitTypes]="product.adUnitTypes"></app-ad-unit-icons>
          </div>
        </ng-container>
        <ng-container *ngIf="!product?.adUnitTypes?.length">
          <p>{{'adUnit.adUnit' | translate}}: {{'creative.noCreatives' | translate}}</p>
        </ng-container>
        <div class="align-center-gap-10">
          {{'product.screenOrientation' | translate}}: {{product.screenOrientation || '-'}}
        </div>
      </div>

      <div class="side-dialog-header-row">
        <button (click)="onCreativesPreviewClicked()"
                class="button-narrow" mat-stroked-button color="primary">
          <fa-icon [icon]="faEye"></fa-icon>
          {{'product.productPreview.creativesPreviewButton' | translate}}
        </button>
      </div>
    </div>
    <hr class="separator separator--no-margin"/>
    <div class="preview-body--scrollable text-light side-dialog--padding-horizontal">
      <div *ngFor="let platform of product.platforms" class="preview-row--separated">
        <p class="align-center-gap-10">
          <app-custom-icon *ngIf="!isPlatformWeb(platform.type)"
                           [name]="getCustomIconName(platform.type)" [height]="21">
          </app-custom-icon>
          <fa-icon *ngIf="isPlatformWeb(platform.type)" class="display-icon" [icon]="faDisplay"></fa-icon>

          <span>{{'platform.platformType' | translate}}:
           <span class="mat-body-1">{{'platformType.' + platform.type | translate}}</span>
          </span>
        </p>
        <p>
          {{'product.enabledStatus' | translate}}:&nbsp;{{!platform.disabled ? ('basic.yes' | translate) : ('basic.no' | translate)}}
        </p>
        <p class="align-center-gap-5">
          {{isBundleId(platform) ? ('product.productForm.bundleId' | translate) : ('product.productForm.storeIdentifier' | translate)}}:&nbsp;
          {{platform.platformId || '-'}}
          <a class="cursor-pointer" *ngIf="linkToStoreSupported(platform)"
             [href]="getStoreLink(platform.type, platform.platformId)" target="_blank">
            <fa-icon [appIconTooltip]="faArrowUpRightFromSquare" [icon]="faArrowUpRightFromSquare" class="color-primary p-5"></fa-icon>
          </a>
        </p>

        <p *ngIf="hasExecutionPoint(platform, ExecutionPointType.GOOGLE_PLAY) && getExecutionPoint(platform, ExecutionPointType.GOOGLE_PLAY).utmCampaign">
          {{'product.productForm.utmCampaign' | translate}}:&nbsp;{{getExecutionPoint(platform, ExecutionPointType.GOOGLE_PLAY).utmCampaign}}</p>

        <ng-container *ngIf="hasExecutionPoint(platform, ExecutionPointType.APP_STORE)">
        <p>
          {{'product.productForm.productPageIds' | translate}}:
          <span *ngIf="this.isProductPageIdListVisible(platform)">
          <ng-container *ngFor="let pageId of getExecutionPoint(platform, ExecutionPointType.APP_STORE).productPageIds">
            <span class="align-center-gap-5">{{pageId}}
          <a class="cursor-pointer" *ngIf="platform.platformId" [href]="getStoreLink(platform.type, platform.platformId, pageId)" target="_blank">
            <fa-icon [appIconTooltip]="faArrowUpRightFromSquare" [icon]="faArrowUpRightFromSquare" class="color-primary p-5"></fa-icon>
          </a>
            </span>
          </ng-container>
          </span>
          <span *ngIf="!this.isProductPageIdListVisible(platform)">
            -
          </span>
        </p>
        <p>
          <span *ngIf="getExecutionPoint(platform, ExecutionPointType.APP_STORE).useDefaultProductPage">
            {{'product.productForm.useDefaultPage' | translate}}: {{'basic.yes' | translate}}
          </span>
          <span *ngIf="!getExecutionPoint(platform, ExecutionPointType.APP_STORE).useDefaultProductPage">
            {{'product.productForm.useDefaultPage' | translate}}: {{'basic.no' | translate}}
          </span>
        </p>
        </ng-container>

        <p class="align-center-gap-5" *ngIf="hasUrl(platform)">
          {{'product.productForm.url' | translate}}:&nbsp;{{getUrl(platform)}}
          <a *ngIf="urlStartsWithHttps(platform)" class="cursor-pointer" [href]="getUrl(platform)" target="_blank">
            <fa-icon [appIconTooltip]="faArrowUpRightFromSquare" [icon]="faArrowUpRightFromSquare" class="color-primary p-5"></fa-icon>
          </a>
        </p>
        <p>
          {{'product.externalTracking' | translate}}:&nbsp;{{getTracking(platform)}}
        </p>
        <p>
          {{'product.productForm.optimizedQueue' | translate}}:&nbsp;{{
          platform.useOptimizedQueue ? ('basic.yes' | translate) : ('basic.no' | translate)}}
        </p>
        <p *ngFor="let tracking of platform.externalTrackings">
          {{tracking.name}}&nbsp;{{tracking.trackingType}}: {{tracking.url}}
        </p>
        <hr>
      </div>

      <div class="side-dialog-action-buttons">
        <button (click)="onCloseClicked()" mat-stroked-button color="primary">
          <fa-icon [icon]="faXMark" class="close-icon-big"></fa-icon>
          {{'button.close' | translate}}
        </button>
        <button (click)="onDeleteProductClicked()" mat-flat-button color="primary">
          <fa-icon [icon]="faTrash"></fa-icon>
          {{'product.deleteProductButton' | translate}}
        </button>
        <button (click)="onEditProductClicked()" mat-flat-button color="primary">
          <fa-icon [icon]="faPenToSquare"></fa-icon>
          {{'product.editProductButton' | translate}}
        </button>
      </div>
    </div>
  </div>
</app-base-side-dialog>
