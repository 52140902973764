import {Injectable} from '@angular/core';
import {Sort} from '../../interface/ui/my-table/sort.model';
import {Header} from '../../interface/ui/my-table/header.model';
import {SortDirection} from '../../enum/ui/table/SortDirection';
import {HeaderEvent} from '../../interface/ui/my-table/header-event.model';

@Injectable({
  providedIn: 'root'
})
export class SortService {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getSort(currentSort: Sort<any>, event: HeaderEvent): Sort<any> {
    const headerName = event.header.name;
    if (currentSort?.name !== headerName) {
      return {name: headerName, direction: SortDirection.ascending};
    } else if (currentSort.name === headerName && currentSort.direction === SortDirection.ascending) {
      return {name: currentSort.name, direction: SortDirection.descending};
    } else if (currentSort.name === headerName && currentSort.direction === SortDirection.descending) {
      return null;
    }
    return currentSort;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updateSortHeaders(headers: Header<any>[], sort: Sort<any>): void {
    headers.forEach(header => {
      header.isSelected = header.name === sort?.name;
      header.direction = header.isSelected ? sort.direction : SortDirection.ascending;
    });
  }
}
