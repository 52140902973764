import {Injectable} from '@angular/core';
import {ImageFormats} from 'src/app/shared/enum/ImageFormats';
import {VideoFormats} from 'src/app/shared/enum/VideoFormats';
import {IUserExif} from 'src/app/asset/interface/IUserExif';
import {IVideoMetaData} from 'src/app/asset/interface/IVideoMetaData';
import {FileType} from '../../enum/FileType';
import {ImageDataService} from './image-data.service';
import {VideoDataService} from './video-data.service';
import {FileFormatSimple} from '../../../asset/type/FileFormatSimple';
import {FileFormatType} from '../../../asset/type/FileFormatType';
import {ImageFormatSimple} from '../../../asset/enum/ImageFormatSimple';

@Injectable({
  providedIn: 'root'
})
export class MetaDataService {

  constructor(private imageDataService: ImageDataService, private videoDataService: VideoDataService) { }

  public async getUserExif(file: File): Promise<IUserExif> {
    const userExif: IUserExif =  {
      name: file.name,
      size: file.size,
      type: file.type
    };

    if (this.getFileType(file) === FileType.image) {
      userExif.resolution = await this.imageDataService.getImageResolution(file);
    }

    if (this.getFileType(file) === FileType.video) {
      const videoMetaData: IVideoMetaData = await this.videoDataService.getVideoDuration(file);
      userExif.resolution = { width: videoMetaData.width, height: videoMetaData.height };
      userExif.duration = videoMetaData.duration;
    }

    return userExif;
  }

  public getSrcStringFromImage(file: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileSrc = reader.result as string;
        resolve(fileSrc);
      };
    });
  }

  public getTextContentFromFile(file: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const fileText = reader.result as string;
        resolve(fileText);
      };
    });
  }

  public isFileValid(file: File): boolean {
    return this.getFileType(file) !== FileType.invalid;
  }

  public isFileImage(fileType: string): boolean {
    const imageMimeTypes = Object.values(ImageFormats);
    return imageMimeTypes.includes(fileType as ImageFormats);
  }

  public isFileVideo(fileType: string): boolean {
    const videMimeTypes = Object.values(VideoFormats);
    return videMimeTypes.includes(fileType as VideoFormats);
  }

  public isFileCsv(file: File): boolean {
    return file?.type === 'text/csv';
  }

  public isEnum(value: string, enumType: {[key: string]: string}): boolean {
    const types = Object.values(enumType);
    return types.includes(value);
  }

  public getFileType(file: File): FileType {
    const fileType = file.type as any;
    if (this.isFileImage(fileType)) {
      return FileType.image;
    } else if (this.isFileVideo(fileType)) {
      return FileType.video;
    } else {
      return FileType.invalid;
    }
  }

  public getFileFormatSimple(type: FileFormatType): FileFormatSimple {
    if (type === ImageFormats.jpeg || type === ImageFormats.jpg) {
      return ImageFormatSimple.jpg;
    } else {
      return type?.split('/')[1] as FileFormatSimple;
    }
  }

}
