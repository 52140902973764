import {Component, Inject} from '@angular/core';
import {faChartWaterfall, faCircleCheck, faPaintbrushPencil, faXmark} from '@fortawesome/pro-light-svg-icons';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {DIALOG_DATA} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {Router} from '@angular/router';

@Component({
  selector: 'app-product-created-dialog',
  templateUrl: './product-created-dialog.component.html',
  styleUrls: ['./product-created-dialog.component.scss']
})
export class ProductCreatedDialogComponent {
  faCircleCheck = faCircleCheck;
  faXMark = faXmark;
  faPaintBrushPencil = faPaintbrushPencil;
  faWaterfall = faChartWaterfall;

  constructor(public dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: IProductCreatedDialogData, private router: Router) {
  }

  public onCloseClicked(): void {
    this.dialogRef.close();
  }

  public onUploadCreativeClicked(): void {
    this.router.navigate(['/creative/add']);
    this.dialogRef.close();
  }

  public onDefineNewWaterfallClicked(): void {
    this.router.navigate(['/waterfall/add']);
    this.dialogRef.close();
  }
}

export interface IProductCreatedDialogData {
  forCreativesList: boolean;
}
