import { OverlayRef } from '@angular/cdk/overlay';
import { Subject, Observable } from 'rxjs';
import {setBackdropOnlyForOverlayOnTop} from '../../../util/dialog/dialog-utils';
import {IDialogConfig} from '../../../interface/ui/dialog/IDialogConfig';
import {DialogGeneralService} from '../dialog-general/dialog-general.service';

export class DialogRef {
  private afterClosedSubject: Subject<any> = new Subject<any>();

  constructor(private overlayRef: OverlayRef, private dialogGeneralService: DialogGeneralService, private config: IDialogConfig) {
    this.overlayRef.backdropClick().subscribe(() => this.close());
  }

  public close(result?: any, backActionActive: boolean = false): void {
    const parentEl = this.overlayRef.hostElement.parentElement;
    this.overlayRef.dispose();
    setBackdropOnlyForOverlayOnTop(parentEl);
    this.afterClosedSubject.next(result);
    this.afterClosedSubject.complete();
    if (this.config.backActionDialog && backActionActive) {
      this.dialogGeneralService.open(this.config.backActionDialog);
    }
  }

  public afterClosed(): Observable<any> {
    return this.afterClosedSubject.asObservable();
  }
}
