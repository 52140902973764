import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {hasError} from '../../../shared/util/form/form-utils';
import {
  faArrowRightArrowLeft,
  faArrowUpRightFromSquare,
  faFile,
  faTrash,
  faXmark
} from '@fortawesome/pro-light-svg-icons';
import {DialogService} from '../../../shared/service/dialog/dialog/dialog.service';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {DIALOG_DATA} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {IconOriginType} from '../../../shared/enum/ui/icons/IconOriginType';
import {Status} from '../../../shared/enum/Status';
import {UploadDialogResult} from '../../interface/UploadDialogResult';

@Component({
  selector: 'app-zip-upload-dialog',
  templateUrl: './zip-upload-dialog.component.html',
  styleUrl: './zip-upload-dialog.component.scss'
})
export class ZipUploadDialogComponent {
  @ViewChild('fileDropRef') fileElementRef: ElementRef;
  public filesInfo: { file: File, sizeValid: boolean, typeValid: boolean }[] = [];
  public readonly maxFileSize = 1073741824; // 1GB

  IconOriginType = IconOriginType;
  public faTrash = faTrash;

  public hasError = hasError;
  protected readonly faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  protected readonly faArrowRightArrowLeft = faArrowRightArrowLeft;
  protected readonly Status = Status;
  protected readonly faXmark = faXmark;
  protected readonly faFile = faFile;

  constructor(public dialog: DialogService, public dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: {
    name: string
  }) {
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onConfirm(): void {
    const res: UploadDialogResult = {files: this.filesInfo.map(fileInfo => fileInfo.file)};
    this.dialogRef.close(res);
  }

  public isNextBtnDisabled(): boolean {
    return this.filesInfo.length === 0 || !this.filesValid();
  }

  public onFileDropped(files: FileList): void {
    if (files) {
      this.handleFilesSelected(files);
    }
  }

  public onFileSelected(event: any): void {
    if (event.target.files) {
      this.handleFilesSelected(event.target.files);

    }
  }

  private handleFilesSelected(files: FileList): void {
    Array.from(files).forEach((file: File) => {
      this.filesInfo.push({
        file,
        sizeValid: file.size <= this.maxFileSize,
        typeValid: file.name.endsWith('.zip')
      });
    });
    this.fileElementRef.nativeElement.value = '';
  }

  private filesValid(): boolean {
    return this.filesInfo.every(fileInfo => fileInfo.sizeValid && fileInfo.typeValid);
  }

  public onRemoveFile(idx: number): void {
    this.filesInfo.splice(idx, 1);
  }
}
