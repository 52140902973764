import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {every, isObject} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getHttpParams(query: { [key: string]: any }): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(query).forEach(key => httpParams = this.updateHttpParams(key, query[key], httpParams));
    return httpParams;
  }

  private updateHttpParams(key: string, value: any, httpParams: HttpParams): HttpParams {
    if (this.isArrayOfPrimitives(value)) {
      return this.handlePrimitivesArray(key, value, httpParams);
    } else if (this.isArrayOfObjects(value)) {
      return this.handleObjectsArray(key, value, httpParams);
    } else if (this.isQueryParamNotEmpty(value)) {
      return httpParams.set(key, value);
    }
    return httpParams;
  }

  private isArrayOfPrimitives(value: any): boolean {
    return Array.isArray(value) && !this.isArrayOfObjects(value);
  }

  private isArrayOfObjects(value: any): boolean {
    if (!Array.isArray(value)) {
      return false;
    }
    return every(value, isObject);
  }

  private handlePrimitivesArray(key: string, value: any[], httpParams: HttpParams): HttpParams {
    value.forEach((element: any) => {
      httpParams = httpParams.append(key, element);
    });
    return httpParams;
  }

  private handleObjectsArray(keyPrefix: string, value: any[], httpParams: HttpParams): HttpParams {
    value.forEach((element: any, idx: number) => {
      Object.keys(element).forEach(key => {
        const paramKey = `${keyPrefix}[${idx}].${key}`;
        httpParams = httpParams.set(paramKey, element[key]);
      });
    });
    return httpParams;
  }

  private isQueryParamNotEmpty(value: any): boolean {
    return value !== null && value !== undefined;
  }
}
