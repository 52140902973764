import {Component, Inject} from '@angular/core';
import {DialogService} from '../../../shared/service/dialog/dialog/dialog.service';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {IconOriginType} from 'src/app/shared/enum/ui/icons/IconOriginType';
import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {DIALOG_DATA} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {FormControl, ValidationErrors} from '@angular/forms';
import {hasError} from '../../../shared/util/form/form-utils';

@Component({
  selector: 'app-delete-product-dialog',
  templateUrl: './delete-product-dialog.component.html',
  styleUrls: ['./delete-product-dialog.component.scss']
})
export class DeleteProductDialogComponent {
  IconOriginType = IconOriginType;
  public faTrash = faTrash;
  public nameControl = new FormControl<string>('', [this.nameCorrect.bind(this)]);

  public hasError = hasError;

  constructor(public dialog: DialogService, public dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: { name: string }) {
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onConfirm(): void {
    if (this.nameControl.valid) {
      this.dialogRef.close(true);
    }
  }

  public nameCorrect(control: FormControl<string>): ValidationErrors | null {
    return control.value === this.data.name ? null : {nameNotCorrect: true};
  }

}
