import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  Output, SimpleChanges, Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Subscription} from 'rxjs';
import {CellEvent} from '../../../interface/ui/my-table/cell-event.model';
import {CellHandler} from '../../../interface/ui/my-table/cell-handler.interface';
import {Cell} from '../../../interface/ui/my-table/cell.model';
import {CellBasicComponent} from '../cell-basic/cell-basic.component';

@Component({
  selector: 'app-cell',
  templateUrl: './cell.component.html',
  styleUrls: ['./cell.component.scss']
})
export class CellComponent implements AfterViewInit, OnDestroy, CellHandler, OnChanges {
  @Input() public cell: Cell;
  @Output() public cellEvent: EventEmitter<CellEvent> = new EventEmitter<CellEvent>();
  @ViewChild('cell', {read: ViewContainerRef}) container: ViewContainerRef;
  private sub: Subscription;
  private componentRef: ComponentRef<CellHandler>;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }


  public ngAfterViewInit(): void {
    this.cell?.component ? this.createComponent(this.cell.component) : this.createComponent(CellBasicComponent);
    this.changeDetectorRef.detectChanges();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.shouldDetectChangesInChildComponent(changes)) {
      this.componentRef.instance.cell = this.cell;
    }
  }

  private shouldDetectChangesInChildComponent(changes: SimpleChanges): boolean {
    return this.componentRef && changes.cell && !changes.cell.firstChange && changes.cell.currentValue !== changes.cell.previousValue;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createComponent(type: Type<any>): void {
    this.container.clear();
    const componentRef: ComponentRef<CellHandler> = this.container.createComponent(type);
    componentRef.instance.cell = this.cell;
    this.sub = componentRef.instance.cellEvent?.subscribe((event) => this.cellEvent.emit(event));
    this.componentRef = componentRef;
  }

  public ngOnDestroy(): void {
    this.container.clear();
    this.sub?.unsubscribe();
  }
}
