import {Injectable} from '@angular/core';
import {IWaterfall} from '../../interface/IWaterfall';
import {WaterfallTableRowType} from '../../type/WaterfallTableRowType';
import {getRegionsNameShort} from '../../../shared/util/region/region.util';
import {AdUnitMapperService} from '../../../ad-unit/service/ad-unit-mapper.service';
import {WaterfallDialogService} from '../waterfall-dialog/waterfall-dialog.service';
import {ProductDialogService} from '../../../product/service/product-dialog/product-dialog.service';
import {PlatformMapperService} from '../../../product/service/platform-mapper/platform-mapper.service';
import {PlatformType} from '../../../product/enum/PlatformType';
import {Placement} from '../../../shared/enum/ui/tooltip/Placement';
import {TableCellService} from '../../../shared/service/table-cell/table-cell.service';
import {CellBasic} from '../../../shared/interface/ui/my-table/cell.model';
import {ActionsCellComponent} from '../../../shared/component/common/actions-cell/actions-cell.component';
import {TableAction} from '../../../shared/enum/TableAction';
import {faEye, faPenToSquare, faTrash} from '@fortawesome/pro-light-svg-icons';
import {WaterfallRow} from '../../interface/WaterfallRow';

@Injectable({
  providedIn: 'root'
})
export class WaterfallMapperService {

  constructor(private adUnitMapperService: AdUnitMapperService, private waterfallDialogService: WaterfallDialogService,
              private productDialogService: ProductDialogService, private platformsMapperService: PlatformMapperService,
              private tableCellService: TableCellService) {
  }

  private openWaterfallPreview(waterfallRow: WaterfallTableRowType): void {
    this.waterfallDialogService.openWaterfallPreviewDialog(waterfallRow.id.realValue);
  }

  private openProductPreview(waterfallRow: WaterfallTableRowType): void {
    this.productDialogService.openProductPreview(waterfallRow.productId.realValue);
  }

  public async mapWaterfallsToTableRows(waterfalls: IWaterfall[]): Promise<WaterfallTableRowType[]> {
    return Promise.all(waterfalls.map(async (waterfall) => ({
      id: {value: waterfall.id, realValue: waterfall.id},
      name: {value: waterfall.name, cssClass: 'cursor-pointer', handlerFn: this.openWaterfallPreview.bind(this)},
      productName: {
        value: waterfall.product?.name,
        cssClass: 'cursor-pointer',
        handlerFn: this.openProductPreview.bind(this)
      },
      productId: {value: waterfall.product?.id, realValue: waterfall.product?.id},
      platform: await this.platformsMapperService.getPlatformCellFromType(this.getPlatformTypeCsv(waterfall.platform?.type)),
      regions: this.tableCellService.getComplexCell(getRegionsNameShort(waterfall.regions), Placement.LEFT, false),
      adUnits: await this.adUnitMapperService.getAdUnitsCell(waterfall.adUnits),
      destinations: this.tableCellService.getComplexCell(waterfall.promotedProducts?.map(el => el.name) || []),
      campaigns: this.tableCellService.getComplexCell(waterfall.campaigns?.map(el => el.name) || [], Placement.LEFT)
    })));
  }

  public async mapWaterfallsToRows(waterfalls: IWaterfall[]): Promise<WaterfallRow[]> {
    return Promise.all(waterfalls.map(async (waterfall) => ({
      name: {value: waterfall.name, cssClass: 'cursor-pointer', cssClassTd: ['column-waterfalls-list-name']},
      productName: {
        value: waterfall.product?.name,
        cssClass: 'cursor-pointer'
      },
      platform: await this.platformsMapperService.getPlatformCellFromType(this.getPlatformTypeCsv(waterfall.platform?.type)) as CellBasic,
      regions: this.tableCellService.getComplexCell(getRegionsNameShort(waterfall.regions), Placement.LEFT, false) as CellBasic,
      adUnits: await this.adUnitMapperService.getAdUnitsCell(waterfall.adUnits) as CellBasic,
      destinations: this.getDestinationsCell(waterfall),
      campaigns: this.getCampaignsCell(waterfall),
      actions: {
        component: ActionsCellComponent, actions: [
          {name: TableAction.show, iconFa: faEye},
          {name: TableAction.edit, iconFa: faPenToSquare},
          {name: TableAction.delete, iconFa: faTrash},
        ]
      },
      trackById: waterfall.id,
      waterfall
    })));
  }

  private getDestinationsCell(waterfall: IWaterfall): CellBasic {
    const cell = this.tableCellService.getComplexCell(waterfall.promotedProducts?.map(el => el.name) || []) as CellBasic;
    cell.cssClassTd = ['column-waterfalls-list'];
    return cell;
  }

  private getCampaignsCell(waterfall: IWaterfall): CellBasic {
    const cell = this.tableCellService.getComplexCell(waterfall.campaigns?.map(el => el.name) || [], Placement.LEFT) as CellBasic;
    cell.cssClassTd = ['column-waterfalls-list'];
    return cell;
  }

  public getPlatformTypeCsv(platformType: string): PlatformType {
    platformType = platformType?.toLowerCase()?.trim();
    return platformType as PlatformType;
  }
}
