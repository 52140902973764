import {AbstractControl, FormControl, ValidationErrors, Validators} from '@angular/forms';

export const  URL_REGEXP = /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
export const GOOGLE_PLAY_REGEX = /^market:\/\/details[^\s]+$/;
export const SAMSUNG_REGEX = /^samsungapps:\/\/[^\s]+$/;
export const HUAWEI_REGEX = /^appmarket:\/\/details[^\s]+$/;
export const AMAZON_REGEX = /^amzn:\/\/apps\/android[^\s]+$/;

export function urlStartsWithHttps(control: FormControl<string>): ValidationErrors | null {
  if (!control.value || !control.value?.trim()) {
    return null;
  }
  const isValid = control.value?.trim()?.startsWith('https://');
  return isValid ? null : {httpsMissing: true};
}

export function conditionalUrlValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value || !control.value?.trim()) {
    return null;
  }
  const valid = URL_REGEXP.test(control.value);
  return valid ? null : {urlPattern: true};
}

export function androidUrlValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value || !control.value?.trim()) {
    return null;
  }
  const valid = URL_REGEXP.test(control.value) || GOOGLE_PLAY_REGEX.test(control.value);
  return valid ? null : {androidUrlPattern: true};
}

export function samsungUrlValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value || !control.value?.trim()) {
    return null;
  }
  const valid = URL_REGEXP.test(control.value) || SAMSUNG_REGEX.test(control.value);
  return valid ? null : {samsungUrlPattern: true};
}

export function amazonUrlValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value || !control.value?.trim()) {
    return null;
  }
  const valid = URL_REGEXP.test(control.value) || AMAZON_REGEX.test(control.value);
  return valid ? null : {amazonUrlPattern: true};
}

export function huaweiUrlValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value || !control.value?.trim()) {
    return null;
  }
  const valid = URL_REGEXP.test(control.value) || HUAWEI_REGEX.test(control.value)
    || GOOGLE_PLAY_REGEX.test(control.value);
  return valid ? null : {huaweiUrlPattern: true};
}

