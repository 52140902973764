import {PlatformType} from '../enum/PlatformType';
import {IPlatform} from '../interface/platform/IPlatform';
import {getUrl} from './platform-form-template-utils';

export function getStoreLink(platformType: PlatformType, platformId: string, productPageId?: string): string {
  if (platformType === PlatformType.ANDROID) {
    return `https://play.google.com/store/apps/details?id=${platformId}`;
  } else if (platformType === PlatformType.IOS) {
    return productPageId ? `https://apps.apple.com/us/app/id${platformId}?ppid=${productPageId}` :
      `https://apps.apple.com/us/app/id${platformId}`;
  } else if (platformType === PlatformType.SAMSUNG) {
    return `https://galaxystore.samsung.com/detail/${platformId}`;
  }
}

/** Checks if a platform supports generating a link to the store.
 * Huawei and Samsung platforms are not supported as their store URLs do not use the bundleId.
 * Web is not supported as it does not have a store.
 */
export function linkToStoreSupported(platform: IPlatform): boolean {
  return platform.platformId && platform.type !== PlatformType.HUAWEI &&
    platform.type !== PlatformType.AMAZON && platform.type !== PlatformType.WEB;
}
