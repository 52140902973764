import {Injectable} from '@angular/core';
import {IConfirmDialogData} from '../../interface/ui/dialog/IConfirmDialogData';
import {IconOriginType} from '../../enum/ui/icons/IconOriginType';
import {faArrowRightArrowLeft, faFloppyDiskCircleArrowRight, faTrash} from '@fortawesome/pro-light-svg-icons';
import {firstValueFrom} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {DialogRef} from './dialog/dialog-ref';
import {ConfirmDialogComponent} from '../../component/common/confirm-dialog/confirm-dialog.component';
import {DialogType} from '../../enum/ui/dialog/Dialog';
import {DialogService} from './dialog/dialog.service';
import {ConfirmDialogConfig} from '../../interface/ui/dialog/ConfirmDialogConfig';
import {CustomIconName} from '../../enum/ui/icons/CustomIconName';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(private translateService: TranslateService, private dialogService: DialogService) { }

  private static getDeletePrefix(objectName: string): string {
    return objectName + '.' + 'deleteDialog';
  }

  private static getDisablePrefix(objectName: string): string {
    return objectName + '.' + 'disableDialog';
  }

  private static getChangePrefix(objectName: string): string {
    return objectName + '.' + 'changeDialog';
  }

  private static getSavePrefix(objectName: string): string {
    return objectName + '.' + 'saveDialog';
  }

  public async openConfirmFormClose(): Promise<DialogRef> {
    const data: IConfirmDialogData = await this.getConfirmFormCloseDialogData();
    return this.dialogService.open(ConfirmDialogComponent, {type: DialogType.center, data});
  }

  public async openDeleteDialog(dialogConfig: ConfirmDialogConfig): Promise<DialogRef> {
    const data: IConfirmDialogData = await this.getDeleteDialogData(dialogConfig);
    return this.dialogService.open(ConfirmDialogComponent, {type: DialogType.center, data});
  }

  public async openDisableDialog(dialogConfig: ConfirmDialogConfig): Promise<DialogRef> {
    const data: IConfirmDialogData = await this.getDisableDialogData(dialogConfig);
    return this.dialogService.open(ConfirmDialogComponent, {type: DialogType.center, data});
  }

  public async openChangeDialog(dialogConfig: ConfirmDialogConfig): Promise<DialogRef> {
    const data: IConfirmDialogData = await this.getChangeDialogData(dialogConfig);
    return this.dialogService.open(ConfirmDialogComponent, {type: DialogType.center, data});
  }

  public async openSaveDialog(dialogConfig: ConfirmDialogConfig): Promise<DialogRef> {
    const data: IConfirmDialogData = await this.getSaveDialogData(dialogConfig);
    return this.dialogService.open(ConfirmDialogComponent, {type: DialogType.center, data});
  }

  public  async getDeleteDialogData(dialogConfig: ConfirmDialogConfig): Promise<IConfirmDialogData> {
    const prefix = ConfirmDialogService.getDeletePrefix(dialogConfig.objectName);
    return {
      headerIcon: {type: IconOriginType.EXTERNAL_LIBRARY, iconRef: faTrash},
      header: await firstValueFrom(this.translateService.get(prefix + '.header')),
      text: await firstValueFrom(this.translateService.get(prefix + '.text', dialogConfig.data)),
      warning: await this.getDeleteDialogWarning(prefix),
      acceptButton: {
        name: await firstValueFrom(this.translateService.get('button.delete')),
        icon: {type: IconOriginType.EXTERNAL_LIBRARY, iconRef: faTrash}
      },
      closeButton: {
        name: await firstValueFrom(this.translateService.get('button.cancel'))
      }
    };
  }

  private async getDeleteDialogWarning(prefix: string): Promise<string> {
    const warningMessage = await firstValueFrom(this.translateService.get(prefix + '.warning'));
    return warningMessage === prefix + '.warning' ? null : warningMessage;
  }

  public  async getDisableDialogData(dialogConfig: ConfirmDialogConfig): Promise<IConfirmDialogData> {
    const prefix = ConfirmDialogService.getDisablePrefix(dialogConfig.objectName);
    return {
      headerIcon: {type: IconOriginType.CUSTOM, iconName: CustomIconName.TOGGLE_DISABLED},
      header: await firstValueFrom(this.translateService.get(prefix + '.header')),
      text: await firstValueFrom(this.translateService.get(prefix + '.text', dialogConfig.data)),
      warning: await firstValueFrom(this.translateService.get(prefix + '.warning')),
      acceptButton: {
        name: await firstValueFrom(this.translateService.get('button.disable')),
      },
      closeButton: {
        name: await firstValueFrom(this.translateService.get('button.cancel'))
      }
    };
  }

  public async getChangeDialogData(dialogConfig: ConfirmDialogConfig): Promise<IConfirmDialogData> {
    const prefix = ConfirmDialogService.getChangePrefix(dialogConfig.objectName);
    return {
      headerIcon: {type: IconOriginType.EXTERNAL_LIBRARY, iconRef: faArrowRightArrowLeft},
      header: await firstValueFrom(this.translateService.get(prefix + '.header')),
      text: await firstValueFrom(this.translateService.get(prefix + '.text', dialogConfig.data)),
      warning: await firstValueFrom(this.translateService.get(prefix + '.warning')),
      acceptButton: {
        name: await firstValueFrom(this.translateService.get('button.enable')),
      },
      closeButton: {
        name: await firstValueFrom(this.translateService.get('button.cancel'))
      }
    };
  }

  public async getSaveDialogData(dialogConfig: ConfirmDialogConfig): Promise<IConfirmDialogData> {
    const prefix = ConfirmDialogService.getSavePrefix(dialogConfig.objectName);
    return {
      headerIcon: {type: IconOriginType.EXTERNAL_LIBRARY, iconRef: faFloppyDiskCircleArrowRight},
      header: await firstValueFrom(this.translateService.get(prefix + '.header')),
      text: await firstValueFrom(this.translateService.get(prefix + '.text', dialogConfig.data)),
      acceptButton: {
        name: await firstValueFrom(this.translateService.get('button.save')),
        icon: {type: IconOriginType.EXTERNAL_LIBRARY, iconRef: faFloppyDiskCircleArrowRight}
      },
      closeButton: {
        name: await firstValueFrom(this.translateService.get('button.cancel'))
      }
    };
  }

  public async getConfirmFormCloseDialogData(): Promise<IConfirmDialogData> {
    return {
      header: await firstValueFrom(this.translateService.get('form.discardChangesHeader')),
      text: await firstValueFrom(this.translateService.get('form.confirmFormCloseMessage')),
      acceptButton: {
        name: await firstValueFrom(this.translateService.get('button.discardChanges')),
      },
      closeButton: {
        name: await firstValueFrom(this.translateService.get('button.cancel'))
      }
    };
  }
}
