import {ICampaignFormControlsConfig} from '../interface/ICampaignFormControlsConfig';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {CampaignFormSteps} from '../enum/CampaignFormSteps';
import {CampaignFormAccessor} from './campaign-form-accessor';
import {Tab} from '../../shared/interface/ui/tabs/Tab';

export function getControl(key: keyof ICampaignFormControlsConfig, formGroup: UntypedFormGroup): AbstractControl {
  return formGroup?.get(key);
}


export function isStepValid(step: Tab, formGroupAccessor: CampaignFormAccessor): boolean {
  switch (step?.name as CampaignFormSteps) {
    case CampaignFormSteps.CHOOSE_PROMOTED_PRODUCT:
      return formGroupAccessor.product.valid;
    case CampaignFormSteps.CREATE_CAMPAIGN:
      // negation of "invalid" is used due to possibility of control being in disabled state
      return formGroupAccessor.name.valid && !formGroupAccessor.startDate.invalid &&
        !formGroupAccessor.endDate.invalid && formGroupAccessor.isDefault.valid;
    case CampaignFormSteps.CHOOSE_CREATIVES:
      return formGroupAccessor.creativesValues && formGroupAccessor.creativesValues.length > 0;
  }
}

export function updateStepsSubLabels(steps: Tab[], formGroupAccessor: CampaignFormAccessor): void {
  steps.forEach((step) => {
    step.subLabel = getSubLabel(step.name as CampaignFormSteps, formGroupAccessor);
    step.textBorderDark = !!step.subLabel;
  });
}

export function getSubLabel(name: CampaignFormSteps, formGroupAccessor: CampaignFormAccessor): string {
  switch (name) {
    case CampaignFormSteps.CHOOSE_PROMOTED_PRODUCT:
      return formGroupAccessor.productValue?.name;
    case CampaignFormSteps.CREATE_CAMPAIGN:
      return formGroupAccessor.name?.value;
    case CampaignFormSteps.CHOOSE_CREATIVES:
      return getCreativesSubLabel(formGroupAccessor);
  }
}

function getCreativesSubLabel(formGroupAccessor: CampaignFormAccessor): string {
  const length = formGroupAccessor.creatives.value.length;
  return length ? length + (length > 1 ? ' creatives' : ' creative') : null;
}
