import {FormControl, FormGroup, ValidationErrors} from '@angular/forms';
import {IPromotedProductCampaign} from '../../product/interface/IPromotedProductCampaign';
import {IBasicSelectOption} from '../../shared/interface/ui/form/IBasicSelectOption';
import {IWaterfallForm} from '../interface/IWaterfallForm';
import {PlatformType} from '../../product/enum/PlatformType';
import _ from 'lodash';
import {IProduct} from '../../product/interface/IProduct';
import {IAdUnit} from '../../ad-unit/interface/IAdUnit';
import {ICampaignInWaterfall} from '../../campaign/interface/ICampaignInWaterfall';
import {IPlatformSmallInfo} from '../../product/interface/platform/IPlatformSmallInfo';

export function campaignsRequired(formArray: FormControl<IPromotedProductCampaign[]>): null | ValidationErrors {
    return hasCampaigns(formArray.value) ? null : {campaignsRequired: true};
}

export function creativesRequired(formArray: FormControl<IPromotedProductCampaign[]>): null | ValidationErrors {
  return hasCreatives(formArray.value) ? null : {creativesRequired: true};
}

export function platformForDestinationRequired(formControl: FormControl<IPromotedProductCampaign[]>): null | ValidationErrors {
  const parentForm = formControl?.parent as FormGroup<IWaterfallForm>; // "?" is used, because parentForm is not defined in first call
  const platformType: PlatformType = parentForm?.controls?.platform?.value?.name as PlatformType;
  if (!platformType) {
    return null;
  }

  for (const productCampaign of formControl.value) {
      if (!isDestinationPlatformValid(productCampaign?.product, platformType)) {
      return { platformForDestinationRequired: true };
    }
  }

  return null;
}

export function isSamsungAndroidCombination(product: IProduct, platformType: PlatformType): boolean {
  return platformType === PlatformType.SAMSUNG &&
         !!product?.platforms?.some(platform => platform.type === PlatformType.ANDROID);
}

export function hasCampaignOrientationMismatch(campaign: ICampaignInWaterfall,
  campaignProductCampaignErrors: ValidationErrors): boolean {
  return campaign?.id && _.isArray(campaignProductCampaignErrors?.mismatchedCampaignIds) &&
    campaignProductCampaignErrors.mismatchedCampaignIds.includes(campaign.id);
}

export function hasDestinationPlatformMissing(destination: IProduct, platformType: PlatformType): boolean {
  return ! (platformType && hasPlatformOrWeb(destination, platformType));
}

function hasPlatformOrWeb(destination: IProduct, platformType: PlatformType): boolean {
  return destination?.platforms?.some(platform => platform.type === platformType || platform.type === PlatformType.WEB);
}

export function isDestinationPlatformValid(product: IProduct, platformType: PlatformType): boolean {
  return !hasDestinationPlatformMissing(product, platformType) || isSamsungAndroidCombination(product, platformType);
}


export function platformForDestinationEnabled(formControl: FormControl<IPromotedProductCampaign[]>): null | ValidationErrors {
  const parentForm = formControl?.parent as FormGroup<IWaterfallForm>; // "?" is used, because parentForm is not defined in first call
  const platformType: PlatformType = parentForm?.controls?.platform?.value?.name as PlatformType;
  if (!platformType) {
    return null;
  }

  for(const productCampaign of formControl.value) {
    if (hasDestinationPlatformDisabled(productCampaign?.product, platformType)) {
      return { platformForDestinationDisabled: true }
    }
  }

  return null;
}

export function hasDestinationPlatformDisabled(product: IProduct, platformType: PlatformType): boolean {
  if (!isDestinationPlatformValid(product, platformType)) {
    return false;
  }

  const destinationPlatform = getDestinationPlatform(product, platformType);
  return destinationPlatform.disabled === true;
}

function getDestinationPlatform(product: IProduct, platformType: PlatformType): IPlatformSmallInfo {
  const platformMatch = product?.platforms?.find((platform) => platform?.type === platformType);
  const isSamsungWithAndroidMatch = isSamsungAndroidCombination(product, platformType);
  const webPlatform = product?.platforms?.find((platform) => platform?.type === PlatformType.WEB);
  if (platformMatch) {
    return platformMatch;
  } else if (isSamsungWithAndroidMatch) {
    return product?.platforms?.find((platform) => platform?.type === PlatformType.ANDROID);
  } else if (webPlatform) {
    return webPlatform;
  } else {
    return null;
  }
}

export function adUnitsForCampaignRequired(formControl: FormControl<IPromotedProductCampaign[]>): null | ValidationErrors {
  const parentForm = formControl.parent as FormGroup<IWaterfallForm>;
  const adUnits: IAdUnit[] = getActiveAdUnits(parentForm?.controls?.adUnits?.value as { isActive: boolean, adUnit: IAdUnit }[]);
  if (_.isEmpty(adUnits)) {
    return null;
  }

  let isValid = true;
  for (const productCampaign of formControl.value) {
    if (hasCampaignMissingAdUnitsError(productCampaign.campaign, adUnits)) {
      isValid = false;
      break;
    }
  }
  return isValid ? null : {adUnitsForCampaignRequired: true};
}

export function getActiveAdUnits(adUnitGroups: { isActive: boolean, adUnit: IAdUnit }[]): IAdUnit[] {
  return adUnitGroups?.filter(adUnitGroup => adUnitGroup.isActive === true)?.map(adUnitGroup => adUnitGroup.adUnit)
    || [];
}

export function hasCampaignMissingAdUnitsError(campaign: ICampaignInWaterfall, adUnits: IAdUnit[]): boolean {
  return !adUnits.every(adUnit => campaign?.adUnits?.some(campaignAdUnit => campaignAdUnit.type === adUnit.type));
}

export function platformDefined(formControl: FormControl<IBasicSelectOption>): null | ValidationErrors {
  const parentForm = formControl.parent as FormGroup<IWaterfallForm>;
  if (preventPlatformValidation(formControl)) {
    return null;
  }
  const productControl = parentForm?.controls?.promotingProduct?.value;
  const productPlatformTypes = productControl.platforms?.map(platform => platform.type);
  return productPlatformTypes.includes(formControl.value?.name as PlatformType)  ? null : {platformNotDefined: true};
}

export function platformEnabled(formControl: FormControl<IBasicSelectOption>): null | ValidationErrors {
  const hasPlatformDefinedError = platformDefined(formControl);
  if (hasPlatformDefinedError || preventPlatformValidation(formControl)) {
    return null;
  }

  const parentForm = formControl.parent as FormGroup<IWaterfallForm>;
  const productControl = parentForm?.controls?.promotingProduct?.value;
  const platform = productControl.platforms?.find(pl => pl.type === formControl.value?.name);
  return platform?.disabled === true ? {platformDisabled: true} : null;
}

function preventPlatformValidation(platformControl: FormControl<IBasicSelectOption>): boolean {
  const parentForm = platformControl.parent as FormGroup<IWaterfallForm>;
  return !parentForm || !parentForm?.controls?.promotingProduct?.value || !platformControl.value;
}

export function hasCampaigns(productsCampaigns: IPromotedProductCampaign[]): boolean {
  return productsCampaigns.every(productCampaign => !!productCampaign.campaign?.id);
}


export function hasCreatives(productsCampaigns: IPromotedProductCampaign[]): boolean {
  return hasCampaigns(productsCampaigns) &&
    productsCampaigns.every(productCampaign => productCampaign.campaign?.creatives?.length > 0);
}
