import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { faSearch, faXmark } from '@fortawesome/pro-light-svg-icons';
import {UntypedFormControl} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-search-control',
  templateUrl: './search-control.component.html',
  styleUrls: ['./search-control.component.scss']
})
export class SearchControlComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: ElementRef;
  @Input() isNarrow = false;
  @Input() valueChangesObservable: Observable<string>;
  @Input() focusObservable: Observable<void>;

  faSearch = faSearch;
  faXmark = faXmark;
  searchFormControl = new UntypedFormControl('');

  @Output() valueChanges = new EventEmitter<{value: string, shouldSubmit: boolean}>();
  @Output() focusIn = new EventEmitter();
  @Output() focusOut = new EventEmitter();

  private subscriptions: Subscription[] = [];

  constructor() { }

  ngOnInit(): void {
    this.subscriptions.push(this.observeControlValueChanges(), this.observeInputValueChanges(), this.observeFocus());
  }

  private observeControlValueChanges(): Subscription {
    return this.searchFormControl.valueChanges.subscribe((value) => {
      this.valueChanges.emit({value: value.trim(), shouldSubmit: false});
    });
  }

  private observeInputValueChanges(): Subscription {
    return this.valueChangesObservable?.subscribe((value) => {
      this.searchFormControl.setValue(value);
    });
  }

  private observeFocus(): Subscription {
    return this.focusObservable?.subscribe(() => {
      setTimeout(() => this.searchInput.nativeElement.focus(), 10);
    });
  }

  public focusOutInput(): void {
    this.searchInput.nativeElement.blur();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  public onClearInputClicked(): void {
    this.searchFormControl.setValue('', {emitEvent: false});
    this.valueChanges.emit({value: '', shouldSubmit: true});
  }
}
