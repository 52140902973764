import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-base-select-button-content',
  templateUrl: './base-select-button-content.component.html',
  styleUrls: ['./base-select-button-content.component.scss']
})
export class BaseSelectButtonContentComponent {
  @Input() isTableFilter = false;
  @Input() placeholder: string;
  @Input() showContent = false;


  constructor() { }

}
