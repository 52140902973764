import {Component, EventEmitter, Input, Output} from '@angular/core';

import {
  faArrowDown19 as faArrowDown19Regular,
  faArrowDownZA as faArrowDownZARegular,
  faArrowUp91 as faArrowUp91Regular,
  faArrowUpAZ as faArrowUpAZRegular
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowDown19 as faArrowDown19Light,
  faArrowDownZA as faArrowDownZALight,
  faArrowUp91 as faArrowUp91Light,
  faArrowUpAZ as faArrowUpAZLight
} from '@fortawesome/pro-light-svg-icons';

import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {HeaderEvent} from '../../../interface/ui/my-table/header-event.model';
import {HeaderHandler} from '../../../interface/ui/my-table/header-handler.interface';
import {Header} from '../../../interface/ui/my-table/header.model';
import {SortType} from '../../../enum/ui/table/SortType';
import {SortDirection} from '../../../enum/ui/table/SortDirection';

@Component({
  selector: 'app-table-header-basic',
  templateUrl: './table-header-basic.component.html',
  styleUrls: ['./table-header-basic.component.scss']
})
export class TableHeaderBasicComponent implements HeaderHandler {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input({required: true}) public header: Header<any>;
  @Output() public headerEvent: EventEmitter<HeaderEvent> = new EventEmitter();

  public  sortIcons = {
    regular: {
      numeric: {
        down: faArrowDown19Regular,
        up: faArrowUp91Regular,
      },
      alphabetic: {
        down: faArrowDownZARegular,
        up: faArrowUpAZRegular
      }
    }, light: {
      numeric: {
        up: faArrowUp91Light,
        down: faArrowDown19Light
      },
      alphabetic: {
        up: faArrowUpAZLight,
        down: faArrowDownZALight
      }
    }
  };

  public get icon() {
    return this.getSortIcon();
  }
  public getSortIcon(): IconDefinition {
    return this.header?.isSelected ?
      this.getRegularSortIcon(this.header.sortType, this.header.direction)
      : this.getLightSortIcon(this.header.sortType);
  }

  private getRegularSortIcon(type: SortType, direction: SortDirection): IconDefinition {
    const typeIcons: {up: IconDefinition, down: IconDefinition} = type === SortType.numeric ?
      this.sortIcons.regular.numeric : this.sortIcons.regular.alphabetic;
    return direction === SortDirection.ascending ? typeIcons.up : typeIcons.down;
  }

  private getLightSortIcon(type: SortType): IconDefinition {
    return type === SortType.numeric ? this.sortIcons.light.numeric.up : this.sortIcons.light.alphabetic.up;
  }

  public onClick(): void {
    if (this.header.sortType) {
      this.headerEvent.emit({header: this.header, type: 'sort'});
    }
  }
}


