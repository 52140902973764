import {Component, Input, OnInit} from '@angular/core';
import {EnumTranslateService} from '../../../service/translate/enum-translate.service';
import {ProductTag} from '../../../../product/enum/ProductTag';
import {Color} from '../../../enum/ui/Color';
import {CampaignTag} from '../../../../campaign/enum/CampaignTag';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
  // eslint-disable-next-line
  @Input('name') name: string;
  @Input() public color: string;
  public label: string;

  constructor(private enumTranslateService: EnumTranslateService) { }

  ngOnInit(): void {
    this.enumTranslateService.getTranslation('allTags.', this.name).then(value => {
      this.label = value;
    });

    if(!this.color) {
      this.setTagColor();
    }
  }

  private setTagColor(): void {
    switch (this.name) {
      case ProductTag.HYPER_CASUAL:
        this.color = Color.DARK_GREY_SECONDARY;
        break;
      case ProductTag.MID_CORE:
        this.color = Color.DARK_GREY_FOURTH;
        break;
      case ProductTag.PUBLISHING:
        this.color = Color.DARK_GREY_PRIMARY;
        break;
      case CampaignTag.DEFAULT_CAMPAIGN:
        this.color = Color.PRIMARY;
        break;
      default:
        this.color = Color.DARK_GREY_PRIMARY;
        break;
    }
  }

}
