import {UntypedFormControl, ValidationErrors} from '@angular/forms';

export function storeIdentifierIosLengthValidator(control: UntypedFormControl): ValidationErrors | null {
  if(!control.value?.trim()) {
    return null;
  }

  const isValid = control.value.toString().length > 8 && control.value.toString().length < 12;
  return isValid ? null : { storeIdLengthIOS: true };
}

export function storeIdentifierIosNumericValidator(control: UntypedFormControl): ValidationErrors | null {
  if(!control.value?.trim()) {
    return null;
  }

  const isValid = /^[0-9]*$/.test(control.value);
  return isValid ? null : { storeIdNumericIOS: true };
}
