import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {compareAlphabetical} from '../../../shared/util/table/sort-utils';

@Component({
  selector: 'app-platforms-icons',
  templateUrl: './platforms-icons.component.html',
  styleUrls: ['./platforms-icons.component.scss']
})
export class PlatformsIconsComponent implements OnChanges {
  @Input() platformTypes: string[];
  @Input() platformTypesSorted: string[];

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.platformTypes.firstChange || (changes.platformTypes.previousValue !== changes.platformTypes.currentValue)) {
      this.platformTypesSorted = this.platformTypes?.sort((a, b) => compareAlphabetical(a,b));
    }
  }

  public getClass(platformType: string): {[key: string]: string} {
    const classes = {};
    classes[platformType.toLowerCase() + '-icon-col'] = true;
    return classes;
  }

}
