import { Injectable } from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {SnackbarService} from '../snackbar/snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorService {

  constructor(private snackbarService: SnackbarService, private translateService: TranslateService) { }

  public showApiErrorSnackbar(error: HttpErrorResponse, messageKey?: string): void {
    if (error?.status === 401) {
      return;
    }
    if (error?.status === 403) {
      this.showPermissionErrorSnackbar();
      return;
    }

    if (this.shouldHandle4XXError(error)) {
      this.handle4XXError(error);
    } else {
      this.handleUnexpectedError(messageKey);
    }
  }

  private async showPermissionErrorSnackbar(): Promise<void> {
    const message = await firstValueFrom(this.translateService.get('error.permission'));
    this.snackbarService.openErrorSnackbar(message);
  }

  private shouldHandle4XXError(error: HttpErrorResponse): boolean {
    return this.is4XXError(error) && !!this.getApiErrorMessage(error);
  }

  private getApiErrorMessage(error: HttpErrorResponse): boolean {
    return error?.error?.message || error?.error;
  }

  private handle4XXError(error: HttpErrorResponse): void {
    const errorMessage = JSON.stringify(this.getApiErrorMessage(error));
    this.snackbarService.openErrorSnackbar(errorMessage, undefined, true);
  }

  private is4XXError(error: HttpErrorResponse): boolean {
    return error && error.status >= 400 && error.status < 500 && error.status !== 401;
  }

  private async handleUnexpectedError(messageKey?: string): Promise<void> {
    const message = await firstValueFrom(this.translateService.get(messageKey));
    this.snackbarService.openErrorSnackbar(message);
  }
}
