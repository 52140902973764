import {Overlay, ComponentType, GlobalPositionStrategy, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Injectable, Injector} from '@angular/core';
import {DialogRef} from './dialog-ref';
import {BACK_ACTION, DIALOG_DATA} from './dialog-tokens';
import {IDialogConfig} from '../../../interface/ui/dialog/IDialogConfig';
import {DialogType} from '../../../enum/ui/dialog/Dialog';
import {setBackdropOnlyForOverlayOnTop} from '../../../util/dialog/dialog-utils';
import {DialogGeneralService} from '../dialog-general/dialog-general.service';
import {IBackAction} from '../../../interface/ui/dialog/IBackAction';



@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private overlay: Overlay, private injector: Injector, private dialogGeneralService: DialogGeneralService) {
  }

  private static getSideDialogPosition(initialPosition: GlobalPositionStrategy): GlobalPositionStrategy {
    return initialPosition.right('20px').top('20px');
  }

  private static getCenterDialogPosition(initialPosition: GlobalPositionStrategy): GlobalPositionStrategy {
    return initialPosition.centerHorizontally().centerVertically();
  }

  open<T>(component: ComponentType<T>, config: IDialogConfig): DialogRef {
    const positionStrategy = this.getDialogPosition(config.type);
    const overlayRef = this.getOverlayRef(positionStrategy, config.type);
    const dialogRef = new DialogRef(overlayRef, this.dialogGeneralService, config);

    const injector = this.getDialogInjector(dialogRef, config);

    const portal = new ComponentPortal(component, null, injector);
    overlayRef.attach(portal);
    setBackdropOnlyForOverlayOnTop(overlayRef.hostElement.parentElement);

    return dialogRef;
  }

  private getOverlayRef(positionStrategy: GlobalPositionStrategy, dialogType: DialogType): OverlayRef {
    return this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      panelClass: dialogType === DialogType.side ? 'side-dialog-panel' : 'center-dialog-panel',
    });
  }

  private getDialogPosition(dialogType: DialogType) : GlobalPositionStrategy {
    const initialPosition: GlobalPositionStrategy = this.overlay.position().global();
    return dialogType === DialogType.side ? DialogService.getSideDialogPosition(initialPosition) :
      DialogService.getCenterDialogPosition(initialPosition);
  }

  private getDialogInjector(dialogRef: DialogRef, config: IDialogConfig): Injector {
    const backAction: IBackAction = config.backActionDialog ? {backActionDialogConfig: config.backActionDialog} as IBackAction : null;
    return Injector.create({
      parent: this.injector,
      providers: [
        {provide: DialogRef, useValue: dialogRef},
        {provide: DIALOG_DATA, useValue: config.data},
        {provide: BACK_ACTION, useValue: backAction}
      ],
    });
  }

}
