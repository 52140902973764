import {Injectable} from '@angular/core';
import {RequestType} from 'src/app/shared/enum/RequestType';
import {IAdUnit} from 'src/app/ad-unit/interface/IAdUnit';
import {ApiGeneralService} from '../../shared/service/api/api-general.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {compareAlphabetical} from '../../shared/util/table/sort-utils';

/**
 * Service responsible for handling API requests related to Ad Units.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiAdUnitService {

  /**
   * Constructor for ApiAdUnitService.
   * @param apiGeneralService ApiGeneralService for handling general API requests.
   */
  constructor(
    private apiGeneralService: ApiGeneralService
  ) {
  }

  /**
   * Fetches all Ad Units from the API.
   * @returns Observable containing an array of Ad Units.
   */
  public getAllAdUnits(): Observable<IAdUnit[]> {
    const url = 'AdUnits';
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url
    }).pipe(map((adUnits: IAdUnit[]) => {
      return adUnits.sort((a, b) => compareAlphabetical(a.name, b.name));
    }));
  }
}
