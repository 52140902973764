import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthApiService} from '../auth-api/auth-api.service';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {User} from '../../../interface/auth/User';
import {SocialAuthService} from '@abacritt/angularx-social-login';
import {ApiErrorService} from '../../api/api-error.service';
import {RefreshTokenResponse} from '../../../interface/auth/RefreshTokenResponse';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject: BehaviorSubject<User>;
  private readonly USER_KEY = 'user';

  constructor(private router: Router,
              private authApiService: AuthApiService,
              private socialAuthService: SocialAuthService,
              private apiErrorService: ApiErrorService) {
    this.init();
  }

  public init(): void {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem(this.USER_KEY)));
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  public async login(token: string): Promise<void> {
    try {
      const user = await firstValueFrom(this.authApiService.login({idToken: token}));
      localStorage.setItem(this.USER_KEY, JSON.stringify(user));
      this.userSubject.next(user);
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'login.loginError');
      throw error;
    }
  }

  public async logout(sessionExpired: boolean = false): Promise<void> {
    localStorage.removeItem(this.USER_KEY);
    this.userSubject.next(null);
    this.socialAuthService.signOut().finally(() => {
      sessionExpired ? this.router.navigate(['/login'],  {queryParams: {sessionExpired: true}}) :
        this.router.navigate(['/login']);
    });
  }

  public get user(): Observable<User> {
    return this.userSubject.asObservable();
  }

  public isLoggedIn(): boolean {
    return !!this.userValue;
  }

  public refreshToken(): Observable<RefreshTokenResponse> {
    const refreshToken = this.userValue?.refreshToken;
    return this.authApiService.refreshToken({refreshToken});
  }

  public handleRefreshTokenSuccess(refreshTokenRes: RefreshTokenResponse): void {
    const user = this.userValue;
    user.token = refreshTokenRes.accessToken;
    user.refreshToken = refreshTokenRes.refreshToken;
    localStorage.setItem(this.USER_KEY, JSON.stringify(user));
    this.userSubject.next(user);
  }

  public handleRefreshTokenError(): void {
    if (this.isLoggedIn()) {
      this.logout(true);
    }
  }
}
