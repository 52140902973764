import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {faCheck} from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements ControlValueAccessor {
  @ViewChild('checkboxInput') checkbox: ElementRef;
  @Input() preventChange = false;
  @Input() sizeSmall = false;

  public isChecked = false;
  public faCheck = faCheck;
  public onChange: any = () => {
  }
  public onTouched: any = () => {
  }

  constructor(public control: NgControl) {
    this.control.valueAccessor = this;
  }

  public onInputChange(): void {
    if (!this.preventChange) {
      this.isChecked = !this.isChecked;
    } else {
      this.checkbox.nativeElement.checked = this.isChecked;
    }
    this.onChange(this.isChecked);
    this.onTouched();
  }

  public onInputClicked(event: Event): void {
    if (this.preventChange) {
      event.stopPropagation();
    }
  }


  /* ControlValueAccessor */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: any): void {
    if (obj === undefined || obj === null) {
      this.onChange(false);
      this.onTouched();
      return;
    }
    this.isChecked = obj;
  }

}
