import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {ApiErrorService} from '../../shared/service/api/api-error.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient, private apiErrorService: ApiErrorService) {
  }

  public canUploadUsingPresignedUrls(): boolean {
    return environment.production === true;
  }

  public uploadFileAWSS3(fileuploadurl: string, contenttype: string, file: File): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': contenttype});
    return this.http.put(fileuploadurl, file, {headers}).pipe(catchError((error) => {
      this.apiErrorService.showApiErrorSnackbar(error, 'asset.uploadFileError');
      return throwError(() => error);
    }));
  }

}
