<app-base-select-button [isFilterSelected]="isTableFilter && isValueSelected()" *ngIf="!customButton"
                        [cssClasses]= "cssButton"
                        [disabled]="control.disabled"
                        [isArrowGrayedOut]="isControlGreyedOut()"
                        [errorDisplayed]="hasRequiredError(control)"
                        (dropdownToggled)="onDropdownToggled($event)"
                        [isDropdownOpen]="isDropdownOpen" [dropdown]="dropdown">
  <span [ngClass]="isControlGreyedOut() ? 'color-light-grey-primary' : ''" #lengthChecker
        [isActive]="isEllipsisActive(lengthChecker)" appTooltip=" {{getSelectPlaceholder()}}"
        class="overflow-ellipsis whitespace-nowrap">
    {{getSelectPlaceholder()}}
  </span>
</app-base-select-button>

<button [placement]="placement" [dropdownTriggerFor]="dropdown" (isOpen)="onDropdownToggled($event)"
        mat-flat-button color="primary" *ngIf="customButton">
  <fa-icon [icon]="customButton.icon"></fa-icon>
  {{customButton.name}}
</button>


<app-dropdown [placement]="placement" #dropdown>
  <app-select-product-dropdown [showAddProductButton]="showAddProductButton" [displayedItemsCount]="displayedItemsCount" [isMultiSelect]="isMultiSelect"
                               (selectionChange)="onSelectionChanged($event)" [selectedItemsInitial]="selectedItemsMulti"
                               [dropdown]="dropdown" [dropdownOpenedSubject]="dropdownOpenedSubject"
                                [excludedIds]="excludedIds">
  </app-select-product-dropdown>
</app-dropdown>
