<div class="filters-wrapper">
  <span class="label-large">Filters:</span>
  <ng-template #filters>
  </ng-template>

  <button (click)="onClearClicked()" class="basic-button button-narrow" mat-button>
    <fa-icon class="icon-x-clear" [icon]="faXmark"></fa-icon>
    {{'table.clearFilters' | translate}}
  </button>
</div>
