import {AbstractControl, FormArray, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {PlatformType} from '../enum/PlatformType';
import {noWhitespaceValidator} from '../../shared/util/form/validators/no-whitespace.validator';
import {merge, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  amazonUrlValidator,
  androidUrlValidator,
  conditionalUrlValidator, huaweiUrlValidator, samsungUrlValidator,
  URL_REGEXP,
  urlStartsWithHttps
} from '../../shared/util/form/validators/url.validator';
import {reverseDomainValidator} from './reverse-domain.validator';
import {storeIdentifierIosLengthValidator, storeIdentifierIosNumericValidator} from './store-identifier-ios.validator';
import {uuidValidator} from './uuid.validator';
import {utmCampaignValidator} from './utm-campaign.validator';
import {IPlatformFormGroup} from '../interface/platform/IPlatformFormGroup';

export function getPlatformType(formGroup: FormGroup<IPlatformFormGroup>): PlatformType {
  return formGroup.controls.type.value?.name as PlatformType;
}

export function getPlatformsObservable(platforms: FormArray<FormGroup<IPlatformFormGroup>>): Observable<number> {
  return merge(...platforms.controls
    .map((formGroup: FormGroup<IPlatformFormGroup>, index: number) =>
      formGroup.valueChanges
        .pipe(map(() => index))));
}

export function getPlatformTypeInputsObservable(platforms: FormArray<FormGroup<IPlatformFormGroup>>): Observable<number> {
  return merge(...platforms.controls
    .map((formGroup: FormGroup<IPlatformFormGroup>, index: number) =>
      formGroup.controls.type.valueChanges
        .pipe(map(() => index))));
}

export function setDependantFieldsValidations(formGroup: FormGroup<IPlatformFormGroup>): void {
  setValidatorsForFields(['platformId', 'defaultURL', 'productPageIds', 'utmCampaign'], formGroup);
}

export function setValidatorsForFields(fields: (keyof IPlatformFormGroup)[], formGroup: FormGroup<IPlatformFormGroup>): void {
  fields.forEach((field) => {
    switch (field) {
      case 'defaultURL':
        updateUrlValidator(formGroup.controls.defaultURL, formGroup);
        break;
      case 'platformId':
        updatePlatformIdValidator(formGroup);
        break;
      case 'productPageIds':
        updateProductPageIdsValidators(formGroup);
        break;
      case 'utmCampaign':
        updateUtmCampaignValidators(formGroup);
        break;
    }
  });
}

export function updateRequiredControlValidator(isRequired: boolean, control: AbstractControl): void {
  if (isRequired) {
    control.addValidators([Validators.required, noWhitespaceValidator]);
  } else {
    control.clearValidators();
    control.setErrors(null);
  }
}

function updateUrlValidator(control: AbstractControl, formGroup: FormGroup<IPlatformFormGroup>): void {
  switch (formGroup.controls.type.value?.name) {
    case PlatformType.WEB:
      control.addValidators(getUrlValidatorWeb());
      break;
    case PlatformType.IOS:
      control.addValidators(getUrlValidatorIOS());
      break;
    case PlatformType.ANDROID:
      control.addValidators(getUrlValidatorAndroid());
      break;
    case PlatformType.HUAWEI:
      control.addValidators(getUrlValidatorHuawei());
      break;
    case PlatformType.SAMSUNG:
      control.addValidators(getUrlValidatorSamsung());
      break;
    case PlatformType.AMAZON:
      control.addValidators(getUrlValidatorAmazon());
      break;
  }
}

function getUrlValidatorWeb(): ValidatorFn[] {
  return [Validators.required, noWhitespaceValidator, conditionalUrlValidator, urlStartsWithHttps];
}

function getUrlValidatorIOS(): ValidatorFn[] {
  return [conditionalUrlValidator, urlStartsWithHttps];
}

function getUrlValidatorAndroid(): ValidatorFn[] {
  return [androidUrlValidator];
}

function getUrlValidatorHuawei(): ValidatorFn[] {
  return [huaweiUrlValidator];
}

function getUrlValidatorSamsung(): ValidatorFn[] {
  return [samsungUrlValidator];
}

function getUrlValidatorAmazon(): ValidatorFn[] {
  return [amazonUrlValidator];
}

function updatePlatformIdValidator(formGroup: FormGroup<IPlatformFormGroup>): void {
  const control: AbstractControl = formGroup.controls.platformId;
  const platformType: PlatformType = getPlatformType(formGroup);
  control.addValidators([Validators.required, noWhitespaceValidator]);
  if (platformType === PlatformType.IOS) {
    control.addValidators([storeIdentifierIosLengthValidator, storeIdentifierIosNumericValidator]);
  } else if (hasBundleId(platformType)) {
    control.addValidators([reverseDomainValidator]);
  }
}

export function hasBundleId(platformType: PlatformType): boolean {
  return platformType === PlatformType.ANDROID || platformType === PlatformType.SAMSUNG ||
    platformType === PlatformType.AMAZON || platformType === PlatformType.HUAWEI;
}

function updateProductPageIdsValidators(formGroup: FormGroup<IPlatformFormGroup>): void {
  const productPageIdArray = formGroup.controls.productPageIds;
  productPageIdArray.controls.forEach(control => control.setValidators([uuidValidator]));
}

function updateUtmCampaignValidators(formGroup: FormGroup<IPlatformFormGroup>): void {
  const utmCampaignControl = formGroup.controls.utmCampaign;
  utmCampaignControl.setValidators([utmCampaignValidator]);
}
