import {Injectable, Injector} from '@angular/core';
import {IDialogConfig} from '../../../interface/ui/dialog/IDialogConfig';
import {DialogService} from '../dialog/dialog.service';
import {ExpandedDialogService} from '../expanded-dialog/expanded-dialog.service';
import {DialogType} from '../../../enum/ui/dialog/Dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogGeneralService {

  constructor(private injector: Injector) { }

  open(dialogConfig: IDialogConfig): void {
    // these services are not injected in constructor to avoid circular dependency
    const dialogService: DialogService = this.injector.get(DialogService);
    const expandedDialogService: ExpandedDialogService = this.injector.get(ExpandedDialogService);
    dialogConfig.type === DialogType.expanded ? expandedDialogService.open(dialogConfig.component, dialogConfig) :
      dialogService.open(dialogConfig.component, dialogConfig);
  }
}
