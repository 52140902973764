import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ComponentRef, EventEmitter, HostListener,
  Inject,
  Injector, OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {TooltipType} from '../../../enum/ui/tooltip/TooltipType';
import {TOOLTIP_DATA} from '../../../directive/tooltip/tooltip-token';
import {Placement} from '../../../enum/ui/tooltip/Placement';
import {ITooltip} from '../../../interface/ui/tooltip/ITooltip';
import {TOOLTIP_CONTENT_DATA} from '../../../directive/tooltip/tooltip-content-token';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements AfterViewInit, OnDestroy {
  @ViewChild('content', {read: ViewContainerRef}) content: ViewContainerRef;
  @Output() public mouseOut: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public closeClicked: EventEmitter<void> = new EventEmitter<void>();

  public isMouseInside = false;

  public TooltipType = TooltipType;
  public Placement = Placement;

  private subscriptions: Subscription[] = [];

  constructor(@Inject(TOOLTIP_DATA) public data: ITooltip, private injector: Injector, private changeDetectorRef: ChangeDetectorRef) { }

  @HostListener('mouseenter')
  private enter(): void {
    this.isMouseInside = true;
  }

  @HostListener('mouseleave')
  private out(): void {
    this.isMouseInside = false;
    this.mouseOut.emit(true);
  }

  public ngAfterViewInit(): void {
    if (this.data.type === TooltipType.CONTENT_TEMPLATE) {
      this.createContentComponent();
    }
  }

  private createContentComponent(): void {
    const injector: Injector = Injector.create({
      parent: this.injector,
      providers: [
        {provide: TOOLTIP_CONTENT_DATA, useValue: this.data.contentComponent.data}
      ],
    });
    const componentRef = this.content.createComponent(this.data.contentComponent.type, {injector});
    this.observeCloseClickedEvent(componentRef);
    this.changeDetectorRef.detectChanges();
  }

  private observeCloseClickedEvent(componentRef: ComponentRef<any>): void {
    const sub = componentRef.instance.closeClicked?.subscribe(() => {
      this.closeClicked.emit();
    });
    this.subscriptions.push(sub);
  }

  public getTooltipCssClasses(): any {
    const classes =  {
      'tooltip-right': this.data.placement === Placement.RIGHT,
      'tooltip-top': this.data.placement === Placement.TOP,
      'tooltip-left': this.data.placement === Placement.LEFT};
    if (this.data.cssClass) {
      classes[this.data.cssClass] = true;
    }
    return classes;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }

}
