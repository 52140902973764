import {Injectable} from '@angular/core';
import {ITableCell} from '../../shared/interface/ui/table/ITableCell';
import {IconOriginType} from '../../shared/enum/ui/icons/IconOriginType';
import {CustomIconName} from '../../shared/enum/ui/icons/CustomIconName';
import {TooltipType} from '../../shared/enum/ui/tooltip/TooltipType';
import {Placement} from '../../shared/enum/ui/tooltip/Placement';
import {firstValueFrom} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {IAdUnit} from '../interface/IAdUnit';
import {AdUnitService} from './ad-unit';
import {ICustomIcon} from '../../shared/interface/ui/icon/Icon';
import {compareAlphabetical} from '../../shared/util/table/sort-utils';
import {IAdUnitSmallInfo} from '../interface/IAdUnitSmallInfo';
import {MyIcon} from '../../shared/type/MyIcon';
import { CellBasic } from 'src/app/shared/interface/ui/my-table/cell.model';

/**
 * Service responsible for mapping Ad Units and providing cells for UI representation.
 */
@Injectable({
  providedIn: 'root'
})
export class AdUnitMapperService {
  public adUnits: IAdUnit[];
  private adUnitsLoaded: Promise<IAdUnit[]>;

  /**
   * Constructor for AdUnitMapperService.
   * @param translateService TranslateService for language translation.
   * @param adUnitService AdUnitService for fetching Ad Units data.
   */
  constructor(private translateService: TranslateService, private adUnitService: AdUnitService) {
    this.initAdUnits();
  }

  /** Initializes Ad Units by fetching data from the Ad Unit service. */
  private async initAdUnits(): Promise<void> {
    this.adUnitsLoaded = this.adUnitService.getAdUnitsFromSubject();
    this.adUnits = await this.adUnitsLoaded;
  }

  /**
   * Generates a table cell with icons based on Ad Unit types.
   * @param adUnitTypes Array of Ad Unit types.
   * @param noItemsMsgKey Key for the message when there are no items.
   * @returns Promise resolving to a table cell with icons.
   */
  public async getAdUnitsCellFromTypes(adUnitTypes: string[], noItemsMsgKey?: string): Promise<CellBasic> {
    if (adUnitTypes?.length > 0) {
      const adUnitTypesSorted = adUnitTypes.sort((a, b) => compareAlphabetical(a, b));
      let tableCellIcons: MyIcon[] = await Promise.all(
        adUnitTypesSorted.map(async adUnitType => (await this.getAdUnitIcon(adUnitType)))
      );
      tableCellIcons = tableCellIcons.filter(el => el);
      return {icons: tableCellIcons, realValue: adUnitTypesSorted};
    } else {
      return await this.getAdUnitsEmptyCell(adUnitTypes, noItemsMsgKey);
    }
  }

  /**
   * Generates an empty table cell for Ad Units.
   * @param adUnitTypes Array of Ad Unit types.
   * @param noItemsMsgKey Key for the message when there are no items.
   * @returns Promise resolving to an empty table cell.
   */
  private async getAdUnitsEmptyCell(adUnitTypes: string[], noItemsMsgKey?: string): Promise<CellBasic> {
    return {
      realValue: adUnitTypes,
      value: noItemsMsgKey ? await firstValueFrom(this.translateService.get(noItemsMsgKey)) : '-'
    };
  }

  /**
   * Generates a table cell with icons based on an array of AdUnitSmallInfo.
   * @param adUnits Array of AdUnitSmallInfo.
   * @returns Promise resolving to a table cell with icons.
   */
  public async getAdUnitsCell(adUnits: IAdUnitSmallInfo[]): Promise<ITableCell> {
    return this.getAdUnitsCellFromTypes(adUnits.map(adUnit => adUnit.type));
  }

  /**
   * Generates a table cell with icons and text for an Ad Unit.
   * @param name Ad Unit name.
   * @param type Ad Unit type.
   * @returns Promise resolving to a table cell with text and icons.
   */
  public async getAdUnitCellWithText(name: string, type: string): Promise<ITableCell> {
    const icon = await this.getAdUnitIcon(type, false);
    return {value: name, icons: icon ? [icon] : []};
  }

  /**
   * Generates a table cell with icons based on Ad Unit format IDs.
   * @param adUnitFormatIds Array of Ad Unit format IDs.
   * @returns Promise resolving to a table cell with icons.
   */
  public async getAdUnitFormatCell(adUnitFormatIds: string[]): Promise<ITableCell> {
    await this.adUnitsLoaded;
    if (adUnitFormatIds?.length > 0) {
      const icons = await this.getFormatIcons(adUnitFormatIds);
      return {icons, realValue: adUnitFormatIds};
    } else {
      return {realValue: adUnitFormatIds, value: '-'};
    }
  }

  /**
   * Retrieves an array of format icons for the provided format IDs.
   * @param formatIds Array of format IDs.
   * @returns Promise resolving to an array of format icons.
   */
  private async getFormatIcons(formatIds: string[]): Promise<MyIcon[]> {
    const adUnits = await this.getAdUnitsFromFormatIds(formatIds);
    const tooltips: string[] = this.getTooltipsForFormats(formatIds, adUnits);
    return await Promise.all(adUnits.map(async (adUnit, idx) =>
      (await this.getAdUnitIcon(adUnit.type, true, tooltips[idx])))
    );
  }

  /**
   * Retrieves tooltips for formats based on the provided format IDs and Ad Units.
   * @param formatIds Array of format IDs.
   * @param adUnits Array of Ad Units.
   * @returns Array of tooltips for formats.
   */
  public getTooltipsForFormats(formatIds: string[], adUnits: IAdUnit[]): string[] {
    return adUnits.map(adUnit => this.getFormatsTooltip(formatIds, adUnit));
  }

  /**
   * Generates a tooltip for the provided format IDs and Ad Unit.
   * @param formatIds Array of format IDs.
   * @param adUnit Ad Unit for which the tooltip is generated.
   * @returns Tooltip string for the specified format IDs and Ad Unit.
   */
  public getFormatsTooltip(formatIds: string[], adUnit: IAdUnit): string {
    const formats: string[] = [];
    adUnit.adUnitFormats.forEach(format => {
      if (formatIds.includes(format.id)) {
        formats.push(format.name);
      }
    });
    return formats.join(', ');
  }

  /**
   * Retrieves an Ad Unit icon based on the provided type.
   * @param type Ad Unit type.
   * @param withTooltip Flag indicating whether to include a tooltip.
   * @param tooltipText Optional tooltip text.
   * @returns Promise resolving to an Ad Unit icon.
   */
  public async getAdUnitIcon(type: string, withTooltip: boolean = true, tooltipText?: string): Promise<MyIcon> {
    if (!AdUnitMapperService.iconNameExists(type)) {
      const iconDefault: ICustomIcon = {type: IconOriginType.CUSTOM, iconName: CustomIconName.AD_UNIT_DEFAULT, class: 'ad-unit-icon'};
      if (withTooltip) {
        iconDefault.tooltip = {
          text: tooltipText ? tooltipText : await this.getAdUnitName(type),
          type: TooltipType.TEXT,
          placement: Placement.TOP
        };
      }
      return iconDefault;
    }
    const icon: ICustomIcon = {type: IconOriginType.CUSTOM, iconName: (type as CustomIconName), class: 'ad-unit-icon'};
    if (withTooltip) {
      icon.tooltip = {
        text: tooltipText ? tooltipText : await this.getAdUnitName(type),
        type: TooltipType.TEXT,
        placement: Placement.TOP
      };
    }
    return icon;
  }

  /**
   * Checks if an icon with the given name exists in the CustomIconName enum.
   * @param iconName Name of the icon.
   * @returns `true` if the icon name exists, `false` otherwise.
   */
  private static iconNameExists(iconName: string): boolean {
    return (Object as any).values(CustomIconName).includes(iconName);
  }

  /**
   * Retrieves the Ad Unit name based on the provided type.
   * @param type Ad Unit type.
   * @returns Promise resolving to the Ad Unit name.
   */
  public async getAdUnitName(type: string): Promise<string> {
    await this.adUnitsLoaded;
    return this.adUnits.find(adUnit => adUnit.type.toLowerCase() === type.toLowerCase())?.name;
  }

  /**
   * Retrieves Ad Unit types based on Ad Unit format IDs.
   * @param adUnitFormatIds Array of Ad Unit format IDs.
   * @returns Promise resolving to an array of Ad Unit types.
   */
  public async getAdUnitTypesFromFormatIds(adUnitFormatIds: string[]): Promise<string[]> {
    await this.adUnitsLoaded;
    const adUnitsForFormats = this.adUnits.filter(adUnit => this.adUnitMatchesFormats(adUnit, adUnitFormatIds));
    return adUnitsForFormats.map(adUnit => adUnit.type);
  }

  /**
   * Retrieves Ad Units based on Ad Unit format IDs.
   * @param adUnitFormatIds Array of Ad Unit format IDs.
   * @returns Promise resolving to an array of Ad Units.
   */
  public async getAdUnitsFromFormatIds(adUnitFormatIds: string[]): Promise<IAdUnit[]> {
    await this.adUnitsLoaded;
    return this.adUnits.filter(adUnit => this.adUnitMatchesFormats(adUnit, adUnitFormatIds))
      .sort((a, b) => compareAlphabetical(a.type, b.type));
  }

  /**
   * Checks if an Ad Unit matches the provided Ad Unit format IDs.
   * @param adUnit Ad Unit to check.
   * @param adUnitFormatIds Array of Ad Unit format IDs.
   * @returns `true` if the Ad Unit matches, `false` otherwise.
   */
  private adUnitMatchesFormats(adUnit: IAdUnit, adUnitFormatIds: string[]): boolean {
    return adUnit.adUnitFormatIds.some(adUnitFormatId => adUnitFormatIds.includes(adUnitFormatId));
  }
}
