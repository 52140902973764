import {Injectable} from '@angular/core';
import {Page} from '../../interface/ui/my-table/page.model';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  public getPagesCount(length: number, pageSize: number): number {
    return !length || length === 0 ? 1 : Math.ceil(length / pageSize);
  }

  public nextDisabled(page: Page, length: number): boolean {
    return page.index + 1 > this.getPagesCount(length, page.size);
  }

  public previousDisabled(pageIdx: number): boolean {
    return pageIdx - 1 < 1;
  }

  public isValidPage(page: Page, length: number): boolean {
    return page.index && page.index > 0 && page.index <= this.getPagesCount(length, page.size);
  }

  public getValidPageIndex(page: Page, length: number): number {
    if (!page.index || page.index < 1) {
      return 1;
    } else if (page.index > this.getPagesCount(length, page.size)) {
      return this.getPagesCount(length, page.size);
    }
    return page.index;
  }
}
