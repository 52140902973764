import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import * as _ from 'lodash';
import {hasRequiredError} from 'src/app/shared/util/form/form-utils';
import {IButtonSimple} from '../../../shared/interface/ui/IButtonSimple';
import {Placement} from '../../../shared/enum/ui/tooltip/Placement';
import {Subject} from 'rxjs';
import {ProductSimple} from '../../interface/IProductSimple';

@Component({
  selector: 'app-select-product-control',
  templateUrl: './select-product-control.component.html',
  styleUrls: ['./select-product-control.component.scss']
})
export class SelectProductControlComponent implements ControlValueAccessor {
  @Input() isMultiSelect = true;
  @Input() isTableFilter = false;
  @Input() placeholder: string;
  @Input() displayedItemsCount = 4;
  @Input() showAddProductButton = true;
  @Input() placement: Placement = Placement.BOTTOM;
  @Input() excludedIds: string[] = [];
  @Input() cssButton: string[] = [];

  @Input() customButton: IButtonSimple;

  public isDropdownOpen = false;
  public dropdownOpenedSubject = new Subject<void>();
  public selectedItemsMulti: ProductSimple[] = [];

  public selectedItemSingle: ProductSimple;

  public hasRequiredError: (control: NgControl) => boolean = hasRequiredError;

  public onChange: any = () => {
  }

  public onTouched: any = () => {
  }

  constructor(public control: NgControl, private changeDetectorRef: ChangeDetectorRef) {
    this.control.valueAccessor = this;
  }

  public isEllipsisActive(e: HTMLElement): boolean {
    return e ? (e.offsetWidth < e.scrollWidth) : false;
  }

  public onDropdownToggled(isOpen: boolean): void {
    this.isDropdownOpen = isOpen;
    if (isOpen) {
      this.dropdownOpenedSubject.next();
    } else {
      this.onTouched();
    }
    this.selectedItemsMulti = _.cloneDeep(this.selectedItemsMulti);
    this.selectedItemSingle = _.cloneDeep(this.selectedItemSingle);
    this.changeDetectorRef.detectChanges();
  }

  public onSelectionChanged(selectedItems: ProductSimple[] | ProductSimple): void {
    if (this.isMultiSelect) {
      this.selectedItemsMulti = selectedItems as ProductSimple[];
      this.onChange(this.selectedItemsMulti);
    } else {
      this.selectedItemSingle = selectedItems as ProductSimple;
      this.onChange(this.selectedItemSingle);
    }
  }

  public isValueSelected(): boolean {
    if (this.isMultiSelect) {
      return this.isValueSelectedMulti();
    } else {
      return this.isValueSelectedSingle();
    }
  }

  private isValueSelectedMulti(): boolean {
    return this.selectedItemsMulti?.length > 0;
  }

  private isValueSelectedSingle(): boolean {
    return !!this.selectedItemSingle;
  }

  public getSelectPlaceholder(): string {
    if (this.isMultiSelect) {
      return this.getPlaceholderForMulti();
    } else {
      return this.getPlaceholderForSingle();
    }
  }

  public getPlaceholderForMulti(): string {
    return this.selectedItemsMulti?.length > 0  ? this.selectedItemsMulti.map(el => el.name).join(', ') : this.placeholder;
  }

  public getPlaceholderForSingle(): string {
    return this.selectedItemSingle ? this.selectedItemSingle.name : this.placeholder;
  }

  public isControlGreyedOut(): boolean {
    return !this.control.value && !this.isDropdownOpen;
  }

  /* Control Value Accessor */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    if (this.isMultiSelect) {
      this.selectedItemsMulti = _.cloneDeep(obj) || [];
    } else {
      this.selectedItemSingle = _.cloneDeep(obj);
    }
  }
}
