import {Injectable} from '@angular/core';
import {DialogRef} from '../dialog/dialog/dialog-ref';
import {ConfirmDialogService} from '../dialog/confirm-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class FormCloseService {

  constructor(private confirmDialogService: ConfirmDialogService) {
  }

  public async getConfirmation(): Promise<boolean> {
    const dialogRef: DialogRef = await this.confirmDialogService.openConfirmFormClose();
    return new Promise(resolve => {
      dialogRef.afterClosed().subscribe(async (value) => {
        resolve(!!value);
      });
    });
  }
}
