import {Component, Input} from '@angular/core';
import {ControlValueAccessor, FormControl, NgControl} from '@angular/forms';
import {ICheckboxFilterVal} from '../../../../../interface/ui/table/ICheckboxFilterVal';

@Component({
  selector: 'app-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  styleUrls: ['./checkbox-filter.component.scss']
})
export class CheckboxFilterComponent implements ControlValueAccessor {
  @Input() label: string;

  public checkbox = new FormControl<boolean>(false);
  public onChange: (value: ICheckboxFilterVal) => void = () => {
  }
  public onTouched: any = () => {
  }

  constructor(public control: NgControl) {
    this.control.valueAccessor = this;
  }

  public onValueChanged(): void {
    this.checkbox.setValue(!this.checkbox.value);
    this.onTouched();
    this.onChange({isSelected: this.checkbox.value});
  }

  /* Control Value Accessor */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: ICheckboxFilterVal): void {
    this.checkbox.setValue(!!obj?.isSelected);
  }

}
