import {Injectable} from '@angular/core';
import {ApiPlatformService} from './api-platform.service';
import {ProductsService} from './product/products.service';
import {ApiErrorService} from '../../shared/service/api/api-error.service';
import {firstValueFrom} from 'rxjs';
import {IDefaultTrackingLink} from '../interface/IDefaultTrackingLink';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor(private apiPlatformService: ApiPlatformService,
              private productService: ProductsService,
              private apiErrorService: ApiErrorService) {
  }

  public async validatePlatformId(platformId: string): Promise<boolean> {
    try {
      return await firstValueFrom(this.apiPlatformService.validatePlatformId(platformId));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'platform.platformValidateErrorMessage');
      throw error;
    }
  }

  public async deletePlatform(id: string): Promise<void> {
    try {
      await firstValueFrom(this.apiPlatformService.deletePlatform(id));
      this.productService.productChangedSubject.next();
      return Promise.resolve();
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'platform.platformDeleteErrorMessage');
      throw error;
    }
  }

  public async deleteExternalTracking(id: string): Promise<void> {
    try {
      await firstValueFrom(this.apiPlatformService.deleteExternalTracking(id));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'platform.trackingDeleteErrorMessage');
      throw error;
    }
  }

  public async getExternalTrackingDefaultLinks(): Promise<IDefaultTrackingLink> {
    try {
      return await firstValueFrom(this.apiPlatformService.getExternalTrackingDefaultLinks());
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'platform.trackingDefaultLinksErrorMessage');
      throw error;
    }
  }

  public async getCustomTrackingDefaultLinks(): Promise<IDefaultTrackingLink> {
    try {
      return await firstValueFrom(this.apiPlatformService.getCustomTrackingDefaultLinks());
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'platform.trackingDefaultLinksErrorMessage');
      throw error;
    }
  }
}
