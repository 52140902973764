import {ChangeDetectorRef, Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {BACK_ACTION} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {IBackAction} from '../../../shared/interface/ui/dialog/IBackAction';
import {Subject} from 'rxjs';
import {WaterfallService} from '../../service/waterfall.service';
import {faPlus} from '@fortawesome/pro-light-svg-icons';
import {IWaterfall} from '../../interface/IWaterfall';
import {WaterfallMapperService} from '../../service/waterfall-mapper/waterfall-mapper.service';
import {WaterfallDialogService} from '../../service/waterfall-dialog/waterfall-dialog.service';
import {ProductsService} from '../../../product/service/product/products.service';
import {ILoadingView} from '../../../shared/interface/ui/ILoadingView';
import {WaterfallEndpoints} from '../../../shared/enum/Endpoints';
import {IProductDetails} from '../../../product/interface/IProductDetails';
import {ProductMapperService} from '../../../product/service/product-mapper/product-mapper.service';

@Component({
  selector: 'app-waterfall-product-list',
  templateUrl: './waterfall-product-list.component.html',
  styleUrls: ['./waterfall-product-list.component.scss']
})
export class WaterfallProductListComponent implements OnInit, ILoadingView {
  @Input() productId: string;

  public faPlus = faPlus;
  public closeDialogSubject = new Subject<void>();

  public refreshWaterfallList: Subject<void> = new Subject();
  public waterfalls: IWaterfall[] = [];
  public dataLoaded = false;

  public product: IProductDetails;
  public loadingEndpointNames = this._loadingEndpointNames.bind(this);

  constructor(@Optional() @Inject(BACK_ACTION) public backAction: IBackAction,
              private waterfallService: WaterfallService, private waterfallMapperService: WaterfallMapperService,
              private waterfallDialogService: WaterfallDialogService, private productService: ProductsService,
              private changeDet: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.initProduct();
    this.fetchWaterfalls();
    this.waterfallService.waterfallChangedSubject.subscribe(async () => {
      await this.fetchWaterfalls();
    });
  }

  private async initProduct(): Promise<void> {
    this.product = await this.productService.getProductByInternalId(this.productId);
  }

  private async fetchWaterfalls(): Promise<void> {
    this.waterfalls = await this.waterfallService.getWaterfallsByProduct(this.productId);
    this.dataLoaded = true;
    this.changeDet.detectChanges();
    this.refreshWaterfallList.next();
  }

  public onAddWaterfallClicked(): void {
    this.waterfallDialogService.openWaterfallAddDialog({product: ProductMapperService.getProductFromDetailed(this.product)});
  }


  private _loadingEndpointNames(): string[] {
    return this.productId ? [WaterfallEndpoints.getWaterfallsByProduct(this.productId),
        WaterfallEndpoints.deleteWaterfall(), WaterfallEndpoints.editWaterfall()]
      : [WaterfallEndpoints.deleteWaterfall(), WaterfallEndpoints.editWaterfall()];
  }
}
