import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ITableButton} from '../../../interface/ui/table/ITableButton';
import {CellHandler} from '../../../interface/ui/my-table/cell-handler.interface';
import {CellButton} from '../../../interface/ui/my-table/cell.model';
import {CellButtonEvent} from '../../../interface/ui/my-table/cell-event.model';
import {NgClasses} from '../../../type/NgClasses';
import {Button} from '../../../interface/ui/my-table/button.model';
@Component({
  selector: 'app-cell-buttons',
  templateUrl: './cell-buttons.component.html',
  styleUrl: './cell-buttons.component.scss'
})
export class CellButtonsComponent implements CellHandler {
  public buttons: ITableButton[];
  @Input({required: true}) cell: CellButton;
  @Output() public cellEvent: EventEmitter<CellButtonEvent> = new EventEmitter<CellButtonEvent>();

  public onClicked(idx: number): void {
    this.cellEvent.emit({type: 'click', buttonIdx: idx});
  }

  public getCssClasses(button: Button): NgClasses {
    const cssClass: NgClasses = {};
    button.cssClasses?.forEach(el => cssClass[el] = true);
    return cssClass;
  }
}
