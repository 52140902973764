import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ExpandedDialogService} from '../../../service/dialog/expanded-dialog/expanded-dialog.service';
import {Router} from '@angular/router';
import {AuthService} from '../../../service/auth/auth/auth.service';
import {RedirectService} from '../../../service/redirect/redirect.service';
import {SidebarService} from '../../../service/navbar/sidebar.service';
import {Subscription} from 'rxjs';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {faGripVertical, faWarning} from '@fortawesome/pro-light-svg-icons';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-sidenav-container',
  templateUrl: './sidenav-container.component.html',
  styleUrls: ['./sidenav-container.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        width: '198px'
      })),
      state('closed', style({
        width: '80px',
      })),
      transition('open <=> closed', [
        animate('0.1s')
      ])
    ]),
    trigger('openCloseContent', [
      state('open', style({
        marginLeft: '198px'
      })),
      state('closed', style({
        marginLeft: '80px',
      })),
      transition('open <=> closed', [
        animate('0.1s')
      ])
    ])
  ],
})
export class SidenavContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('customDialog', {read: ViewContainerRef}) dialog: ViewContainerRef;
  public isMenuOpen = false;
  public navItemsContext: INavItem[] = [
    {iconName: 'dashboard', route: '/dashboard', routeFirstSegment: '/dashboard', name: 'Dashboard'},
    {iconName: 'product', route: '/products/list', routeFirstSegment: '/products', name: 'Product'},
    {iconName: 'creative', route: '/creative/list', routeFirstSegment: '/creative', name: 'Creative'},
    {iconName: 'campaign', route: '/campaign/list', routeFirstSegment: '/campaign', name: 'Campaign'},
    {iconName: 'waterfall', route: '/waterfall/list', routeFirstSegment: '/waterfall', name: 'Waterfall'},
    {faIcon: faGripVertical, route: '/more-games/list', routeFirstSegment: '/more-games', name: 'More Games'},
    {iconName: 'whitelist', route: '/whitelist/list', routeFirstSegment: '/whitelist', name: 'Whitelist'}
  ];

  public isSidebarHidden;
  public subscriptions: Subscription[] = [];
  protected readonly faWarning = faWarning;

  constructor(private expandedDialogService: ExpandedDialogService, private router: Router, private authService: AuthService,
              private redirectService: RedirectService, private sidebarService: SidebarService) {
  }

  ngOnInit(): void {
    const sub = this.sidebarService.sidebarToggle.subscribe(isOpen => {
      this.isSidebarHidden = !isOpen;
    });
    this.subscriptions.push(sub);
  }

  public onLogOutClicked(): void {
    this.authService.logout();
  }

  public onNavItemSelected(link: string): void {
    this.expandedDialogService.closeAll();
    this.redirectSameUrl(link);
  }

  public redirectSameUrl(link: string): void {
    this.redirectService.navigateSameUrl(link);
  }

  public onMenuClose(): void {
    this.isMenuOpen = false;
  }

  public onMenuOpen(): void {
    this.isMenuOpen = true;
  }

  ngAfterViewInit(): void {
    this.expandedDialogService.setRootViewContainerRef(this.dialog);
  }

  public isAssetRouteActive(routeFirstSegment: string): boolean {
    return (routeFirstSegment === '/creative' && this.router.url.startsWith('/assets')) ||
      this.router.url.startsWith(routeFirstSegment);
  }

  public ngOnDestroy():void {
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }

  public showTestServerInfo(): boolean {
    return environment.production === false && !window.location.toString().includes('localhost');
  }
}

interface INavItem {
  iconName?: string;
  route: string;
  routeFirstSegment: string;
  name: string;
  faIcon?: IconDefinition;
}
