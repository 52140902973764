<div class="paginator mat-caption">
  <div class="align-center-gap-10">
  <fa-icon [ngClass]="{'arrow__disabled': previousDisabled}" (click)="previous()" class="arrow arrow__prev" [icon]="faArrowLeft"></fa-icon>
  <input type="number" class="mat-caption"
         autocomplete="off"
         aria-label="page"
         matInput
         name="pagination"
         [formControl]="pageFormControl"
         (keydown.enter)="onBlur($event)"
         (focusout)="onValueEntered()"
  >
  <span>of</span>
    <span>{{pagesCount}}</span>
  <fa-icon [ngClass]="{'arrow__disabled': nextDisabled}" (click)="next()" class="arrow arrow__next" [icon]="faArrowRight"></fa-icon>
  </div>
</div>
