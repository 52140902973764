import {Component, EventEmitter, Input, Output} from '@angular/core';
import { faFloppyDiskCircleArrowRight, faTrash } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-base-dropdown-select',
  templateUrl: './base-dropdown-select.component.html',
  styleUrls: ['./base-dropdown-select.component.scss']
})
export class BaseDropdownSelectComponent {
  @Input() buttonsDisabled = false;
  @Input() showActionButtons = true;

  public faFloppyDiskCircleArrowRight = faFloppyDiskCircleArrowRight;
  public faTrash = faTrash;

  @Output() saveClicked = new EventEmitter();
  @Output() cleanClicked = new EventEmitter();

  constructor() { }

}
