import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {DialogService} from '../../../shared/service/dialog/dialog/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmDialogService} from '../../../shared/service/dialog/confirm-dialog.service';
import {TranslationPrefix} from '../../../shared/enum/ui/dialog/TranslationPrefix';

@Directive({
  selector: '[appDeletePlatform]'
})
export class DeletePlatformDirective {
  // eslint-disable-next-line
  @Input('appDeletePlatform') public isActive = true;
  // eslint-disable-next-line
  @Input('platformName') public platformName: string;
  // eslint-disable-next-line
  @Input('productName') public productName: string;

  @Output() confirmed: EventEmitter<any> = new EventEmitter();

  @HostListener('click')
  onClick(): void {
    if (this.isActive) {
      this.openDialog();
    }
  }

  constructor(private dialogService: DialogService, private translateService: TranslateService,
              private confirmDialogService: ConfirmDialogService) {
  }

  private async openDialog(): Promise<void> {
    const dialogRef: DialogRef = await this.confirmDialogService
      .openDeleteDialog({
        objectName: TranslationPrefix.PLATFORM,
        data: {name: this.productName, platformType: this.platformName}
      });
    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.confirmed.emit();
      }
    });
  }
}
