<app-base-side-dialog [dialogRef]="dialogRef">
  <app-spinner *ngIf="isLoading"></app-spinner>

  <div class="preview-content" *ngIf="asset">
    <div class="text-light side-dialog--padding-horizontal preview-header">
      <p class="color-dark-grey-fourth">{{'asset.assetPreview.assetPreview' | translate}}</p>
      <div class="side-dialog-header-row asset-name">
        <h1 class="align-center-gap-10">{{getNameWithExtension()}}
          <fa-icon (click)="openAssetLinkClicked()" class="color-primary cursor-pointer" [icon]="faArrowUpRightFromSquare"></fa-icon></h1>
      </div>
      <div class="side-dialog-header-row">
        <div class="align-center-gap-10">
          <p>{{'adUnit.adUnit' | translate}}:</p>
          <app-ad-unit-icons [adUnitTypes]="adUnitTypes"></app-ad-unit-icons>
        </div>
        <p>
          {{'product.productForm.productName' | translate}}: {{asset.product?.name}}
        </p>
      </div>
    </div>
    <hr class="separator separator--no-margin">
    <div class="side-dialog--padding-horizontal preview-body--scrollable">
      <div class="text-light">
        <p>{{'asset.assetPreview.adUnitFormat' | translate}}: {{adUnitFormatNames.join(', ')}}</p>
        <p>{{'asset.assetDetails.duration' | translate}}: {{asset.duration}}s</p>
        <p>{{'asset.assetDetails.format' | translate}}: {{asset.format}}</p>
        <p>{{'asset.assetDetails.resolution' | translate}}: {{getResolution()}}</p>
        <p>{{'asset.assetDetails.ratio' | translate}}: {{asset.ratio}}</p>
        <p>{{'asset.assetDetails.size' | translate}}: {{asset.size | fileSize}}</p>
        <hr>
      </div>
      <div class="side-dialog-action-buttons">
        <button (click)="onCloseClicked()" mat-stroked-button color="primary">
          <fa-icon [icon]="faXMark" class="close-icon-big"></fa-icon>
          {{'button.close' | translate}}
        </button>
        <button (click)="onEditAssetClicked()" mat-flat-button color="primary">
          <fa-icon [icon]="faPenToSquare"></fa-icon>
          {{'asset.editAssetButton' | translate}}
        </button>
      </div>
    </div>
  </div>
</app-base-side-dialog>
