<div class="align-center-gap-10">
  <ng-container *ngFor="let button of cell.buttons; let i = index">
    <ng-container *ngIf="!button.type || button.type === 'mat-flat-button'">
      <button [disabled]="!!button.disabled" (click)="onClicked(i)"
              [ngClass]="getCssClasses(button)" class="button-narrow"
              mat-flat-button color="primary">
        <fa-icon *ngIf="button.iconFa" [icon]="button.iconFa"></fa-icon>
        {{button.name}}
      </button>
    </ng-container>
    <ng-container *ngIf="button.type === 'mat-stroked-button'">
      <button [disabled]="!!button.disabled" (click)="onClicked(i)"
              [ngClass]="getCssClasses(button)" class="button-narrow"
              mat-stroked-button color="primary">
        <fa-icon *ngIf="button.iconFa" [icon]="button.iconFa"></fa-icon>
        {{button.name}}
      </button>
    </ng-container>
  </ng-container>
</div>
