import {Injectable} from '@angular/core';
import {faEye, faPaintbrushPencil, faPenToSquare, faTrash} from '@fortawesome/pro-light-svg-icons';

import {IconOriginType} from '../../../shared/enum/ui/icons/IconOriginType';
import {CustomIconName} from '../../../shared/enum/ui/icons/CustomIconName';
import {EnumTranslateService} from '../../../shared/service/translate/enum-translate.service';
import {ProductTag} from '../../enum/ProductTag';
import {IProduct} from '../../interface/IProduct';
import {TranslateService} from '@ngx-translate/core';
import {AdUnitMapperService} from '../../../ad-unit/service/ad-unit-mapper.service';
import {compareAlphabetical} from '../../../shared/util/table/sort-utils';
import {IProductDetails} from '../../interface/IProductDetails';
import {IPlatformSmallInfo} from '../../interface/platform/IPlatformSmallInfo';
import {PlatformMapperService} from '../platform-mapper/platform-mapper.service';
import {TableAction} from 'src/app/shared/enum/TableAction';
import {ProductRow} from '../../interface/ProductRow';
import {CellBasic} from 'src/app/shared/interface/ui/my-table/cell.model';
import {ActionsCellComponent} from 'src/app/shared/component/common/actions-cell/actions-cell.component';
import {Button} from 'src/app/shared/interface/ui/my-table/button.model';
import {CellButtonsComponent} from 'src/app/shared/component/common/cell-buttons/cell-buttons.component';

@Injectable({
  providedIn: 'root'
})
export class ProductMapperService {

  constructor(private enumTranslateService: EnumTranslateService, private translateService: TranslateService,
              private adUnitMapperService: AdUnitMapperService,
              private platformMapperService: PlatformMapperService) {
  }

  public static getProductFromDetailed(productDetailed: IProductDetails): IProduct {
    return {
      ...productDetailed,
      platforms: productDetailed.platforms.map(platform => ({
        id: platform.id,
        type: platform.type,
        platformId: platform.platformId,
        disabled: platform.disabled
      }))
    };
  }

  private getNameCell(product: IProduct, withTags: boolean): CellBasic {
    const cell: CellBasic = {
      value: product.name,
      cssClass: 'cursor-pointer',
      // handlerFn: this.openPreviewHandler.bind(this)
    };
    return withTags ? {...cell, tags: (product.tags as ProductTag[])} : cell;
  }

  public async mapProductsToRows(products: IProduct[], withTags: boolean = true): Promise<ProductRow[]> {
    return Promise.all(products.map(async (product) => {
      const row: ProductRow = {
        id: {value: product.id, realValue: product.id},
        name: this.getNameCell(product, withTags),
        productId: {value: product.boombitId, cssClassTd: ['column-waterfalls-list']},
        adUnit: await this.adUnitMapperService.getAdUnitsCellFromTypes(product.adUnitTypes, 'creative.noCreatives'),
        defaultCampaign: {value: product.defaultCampaign?.name || '-'},
        assignedWaterfalls: {realValue: product.assignedWaterfalls},
        platforms: await this.platformMapperService.getPlatformsCell(product.platforms),
        storeId: await this.getStoreIdCell(product.platforms),
        actions: {
          component: ActionsCellComponent, actions: [
            {name: TableAction.show, iconFa: faEye},
            {name: TableAction.edit, iconFa: faPenToSquare},
            {name: TableAction.delete, iconFa: faTrash},
          ]
        },
        buttons: {
          component: CellButtonsComponent,
          buttons: [this.getManageWaterfallsButton(), this.getUploadNewCreativeButton()],
          cssClassTd: ['two-buttons-column'],
        },
        trackById: product.id,
        product
      };
      return row;
    }));
  }

  public async getStoreIdCell(platforms: IPlatformSmallInfo[]): Promise<CellBasic> {
    const platformsWithStoreId = platforms.filter(platform => !!platform.platformId)
      .sort((a, b) => compareAlphabetical(a.type, b.type));
    const innerCells: CellBasic[] = await Promise.all(platformsWithStoreId.map(async platform => ({
      value: platform.platformId,
      icons: [{
        type: IconOriginType.CUSTOM,
        iconName: platform.type as unknown as CustomIconName,
        class: platform.type.toLowerCase() + '-icon-xs'
      }]
    })));
    return innerCells.length > 0 ? {multiValue: innerCells, cssClassTd: ['column-waterfalls-list']} : {
      value: '-',
      cssClassTd: ['column-waterfalls-list']
    };
  }

  public getManageWaterfallsButton(disabled: boolean = false): Button {
    return {name: 'Manage waterfalls', type: 'mat-flat-button', disabled: disabled};
  }

  public getUploadNewCreativeButton(): Button {
    return {name: 'Upload new creative', iconFa: faPaintbrushPencil, type: 'mat-stroked-button'};
  }
}
