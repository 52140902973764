import {Component, Input} from '@angular/core';
import {ControlValueAccessor, FormControl, NgControl} from '@angular/forms';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements ControlValueAccessor {
  @Input({required: true}) pagesCount: number;
  @Input() public nextDisabled: boolean;
  @Input() public previousDisabled: boolean;
  public pageFormControl: FormControl<number> = new FormControl<number>(1);

  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;

  public onChange: (value: number) => void;
  public onTouched: () => void;

  constructor(private control: NgControl) {
    this.control.valueAccessor = this;
  }

  public next(): void {
    if (!this.nextDisabled) {
      this.pageFormControl.setValue(this.pageFormControl.value + 1);
      this.onChange(this.pageFormControl.value);
    }
  }

  public previous(): void {
    if (!this.previousDisabled) {
      this.pageFormControl.setValue(this.pageFormControl.value - 1);
      this.onChange(this.pageFormControl.value);
    }
  }

  public onValueEntered(): void {
    this.onChange(this.pageFormControl.value);
  }

  public onBlur(event: Event): void {
    const target = event.target as HTMLInputElement;
    target.blur();
  }


  public registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: number): void {
    this.pageFormControl.setValue(value);
  }
}
