<ng-container *ngIf="isDialog">
  <app-base-expanded-dialog [showConfirmPopup]="true" [backAction]="backAction" [closeDialogObservable]="closeDialogSubject.asObservable()">
    <ng-container *ngTemplateOutlet="assetForm"></ng-container>
  </app-base-expanded-dialog>
</ng-container>

<ng-container *ngIf="!isDialog">
  <ng-container *ngTemplateOutlet="assetForm"></ng-container>
</ng-container>


<ng-template #assetForm>
<hr *ngIf="!isDialog" class="nav-separator">
<div class="form-page">
  <div [ngClass]="isDialog ? 'no-padding-top' : ''" class="add-form-header">
    <h1 *ngIf="!isEditMode">{{'asset.assetForm.addAssetHeader' | translate}}</h1>
    <h1 *ngIf="isEditMode">{{'asset.assetForm.editAssetHeader' | translate}}</h1>
    <p *ngIf="!isEditMode">{{'asset.assetForm.addAssetInfo' | translate}}</p>
    <p *ngIf="isEditMode">{{'asset.assetForm.editAssetInfo' | translate}}</p>
  </div>
  <div [ngClass]="{'form-shadow-bottom--hidden': !hasScroll}" class="asset-form form-shadow-bottom" #formScrollable>
    <div class="form-controls-group">
      <div class="form-controls-row">
        <p class="text-16-txt-1-medium" *ngIf="this.initialValue?.product">
          {{'asset.assetForm.productName' | translate}}: <span class="mat-body-2">{{this.initialValue.product.name}}</span></p>
        <div *ngIf="!this.initialValue?.product" class="form-control">
          <div class="label">{{'asset.assetForm.productName' | translate}} <span class="color-error">*</span></div>
          <app-select-product-control [placeholder]="'asset.assetForm.productNamePlaceholder' | translate"
                                      [isMultiSelect]="false"
                                      [formControl]="productFormControl"></app-select-product-control>
          <span *ngIf="hasRequiredError(productFormControl)" class="error-message">{{'form.fieldRequired' | translate}}</span>
        </div>
      </div>
      <div *ngIf="!isEditMode" class="form-controls-row mat-caption">
        <p><span class="color-error">*</span>{{'form.fieldRequiredDescription' | translate}}</p>
      </div>
    </div>
    <div class="form-controls-group">
      <div class="form-controls-row">
        <div>
          <h2>{{'asset.assetForm.uploadAsset' | translate}}</h2>
          <app-asset-file-input [asset]="asset"
                                (fileSelected)="onFileSelected($event)"
                                (fileRemoved)="onFileDeleted()"
                                [status]="isFileError() ? Status.ERROR : isFileValid() ? Status.SUCCESS : null">

          </app-asset-file-input>
          <p class="asset-error-message" *ngIf="isEditMode && errors.formatIncompatibleForEdit">
            <fa-icon [ngClass]="'color-error'"
                     [icon]="faXMark"></fa-icon>
            {{'asset.assetForm.adUnitFormatChangedError' | translate}}
            <fa-icon [appTooltip]="'asset.assetForm.adUnitFormatTooltip' | translate" class="color-primary cursor-pointer"
                     [icon]="faCircleInfo"></fa-icon>
          </p>
          <p class="asset-error-message" *ngIf="errors.noMatchedFormats">
            <fa-icon [ngClass]="'color-error'"
                     [icon]="faXMark"></fa-icon>
            {{'asset.assetForm.noAdUnitMatchesError' | translate}}
          </p>
          <p class="asset-error-message" *ngIf="errors.nameExists">
            <fa-icon [ngClass]="'color-error'"
                     [icon]="faXMark"></fa-icon>
            {{'asset.assetForm.fileNameExistsError' | translate}}
          </p>
        </div>
      </div>
    </div>
    <div [ngClass]="{'formats-table--inactive': !this.asset}">
      <h2>{{'asset.assetForm.formatsMatched' | translate}}</h2>
      <table>
        <thead>
        <tr>
          <th class="mat-body-1" *ngFor="let matchedFormat of matchedFormats">
            <div class="table-header">
              <fa-icon class="status-icon" [ngClass]="matchedFormat.isMatch ? 'color-success' : 'color-error'"
                       [icon]="matchedFormat.isMatch ? faCheck : faXMark"></fa-icon>
              {{matchedFormat.label}}
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="mat-body-2" *ngFor="let matchedFormat of matchedFormats">
            <div class="table-cell text-uppercase">
              <fa-icon class="status-icon" [ngClass]="matchedFormat.isMatch ? 'color-success' : 'color-error'"
                       [icon]="matchedFormat.isMatch ? faCheck : faXMark"></fa-icon>
              {{matchedFormat.isMatch ? ('basic.yes' | translate) : ('basic.no' | translate)}}
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>

  <hr>
  <div class="add-form-action-buttons">
    <button (click)="onCancelClicked()" mat-stroked-button color="primary">
      {{'button.cancel' | translate}}
    </button>
    <button [disabled]="!isFormValid()" (click)="onSubmitClicked()" mat-flat-button color="primary">
      <fa-icon [icon]="faFloppyDiskCircleArrowRight"></fa-icon>
      {{'asset.assetForm.saveAssetButton' | translate}}
    </button>
    <mat-spinner *ngIf="isLoading" [diameter]="30"></mat-spinner>
  </div>
</div>
</ng-template>
