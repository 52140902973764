<div class="table-wrapper">
  <div *ngIf="withFilters" class="table-filters">
    <ng-content select="[filtersPrefix]">
    </ng-content>
    <app-table-filters [filters]="filters"
                      (filtersUpdated)="onFiltersUpdated($event)"></app-table-filters>
    <ng-content select="[filtersPostfix]">
    </ng-content>
  </div>

  <div class="table-content" #tableContent>
    <table [ngClass]="getTableCssClass()" [hidden]="noMatchFound || isLoading" mat-table [dataSource]="rows" [trackBy]="trackBy">
      <ng-container *ngFor="let header of headers; let i = index">
        <ng-container [matColumnDef]="header.name">
          <th [ngClass]="getCssClassTh(header)" mat-header-cell *matHeaderCellDef>
            <app-table-header (headerEvent)="onHeaderEvent($event)" [tableHeader]="header"></app-table-header>
          </th>

          <td [ngClass]="getCssClassTd(element[header.name])"
              [style.height.px]="dynamicRowHeight ? '' : rowHeight"
              [style.max-height.px]="dynamicRowHeight ? '' : rowHeight"
              mat-cell *matCellDef="let element;let rowIdx = index">
            <div class="overflow-hidden" [style.max-height.px]="dynamicRowHeight ? '' : rowHeight">
              <app-cell (cellEvent)="onCellEvent($event, header.name, element, rowIdx)" [cell]="element[header.name]"></app-cell>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <tr [style.height.px]="headerHeight" mat-header-row *matHeaderRowDef="headerNames"></tr>
      <tr [ngClass]="getCssClassTr(row)" mat-row *matRowDef="let row; columns: headerNames;"></tr>
    </table>

    <ng-container *ngIf="noMatchFound">
      <ng-container *ngTemplateOutlet="noMatches"></ng-container>
    </ng-container>
    <ng-container *ngIf="isLoading">
      <ng-container *ngTemplateOutlet="isLoadingData"></ng-container>
    </ng-container>
  </div>

  <div *ngIf="withPagination" class="pagination">
    <app-pagination [formControl]="pageControl"
                    [pagesCount]="getPageCount()"
                    [nextDisabled]="isNextDisabled()"
                    [previousDisabled]="isPreviousDisabled()">
    </app-pagination>
  </div>
  <ng-container *ngIf="spinnerForInfinityScroll">
    <ng-container *ngTemplateOutlet="spinnerForInfinityScrollTmpl"></ng-container>
  </ng-container>
</div>

<ng-template #noMatches>
  <div class="justify-center">
    <div class="no-matches-error">
      <span class="x-icon">&#10006;</span>
      <h1 class="headline-large">Sorry, we couldn't find any matches.</h1>
    </div>
  </div>
</ng-template>

<ng-template #isLoadingData>
  <div class="align-center justify-center">
    <div class="no-matches-error">
      <mat-spinner [diameter]="30"></mat-spinner>
      <span *ngIf="loadingText">{{loadingText}}</span>
    </div>
  </div>
</ng-template>

<ng-template #spinnerForInfinityScrollTmpl>
  <div class="align-center justify-center">
      <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</ng-template>
