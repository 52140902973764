import {Component, Input} from '@angular/core';
import {faXmark} from '@fortawesome/pro-light-svg-icons';
import {DialogRef} from '../../../service/dialog/dialog/dialog-ref';

@Component({
  selector: 'app-base-center-dialog',
  templateUrl: './base-center-dialog.component.html',
  styleUrls: ['./base-center-dialog.component.scss']
})
export class BaseCenterDialogComponent {
  faXMark = faXmark;
  @Input() dialogRef: DialogRef;
  @Input() showCloseButton = true;

  constructor() { }

  public onClose(): void {
    this.dialogRef.close();
  }

}
