import {ITableFilterInput} from '../../shared/interface/ui/table/ITableFilterInput';
import {AssetFilter} from '../enum/AssetFilter';
import {IResolution} from '../interface/IResolution';
import {INumberRange} from '../interface/INumberRange';
import {RESPONSIVE} from './ratio.util';

/**
 * Function to generate table filter inputs for asset lists.
 * This function creates an array of table filter inputs with predefined options for filtering asset lists.
 */
export function getFiltersForAssetList(): ITableFilterInput[] {
  return [
    {
      name: AssetFilter.FORMAT, label: 'Format', selectedItems: [], items:
        [{name: 'png', label: 'png'}, {name: 'jpg', label: 'jpg'},
          {name: 'gif', label: 'gif'}, {name: 'mp4', label: 'mp4'}, {name: 'html', label: 'html'}]
    }, {
      name: AssetFilter.RESOLUTION, label: 'Resolution', selectedItems: [], items: [
        {name: '320x50 px', label: '320x50 px', value: ({width: 320, height: 50} as IResolution)},
        {name: '640x100 px', label: '640x100 px', value: ({width: 640, height: 100} as IResolution)},
        {name: '728x90 px', label: '728x90 px', value: ({width: 728, height: 90} as IResolution)},
        {name: '380x380 px', label: '380x380 px', value: ({width: 380, height: 380} as IResolution)},
        {name: '360x640 px', label: '360x640 px', value: ({width: 360, height: 640} as IResolution)},
        {name: '512x512 px', label: '512x512 px', value: ({width: 512, height: 512} as IResolution)},
        {name: '1024x1024 px', label: '1024x1024 px', value: ({width: 1024, height: 1024} as IResolution)},
        {name: RESPONSIVE, label: RESPONSIVE}
      ]
    },
    {
      name: AssetFilter.DURATION, label: 'Duration', selectedItems: [], items: [
        {name: '0 s', label: '0 s', value: ({min: 0, max: 0} as INumberRange)},
        {name: '1 s - 15 s', label: '1 s - 15 s', value: ({min: 1, max: 15} as INumberRange)},
        {name: '15 s - 30 s', label: '15 s - 30 s', value: ({min: 15, max: 30} as INumberRange)},
        {name: '> 30 s', label: '> 30 s', value: ({min: 31, max: null} as INumberRange)}
      ]
    },
    {
      name: AssetFilter.RATIO, label: 'Ratio', selectedItems: [], items: [
        {name: '1:1', label: '1:1'}, {name: '32:5', label: '32:5'}, {name: '364:45', label: '364:45'},
        {name: '9:16', label: '9:16'}, {name: RESPONSIVE, label: RESPONSIVE}
      ]
    },
    {
      name: AssetFilter.SIZE, label: 'Size', selectedItems: [], items: [
        {name: '<150kB', label: '<150 kB', value: ({max: 153600, min: 0} as INumberRange)},
        {name: '150 - 300 kB', label: '150 - 300 kB', value: ({max: 307200, min: 153600} as INumberRange)},
        {name: '>300 kB', label: '>300 kB', value: ({max: null, min: 307200} as INumberRange)}
      ]
    }
  ];
}
