import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  UntypedFormArray,
  ValidationErrors,
} from '@angular/forms';
import {PlatformType} from 'src/app/product/enum/PlatformType';
import {
  getPlatformsObservable,
  getPlatformTypeInputsObservable,
  setDependantFieldsValidations
} from '../../../util/platform-form-utils';
import {IPlatformFormTemplate} from '../../../interface/platform/IPlatformFormTemplate';
import {getPlatformTemplateByType} from '../../../util/platform-form-template-utils';
import {merge, Observable, of, Subscription, timer} from 'rxjs';
import {IBasicSelectOption} from '../../../../shared/interface/ui/form/IBasicSelectOption';
import {EnumTranslateService} from '../../../../shared/service/translate/enum-translate.service';
import {faCircleInfo, faPlus, faTrash} from '@fortawesome/pro-light-svg-icons';
import {hasError, hasRequiredError} from 'src/app/shared/util/form/form-utils';
import {switchMap} from 'rxjs/operators';
import {PlatformService} from '../../../service/platform.service';
import {ExecutionPointType} from '../../../enum/ExecutionPointType';
import {getCountValidatorForPlatform} from '../../../util/platform-type-uniq.validator';
import {uuidValidator} from '../../../util/uuid.validator';
import {IProductDetails} from '../../../interface/IProductDetails';
import {IProductFormGroup} from '../../../interface/IProductFormGroup';
import {IPlatformFormGroup} from '../../../interface/platform/IPlatformFormGroup';
import {ExternalTrackingFormManager} from '../../../class/external-tracking-form-manager';

@Component({
  selector: 'app-platforms-form',
  templateUrl: './platforms-form.component.html',
  styleUrls: ['./platforms-form.component.scss']
})
export class PlatformsFormComponent implements OnInit, OnDestroy {
  @Input() isEditMode = false;
  @Input() public initialValue: IProductDetails;
  public formGroup: FormGroup<IProductFormGroup>;
  public PlatformType = PlatformType;

  public faPlus = faPlus;
  public faTrash = faTrash;
  public faCircleInfo = faCircleInfo;

  private platformTypeChangesSub: Subscription;
  private platformChangesSub: Subscription;
  public platformTypes: IBasicSelectOption[] = [];

  public hasRequiredError: (control: AbstractControl) => boolean = hasRequiredError;
  public hasError: (control: AbstractControl, errorCode: string) => boolean = hasError;

  public ExecutionPointType = ExecutionPointType;
  public trackingSubs: Subscription[] = [];
  private trackingManager: ExternalTrackingFormManager;


  constructor(private rootFormGroup: FormGroupDirective, private enumTranslateService: EnumTranslateService,
              private changeDetectorRef: ChangeDetectorRef, private formBuilder: FormBuilder,
              private platformService: PlatformService) {
  }

  ngOnInit(): void {
    this.formGroup = this.rootFormGroup.control;
    this.trackingManager = new ExternalTrackingFormManager(this.platformService);
    this.initPlatformOptions().then(() => {
      this.setInitialPlatforms();
    });
    this.observeProductIdChanges();
  }

  public async initPlatformOptions(): Promise<void> {
    this.platformTypes = await this.enumTranslateService.getSelectOptionsFromEnum(PlatformType, 'platformType.');
  }

  public observeProductIdChanges(): void {
    this.formGroup.controls.boombitId.valueChanges.subscribe((value) => {
      const webPlatform = this.platforms.controls.find(platform =>
        platform.controls.type.value?.name === PlatformType.WEB);
      if (webPlatform) {
        const newPlatformId = value ? value + '.web' : '';
        webPlatform.controls.platformId.setValue(newPlatformId);
      }
    });
  }

  private setInitialPlatforms(): void {
    if (this.initialValue?.platforms?.length > 0) {
      this.initialValue.platforms.forEach((platform) => {
        const platformFormGroup: FormGroup<IPlatformFormGroup> =
          this.getNewPlatform(getPlatformTemplateByType(platform.type, this.initialValue.boombitId, platform));
        this.addPlatform(platformFormGroup);
      });
    } else {
      this.addPlatform();
    }
    this.showErrorsIfEdit();
  }

  private showErrorsIfEdit(): void {
    if (this.isEditMode) {
      this.formGroup.markAllAsTouched();
    }
  }

  private observeChanges(): void {
    this.watchForPlatformsChanges();
    this.observeExternalTrackingChanges();
  }

  private watchForPlatformsChanges(): void {
    this.platformTypeChangesSub?.unsubscribe();
    this.platformTypeChangesSub = getPlatformTypeInputsObservable(this.platforms)
      .subscribe((index: number) => this.handlePlatformChange(index));

    this.platformChangesSub?.unsubscribe();
    this.platformChangesSub = getPlatformsObservable(this.platforms)
      .subscribe((index: number) => {
          this.setDependantFieldsValidations(this.getPlatform(index));
        }
      );
  }


  public getNewPlatform(platformForm?: IPlatformFormTemplate): FormGroup<IPlatformFormGroup> {
    const group: FormGroup<IPlatformFormGroup> = this.formBuilder.group({
      id: this.formBuilder.control(platformForm?.id ? platformForm.id : ''),
      platformId: this.formBuilder.control(platformForm?.platformId || '',
        [], [this.platformIdUnique.bind(this)]),
      type: this.formBuilder.control(this.platformTypes.find(platformType => platformType.name === platformForm?.type)),
      defaultURL: this.formBuilder.control(platformForm?.defaultURL || ''),
      useDefaultProductPage: this.formBuilder.control(platformForm?.useDefaultProductPage === true),
      productPageIds: platformForm?.productPageIds || this.formBuilder.array([] as FormControl<string>[]),
      utmCampaign: this.formBuilder.control(platformForm?.utmCampaign || ''),
      executionPointsIds: this.formBuilder.control(platformForm?.executionPointsIds?.length > 0 ? platformForm?.executionPointsIds : []),
      useOptimizedQueue: this.formBuilder.control(platformForm?.useOptimizedQueue === true),
      externalTrackingSelect: this.trackingManager.getInitialTrackingSelect(platformForm?.externalTracking || []),
      externalTracking: this.trackingManager.getInitialTrackingInputs(platformForm?.externalTracking || []),
      enabled: this.formBuilder.control(platformForm?.enabled !== false),
    });
    this.setDependantFieldsValidations(group);
    return group;
  }

  public handlePlatformChange(index: number): void {
    const platformTemplate = getPlatformTemplateByType(this.getPlatformTypeFromIdx(index),
      this.formGroup.controls.boombitId.value);
    this.platforms.setControl(index, this.getNewPlatform(platformTemplate));
    this.changeDetectorRef.detectChanges();
    this.observeChanges();
  }

  public addPlatform(platform?: FormGroup<IPlatformFormGroup>): void {
    this.platforms.push(platform ? platform : this.getNewPlatform());
    this.changeDetectorRef.detectChanges();
    this.observeChanges();
  }

  public onDeletePlatformClicked(index: number): void {
    if (!this.hasPlatformId(index)) {
      this.deletePlatform(index);
    }
  }

  public deletePlatformConfirmed(index: number): void {
    this.platformService.deletePlatform(this.getControl(index, 'id').value).then(
      () => this.deletePlatform(index)
    );
  }

  public changePlatformTypeAndDelete(index: number): void {
    this.platformService.deletePlatform(this.getControl(index, 'id').value).then(
      () => this.handlePlatformChange(index)
    );
  }

  public hasPlatformId(index: number): boolean {
    return !!this.getControl(index, 'id').value;
  }

  public deletePlatform(index: number): void {
    this.platforms.removeAt(index);
    if (this.platforms.length === 0) {
      this.platforms.push(this.getNewPlatform());
    }
    this.changeDetectorRef.detectChanges();
    this.observeChanges();
  }

  public addProductPageID(platformIndex: number): void {
    this.getProductPageIds(platformIndex)
      .push(this.formBuilder.control('', [uuidValidator]));
  }

  public removeProductPageID(platformIndex: number, productPageIndex: number): void {
    this.getProductPageIds(platformIndex).removeAt(productPageIndex);
  }

  public clearProductPageID(platformIndex: number, productPageIndex: number): void {
    this.getProductPageIds(platformIndex).at(productPageIndex).setValue('');
  }

  public useDefaultProductPageChanged(index: number): void {
    const isChecked = this.getControl(index, 'useDefaultProductPage').value;
    this.getControl(index, 'useDefaultProductPage').setValue(!isChecked);
  }


  public onOptimizedQueueChanged(index: number): void {
    const isChecked = this.getControl(index, 'useOptimizedQueue').value;
    this.getControl(index, 'useOptimizedQueue').setValue(!isChecked);
  }

  private observeExternalTrackingChanges(): void {
    this.trackingSubs.forEach(sub => sub?.unsubscribe());
    this.platforms.controls.forEach(platformGroup => {
      const sub = merge(...platformGroup.controls.externalTrackingSelect.controls.map(control => control.valueChanges))
        .subscribe((value) => {
          value.isSelected ? this.trackingManager.createTrackingGroup(value.name, platformGroup.controls.externalTracking)
            : this.trackingManager.removeTrackingGroup(value.name, platformGroup.controls.externalTracking);
        });
      this.trackingSubs.push(sub);
    });
  }

  /* Validation */
  public setDependantFieldsValidations(group: FormGroup<IPlatformFormGroup>): void {
    setDependantFieldsValidations(group);
    this.changeDetectorRef.detectChanges();
  }

  public platformIdUnique(control: FormControl<string>): Observable<ValidationErrors | null> {
    if (!control.value) {
      return of(null);
    } else if (this.isInitialPlatformIdValue(control.value)) {
      return of(null);
    } else {
      return timer(1000).pipe(switchMap(async () => {
        if (this.isPlatformIdDuplicatedInForm(control.value)) {
          return { platformIdExists: true };
        } else {
          const platformIdExists: boolean = await this.platformService.validatePlatformId(control.value);
          return platformIdExists ? { platformIdExists: true } : null;
        }
      }));
    }
  }

  private isPlatformIdDuplicatedInForm(value: string): boolean {
    return this.platforms.controls.filter((platformGroup: FormGroup<IPlatformFormGroup>) =>
      platformGroup.controls.platformId.value === value).length > 1;
  }

  private isInitialPlatformIdValue(value: string): boolean {
    return !!this.initialValue?.platforms?.find(platform => platform.platformId === value);
  }


  public hasPlatformCountError(index: number): boolean {
    const platformType: PlatformType = this.getPlatformTypeFromIdx(index);
    const validatorName = getCountValidatorForPlatform(platformType);
    return this.platforms.hasError(validatorName);
  }

  /* Form field controls getters */
  public get platforms(): FormArray<FormGroup<IPlatformFormGroup>> {
    return this.formGroup.controls.platforms;
  }

  public getPlatform(index: number): FormGroup<IPlatformFormGroup> {
    return this.platforms.at(index);
  }

  public getProductPageIds(platformIndex: number): UntypedFormArray {
    return this.platforms.at(platformIndex).controls.productPageIds as UntypedFormArray;
  }

  public getPlatformTypeFromIdx(index: number): PlatformType {
    return this.platforms.at(index).controls.type.value?.name as PlatformType;
  }

  public getControl(platformIndex: number, controlName: keyof IPlatformFormGroup): AbstractControl {
    return this.platforms.at(platformIndex).get(controlName);
  }

  public getProductPageIdControl(platformIndex: number, productPageIdIndex: number): AbstractControl {
    return this.getProductPageIds(platformIndex).at(productPageIdIndex);
  }

  public getIncorrectMacros(control: FormControl<string>): string {
    return control.getError('invalidMacros');
  }

  public isEllipsisActive(e: HTMLElement): boolean {
    return (e.offsetWidth < e.scrollWidth);
  }

  public ngOnDestroy(): void {
    this.platformTypeChangesSub?.unsubscribe();
    this.platformChangesSub?.unsubscribe();
    this.trackingSubs.forEach(sub => sub?.unsubscribe());
  }

}
