import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {compareAlphabetical} from '../../../shared/util/table/sort-utils';
import { CustomIconName } from 'src/app/shared/enum/ui/icons/CustomIconName';

/**
 * Component representing icons for ad units.
 */
@Component({
  selector: 'app-ad-unit-icons',
  templateUrl: './ad-unit-icons.component.html',
  styleUrls: ['./ad-unit-icons.component.scss']
})
export class AdUnitIconsComponent implements OnChanges{
  /** Array containing ad unit types for displaying icons. */
  @Input() adUnitTypes: string[] = [];

  /** Sorted array of ad unit types for displaying icons. */
  public adUnitTypesSorted: string[] = [];

  /**
   * Method called on each change (adUnitTypes).
   * Sorts adUnitTypes alphabetically and assigns to adUnitTypesSorted.
   * @param changes Objects representing changes in the component.
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.adUnitTypes.firstChange || (changes.adUnitTypes.previousValue !== changes.adUnitTypes.currentValue)) {
      this.adUnitTypesSorted = this.adUnitTypes.sort((a, b) => compareAlphabetical(a,b));
    }
  }

  /**
   * Checks if an icon with the name adUnitType exists in the CustomIconName set.
   * @param adUnitType Name of the ad unit type.
   * @returns `true` if the icon exists, otherwise `false`.
   */
  iconNameExists(adUnitType: string): boolean {
    return (Object as any).values(CustomIconName).includes(adUnitType);
  }
}
