import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {SearchAutocompleteComponent} from '../../common/search/search-autocomplete/search-autocomplete.component';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
  @ViewChild(SearchAutocompleteComponent) searchAutocompleteCmp: SearchAutocompleteComponent;

  @Input() isNarrow = false;
  @Input() autocompleteItems: string[] = [];
  // eslint-disable-next-line
  @Output('value') valueEmitter: EventEmitter<string> = new EventEmitter<string>();

  public value = '';
  constructor() { }

  public onValueChanged(event: {value: string, shouldSubmit: boolean}): void {
    this.value = event.value;
    if (event.shouldSubmit) {
      this.onSearchSubmitted();
    }
  }

  public onSearchSubmitted(): void {
    this.valueEmitter.emit(this.value);
    this.searchAutocompleteCmp.closeDropdown();
  }

}
