<!--<app-multi-select [formControl]="control"-->
<!--                  [position]="position"-->
<!--                  [label]="filter.label"-->
<!--                  [items]="filter.items"-->
<!--                  [searchable]="false"-->
<!--                  [customButton]="true">-->
<!--  <ng-container customTrigger>-->
<!--    <ng-container *ngTemplateOutlet="button"></ng-container>-->
<!--  </ng-container>-->
<!--</app-multi-select>-->


<!--<ng-template #button>-->
<!--  <button type="button" mat-stroked-button color="primary" class="filter-btn">-->
<!--    <span class="tw-inline-flex tw-align-baseline tw-gap-[5px]">-->
<!--      {{filter.label + (areFiltersSelected ? ':' : '')}}-->
<!--      <ng-container *ngIf="areFiltersSelected"><span class="tw-text-gray-800 tw-whitespace-nowrap">{{selectedFilters}}</span>-->
<!--      </ng-container>-->
<!--      <fa-icon class="dropdown-icon" [icon]="faAngleDown"></fa-icon>-->
<!--    </span>-->
<!--  </button>-->
<!--</ng-template>-->


<app-multi-select-input *ngIf="isMultiSelectFilter(filter)"
                        [isTableFilter]="true"
                        [items]="filter.items"
                        [label]="filter.label"
                        [withAutocomplete]="!!filter.withAutocomplete"
                        [formControl]="control" >
</app-multi-select-input>

<app-checkbox-filter 
  *ngIf="isCheckboxFilter(filter)"
  [isTableFilter]="true"
  [label]="filter.label" 
  [formControl]="control">
</app-checkbox-filter>


<!--<app-date-select-control *ngIf="filters[i].isDate" [isTableFilter]="true" [placeholder]="filters[i].label"-->
<!--                         [formControlName]="i">-->
<!--</app-date-select-control>-->
<!--<app-date-range-control *ngIf="filters[i].isDateRange" [isTableFilter]="true" [placeholder]="filters[i].label" [formControlName]="i"></app-date-range-control>-->
<!--<div class="checkbox-filter" *ngIf="isCheckboxFilter(filters[i])">-->
<!--  <app-checkbox-filter [formControlName]="i" [label]="filters[i].label"></app-checkbox-filter>-->
<!--</div>-->
