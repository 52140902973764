<app-base-expanded-dialog [backAction]="backAction" [closeDialogObservable]="closeDialogSubject.asObservable()">

  <div class="form-page">
    <div class="add-form-header no-padding-top">
      <h1 >{{'waterfall.manageWaterfalls' | translate}}</h1>
      <p [innerHTML]="'waterfall.manageWaterfallsDescription' | translate" ></p>
    </div>
    <div class="form-page-content waterfall-list-content">
      <div class="w-100">
        <app-waterfall-list-draggable [loadingEndpointNames]="loadingEndpointNames"
          [waterfallsLoaded]="dataLoaded" [product]="product"
                                                       [refreshSubject]="refreshWaterfallList" [waterfalls]="waterfalls">

      </app-waterfall-list-draggable></div>
      <button class="flex-shrink-0" (click)="onAddWaterfallClicked()" mat-flat-button color="primary">
        <fa-icon [icon]="faPlus"></fa-icon>
        {{'waterfall.addNextWaterfall' | translate}}
      </button>
  </div>
  </div>
</app-base-expanded-dialog>
