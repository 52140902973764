import {Injectable} from '@angular/core';
import {RequestType} from 'src/app/shared/enum/RequestType';
import {ApiGeneralService} from '../../shared/service/api/api-general.service';
import {Observable} from 'rxjs';
import {IDefaultTrackingLink} from '../interface/IDefaultTrackingLink';

@Injectable({
  providedIn: 'root'
})
export class ApiPlatformService {

  constructor(
    private apiGeneralService: ApiGeneralService
  ) {
  }

  public deletePlatform(id: string): Observable<void> {
    const url = `Platforms/${id}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.delete,
      url,
    });
  }

  public validatePlatformId(platformId: string): Observable<boolean> {
    const url = `Platforms/ValidatePlatformId/${platformId}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url
    });
  }

  public deleteExternalTracking(id: string): Observable<void> {
    const url = `ExternalTracking/${id}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.delete,
      url
    });
  }

  public getExternalTrackingDefaultLinks(): Observable<IDefaultTrackingLink> {
    const url = 'ExternalTracking/GetDefaultLinks';
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url
    });
  }

  public getCustomTrackingDefaultLinks(): Observable<IDefaultTrackingLink> {
    const url = 'ExternalTracking/GetDefaultCustomLinks';
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url
    });
  }
}
