import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {DIALOG_DATA} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {faArrowUpRightFromSquare, faPenToSquare, faXmark} from '@fortawesome/pro-light-svg-icons';
import {AssetDialogService} from '../../service/asset-dialog/asset-dialog.service';
import {AssetLinkService} from '../../service/asset-link/asset-link.service';
import {RESPONSIVE} from '../../util/ratio.util';
import {getSideDialogConfig} from '../../../shared/util/dialog/dialog-back-action.util';
import {AssetService} from '../../service/asset.service';
import {AdUnitMapperService} from '../../../ad-unit/service/ad-unit-mapper.service';
import {AssetEndpointsEnum} from '../../../shared/enum/Endpoints';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {Subscription} from 'rxjs';
import {IAssetDetails} from '../../interface/IAssetDetails';
import {AdUnitService} from '../../../ad-unit/service/ad-unit';

/** 
 * Component representing the preview of an asset. 
 * This component is used to display detailed information about a specific asset. 
 */
@Component({
  selector: 'app-asset-preview',
  templateUrl: './asset-preview.component.html',
  styleUrls: ['./asset-preview.component.scss']
})
export class AssetPreviewComponent implements OnInit, OnDestroy {
  /** Property to store detailed information about the asset. */
  public asset: IAssetDetails;

  /** Array containing ad unit types associated with the asset. */
  public adUnitTypes: string[] = [];

  /** FontAwesome icons used in the component. */
  public faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  public faXMark = faXmark;
  public faPenToSquare = faPenToSquare;

  /** Loading status flag. */
  public isLoading = false;

  /** Array of endpoint names being monitored for loading status. */
  private loadingEndpointNames: string[] = [AssetEndpointsEnum.GET_ASSET_BY_INTERNAL_ID];

  /** Array of subscriptions to be unsubscribed during component destruction. */
  private subscriptions: Subscription[] = [];

  /** Array containing ad unit format names associated with the asset. */
  public adUnitFormatNames: string[];

  /**
   * Constructor for AssetPreviewComponent.
   * @param dialogRef Reference to the dialog displaying the asset preview.
   * @param data Input data containing the asset object with an `id` property.
   * @param assetDialogService Service for managing asset dialogs.
   * @param assetLinkService Service for handling asset links.
   * @param assetService Service for managing assets.
   * @param adUnitMapper Service for mapping ad units.
   * @param loadingService Service for managing loading status.
   * @param adUnitService Service for managing ad units.
   */
  constructor(public dialogRef: DialogRef,
              @Inject(DIALOG_DATA) public data: { asset: {id: string} }, private assetDialogService: AssetDialogService,
              private assetLinkService: AssetLinkService, private assetService: AssetService, private adUnitMapper: AdUnitMapperService,
              private loadingService: LoadingService, private adUnitService: AdUnitService) {
  }

  /**
   * Lifecycle hook called before component destruction.
   * Unsubscribes from active subscriptions to prevent memory leaks.
   */
  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }

  /**
   * Lifecycle hook called after component initialization.
   * Initializes the asset details and observes loading status.
   */
  public ngOnInit(): void {
    this.initializeAsset();
    this.observeLoading();
  }

  /** Method to set ad unit format names based on ad unit format IDs associated with the asset. */
  public async setAdUnitFormatNames(): Promise<void> {
    const adUnitFormats = await this.adUnitService.getAdUnitFormatsFromSubject();
    this.adUnitFormatNames = adUnitFormats
      .filter(adUnitFormat => this.asset.adUnitFormatIds.includes(adUnitFormat.id))
      .map(adUnitFormat => adUnitFormat.name);
  }

  /** Method to initialize asset details by fetching them from the server. */
  private async initializeAsset(): Promise<void> {
    this.asset = await this.assetService.getAssetById(this.data.asset.id);
    this.adUnitTypes = await this.adUnitMapper.getAdUnitTypesFromFormatIds(this.asset.adUnitFormatIds);
    this.setAdUnitFormatNames();
  }

  /** Method to observe loading status using the loading service. */
  private observeLoading(): void {
    const sub = this.loadingService.loadingEndpointsObs().subscribe((loadingEndpoints) => {
      this.isLoading = this.loadingService.areEndpointsLoading(this.loadingEndpointNames, loadingEndpoints);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Method to get the name of the asset along with its extension.
   * @returns The name of the asset with its extension.
   */
  public getNameWithExtension(): string {
    return this.asset.originalName;
  }

  /** Method called when the close button is clicked, closes the asset preview dialog. */
  public onCloseClicked(): void {
    this.dialogRef.close();
  }

  /** Method called when the edit asset button is clicked, opens the asset edit dialog. */
  public onEditAssetClicked(): void {
    this.assetDialogService.openAssetEditDialog(this.asset.id, getSideDialogConfig(AssetPreviewComponent, null, this.data));
    this.dialogRef.close();
  }

  /** Method called when the open asset link button is clicked, opens the asset link in a new tab. */
  public openAssetLinkClicked(): void {
    this.assetLinkService.openAssetInNewTab(this.asset);
  }

  /**
   * Method to get the resolution of the asset as a string.
   * @returns The resolution of the asset as a string.
   */
  public getResolution(): string {
    return this.asset.ratio === RESPONSIVE ? RESPONSIVE :
      this.asset.resolution.width + 'x' + this.asset.resolution.height + ' px';
  }
}
