import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CellHandler} from '../../../interface/ui/my-table/cell-handler.interface';
import {CellBasic} from '../../../interface/ui/my-table/cell.model';
import {ITableRowType} from '../../../type/ITableRowType';
import {ITableCol} from '../../../interface/ui/table/ITableCol';
import { IconOriginType } from 'src/app/shared/enum/ui/icons/IconOriginType';
import { Color } from 'src/app/shared/enum/ui/Color';
import {CellEvent} from '../../../interface/ui/my-table/cell-event.model';



@Component({
  selector: 'app-cell-basic',
  templateUrl: './cell-basic.component.html',
  styleUrls: ['./cell-basic.component.scss']
})
export class CellBasicComponent implements CellHandler {
  @Input({required: true}) public cell: CellBasic;
  @Output() cellEvent: EventEmitter<CellEvent> = new EventEmitter<CellEvent>();



  @Input() element: ITableRowType;
  @Input() column: ITableCol<any>;

  IconOriginType = IconOriginType;
  Color = Color;

  constructor() { }

  public onTableCellClicked(): void {
    this.cellEvent.emit({cell: this.cell});
  }

  public getCellVal(cell: CellBasic): string {
    return cell?.value !== '' ? cell?.value?.toString() : '-';
  }

  public shouldShowTooltipForEllipsis(elementRef: any): boolean {
    return elementRef ? (elementRef.offsetWidth < elementRef.scrollWidth) : false;
  }

  public getCell(cellUntyped: any): CellBasic {
    return cellUntyped as CellBasic;
  }

  public get tableCell(): CellBasic {
    return this.cell;
  }
}
