import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestType } from 'src/app/shared/enum/RequestType';
import { IRequestOptions } from 'src/app/shared/interface/api/IResquestOptions';
import { RequestParams } from 'src/app/shared/interface/api/IRequestParams';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiGeneralService {

  constructor(
    private http: HttpClient
  ) { }

  public sendRequest(requestParams: RequestParams): Observable<any> {
    const url = this.getUrl(requestParams.url);


    let requestRef: Observable<any>;

    switch(requestParams.method) {
        case RequestType.get:
          // @ts-ignore
          requestRef = this.http.get(url, this.createRequestHeader(RequestType.get, requestParams));
            break;
        case RequestType.post:
          // @ts-ignore
          requestRef = this.http.post(url, requestParams.data, this.createRequestHeader(RequestType.post));
            break;
      case RequestType.put:
        // @ts-ignore
        requestRef = this.http.put(url, requestParams.data);
        break;
        case RequestType.delete:
          // @ts-ignore
          requestRef = this.http.delete(url, this.createRequestHeader(RequestType.delete, requestParams));
          break;
        default:
            throw new Error('unhandled exception ' + requestParams.method);
    }

    return requestRef;
  }

  public getUrl(requestName: string): string {
      return environment.apiUrl + '/api/' + requestName;
  }

  private createRequestHeader(requestType: RequestType, requestParams?: RequestParams): IRequestOptions {
      switch(requestType) {
            case RequestType.get:
                return this.createGetRequestHeader(requestParams);
            case RequestType.post:
                return this.createPostRequestHeader();
          case RequestType.delete:
            return this.createDeleteRequestHeader(requestParams);
        default:
                throw new Error('unhandled exception ' + requestType);
      }
  }

  private createDeleteRequestHeader(requestParams: RequestParams): IRequestOptions {
    return {
      params: requestParams?.params
    };
  }

  private createPostRequestHeader(): IRequestOptions {
    let httpOptions;

    httpOptions = {
        headers: new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }),
        withCredentials: false
    };

    return httpOptions;
  }

  private createGetRequestHeader(requestParams: RequestParams): IRequestOptions {
    return {
        headers: new HttpHeaders({
            Accept: 'application/json',
        }),
        withCredentials: false,
      params: requestParams.params
    };
  }

}
