import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Input, OnDestroy,
  Output, Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Subscription} from 'rxjs';
import {HeaderEvent} from '../../../interface/ui/my-table/header-event.model';
import {Header} from '../../../interface/ui/my-table/header.model';
import {HeaderHandler} from '../../../interface/ui/my-table/header-handler.interface';
import {TableHeaderBasicComponent} from '../table-header-basic/table-header-basic.component';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements AfterViewInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input({required: true}) public tableHeader: Header<any>;
  @Output() public headerEvent: EventEmitter<HeaderEvent> = new EventEmitter<HeaderEvent>();
  @ViewChild('header', { read: ViewContainerRef }) container: ViewContainerRef;
  private sub: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }


  public ngAfterViewInit(): void {
    this.tableHeader.component ? this.createComponent(this.tableHeader.component) : this.createComponent(TableHeaderBasicComponent);
    this.changeDetectorRef.detectChanges();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public createComponent(component: Type<any>): void {
    this.container.clear();
    const componentRef: ComponentRef<HeaderHandler> = this.container.createComponent(component);
    componentRef.instance.header = this.tableHeader;
    this.sub = componentRef.instance.headerEvent?.subscribe((event: HeaderEvent) => this.headerEvent.emit(event));
  }

  public ngOnDestroy(): void {
    this.container.clear();
    this.sub?.unsubscribe();
  }
}
