import {Injectable} from '@angular/core';
import {isNumber} from 'lodash';
import { QueryRaw } from '../interface/api/QueryRaw';
import {ITableOptions} from '../interface/ui/table/ITableOptions';


@Injectable({
  providedIn: 'root'
})
export class QueryMapperService {

  public getParams(query: ITableOptions<any, any>): QueryRaw {
    let params: QueryRaw = {};
    params = this.updatePage(query, params);
    params = this.updateSort(query, params);
    params = this.updateSearch(query, params);
    return params;
  }

  private updatePage(query: ITableOptions<any, any>, params: QueryRaw): QueryRaw {
    params = this.updatePageSize(query, params);
    params = this.updatePageNumber(query, params);
    return params;
  }

  private updatePageNumber(query: ITableOptions<any, any>, params: QueryRaw): QueryRaw {
    if (isNumber(query.page.pageIndex)) {
      params.Page = query.page.pageIndex + 1;
    }
    return params;
  }

  private updatePageSize(query: ITableOptions<any, any>, params: QueryRaw): QueryRaw {
    if (isNumber(query.page.pageSize)) {
      params.PageSize = query.page.pageSize;
    }
    return params;
  }

  private updateSort(query: ITableOptions<any, any>, params: QueryRaw): QueryRaw {
    if (query.sort?.name) {
      params.ColumnName = query.sort?.name;
    }
    if (query.sort?.direction) {
      params.Direction = query.sort.direction;
    }
    return params;
  }

  private updateSearch(query: ITableOptions<any, any>, params: QueryRaw): QueryRaw {
    if (query.searchPhrase) {
      params.Search = query.searchPhrase;
    }
    return params;
  }

}
