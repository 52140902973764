import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ICampaign} from '../../interface/ICampaign';
import {DatePipe} from '@angular/common';
import {CampaignTag} from '../../enum/CampaignTag';
import {AdUnitMapperService} from '../../../ad-unit/service/ad-unit-mapper.service';
import {CellBasic} from '../../../shared/interface/ui/my-table/cell.model';
import {ActionsCellComponent} from '../../../shared/component/common/actions-cell/actions-cell.component';
import {TableAction} from '../../../shared/enum/TableAction';
import {faArrowPointer, faCircleCheck, faEye, faPenToSquare, faTrash} from '@fortawesome/pro-light-svg-icons';
import {CampaignRow} from '../../interface/CampaignRow';
import {CampaignSelectRow} from '../../interface/CampaignSelectRow';
import {CellButtonsComponent} from '../../../shared/component/common/cell-buttons/cell-buttons.component';
import {Button} from '../../../shared/interface/ui/my-table/button.model';

@Injectable({
  providedIn: 'root'
})
export class CampaignMapperService {

  constructor(private translateService: TranslateService, private datePipe: DatePipe,
              private adUnitMapperService: AdUnitMapperService) {
  }

  public async mapCampaignsSelectToRows(campaigns: ICampaign[], selectedId: string): Promise<CampaignSelectRow[]> {
    const rows: CampaignRow[] = await this.mapCampaignsToRows(campaigns);
    return rows.map(row => ({
      ...row,
      actions: {
        component: ActionsCellComponent, actions: [
          {name: TableAction.show, iconFa: faEye}]
      },
      buttons: {
        component: CellButtonsComponent,
        buttons: selectedId === row.campaign.id ? [this.getSelectedSuccessButton()] : [this.getSelectButton()]
      }
    }));
  }

  public async mapCampaignsToRows(campaigns: ICampaign[]): Promise<CampaignRow[]> {
    return Promise.all(campaigns.map(async (campaign) => {
      const row: CampaignRow = {
        name: this.getName(campaign),
        productName: {
          value: campaign.product?.name,
          cssClass: 'cursor-pointer'
        },
        adUnits: await this.adUnitMapperService.getAdUnitsCell(campaign.adUnits) as CellBasic,
        actions: {
          component: ActionsCellComponent, actions: [
            {name: TableAction.show, iconFa: faEye},
            {name: TableAction.edit, iconFa: faPenToSquare},
            {name: TableAction.delete, iconFa: faTrash},
          ]
        },
        startDate: this.getDateCell(campaign.startDate) as CellBasic,
        endDate: this.getDateCell(campaign.endDate) as CellBasic,
        trackById: campaign.id,
        campaign
      };
      return row;
    }));
  }

  private getName(campaign: ICampaign): CellBasic {
    const cell: CellBasic = {
      value: campaign.name,
      cssClass: 'cursor-pointer',
      cssClassTd: ['column--no-tags-tablet']
    };
    return campaign.default ? {...cell, tags: [CampaignTag.DEFAULT_CAMPAIGN]} : cell;
  }

  public getDateCell(date: Date): CellBasic {
    if (date.getTime() === 0) {
      return {value: '-', cssClassTd: ['display-none-xs']};
    } else {
      return {value: this.datePipe.transform(date, 'dd/MM/YYYY'), cssClassTd: ['display-none-xs']};
    }
  }

  public getSelectButton(): Button {
    return {name: 'Select', iconFa: faArrowPointer, type: 'mat-flat-button'};
  }

  public getSelectedSuccessButton(): Button {
    return {name: 'Selected', iconFa: faCircleCheck, cssClasses: ['background-success'], type: 'mat-flat-button'};
  }
}
