import {Component, Input} from '@angular/core';
import {CellDate} from '../../../interface/ui/table/CellDate';

@Component({
  selector: 'app-cell-date',
  templateUrl: './cell-date.component.html',
  styleUrl: './cell-date.component.scss'
})
export class CellDateComponent {
  @Input() cell: CellDate;

}
