import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {IActionIcon} from '../../interface/ui/icon/ActionIcon';
import {EnumTranslateService} from '../translate/enum-translate.service';
import {ACTION_ICONS_WITHOUT_TOOLTIPS} from '../../util/icons/action-icons.util';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  private iconsTooltipsSubject: BehaviorSubject<IActionIcon[]> = new BehaviorSubject([]);

  constructor(private enumTranslateService: EnumTranslateService) {
    this.initializeIconsTooltips();
  }

  private async initializeIconsTooltips(): Promise<void> {
    const prefix = 'actionIconsTooltips.';
    const iconsTooltips: IActionIcon[] = await Promise.all(ACTION_ICONS_WITHOUT_TOOLTIPS.map(async actionIcon =>
      ({...actionIcon, tooltip: await this.enumTranslateService.getTranslation(prefix, actionIcon.type)})
    ));
    this.iconsTooltipsSubject.next(iconsTooltips);
  }

  public async getActionIcons(): Promise<IActionIcon[]> {
    return this.iconsTooltipsSubject.getValue().length > 0 ?
      this.iconsTooltipsSubject.getValue() : firstValueFrom(this.iconsTooltipsSubject);
  }

  public async getActionIconByDefinition(iconDefinition: IconDefinition): Promise<IActionIcon> {
    const icons = await this.getActionIcons();
    return icons.find(icon => icon.icon === iconDefinition);
  }
}
