/** Constant representing the string value for responsive ratio. */
export const RESPONSIVE = 'responsive';

/**
 * Function to calculate the aspect ratio based on width and height.
 * This function calculates the aspect ratio given the width and height of an asset.
 * @param width The width of the asset.
 * @param height The height of the asset.
 * @returns The aspect ratio of the asset in the format "width:height".
 */
export function getRatio(width: number, height: number): string {
  width = Math.round(width);
  height = Math.round(height);
  const greatestCommonDivisor = getGreatestCommonDivisor(width, height);
  return width/greatestCommonDivisor + ':' + height/greatestCommonDivisor;
}

/**
 * Function to calculate the greatest common divisor of two numbers.
 * This function calculates the greatest common divisor of two numbers using recursion.
 * @param a The first number.
 * @param b The second number.
 * @returns The greatest common divisor of the two numbers.
 */
function getGreatestCommonDivisor(a: number, b: number): number {
  return (b === 0) ? a : getGreatestCommonDivisor (b, a%b);
}
