<app-base-select-button [isTableFilter]="isTableFilter" [isFilterSelected]="isTableFilter && this.control.value?.length > 0" [isArrowGrayedOut]="isControlGreyedOut()"
                        [errorDisplayed]="hasAnyError(control)"
                        (dropdownToggled)="onDropdownToggled($event)"
                        [isDropdownOpen]="isDropdownOpen" [dropdown]="dropdown">
  <ng-container *ngIf="isTableFilter">
    <ng-container *ngTemplateOutlet="buttonContentTableFilter"></ng-container>
  </ng-container>
  <ng-container *ngIf="!isTableFilter">
    <ng-container *ngTemplateOutlet="buttonContentNotTableFilter"></ng-container>
  </ng-container>
</app-base-select-button>

<app-dropdown #dropdown>
  <app-base-dropdown-select (saveClicked)="onSaveClicked()" (cleanClicked)="onCleanClicked()">
    <div *ngIf="!withAutocomplete" class="options-wrapper dropdown-scroll m-h-250">
      <div [ngClass]="containsItemWithName(item.name) ? 'text-14-txt-2-semi-bold' : ''" (click)="onItemClicked(item)"
           class="mat-caption option" *ngFor="let item of items">
        {{item.label}}
        <fa-icon *ngIf="containsItemWithName(item.name)" class="check-icon" [icon]="faCheck"></fa-icon>
      </div>
    </div>

    <div class="dropdown-scroll" *ngIf="withAutocomplete">
      <div class="dropdown-select-row">
        <div class="dropdown-search-control">
          <app-search-control [focusObservable]="dropdownOpenedSubject.asObservable()"
                              (valueChanges)="onSearchValueChanges($event.value)" [isNarrow]="true"></app-search-control>
        </div>
      </div>
      <hr style="margin: 0" *ngIf="selectedItems.length">
      <div [hidden]="selectedItems.length === 0" class="options-wrapper options-row--selected-items" #multiItemsContainer>
        <div (click)="onItemClicked(selectedItem)" class="mat-caption option text-14-txt-2-semi-bold"
             *ngFor="let selectedItem of selectedItems">
          {{selectedItem.label}}
          <fa-icon class="check-icon" [icon]="faCheck"></fa-icon>
        </div>
      </div>
      <hr style="margin: 0">
      <div>
        <div class="options-wrapper options-wrapper--scroll">
          <div (click)="onItemClicked(displayedItem)" class="mat-caption option"
               *ngFor="let displayedItem of displayedItems">{{displayedItem.label}}</div>
          <div class="align-center-gap-5 error-message" *ngIf="shouldShowNoResultsError()">
            <span class="color-error x-mark-icon">&#10006;</span>
            <p class="margin-0 text-14-txt-2-medium"> {{'table.noResultsFound' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </app-base-dropdown-select>
</app-dropdown>


<ng-template #buttonContentTableFilter>
  <span>
    {{label}}<span *ngIf="getControlValue()?.length">: </span>
  </span>
  <span class="text-14-txt-2-semi-bold color-dark-grey" *ngIf="getControlValue()?.length">
    {{getControlValue()}}
  </span>
</ng-template>

<ng-template #buttonContentNotTableFilter>
  <div #lengthChecker
       [isActive]="isEllipsisActive(lengthChecker)" appTooltip="{{getControlValue()}}"
       class="overflow-ellipsis">
  <span class="select-placeholder" *ngIf="!getControlValue()?.length">
    {{label}}
  </span>
    <span class="mat-caption color-dark-grey" *ngIf="getControlValue()?.length">
    {{getControlValue()}}
  </span>
  </div>
</ng-template>
