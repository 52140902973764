import {Directive, ElementRef, HostListener, Injector, Input, OnDestroy, ViewContainerRef} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {ComponentPortal} from '@angular/cdk/portal';
import {TooltipComponent} from '../../component/common/tooltip/tooltip.component';
import {TOOLTIP_DATA} from '../tooltip/tooltip-token';
import {Placement} from '../../enum/ui/tooltip/Placement';
import {TooltipDirective} from '../tooltip/tooltip.directive';
import {TooltipType} from '../../enum/ui/tooltip/TooltipType';
import {IActionIcon} from '../../interface/ui/icon/ActionIcon';
import {IconService} from '../../service/icon/icon.service';

@Directive({
  selector: '[appIconTooltip]'
})
export class IconTooltipDirective implements OnDestroy {

  @Input('appIconTooltip') public iconDefinition: IconDefinition;

  private overlayRef: OverlayRef;
  public isActive = true;
  public icon: IActionIcon;

  constructor(private elementRef: ElementRef,
              private viewContainerRef: ViewContainerRef,
              private overlay: Overlay, private injector: Injector, private iconService: IconService) {
  }

  @HostListener('mouseenter')
  private show(): void {
    if (!this.overlayRef && this.isActive) {
      this.createTooltip();
    }
  }

  @HostListener('mouseleave')
  private hide(): void {
    this.removeTooltip();
  }

  private async createTooltip(): Promise<void> {
    await this.setIcon();

    this.overlayRef = this.overlay.create({
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions(TooltipDirective.getPosition(Placement.TOP))
    });
    const portal = new ComponentPortal(TooltipComponent, null, this.getTooltipInjector());
    this.overlayRef.attach(portal);
  }

  private async setIcon(): Promise<void> {
    this.icon = await this.iconService.getActionIconByDefinition(this.iconDefinition);
  }

  private removeTooltip(): void {
    if (!this.overlayRef) {
      return;
    }
    this.overlayRef.detach();
    this.overlayRef = null;
  }

  private getTooltipInjector(): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [
        {provide: TOOLTIP_DATA, useValue: {text: this.icon.tooltip, placement: Placement.TOP, type: TooltipType.TEXT}},
      ]
    });
  }

  public ngOnDestroy(): void {
    this.removeTooltip();
  }
}
