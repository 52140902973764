<mat-sidenav-container [ngClass]="{'sidenav-hidden': isSidebarHidden}" id="sidenavContainer" class="container">
  <mat-sidenav (mouseenter)="onMenuOpen()" (mouseleave)="onMenuClose()"
               [@openClose]="isMenuOpen ? 'open' : 'closed'"
               [class.menu-open]="isMenuOpen"
               id="sidenav" mode="side"
               opened>
    <div class="sidenav-wrapper">
      <a routerLink="/dashboard">
      <img (click)="onNavItemSelected('/dashboard')" alt="logo" class="logo" src="../../../../../assets/icons/logo.svg">
      </a>
      <div>
        <ng-container *ngFor="let item of navItemsContext">
          <ng-container *ngTemplateOutlet="navItem;context: item">
          </ng-container>
        </ng-container>
      </div>
      <div class="nav-item-wrapper log-out-wrapper" (click)="onLogOutClicked()">
        <img alt="log-out-icon" class="icon" src="../../../../../assets/icons/log-out.svg">
        <span class="whitespace-nowrap" *ngIf="isMenuOpen">{{'button.logOut' | translate}}</span>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content [@openCloseContent]="isMenuOpen ? 'open' : 'closed'" #sidenavContent>
    <ng-template #customDialog>
    </ng-template>
    <div class="environment-info mat-caption" *ngIf="showTestServerInfo()">
      <fa-icon [icon]="faWarning"></fa-icon>
      {{'testServerInfo' | translate}}</div>
    <div [ngClass]="{'content--test-server': showTestServerInfo()}" class="content">
      <router-outlet></router-outlet></div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template let-routeFirstSegment="routeFirstSegment" let-name="name" let-route="route" let-iconName="iconName" let-faIcon="faIcon" #navItem>
  <a [routerLink]="route">
  <div (click)="onNavItemSelected(route)" class="nav-item-wrapper" [ngClass]="{'active-item': isAssetRouteActive(routeFirstSegment)}">
    <div class="nav-link-active-rectangle"></div>
    <img *ngIf="!faIcon" alt="nav-icon" class="icon nav-icon" [src]="'../../../../assets/icons/' + iconName + '.svg'">
    <div *ngIf="!!faIcon" class="fa-icon-wrapper">
      <fa-icon  class="fa-nav-icon" [icon]="faIcon"></fa-icon>
    </div>
    <span class="whitespace-nowrap" *ngIf="isMenuOpen">{{name}}</span>
  </div>
  </a>
</ng-template>
