<app-base-center-dialog [showCloseButton]="false" [dialogRef]="dialogRef">
  <div class="confirm-dialog">

    <h1 class="margin-0">{{'asset.uploadDialog.uploadHeader' | translate}}</h1>

    <div class="file-selection-wrapper">
      <div class="dropzone-wrapper"
           appFileDnd (fileDropped)="onFileDropped($event)">
        <div class="dropzone">
          <input accept=".zip" type="file" multiple #fileDropRef (change)="onFileSelected($event)"/>
          <div class="d-flex align-center">
            <p class="text-14-txt-2-semi-bold">{{'asset.uploadDialog.uploadTextPrefix' | translate}}</p>
            <button mat-button (click)="fileDropRef.click()">
              <span style="text-decoration: underline" class="text-14-txt-2-semi-bold">{{'asset.uploadDialog.uploadTextSuffixButton' | translate}}</span>
            </button>
          </div>
        </div>
      </div>
      <p class="text-12-txt-3-regular color-dark-grey">Max file size: {{maxFileSize | fileSize}}, File type: zip</p>


    <ng-container *ngIf="filesInfo.length > 0">
      <div *ngFor="let fileInfo of filesInfo; let i = index" class="selected-file">
        <div class="selected-file-details-wrapper">
          <fa-icon class="file-icon" [icon]="faFile"></fa-icon>
          <div class="selected-file-details">
            <p class="text-12-txt-3-medium">{{fileInfo.file.name}}</p>
            <p class="text-12-txt-3-light">{{fileInfo.file.size | fileSize}}</p>
            <p class="text-12-txt-3-light color-error" *ngIf="!fileInfo.sizeValid"> {{'asset.uploadDialog.sizeTooBig' | translate}}</p>
            <p class="text-12-txt-3-light color-error" *ngIf="!fileInfo.typeValid">  {{'asset.uploadDialog.invalidTypeZip' | translate}}</p>
          </div>
        </div>
        <fa-icon (click)="onRemoveFile(i)" class="remove-icon" [icon]="faXmark"></fa-icon>
      </div>
    </ng-container>
    </div>



    <div class="actions-buttons">
      <button (click)="onCancel()" mat-stroked-button color="primary">
        {{'button.cancel' | translate}}
      </button>
      <button [disabled]="isNextBtnDisabled()" (click)="onConfirm()" mat-flat-button color="primary">
        {{'button.next' | translate}}
      </button>
    </div>
  </div>
</app-base-center-dialog>
