import {Injectable} from '@angular/core';
import {CampaignsService} from '../campaigns.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignListService {
  constructor(private campaignService: CampaignsService) {
  }

  public async getAutocomplete(): Promise<string[]> {
    return await this.campaignService.getCampaignAutocomplete();
  }
}
