import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(private router: Router) {
  }

  public navigateSameUrl(nextUrl: string): void {
    if (nextUrl === this.router.url) {
      this.reloadComponentByUrl(nextUrl);
    }
  }

  public reloadComponentByUrl(url: string): void {
    setTimeout(() => {
      this.router.navigateByUrl('/dummy-route', {skipLocationChange: true}).then((isSuccess)=> {
        if (isSuccess) {
          this.router.navigate([url]);
        }
      });
    }, 50);

  }
}
