import * as _ from 'lodash';
import {IBasicSelectOption} from '../interface/ui/form/IBasicSelectOption';

export function getOrderedSearchItems(items: string[], searchString: string): string[] {
  const searchStr = searchString.toLowerCase();
  const itemsContainingValue = items
    .filter(item => item.toLowerCase().includes(searchStr));
  itemsContainingValue.sort((a, b) =>
    a.toLowerCase().indexOf(searchStr) - b.toLowerCase().indexOf(searchStr));

  return  _.uniq(itemsContainingValue);
}

export function getOrderedSearchItemsByName<T extends {name?: string}>(items: T[], searchString: string): T[] {
  const searchStr = searchString.toLowerCase();
  const itemsContainingValue = items
    .filter(item => itemIncludesSearch(item, searchString));
  itemsContainingValue.sort((a, b) =>
    a.name.toLowerCase().indexOf(searchStr) - b.name.toLowerCase().indexOf(searchStr));

  return  _.uniq(itemsContainingValue);
}

export function itemIncludesSearch<T extends {name?: string}>(item: T, searchString: string): boolean {
  return item.name?.toLowerCase()?.includes(searchString.toLowerCase());
}

export function getOrderedSearchItemsByLabel(items: IBasicSelectOption[], searchString: string): IBasicSelectOption[] {
  const searchStr = searchString.toLowerCase();
  const itemsContainingValue = items
    .filter(item => includesSearchStringOrKeywords(item, searchStr));
  itemsContainingValue.sort((a, b) =>
    a.label.toLowerCase().indexOf(searchStr) - b.label.toLowerCase().indexOf(searchStr));
  return _.uniq(itemsContainingValue);
}

export function includesSearchStringOrKeywords(item: IBasicSelectOption, searchString: string): boolean {
  return item.label?.toLowerCase()?.includes(searchString.toLowerCase()) || includesKeywords(item, searchString);
}


function includesKeywords(item: IBasicSelectOption, searchString: string): boolean {
  return item.keywords?.length > 0 && item.keywords.some(keyword => keyword.toLowerCase().includes(searchString));
}

export function hasKeywords(item: IBasicSelectOption, searchString: string): boolean {
  return item.keywords?.length > 0 && item.keywords.some(keyword => keyword.toLowerCase() === searchString.toLowerCase());
}
