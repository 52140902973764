import {Injectable} from '@angular/core';
import {ProductsService} from './product/products.service';
import {Observable, ReplaySubject} from 'rxjs';
import {ProductSimple} from '../interface/IProductSimple';

@Injectable({
  providedIn: 'root'
})
export class ProductSelectService {
  private productListSubject: ReplaySubject<ProductSimple[]> = new ReplaySubject<ProductSimple[]>(1);

  constructor(private productService: ProductsService) {
    this.fetchProducts();
    this.observeProductChanges();
  }

  private async fetchProducts(): Promise<void> {
    const products = await this.productService.getProducts();
    this.productListSubject.next(products);
  }

  private observeProductChanges(): void {
    this.productService.productChangedSubject.subscribe(() => {
      this.fetchProducts();
    });
  }

  public getProducts(): Observable<ProductSimple[]> {
    return this.productListSubject.asObservable();
  }
}
