import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {useLogRocket} from '../../util/logrocket.util';
import LogRocket from 'logrocket';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor() {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => {
      ErrorInterceptor.logToLogRocket(error);
      return throwError(() => error);
    }));
  }

  private static logToLogRocket(error: any): void {
    if (useLogRocket() && ErrorInterceptor.isServerError(error)) {
      LogRocket.captureException(error);
    }
  }

  private static isServerError(error: any): boolean {
    return /^5\d{2}$/.test(error?.status?.toString());
  }
}
