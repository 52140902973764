import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {IAsset} from '../../interface/IAsset';
import {AssetService} from '../../service/asset.service';
import {faCheck, faCircleInfo, faFloppyDiskCircleArrowRight, faXmark} from '@fortawesome/pro-light-svg-icons';
import {catchError, firstValueFrom, Subject, throwError} from 'rxjs';
import {SnackbarService} from '../../../shared/service/snackbar/snackbar.service';
import * as _ from 'lodash';
import {Status} from 'src/app/shared/enum/Status';
import {BACK_ACTION} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {IBackAction} from '../../../shared/interface/ui/dialog/IBackAction';
import {FormControl, Validators} from '@angular/forms';
import {hasRequiredError} from '../../../shared/util/form/form-utils';
import {IAdUnit} from '../../../ad-unit/interface/IAdUnit';
import {AdUnitService} from '../../../ad-unit/service/ad-unit';
import {MessageService} from '../../../shared/service/message/message.service';
import {IMatchedAdUnitFormat} from '../../interface/IMatchedAdUnitFormat';
import {ICanDeactivateFormCmp} from '../../../shared/interface/ui/form/ICanDeactivateFormCmp';
import {TranslateService} from '@ngx-translate/core';
import {IAssetDetails} from '../../interface/IAssetDetails';
import {IFormScrollable} from '../../../shared/interface/ui/form/IFormCmp';
import {IFormScrollableHandler} from '../../../shared/interface/ui/form/IFormScrollableHandler';
import {FormScrollableHandler} from '../../../shared/class/FormScrollableHandler';
import {ImageDataService} from '../../../shared/service/file-utils/image-data.service';
import {IAssetFileOutput} from '../../interface/IAssetFileOutput';
import {ProductsService} from '../../../product/service/product/products.service';
import {ProductSimple} from '../../../product/interface/IProductSimple';
import {FileUploadService} from '../../service/file-upload.service';

/** Component for handling asset form, allowing users to create or edit assets. */
@Component({
  selector: 'app-asset-form',
  templateUrl: './asset-form.component.html',
  styleUrls: ['./asset-form.component.scss']
})
export class AssetFormComponent implements OnInit, ICanDeactivateFormCmp, IFormScrollable, AfterViewChecked {
  /** Reference to the scrollable element within the form. */
  @ViewChild('formScrollable') formScrollable: ElementRef;
  private _hasScroll;

  /** Input property indicating whether the form is in edit mode. */
  @Input() isEditMode = false;

  /** Input property indicating whether the form is used within a dialog. */
  @Input() isDialog = false;

  /** Input property representing the initial value of the asset details. */
  @Input() initialValue: IAssetDetails;

  /** Boolean indicating whether the form can be deactivated. */
  public canDeactivateForm = false;

  /** Object to store various error states related to the form. */
  public errors = {
    nameExists: null,
    noMatchedFormats: null,
    formatIncompatibleForEdit: false
  };

  /** Utility function to check for required form errors. */
  public hasRequiredError = hasRequiredError;

  /** Form control for selecting a product. */
  public productFormControl: FormControl<ProductSimple>;

  /** Enum representing the status types. */
  Status = Status;

  /** Array to store promises for async operations. */
  public promises: Promise<any>[] = [];

  /** Object representing the asset being created or edited. */
  public asset: IAsset;

  /** The selected file. */
  public file: File;

  /** Array of matched ad unit formats for the asset. */
  public matchedFormats: IMatchedAdUnitFormat[] = [];

  /** FontAwesome icons used in the component. */
  public faFloppyDiskCircleArrowRight = faFloppyDiskCircleArrowRight;
  public faCircleInfo = faCircleInfo;
  public faCheck = faCheck;
  public faXMark = faXmark;

  /** Boolean indicating whether the form is in a loading state. */
  public isLoading = false;

  /** Subject to close the dialog in case it's used in a dialog context. */
  public closeDialogSubject = new Subject<boolean>();

  /** Form scrollable handler to manage scrolling behavior. */
  private formScrollableHandler: IFormScrollableHandler;

  /**
   * Constructor for AssetFormComponent.
   * @param backAction Optional injected back action for custom navigation behavior.
   * @param router Angular router for navigation.
   * @param assetService Service for handling asset-related operations.
   * @param snackbarService Service for displaying snackbar messages.
   * @param adUnitService Service for handling ad unit-related operations.
   * @param messageService Service for displaying messages.
   * @param translateService Service for language translation.
   * @param changeDetectorRef Angular's ChangeDetectorRef for manual change detection.
   * @param imageDataService Service for handling image data related operations.
   * @param productService Service for handling product-related operations.
   * @param fileUploadService Service for handling file upload to aws
   */
  constructor(@Optional() @Inject(BACK_ACTION) public backAction: IBackAction, private router: Router,
              private assetService: AssetService, private snackbarService: SnackbarService,
              private adUnitService: AdUnitService, private messageService: MessageService,
              private translateService: TranslateService, public changeDetectorRef: ChangeDetectorRef,
              private imageDataService: ImageDataService, private productService: ProductsService,
              private fileUploadService: FileUploadService) {
    this.formScrollableHandler = new FormScrollableHandler(this);
  }

  /**
   * Helper method to get the file extension from a given format.
   * @param format The file format.
   * @returns The file extension.
   */
  private static getFileExtension(format: string): string {
    return '.' + format;
  }

  /**
   * Helper method to get the file name without extension.
   * @param fileName The file name with extension.
   * @returns The file name without extension.
   */
  private static getFileNameWithoutExtension(fileName: string): string {
    return fileName.split('.')[0];
  }

  /** Lifecycle hook called after component initialization. */
  public ngOnInit(): void {
    this.initProduct().then(async () => {
      await this.initializeAdUnits();
      if (this.isEditMode) {
        this.initializeEditForm();
      }
    });
  }

  /** Lifecycle hook called after the view has been checked. */
  public ngAfterViewChecked(): void {
    this.formScrollableHandler.setScrollStatus();
  }

  /** Initializes the ad units for the asset. */
  private async initializeAdUnits(): Promise<void> {
    const adUnits: IAdUnit[] = await this.adUnitService.getAdUnitsFromSubject();
    this.matchedFormats = adUnits.map(adUnit => ({
      label: adUnit.name, name: adUnit.type, isMatch: false,
      adUnit, matchedAdUnitFormatIds: []
    }));
  }

  /** Initializes the product form control based on edit mode. */
  private async initProduct(): Promise<void> {
    if (this.isEditMode) {
      const product = await this.productService.getProductByInternalId(this.initialValue.product.id);
      this.productFormControl = new FormControl<ProductSimple>(product, [Validators.required]);
    } else {
      this.productFormControl = new FormControl<ProductSimple>(null, [Validators.required]);
    }
  }

  /** Initializes the form for editing an existing asset. */
  public initializeEditForm(): void {
    this.asset = _.cloneDeep(this.initialValue);
    this.updateMatchedFormats(this.asset.adUnitFormatIds);
    this.errors = {
      nameExists: false,
      noMatchedFormats: !this.initialValue.adUnitFormatMatches.length,
      formatIncompatibleForEdit: false
    };
  }

  /** Handles the cancel button click event. */
  public onCancelClicked(): void {
    if (!this.isDialog) {
      this.router.navigate(['/assets/list']);
    } else {
      this.closeDialogSubject.next(false);
    }
  }

  /** Handles the submit button click event. */
  public async onSubmitClicked(): Promise<void> {
    if (this.isFormValid() && this.asset) {
      await this.handleSubmit();
    }
  }

  /** Handles the form submission. */
  public async handleSubmit(): Promise<void> {
    await Promise.all(this.promises);
    if (this.isEditMode) {
      await this.handleEditAssetSubmit();
    } else {
      await this.handleNewAssetSubmit();
    }
  }

  /** Handles the submission of a new asset. */
  public async handleNewAssetSubmit(): Promise<void> {
    await this.handleFileUpload();
    await this.createAsset();
  }

  /** Handles the submission of an edited asset. */
  public async handleEditAssetSubmit(): Promise<void> {
    if (this.file) {
      await this.handleFileUpload();
      await this.updateAsset();
    } else {
      const message = await firstValueFrom(this.translateService.get('asset.assetEditedMessage'));
      this.snackbarService.openSuccessSnackbar(message);
      this.closeDialogSubject.next(true);
    }
  }

  /** Handles the file upload operation. */
  public async handleFileUpload(): Promise<void> {
    if (this.fileUploadService.canUploadUsingPresignedUrls()) {
      await this.handleUploadUsingPresignedUrls();
    } else {
      await this.handleUploadUsingEndpoint();
    }
  }

  private async handleUploadUsingEndpoint(): Promise<void> {
    this.isLoading = true;
    const newFile = new File([this.file], this.asset.name, {type: this.file.type});
    const boombitId = this.productFormControl.value.boombitId;
    await this.assetService.uploadFile(newFile, boombitId);
    this.asset.url = this.imageDataService.getFileSrcUrl(this.asset.name, boombitId);
  }

  private async handleUploadUsingPresignedUrls(): Promise<void> {
    this.isLoading = true;
    const boombitId = this.productFormControl.value.boombitId;
    const presignedUrlResponse = await this.assetService.generatePresignedUrls(
      [{originalName: this.file.name, boombitId, contentType: this.file.type}]);
    const fileUploadUrl = presignedUrlResponse[this.file.name].url;
    const newFileName = presignedUrlResponse[this.file.name].generatedName + '.' + this.file.name.split('.').pop();
    const newFile = new File([this.file], newFileName, {type: this.file.type});
    await firstValueFrom(this.fileUploadService.uploadFileAWSS3(fileUploadUrl, this.file.type, newFile).pipe(catchError((error) => {
      this.isLoading = false;
      return throwError(() => error);
    })));
    this.asset.url = presignedUrlResponse[this.file.name]?.assetUrl;
    this.asset.name = newFileName;
  }

  /** Creates a new asset. */
  public async createAsset(): Promise<void> {
    this.assetService.createAsset(this.productFormControl.value.id, this.asset).then(async () => {
      const message = await firstValueFrom(this.translateService.get('asset.assetCreatedMessage'));
      this.snackbarService.openSuccessSnackbar(message);
      this.canDeactivateForm = true;
      this.router.navigate(['/assets/list']);
    }).catch(() => this.stopLoadingSpinner());
  }

  /** Updates an existing asset. */
  public async updateAsset(): Promise<void> {
    this.asset.id = this.initialValue.id;
    this.assetService.updateAsset(this.asset, this.initialValue.product.id).then(async () => {
      const message = await firstValueFrom(this.translateService.get('asset.assetEditedMessage'));
      this.snackbarService.openSuccessSnackbar(message);
      this.closeDialogSubject.next(true);
    }).catch(() => this.stopLoadingSpinner());
  }

  /** Stops the loading spinner. */
  public stopLoadingSpinner(): void {
    this.isLoading = false;
  }

  /** Handles the deletion of a file. */
  public onFileDeleted(): void {
    this.file = null;
    this.asset = null;
    this.clearErrors();
    this.clearAllMatchedFormats();
  }

  /**
   * Handles the selection of a file.
   * @param data Object containing the selected file and associated asset details.
   */
  public async onFileSelected(data: IAssetFileOutput): Promise<void> {
    this.file = data.file;
    this.asset = data.asset;
    this.clearErrors();
    this.clearAllMatchedFormats();
    if (!this.fileUploadService.canUploadUsingPresignedUrls()) {
      this.setRandomNameForAsset();
    }
    this.checkOriginalNameUnique();
    await this.getMatchedFormats();
    if (this.isEditMode) {
      this.checkAdUnitFormatMatch();
    }
  }

  /** Checks whether the ad unit formats match for editing an existing asset. */
  private checkAdUnitFormatMatch(): void {
    this.errors.formatIncompatibleForEdit = !this.adUnitFormatsUnchanged();
  }

  /**
   * Checks whether the ad unit formats remain unchanged during editing.
   * @returns `true` if ad unit formats remain unchanged, otherwise `false`.
   */
  private adUnitFormatsUnchanged(): boolean {
    return this.initialValue.adUnitFormatIds.every(adUnitFormatId =>
      this.matchedFormats.some(format => format.matchedAdUnitFormatIds.includes(adUnitFormatId) && format.isMatch === true)
    );
  }

  /** Sets a random name for the asset. */
  private async setRandomNameForAsset(): Promise<void> {
      const assetNamePromise = this.assetService.getRandomAssetName(30);
      this.promises.push(assetNamePromise);
      this.asset.name = await assetNamePromise + '.' + this.asset.format;
  }

  /** Checks whether the original name is unique. */
  private async checkOriginalNameUnique(): Promise<void> {
    if (this.isOriginalNameInitial()) {
      this.errors.nameExists = false;
    } else {
      const originalNamePromise = this.assetService.checkOriginalName(this.asset.originalName);
      this.promises.push(originalNamePromise);
      this.errors.nameExists = await originalNamePromise;
    }
  }

  /**
   * Checks whether the original name is the initial one.
   * @returns `true` if the original name is unchanged, otherwise `false`.
   */
  private isOriginalNameInitial(): boolean {
    return this.isEditMode && this.initialValue.originalName === this.asset.originalName;
  }

  /** Retrieves the matched ad unit formats for the asset. */
  public async getMatchedFormats(): Promise<void> {
    await this.assetService.getAssetAdUnitFormats(this.asset).then((res) => {
      this.updateMatchedFormats(res.map(adUnitFormat => adUnitFormat.id));
    }).catch(() => {
      this.errors.noMatchedFormats = true;
    });
  }

  /**
   * Updates the matched ad unit formats based on the provided ad unit format IDs.
   * @param adUnitFormatIds The array of ad unit format IDs.
   */
  public updateMatchedFormats(adUnitFormatIds: string[]): void {
    this.clearAdUnitFormatIds();
    adUnitFormatIds.forEach(adUnitFormatId => this.setMatchedFormats(adUnitFormatId));
  }

  /**
   * Sets the matched ad unit formats for a given ad unit format ID.
   * @param adUnitFormatId The ad unit format ID.
   */
  private setMatchedFormats(adUnitFormatId: string): void {
    this.getFormatsIncludingAdUnitFormat(adUnitFormatId).forEach(matchedFormat => {
      this.handleMatchFound(matchedFormat, adUnitFormatId);
    });
  }

  /**
   * Handles the case when a matching ad unit format is found for a given ad unit format ID.
   * @param matchedFormat The matched ad unit format.
   * @param adUnitFormatId The ad unit format ID.
   */
  private handleMatchFound(matchedFormat: IMatchedAdUnitFormat, adUnitFormatId: string): void {
    matchedFormat.isMatch = true;
    matchedFormat.matchedAdUnitFormatIds.push(adUnitFormatId);
    matchedFormat.matchedAdUnitFormatIds = _.uniq(matchedFormat.matchedAdUnitFormatIds);
    this.errors.noMatchedFormats = false;
  }

  /** Clears the ad unit format IDs for all matched ad unit formats. */
  private clearAdUnitFormatIds(): void {
    this.matchedFormats.forEach(matchedFormat => matchedFormat.matchedAdUnitFormatIds = []);
  }

  /**
   * Retrieves the matched ad unit formats that include a given ad unit format ID.
   * In case one ad unit format is assigned to more than one ad unit.
   * @param adUnitFormatId The ad unit format ID.
   * @returns An array of matched ad unit formats.
   */
  private getFormatsIncludingAdUnitFormat(adUnitFormatId: string): IMatchedAdUnitFormat[] {
    return this.matchedFormats.filter(format => this.includesAdUnitFormat(format, adUnitFormatId));
  }

  /**
   * Checks if a matched ad unit format includes a specific ad unit format ID.
   * @param matchedFormat The matched ad unit format.
   * @param adUnitFormatId The ad unit format ID.
   * @returns A boolean indicating whether the ad unit format is included.
   */
  public includesAdUnitFormat(matchedFormat: IMatchedAdUnitFormat, adUnitFormatId: string): boolean {
    return matchedFormat.adUnit.adUnitFormatIds.includes(adUnitFormatId);
  }

  /**
   * Checks if there is an error related to the file.
   * @returns A boolean indicating whether there is a file error.
   */
  public isFileError(): boolean {
    return this.errors.noMatchedFormats === true || this.errors.nameExists === true ||
      this.errors.formatIncompatibleForEdit === true;
  }

  /**
   * Checks if the file is considered valid.
   * @returns A boolean indicating whether the file is valid.
   */
  public isFileValid(): boolean {
    return this.errors.nameExists === false && this.errors.noMatchedFormats === false &&
      this.errors.formatIncompatibleForEdit === false;
  }

  /**
   * Checks if the entire form is valid.
   * @returns A boolean indicating whether the entire form is valid.
   */
  public isFormValid(): boolean {
    return this.isFileValid() && (this.isEditMode ? true : this.productFormControl.valid);
  }

  /** Clears all the matched ad unit formats. */
  public clearAllMatchedFormats(): void {
    this.matchedFormats.forEach(matchedFormat => matchedFormat.isMatch = false);
  }

  /** Clears all the error flags. */
  public clearErrors(): void {
    this.errors.nameExists = null;
    this.errors.noMatchedFormats = null;
    this.errors.formatIncompatibleForEdit = false;
  }

  /**
   * Gets the scroll status of the form.
   * @returns A boolean indicating whether the form has scroll.
   */
  public get hasScroll(): boolean {
    return this._hasScroll;
  }

  /**
   * Sets the scroll status of the form.
   * @param value A boolean indicating the scroll status.
   */
  public set hasScroll(value: boolean) {
    this._hasScroll = value;
  }
}
