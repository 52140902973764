import {IProductRaw} from '../interface/IProductRaw';
import {IProductDetails} from '../interface/IProductDetails';
import _ from 'lodash';
import {AtLeast} from '../../shared/type/AtLeast';
import {IExternalTrackingRaw} from '../interface/IExternalTrackingRaw';
import {IPlatform} from '../interface/platform/IPlatform';

export class ExternalTrackingManager {
  constructor() {
  }

  public getTrackingsToDelete(product: IProductRaw, initialProduct: IProductDetails): string[] {
    const initialIds = ExternalTrackingManager.getTrackingIds(initialProduct);
    const updatedIds = ExternalTrackingManager.getTrackingIds(product);
    const alreadyDeletedIds = this.getAlreadyDeletedExternalIds(product, initialProduct);
    return _.difference(initialIds, updatedIds, alreadyDeletedIds);
  }

  private getAlreadyDeletedExternalIds(product: IProductRaw, initialProduct: IProductDetails): string[] {
    const deletedPlatforms = this.getDeletedPlatforms(product, initialProduct);
    return deletedPlatforms.flatMap(platform => platform.externalTrackings?.map(tracking => tracking.id) || []);
  }

  private getDeletedPlatforms(productRaw: IProductRaw, initialProduct: IProductDetails): IPlatform[] {
    return initialProduct.platforms.filter(platform => !productRaw.platforms.some(rawPlatform => rawPlatform.id === platform.id));
  }

  private static getTrackingIds(product: IProductRaw | IProductDetails): string[] {
    const platforms = product.platforms;
    const externalTrackings2d: AtLeast<IExternalTrackingRaw, 'id'>[][] = platforms
      .map(platform => platform.externalTrackings).filter(el => el);
    const externalTrackings: AtLeast<IExternalTrackingRaw, 'id'>[] = [].concat(...externalTrackings2d);
    return externalTrackings.map(externalTracking => externalTracking?.id).filter(el => el);
  }
}
