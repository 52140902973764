import { Injectable } from '@angular/core';
import {ScreenWidth} from '../../enum/ScreenWidth';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  // this value must match corresponding value in scss files
  private readonly laptopWidth = 1800;

  public getScreenWidthType(): ScreenWidth {
    if (this.isLaptop()) {
      return ScreenWidth.LAPTOP;
    } else {
      return ScreenWidth.DEFAULT;
    }
  }

  public isLaptop(): boolean {
    return window.innerWidth < this.laptopWidth;
  }

  public getScreenSize(): number {
    return window.innerWidth;
  }
}
