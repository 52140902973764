import {FormArray, FormGroup, ValidationErrors} from '@angular/forms';
import {isValueEmpty} from '../../shared/util/form/form-utils';
import {IPlatformFormGroup} from '../interface/platform/IPlatformFormGroup';

export function minimumOnePlatform(formArray: FormArray<FormGroup<IPlatformFormGroup>>): ValidationErrors | null {
  return formArray.controls?.length &&
  formArray.controls.some((group: FormGroup<IPlatformFormGroup>) =>
    !isValueEmpty(group.controls.type.value?.name)) ?
    null : {noPlatform: true};
}
