import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {BACK_ACTION, DIALOG_DATA} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {IBackAction} from '../../../shared/interface/ui/dialog/IBackAction';
import {CreativeService} from '../../service/creative.service';
import {PlatformType} from '../../../product/enum/PlatformType';
import {CreativeDialogService} from '../../service/creative-dialog/creative-dialog.service';
import {CustomIconName} from '../../../shared/enum/ui/icons/CustomIconName';
import {Color} from 'src/app/shared/enum/ui/Color';
import {faDisplay, faEdit, faEye, faTrash, faXmark} from '@fortawesome/pro-light-svg-icons';
import {IProduct} from '../../../product/interface/IProduct';
import {Tab} from '../../../shared/interface/ui/tabs/Tab';
import {getSideDialogConfig} from '../../../shared/util/dialog/dialog-back-action.util';
import {TranslateService} from '@ngx-translate/core';
import {firstValueFrom, Subscription} from 'rxjs';
import {CreativeEndpointsEnum} from '../../../shared/enum/Endpoints';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {IDialogConfig} from '../../../shared/interface/ui/dialog/IDialogConfig';
import {ICreativeDetails} from '../../interface/ICreativeDetails';

@Component({
  selector: 'app-product-creatives-preview',
  templateUrl: './product-creatives-preview.component.html',
  styleUrls: ['./product-creatives-preview.component.scss']
})
export class ProductCreativesPreviewComponent implements OnInit, OnDestroy {
  public creatives: ICreativeDetails[] = [];
  public initialCreatives: ICreativeDetails[] = [];

  public faDisplay = faDisplay;
  public faXMark = faXmark;
  public faEye = faEye;
  public faEdit = faEdit;
  public faTrash = faTrash;
  public Color = Color;

  public tabs = [{label: '', name: 'active'}, {label: '', name: 'inactive'}];
  public selectedTab: Tab = {label: '', name: 'active'};

  public isLoading = false;
  private loadingEndpointNames: string[] = [CreativeEndpointsEnum.GET_CREATIVES_FOR_PRODUCT];
  private subscriptions: Subscription[] = [];

  constructor(public dialogRef: DialogRef,
              @Inject(DIALOG_DATA) public data: { product: IProduct },
              @Inject(BACK_ACTION) public backAction: IBackAction,
              private creativeService: CreativeService, private creativeDialogService: CreativeDialogService,
              private translateService: TranslateService, private loadingService: LoadingService) {
  }

  public ngOnInit(): void {
    this.initTranslations();
    this.initCreatives();
    this.observeLoading();
  }

  private async initTranslations(): Promise<void> {
    this.tabs = [{
      label: await firstValueFrom(this.translateService.get('creative.creativeStatus.active')),
      name: 'active'
    },
      {label: await firstValueFrom(this.translateService.get('creative.creativeStatus.inactive')), name: 'inactive'}];
    this.selectedTab = this.tabs[0];
  }

  public async initCreatives(): Promise<void> {
    this.initialCreatives = await this.creativeService.getProductCreatives(this.data.product.id);
    this.updateCreatives();
  }


  private observeLoading(): void {
    const sub = this.loadingService.loadingEndpointsObs().subscribe((loadingEndpoints) => {
      this.isLoading = this.loadingService.areEndpointsLoading(this.loadingEndpointNames, loadingEndpoints);
    });
    this.subscriptions.push(sub);
  }

  public onCreativeDeleted(creativeId: string): void {
    this.initialCreatives = this.initialCreatives.filter(initialCreative => initialCreative.id !== creativeId);
    this.updateCreatives();
  }

  private updateCreatives(): void {
    this.creatives = this.selectedTab.name === 'inactive' ? this.getCreativesByActiveState(false) :
      this.getCreativesByActiveState(true);
  }

  private getCreativesByActiveState(isActive: boolean): ICreativeDetails[] {
    return this.initialCreatives.filter(creative => creative.isActive === isActive);
  }

  public onCloseClicked(): void {
    this.dialogRef.close();
  }

  public onTabChanged(tab: Tab): void {
    this.selectedTab = tab;
    this.updateCreatives();
  }

  public isPlatformWeb(platformType: PlatformType): boolean {
    return platformType === PlatformType.WEB;
  }

  public getCustomIconName(name: string): CustomIconName {
    return name as CustomIconName;
  }

  public get backDialogConfig(): IDialogConfig {
    return getSideDialogConfig(ProductCreativesPreviewComponent, this.backAction, this.data);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }
}
