<ng-container *ngIf="isTableFilter">
  <ng-container *ngTemplateOutlet="buttonContentTableFilter"></ng-container>
</ng-container>
<ng-container *ngIf="!isTableFilter">
  <ng-container *ngTemplateOutlet="buttonContentNotTableFilter"></ng-container>
</ng-container>

<ng-template #buttonContentTableFilter>
  <span>
    {{placeholder}}<span *ngIf="showContent">: </span>
  </span>
  <span class="text-14-txt-2-semi-bold color-dark-grey" *ngIf="showContent">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </span>
</ng-template>

<ng-template #buttonContentNotTableFilter>
  <span class="color-light-grey-primary select-placeholder" *ngIf="!showContent">
    {{placeholder}}
  </span>
  <span class="mat-caption color-dark-grey" *ngIf="showContent">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </span>
</ng-template>


<ng-template #content>
  <ng-content></ng-content>
</ng-template>
