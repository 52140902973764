import {Component, Inject} from '@angular/core';
import {DialogService} from '../../../service/dialog/dialog/dialog.service';
import {DialogRef} from '../../../service/dialog/dialog/dialog-ref';
import {DIALOG_DATA} from '../../../service/dialog/dialog/dialog-tokens';
import {IConfirmDialogData} from '../../../interface/ui/dialog/IConfirmDialogData';
import {IconOriginType} from '../../../enum/ui/icons/IconOriginType';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  IconOriginType = IconOriginType;

  constructor(public dialog: DialogService, public dialogRef: DialogRef,  @Inject(DIALOG_DATA) public data: IConfirmDialogData) { }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onConfirm(): void {
    this.dialogRef.close(true);
  }
}
