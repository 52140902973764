import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Injector, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {
  GoogleLoginProvider,
  GoogleSigninButtonModule,
  SocialAuthServiceConfig,
  SocialLoginModule
} from '@abacritt/angularx-social-login';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {DateAdapter} from '@angular/material/core';
import {SidenavContainerComponent} from './shared/component/common/sidenav-container/sidenav-container.component';
import {FileSizePipe} from './shared/pipe/file-size.pipe';
import {DatePipe} from '@angular/common';
import {CustomDateAdapter} from './shared/util/date/custom-date-adapter';
import {LoginComponent} from './shared/component/login/login/login.component';
import {ErrorInterceptor} from './shared/interceptor/error-interceptor/error.interceptor';
import {JwtInterceptor} from './shared/interceptor/jwt-interceptor/jwt.interceptor';
import {setAppInjector} from './shared/util/app-injector';
import {LoadingInterceptor} from './shared/interceptor/loading-interceptor/loading.interceptor';
import {DummyComponent} from './shared/component/common/dummy/dummy.component';
import {environment} from '../environments/environment';
import {MyErrorHandler} from './shared/error-handler/MyErrorHandler';
import {HttpLoaderFactory, SharedModule} from './shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [
    AppComponent,
    SidenavContainerComponent,
    LoginComponent,
    DummyComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SocialLoginModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    SharedModule,
    GoogleSigninButtonModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId, {oneTapEnabled: false}
            )
          },
        ]
      } as SocialAuthServiceConfig,
    },
    FileSizePipe,
    DatePipe,
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: ErrorHandler, useClass: MyErrorHandler},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    setAppInjector(injector);
  }
}
