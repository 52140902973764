<app-base-expanded-dialog>
  <div class="full-height-page">
    <ng-container *ngIf="product">
    <div class="main-header-row">
      <div class="main-header">
        <h1 class="h1-semibold">{{'campaign.campaignsList' | translate}} <span *ngIf="itemsCount">({{itemsCount}})</span></h1>
        <form>
          <app-search-input [autocompleteItems]="autocompleteItems"  (value)="onSearchValueChanged($event)"></app-search-input>
        </form>
      </div>
    </div>
    <div class="list-table">
      <app-table
        [tableCssClass]="['table-sticky-header']"
        [rowsCount]="itemsCount"
        [filters]="filters"
        (queryUpdated)="onQueryUpdated($event)"
        (cellEvent)="onCellEvent($event)"
        [infinityScroll]="true"
        [withPagination]="false"
        [headers]="headers"
        [isLoading]="isLoading"
        [spinnerForInfinityScroll]="spinnerForInfinityScroll"
        [noMatchFound]="noMatchFound"
        [changeQuery$]="changeQuery$.asObservable()"
        [rows]="rows"></app-table>
    </div>
    </ng-container>
  </div>
</app-base-expanded-dialog>
