<app-base-center-dialog [dialogRef]="dialogRef">
  <div class="product-created-dialog">
    <fa-icon class="success-icon" [icon]="faCircleCheck"></fa-icon>
    <h1>{{'product.productCreatedDialog.header' | translate}}</h1>
    <p *ngIf="!data?.forCreativesList" class="translate-wrapper--strong"
       [innerHTML]="'product.productCreatedDialog.text' | translate"></p>
    <p *ngIf="data?.forCreativesList" class="translate-wrapper--strong"
       [innerHTML]="'product.productCreatedDialog.text2' | translate"></p>
    <div class="actions-buttons">
      <button (click)="onCloseClicked()" mat-stroked-button color="primary">
        <fa-icon class="close-icon" [icon]="faXMark"></fa-icon>
        {{'button.close' | translate}}
      </button>
      <button (click)="onUploadCreativeClicked()" mat-flat-button color="primary">
        <fa-icon [icon]="faPaintBrushPencil"></fa-icon>
        {{'creative.addCreativeButton' | translate}}
      </button>
      <button *ngIf="!data?.forCreativesList" (click)="onDefineNewWaterfallClicked()" mat-flat-button color="primary">
        <fa-icon [icon]="faWaterfall"></fa-icon>
        {{'waterfall.addWaterfall' | translate}}
      </button>
    </div>
  </div>
</app-base-center-dialog>
