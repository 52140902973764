import {Directive, ElementRef, HostListener, Injector, Input, OnDestroy, ViewContainerRef} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TooltipDirective} from '../../../shared/directive/tooltip/tooltip.directive';
import {Placement} from '../../../shared/enum/ui/tooltip/Placement';
import {ComponentPortal} from '@angular/cdk/portal';
import {TooltipComponent} from '../../../shared/component/common/tooltip/tooltip.component';
import {TOOLTIP_DATA} from '../../../shared/directive/tooltip/tooltip-token';
import {TooltipType} from '../../../shared/enum/ui/tooltip/TooltipType';
import {AdUnitMapperService} from '../../service/ad-unit-mapper.service';

/**
 * Directive for displaying ad unit tooltips on hover.
 */
@Directive({
  selector: '[appAdUnitTooltip]'
})
export class AdUnitTooltipDirective implements OnDestroy {
  /** Input to set the ad unit type for the tooltip. */
  @Input('appAdUnitTooltip') public adUnitType: string;

  /** Reference to the overlay displayed for the tooltip. */
  private overlayRef: OverlayRef;

  /** Flag indicating whether the tooltip is active. */
  public isActive = true;

  /** Text content for the tooltip. */
  public text: string;

  /**
   * Constructor for the AdUnitTooltipDirective.
   * @param elementRef Reference to the host element.
   * @param overlay Service for creating overlays.
   * @param injector Injector for dependency injection.
   * @param adUnitMapperService Service for mapping ad unit details.
   */
  constructor(private elementRef: ElementRef,
              private viewContainerRef: ViewContainerRef,
              private overlay: Overlay, private injector: Injector,
              private adUnitMapperService: AdUnitMapperService) {
  }

  /** Host listener for mouseenter event to show the tooltip. */
  @HostListener('mouseenter')
  private show(): void {
    if (!this.overlayRef && this.isActive) {
      this.createTooltip();
    }
  }

  /** Host listener for mouseleave event to hide the tooltip. */
  @HostListener('mouseleave')
  private hide(): void {
    this.removeTooltip();
  }

  /** Asynchronously creates the tooltip and sets its text content. */
  private async createTooltip(): Promise<void> {
    await this.setTooltipText();

    this.overlayRef = this.overlay.create({
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions(TooltipDirective.getPosition(Placement.TOP))
    });
    const portal = new ComponentPortal(TooltipComponent, null, this.getTooltipInjector());
    this.overlayRef.attach(portal);
  }

  /** Asynchronously sets the tooltip text content using the ad unit mapper service. */
  private async setTooltipText(): Promise<void> {
    this.text = await this.adUnitMapperService.getAdUnitName(this.adUnitType);
  }

  /** Removes the tooltip. */
  private removeTooltip(): void {
    if (!this.overlayRef) {
      return;
    }
    this.overlayRef.detach();
    this.overlayRef = null;
  }

  /** Creates and returns an injector for the tooltip component. */
  private getTooltipInjector(): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [
        {provide: TOOLTIP_DATA, useValue: {text: this.text, placement: Placement.TOP, type: TooltipType.TEXT}},
      ]
    });
  }

  /** Lifecycle hook called when the directive is destroyed. */
  public ngOnDestroy(): void {
    this.removeTooltip();
  }

}
