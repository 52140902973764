import {Component, Input} from '@angular/core';
import {CustomIconName} from '../../../enum/ui/icons/CustomIconName';
import {Color} from '../../../enum/ui/Color';
import _ from 'lodash';

@Component({
  selector: 'app-custom-icon',
  templateUrl: './custom-icon.component.html',
  styleUrls: ['./custom-icon.component.scss']
})
export class CustomIconComponent {
  @Input() name: string;
  @Input() color: Color = Color.PRIMARY;
  @Input() height: number;
  @Input() heightLiteral: string;
  public CustomIconName = CustomIconName;

  constructor() { }

  public nameCamelCase(): string {
    return _.camelCase(this.name);
  }

}
