<div class="dropzone-wrapper"
     [ngClass]="{'dropzone-wrapper--success': status === Status.SUCCESS, 'dropzone-wrapper--error': status === Status.ERROR}"
     appFileDnd (fileDropped)="onFileDropped($event)">
  <div class="dropzone">
    <fa-icon class="upload-icon"
             [ngClass]="{'upload-icon--success': status === Status.SUCCESS, 'upload-icon--error': status === Status.ERROR}"
             [icon]="status === 'error' ? faCircleExclamation : status === Status.SUCCESS ? faCircleCheck : faArrowDownToLine">
    </fa-icon>
    <input type="file" #fileDropRef (change)="onFileSelected($event)"/>
    <div>
      <p class="text-12-txt-3-regular">{{'asset.assetForm.dragFile' | translate}}</p>
      <div [ngClass]="{'file-upload-separator--error': status === 'error'}" class="file-upload-separator">
        <span class="text-12-txt-3-light text-uppercase">{{'basic.or' | translate}}</span>
        <hr>
      </div>
    </div>
    <div class="asset-upload-buttons">
    <button *ngIf="!file" class="button-narrow" mat-stroked-button color="primary" (click)="fileDropRef.click()">
      <fa-icon [icon]="faArrowDownToLine"></fa-icon>
      {{'asset.assetForm.uploadFileButton' | translate}}
    </button>
    <button *ngIf="file" class="button-narrow" mat-stroked-button color="primary" (click)="fileDropRef.click()">
      <fa-icon [icon]="faArrowRightArrowLeft"></fa-icon>
      {{'asset.assetForm.changeFileButton' | translate}}
    </button>
      <button class="button-narrow" (click)="addFromLibraryClicked.emit()" *ngIf="insideCreativeForm" mat-flat-button color="primary">
        <fa-icon [icon]="faBookCircleArrowUp"></fa-icon>
        {{'asset.assetForm.addFromLibraryButton' | translate}}
      </button>
    </div>

    <div class="uploaded-asset-info" *ngIf="asset">
      <p class="text-12-txt-3-regular color-dark-grey-fourth">
        {{'asset.assetDetails.ratio' | translate}}: {{asset.ratio}};
        {{'asset.assetDetails.resolution' | translate}}: {{getResolution()}};
        {{'asset.assetDetails.format' | translate}}: {{asset.format}}
      </p>
      <hr style="width: 100%">
      <p [ngClass]="status === 'error' ? 'color-error': ''" class="text-14-txt-2-light file-name-line">
        <span class="file-name">{{asset.originalName}}</span>
        <fa-icon [appIconTooltip]="faArrowUpRightFromSquare"
                 class="color-primary cursor-pointer"
                 [icon]="faArrowUpRightFromSquare"
                  (click)="onOpenPreview()">
        </fa-icon>
        <fa-icon [appIconTooltip]="faTrash"
                 class="color-primary cursor-pointer"
                 [icon]="faTrash"
                  (click)="onDeleteClicked()">
        </fa-icon>
      </p>
    </div>
  </div>
</div>
