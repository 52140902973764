import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {BACK_ACTION, DIALOG_DATA} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {DialogService} from '../../../shared/service/dialog/dialog/dialog.service';
import {CustomIconName} from '../../../shared/enum/ui/icons/CustomIconName';
import {Color} from 'src/app/shared/enum/ui/Color';
import {
  faArrowUpRightFromSquare,
  faDisplay,
  faEye,
  faPenToSquare,
  faTrash,
  faXmark
} from '@fortawesome/pro-light-svg-icons';
import {IPlatform} from '../../interface/platform/IPlatform';
import {IExecutionPoint} from '../../interface/execution-point/IExecutionPoint';
import {ExecutionPointType} from '../../enum/ExecutionPointType';
import {PlatformType} from 'src/app/product/enum/PlatformType';
import {ExpandedDialogService} from '../../../shared/service/dialog/expanded-dialog/expanded-dialog.service';
import {ProductDialogService} from '../../service/product-dialog/product-dialog.service';
import {Subscription} from 'rxjs';
import {CreativeDialogService} from '../../../creative/service/creative-dialog/creative-dialog.service';
import {IBackAction} from '../../../shared/interface/ui/dialog/IBackAction';
import {getSideDialogConfig} from '../../../shared/util/dialog/dialog-back-action.util';
import {ProductsService} from '../../service/product/products.service';
import {ProductEndpointsEnum} from '../../../shared/enum/Endpoints';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {getUrl, hasUrl} from '../../util/platform-form-template-utils';
import {IProductDetails} from '../../interface/IProductDetails';
import {ProductMapperService} from '../../service/product-mapper/product-mapper.service';
import {getStoreLink, linkToStoreSupported} from '../../util/store-url.util';
import _ from 'lodash';
import {hasBundleId} from '../../util/platform-form-utils';

@Component({
  selector: 'app-product-preview',
  templateUrl: './product-preview.component.html',
  styleUrls: ['./product-preview.component.scss']
})
export class ProductPreviewComponent implements OnDestroy, OnInit {
  public ExecutionPointType = ExecutionPointType;
  public PlatformType = PlatformType;
  public Color = Color;

  public faEye = faEye;
  public faXMark = faXmark;
  public faTrash = faTrash;
  public faPenToSquare = faPenToSquare;
  public faDisplay = faDisplay;
  public faArrowUpRightFromSquare = faArrowUpRightFromSquare;

  public deleteProductSubscription: Subscription;
  public product: IProductDetails;

  public isLoading = false;
  private loadingEndpointNames: string[] = [ProductEndpointsEnum.EDIT_PRODUCT, ProductEndpointsEnum.GET_PRODUCT_BY_INTERNAL_ID];
  private subscriptions: Subscription[] = [];

  constructor(public dialog: DialogService, public dialogRef: DialogRef,
              @Inject(BACK_ACTION) public backAction: IBackAction,
              @Inject(DIALOG_DATA) public data: { productId: string},
              private expandedDialogService: ExpandedDialogService, private productDialogService: ProductDialogService,
              private creativeDialogService: CreativeDialogService, private productService: ProductsService,
              private loadingService: LoadingService) {
  }

  public ngOnInit(): void {
    this.initializeProduct();
    this.observeLoading();
  }

  public async initializeProduct(): Promise<void> {
    this.product = await this.productService.getProductByInternalId(this.data.productId);
  }

  private observeLoading(): void {
    const sub = this.loadingService.loadingEndpointsObs().subscribe((loadingEndpoints) => {
      this.isLoading = this.loadingService.areEndpointsLoading(this.loadingEndpointNames, loadingEndpoints);
    });
    this.subscriptions.push(sub);
  }

  public isProductPageIdListVisible(platform: IPlatform): boolean {
    const executionPoint = this.getExecutionPoint(platform, ExecutionPointType.APP_STORE);
    return executionPoint.productPageIds?.length > 0;
  }

  public hasExecutionPoint(platform: IPlatform, executionPointType: ExecutionPointType): boolean {
    return !!this.getExecutionPoint(platform, executionPointType);
  }

  public hasUrl(platform: IPlatform): boolean {
    return hasUrl(platform);
  }

  public getUrl(platform: IPlatform): string {
    return getUrl(platform);
  }

  public urlStartsWithHttps(platform: IPlatform): boolean {
    return getUrl(platform)?.startsWith('https://');
  }

  public getTracking(platform: IPlatform): string {
    const trackingNetworks = _.uniq(platform.externalTrackings?.map(tracking => tracking.name)) || [];
    return trackingNetworks.length > 0 ? trackingNetworks.join(', ') : '-';
  }

  public getExecutionPoint(platform: IPlatform, executionPointType: ExecutionPointType): IExecutionPoint {
    return platform.executionPoints?.find(executionPoint => executionPoint.type === executionPointType);
  }

  public onCloseClicked(): void {
    this.dialogRef.close();
  }

  public onEditProductClicked(): void {
    this.productDialogService.openProductEditDialog(this.product.id,
      getSideDialogConfig(ProductPreviewComponent, this.backAction, this.data));
    this.dialogRef.close();
  }

  public async onDeleteProductClicked(): Promise<void> {
    const dialogRef: DialogRef = await this.productDialogService.openProductDeleteDialog(this.product.name,
      this.product.id);
    this.deleteProductSubscription = dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.dialogRef.close();
        }
      }
    );
  }

  public onCreativesPreviewClicked(): void {
    this.creativeDialogService.openProductCreativesPreview(
      ProductMapperService.getProductFromDetailed(this.product),
      getSideDialogConfig(ProductPreviewComponent, this.backAction, this.data));
    this.dialogRef.close();
  }

  public isPlatformWeb(platformType: PlatformType): boolean {
    return platformType === PlatformType.WEB;
  }

  public linkToStoreSupported(platform: IPlatform): boolean {
    return linkToStoreSupported(platform);
  }

  public getCustomIconName(platformType: PlatformType): CustomIconName {
    return platformType as unknown as CustomIconName;
  }

  public getStoreLink(platformType: PlatformType, platformId: string, productPageId?: string): string {
    return getStoreLink(platformType, platformId, productPageId);
  }

  public isBundleId(platform: IPlatform): boolean {
    return hasBundleId(platform.type);
  }

  public ngOnDestroy(): void {
    this.deleteProductSubscription?.unsubscribe();
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }
}
