import {WaterfallFormSteps} from '../enum/WaterfallFormSteps';
import {IWaterfallForm} from '../interface/IWaterfallForm';
import {FormGroup} from '@angular/forms';
import {Tab} from '../../shared/interface/ui/tabs/Tab';


export function isStepValid(activeStep: Tab, formGroup: FormGroup<IWaterfallForm>): boolean {
  switch (activeStep?.name as WaterfallFormSteps) {
    case WaterfallFormSteps.CHOOSE_PROMOTING_PRODUCT:
      return formGroup.controls.promotingProduct.valid;
    case WaterfallFormSteps.CREATE_WATERFALL:
      return formGroup.controls.name.valid && formGroup.controls.region.valid
        && formGroup.controls.adUnits.valid && formGroup.controls.platform.valid;
    case WaterfallFormSteps.CHOOSE_PRODUCTS:
      return formGroup.controls.promotedProductsCampaigns.valid;
  }
}

export function updateStepsSubLabels(steps: Tab[], formGroup: FormGroup<IWaterfallForm>): void {
  steps.forEach((step) => {
    step.subLabel = getSubLabel(step.name as WaterfallFormSteps, formGroup);
    step.textBorderDark = !!step.subLabel;
  });
}

export function getSubLabel(name: WaterfallFormSteps, formGroup: FormGroup<IWaterfallForm>): string {
  switch (name) {
    case WaterfallFormSteps.CHOOSE_PROMOTING_PRODUCT:
      return formGroup.controls.promotingProduct.value?.name;
    case WaterfallFormSteps.CREATE_WATERFALL:
      return formGroup.controls.name.value;
    case WaterfallFormSteps.CHOOSE_PRODUCTS:
      return getProductsSubLabel(formGroup);
  }
}

function getProductsSubLabel(formGroup: FormGroup<IWaterfallForm>): string {
  const length = formGroup.controls.promotedProductsCampaigns.value.length;
  return length ? length + (length > 1 ? ' products' : ' product') : null;
}
