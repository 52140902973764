import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../../service/auth/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons';
import {SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public faCircleExclamation = faCircleExclamation;
  public hasError = false;
  public sessionExpiredMsg = false;
  public isLoading = false;

  public subscriptions: Subscription[] = [];

  constructor(private authService: AuthService, private router: Router,
              private socialAuthService: SocialAuthService, private route: ActivatedRoute) {
    const sub = this.route.queryParams?.subscribe(params => {
      this.sessionExpiredMsg = !!params.sessionExpired;
    });
    this.subscriptions.push(sub);
  }

  public ngOnInit(): void {
    this.socialAuthService.authState.subscribe(authState => {
      if (authState?.idToken) {
        this.onLoginClicked(authState);
      }
    });
  }

  public async onLoginClicked(socialUser: SocialUser): Promise<void> {
    this.isLoading = true;
    this.authService.login(socialUser.idToken).then(() => {
      this.router.navigate(['/dashboard']).then(() => window.location.reload());
    }).catch(() => {
      this.hasError = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions?.forEach(sub => sub?.unsubscribe());
  }
}
