<ng-container *ngIf="isDialog">
  <app-base-expanded-dialog [showConfirmPopup]="true" [backAction]="backAction" [closeDialogObservable]="closeDialogSubject.asObservable()">
    <ng-container *ngTemplateOutlet="creativeForm"></ng-container>
  </app-base-expanded-dialog>
</ng-container>

<ng-container *ngIf="!isDialog">
  <ng-container *ngTemplateOutlet="creativeForm"></ng-container>
</ng-container>


<ng-template #creativeForm>
  <hr *ngIf="!isDialog" class="nav-separator">
  <div class="form-page">

    <div [ngClass]="isDialog ? 'no-padding-top' : ''" class="add-form-header">
      <h1 *ngIf="!isEditMode">{{'creative.creativeForm.addCreativeHeader' | translate}}</h1>
      <h1 *ngIf="isEditMode">{{'creative.creativeForm.editCreativeHeader' | translate}}</h1>
      <p *ngIf="!isEditMode">{{'creative.creativeForm.addCreativeInfo' | translate}}</p>
      <p *ngIf="isEditMode">{{'creative.creativeForm.editCreativeInfo' | translate}}</p>
    </div>
    <app-spinner *ngIf="isFormLoading"></app-spinner>
    <div [ngClass]="{'form-shadow-bottom--hidden': !hasScroll}" class="creative-form form-shadow-bottom" #formScrollable>
      <form *ngIf="initialized" [formGroup]="formGroup">
        <div class="form-controls-group">
          <div class="form-controls-row">
            <div class="form-control">
              <div class="label">{{'creative.creativeForm.productName' | translate}} <span class="color-error">*</span>
              </div>
              <app-select-product-control [placeholder]="'creative.creativeForm.productNamePlaceholder' | translate"
                                          [isMultiSelect]="false"
                                          formControlName="product"></app-select-product-control>
              <span *ngIf="hasRequiredError(formGroup.get('product'))" class="error-message">
            {{'form.fieldRequired' | translate}}
          </span>
            </div>
            <div class="form-control">
              <div class="label">{{'adUnit.adUnit' | translate}} <span class="color-error">*</span></div>
              <app-single-select-input [items]="adUnitOptions"
                                       [label]="'creative.creativeForm.adUnitPlaceholder' | translate"
                                       formControlName="adUnit"></app-single-select-input>
              <span *ngIf="hasRequiredError(formGroup.get('adUnit'))" class="error-message">
            {{'form.fieldRequired' | translate}}
          </span>
            </div>
            <div class="form-control">
              <div class="label">{{'creative.creativeForm.creativeName' | translate}} <span class="color-error">*</span>
              </div>
              <input name="creativeName" autocomplete="off" formControlName="name" type="text"
                     [placeholder]="'creative.creativeForm.creativeName' | translate">
              <span *ngIf="hasRequiredError(formGroup.get('name'))" class="error-message">
            {{'form.fieldRequired' | translate}}
          </span>
              <span *ngIf="hasError(formGroup.get('name'), 'creativeNameExists')"
                    class="error-message">{{'creative.creativeForm.creativeNameUniqMsg' | translate}}
        </span>
            </div>
          </div>
          <div class="form-controls-row mat-caption">
            <p><span class="color-error">*</span>{{'form.fieldRequiredDescription' | translate}}</p>
          </div>
          <div class="form-controls-row" *ngIf="isRewardedOrInterstitial()">
            <div class="align-center-gap-5 mat-caption">
              <app-toggle-slider formControlName="isStatic">
              </app-toggle-slider>
              <span>{{'creative.creativeForm.isStatic' | translate}}</span>
            </div>
            <div *ngIf="isStatic()" class="align-center-gap-5 mat-caption">
              <app-toggle-slider formControlName="useStaticForDefaultHtml">
              </app-toggle-slider>
              <span>{{'creative.creativeForm.useDefaultHtml' | translate}}</span>
            </div>
          </div>
        </div>
        <div *ngIf="isMoreGamesSelected()" class="form-controls-group">
          <div class="form-controls-row">
            <div class="form-control">
              <div class="label">
                {{'creative.creativeForm.description' | translate}}
                <fa-icon class="color-primary" [icon]="faCircleInfo"
                         [appTooltip]="'creative.creativeForm.descriptionTooltip' | translate">
                </fa-icon>
              </div>
              <input name="description" autocomplete="off" formControlName="description" type="text"
                     [placeholder]="'creative.creativeForm.description' | translate">
            </div>
            <div class="form-control">
              <div class="label">
                {{'creative.creativeForm.incentiveText' | translate}}
                <fa-icon class="color-primary" [icon]="faCircleInfo"
                         [appTooltip]="'creative.creativeForm.incentiveTextTooltip' | translate">
                </fa-icon>
              </div>
              <input name="incentiveText" autocomplete="off" formControlName="incentiveText" type="text"
                     [placeholder]="'creative.creativeForm.incentiveText' | translate">
            </div>
          </div>
        </div>
      </form>
      <div class="form-controls-group">
        <div class="form-controls-row align-center">
          <h2>{{'creative.creativeForm.uploadAssetHeader' | translate}}</h2>
          <a href="https://core.boombit.com/docs/_cross_promotion_baner.html" target="_blank">
            <button class="button-narrow" mat-stroked-button color="primary">
              <fa-icon [icon]="faArrowUpRightFromSquare"></fa-icon>
              {{'creative.creativeForm.assetsDocsButton' | translate}}
            </button>
          </a>
        </div>
        <div [ngStyle]="{'grid-template-columns': 'repeat(' + columnsCount + ', auto)'}" class="asset-inputs-row">
          <div *ngFor="let assetControl of assetsControls">
            <div class="d-inline-block">
              <p class="margin-0 color-primary ad-unit-format-label">
                {{assetControl.adUnitFormat.userFriendlyName}}
                <span *ngIf="assetControl.adUnitFormat.required" class="color-error">*</span>
              </p>
              <app-asset-file-input (fileRemoved)="onAssetDelete(assetControl)"
                                    (fileSelected)="onFileSelected($event, assetControl)"
                                    (addFromLibraryClicked)="onOpenAssetLibraryClicked(assetControl)"
                                    [insideCreativeForm]="true" [status]="assetControl.status"
                                    [asset]="assetControl.asset">

              </app-asset-file-input>
            </div>
            <div class="asset-requirements">
              <p *ngIf="assetControl.adUnitFormatMatchError" class="mat-caption">
                <fa-icon class="color-error" [icon]="faXMark"></fa-icon>
                {{'asset.assetRequirements.adUnitFormatMatch' | translate}} {{assetControl.adUnitFormat.name}}
              </p>
              <p *ngIf="assetControl.adUnitFormat.required" class="mat-caption">
                <fa-icon
                  [ngClass]="!creativeFormUtil.hasAssetRequiredError(assetControl) ? 'color-success' : 'color-error'"
                  [icon]="!creativeFormUtil.hasAssetRequiredError(assetControl) ? faCheck : faXMark"></fa-icon>
                {{'asset.assetRequirements.required' | translate}}
              </p>
              <p *ngIf="assetControl.nameExistsError" class="mat-caption">
                <fa-icon class="color-error x-mark-icon" [icon]="faXMark"></fa-icon>
                {{'asset.assetRequirements.fileNameUniqError' | translate}}
              </p>
              <p class="mat-caption">
                <fa-icon [ngClass]="creativeFormUtil.matchesFileFormat(assetControl) ? 'color-success' : 'color-error'"
                         [icon]="creativeFormUtil.matchesFileFormat(assetControl) ? faCheck : faXMark"></fa-icon>
                {{'asset.assetRequirements.format' | translate}}: {{assetControl.adUnitFormat.formats.join(', ')}}
              </p>
              <p *ngIf="!!assetControl.adUnitFormat.ratio" class="mat-caption">
                <fa-icon [ngClass]="creativeFormUtil.matchesRatio(assetControl) ? 'color-success' : 'color-error'"
                         [icon]="creativeFormUtil.matchesRatio(assetControl) ? faCheck : faXMark"></fa-icon>
                {{'asset.assetRequirements.ratio' | translate}}: {{assetControl.adUnitFormat.ratio}}
              </p>
              <p class="mat-caption">
                <fa-icon [ngClass]="creativeFormUtil.matchesResolution(assetControl) ? 'color-success' : 'color-error'"
                         [icon]="creativeFormUtil.matchesResolution(assetControl) ? faCheck : faXMark"></fa-icon>
                {{'asset.assetRequirements.resolution' | translate}}
                : {{getResolutionLabel(assetControl.adUnitFormat.resolution, assetControl.adUnitFormat.ratio)}}
              </p>
              <p class="mat-caption">
                <fa-icon [ngClass]="creativeFormUtil.matchesMaxSize(assetControl) ? 'color-success' : 'color-error'"
                         [icon]="creativeFormUtil.matchesMaxSize(assetControl) ? faCheck : faXMark"></fa-icon>
                {{'asset.assetRequirements.maxSize' | translate}}: {{assetControl.adUnitFormat.maxSize | fileSize}}
              </p>
              <p class="mat-caption">
                <fa-icon [ngClass]="creativeFormUtil.matchesMaxDuration(assetControl) ? 'color-success' : 'color-error'"
                         [icon]="creativeFormUtil.matchesMaxDuration(assetControl) ? faCheck : faXMark"></fa-icon>
                {{'asset.assetRequirements.maxDuration' | translate}}: {{assetControl.adUnitFormat.maxDuration + 's'}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="add-form-action-buttons">
      <button (click)="onCancelClicked()" mat-stroked-button color="primary">
        {{'button.cancel' | translate}}
      </button>
      <button [disabled]="!isFormValid || formGroup?.pending" (click)="onSubmitClicked()" mat-flat-button color="primary">
        <fa-icon *ngIf="!formGroup?.pending" [icon]="faFloppyDiskCircleArrowRight"></fa-icon>
        <ng-container *ngIf="!formGroup?.pending">{{'creative.creativeForm.saveCreativeButton' | translate}}</ng-container>
        <mat-spinner *ngIf="formGroup?.pending" [diameter]="20" color="accent"></mat-spinner>
        <span *ngIf="formGroup?.pending">{{'creative.creativeForm.pendingButton' | translate}}</span>
      </button>
      <mat-spinner *ngIf="isLoading" [diameter]="30"></mat-spinner>
    </div>
  </div>
</ng-template>
