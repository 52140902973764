import {IExternalTrackingDetailed} from '../interface/IExternalTrackingDetailed';
import {
  ITrackingElement,
  ITrackingFormGroup,
  ITrackingSelectGroup,
  TrackingArray,
  TrackingSelectArray
} from '../interface/ITrackingFormGroup';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {ExternalTrackingType} from '../enum/ExternalTrackingType';
import {AtLeast} from '../../shared/type/AtLeast';
import {trackingLinkValidator} from '../util/tracking-link.validator';
import {HttpMethod} from '../../shared/enum/HttpMethod';
import {TrackingType} from '../enum/TrackingType';
import {AppInjector} from '../../shared/util/app-injector';
import {PlatformService} from '../service/platform.service';
import {IDefaultTrackingLink} from '../interface/IDefaultTrackingLink';

export class ExternalTrackingFormManager {
  private formBuilder;
  private defaultAppsflyerLinks: IDefaultTrackingLink;
  private defaultAppsflyerClickUrl: string;
  private defaultAppsflyerImpressionUrl: string;

  private defaultCustomLinks: IDefaultTrackingLink;
  private defaultCustomClickUrl: string;
  private defaultCustomImpressionUrl: string;

  constructor(private platformService: PlatformService) {
    this.formBuilder = AppInjector.get(FormBuilder);
    this.fetchDefaultTrackingLinks();
  }

  async fetchDefaultTrackingLinks(): Promise<void> {
    this.defaultAppsflyerLinks = await this.platformService.getExternalTrackingDefaultLinks();
    this.defaultAppsflyerClickUrl = this.defaultAppsflyerLinks.onClick;
    this.defaultAppsflyerImpressionUrl = this.defaultAppsflyerLinks.onImpression;

    this.defaultCustomLinks = await this.platformService.getCustomTrackingDefaultLinks();
    this.defaultCustomClickUrl = this.defaultCustomLinks.onClick;
    this.defaultCustomImpressionUrl = this.defaultCustomLinks.onImpression;
  }

  public getInitialTrackingSelect(externalTracking: IExternalTrackingDetailed[]): TrackingSelectArray {
    const trackingSelects: TrackingSelectArray = this.formBuilder.array([] as FormGroup<ITrackingSelectGroup>[]);
    Object.values(ExternalTrackingType).map(name => trackingSelects.push(this.getTrackingSelectOption(name, externalTracking)));
    return trackingSelects;
  }

  public getInitialTrackingInputs(trackingList: IExternalTrackingDetailed[]): TrackingArray {
    const trackingFormArray: TrackingArray = this.formBuilder.array([] as FormGroup<ITrackingFormGroup>[]);
    if (ExternalTrackingFormManager.hasAnyInitialTracking(trackingList)) {
      trackingList.forEach(tracking =>
        this.updateTrackingArray(trackingFormArray, tracking));
    }
    return trackingFormArray;
  }

  public createTrackingGroup(name: string, array: TrackingArray): FormGroup<ITrackingFormGroup> {
    if (this.shouldPreventGroupDuplication(name, array)) {
      return;
    }
    const inputs: FormArray<FormGroup<ITrackingElement>> = this.formBuilder.array([] as FormGroup<ITrackingElement>[]);

    let clickInput: FormGroup<ITrackingElement>;
    let impressionInput: FormGroup<ITrackingElement>;

    if (name === ExternalTrackingType.APPSFLYER) {
      clickInput = this.getTrackingInnerGroup({name, trackingType: TrackingType.ON_CLICK, url: this.defaultAppsflyerClickUrl});
    } else {
      clickInput = this.getTrackingInnerGroup({name, trackingType: TrackingType.ON_CLICK, url: this.defaultCustomClickUrl});
    }
    inputs.push(clickInput);

    if (name === ExternalTrackingType.APPSFLYER) {
      impressionInput = this.getTrackingInnerGroup({name, trackingType: TrackingType.ON_IMPRESSION, url: this.defaultAppsflyerImpressionUrl});
    } else {
      impressionInput = this.getTrackingInnerGroup({name, trackingType: TrackingType.ON_IMPRESSION, url: this.defaultCustomImpressionUrl});
    }
    inputs.push(impressionInput);
    const inputsGroup: FormGroup<ITrackingFormGroup> = this.formBuilder.group({name, trackingElement: inputs});
    array.push(inputsGroup);
    return inputsGroup;
  }

  public removeTrackingGroup(groupName: string, trackingArray: TrackingArray): void {
    const groupIdx = trackingArray.value.findIndex(tracking => tracking.name === groupName);
    if (groupIdx !== -1) {
      trackingArray.removeAt(groupIdx);
    }
  }

  private getTrackingSelectOption(name: string, trackingList: IExternalTrackingDetailed[]): FormGroup<ITrackingSelectGroup> {
    return this.formBuilder.group({
      name: this.formBuilder.control(name as string),
      isSelected: this.formBuilder.control(this.isTrackingInitiallySelected(name, trackingList))
    } as ITrackingSelectGroup);
  }

  private isTrackingInitiallySelected(name: string, trackingList: IExternalTrackingDetailed[]): boolean {
    return trackingList?.some(tracking => tracking.name === name);
  }

  private shouldPreventGroupDuplication(name: string, trackingArray: TrackingArray): boolean {
    return trackingArray.value.some(el => el.name === name);
  }

  private updateTrackingArray(array: TrackingArray, tracking: IExternalTrackingDetailed): void {
    if (!this.shouldPreventGroupDuplication(tracking.name, array)) {
      const group = this.createTrackingGroup(tracking.name, array);
      this.updateTrackingGroup(group, tracking);
    } else {
      const group = array.controls.find(trackingGroup => trackingGroup.value.name === tracking.name);
      this.updateTrackingGroup(group, tracking);
    }
  }

  private static hasAnyInitialTracking(trackingList: IExternalTrackingDetailed[]): boolean {
    return trackingList?.length > 0;
  }

  private getTrackingInnerGroup(tracking: AtLeast<IExternalTrackingDetailed, 'name' | 'trackingType'>): FormGroup<ITrackingElement> {
    return this.formBuilder.group({
      id: this.formBuilder.control(tracking?.id || ''),
      name: this.formBuilder.control(tracking.name || ''),
      url: this.formBuilder.control(tracking?.url || '', [trackingLinkValidator]),
      trackingType: this.formBuilder.control(tracking.trackingType),
      method: this.formBuilder.control(tracking?.method || HttpMethod.GET),
    });
  }

  private updateTrackingGroup(group: FormGroup<ITrackingFormGroup>, tracking: IExternalTrackingDetailed): void {
    const innerGroup = group.controls.trackingElement.controls.find(control => control.value.trackingType === tracking.trackingType);
    innerGroup.controls.url.setValue(tracking.url);
    innerGroup.controls.id.setValue(tracking.id);
  }
}
