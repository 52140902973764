import {Injectable} from '@angular/core';
import {IAsset} from '../../interface/IAsset';
import {ITableCell} from '../../../shared/interface/ui/table/ITableCell';
import {IconOriginType} from '../../../shared/enum/ui/icons/IconOriginType';
import {
  faArrowPointer,
  faArrowUpRightFromSquare,
  faEye,
  faPenToSquare,
  faTrash
} from '@fortawesome/pro-light-svg-icons';
import {FileSizePipe} from '../../../shared/pipe/file-size.pipe';
import {RESPONSIVE} from '../../util/ratio.util';
import {TranslateService} from '@ngx-translate/core';
import {firstValueFrom} from 'rxjs';
import {AdUnitMapperService} from '../../../ad-unit/service/ad-unit-mapper.service';
import {AssetLinkService} from '../asset-link/asset-link.service';
import {AssetDialogService} from '../asset-dialog/asset-dialog.service';
import {ProductDialogService} from '../../../product/service/product-dialog/product-dialog.service';
import {TableCellService} from '../../../shared/service/table-cell/table-cell.service';
import {Placement} from '../../../shared/enum/ui/tooltip/Placement';
import {AssetRow} from '../../interface/AssetRow';
import {CellBasic} from '../../../shared/interface/ui/my-table/cell.model';
import {ActionsCellComponent} from '../../../shared/component/common/actions-cell/actions-cell.component';
import {TableAction} from '../../../shared/enum/TableAction';
import {AssetSelectRow} from '../../interface/AssetSelectRow';
import {CellButtonsComponent} from '../../../shared/component/common/cell-buttons/cell-buttons.component';
import {Button} from '../../../shared/interface/ui/my-table/button.model';
import {TooltipType} from '../../../shared/enum/ui/tooltip/TooltipType';
import {CellDateComponent} from '../../../shared/component/common/cell-date/cell-date.component';

/**
 * Injectable service for mapping asset data to asset table rows.
 * This service provides methods for mapping asset data to table row representations.
 */
@Injectable({
  providedIn: 'root'
})
export class AssetMapperService {

  /**
   * Constructor for AssetMapperService.
   * @param fileSizePipe A pipe for formatting file sizes.
   * @param translateService Service for language translation.
   * @param adUnitMapperService A service for mapping ad unit data.
   * @param assetLinkService A service for managing asset links.
   * @param assetDialogService A service for managing asset dialogs.
   * @param productDialogService A service for managing product dialogs.
   * @param tableCellService A service for managing table cells.
   */
  constructor(private fileSizePipe: FileSizePipe, private translateService: TranslateService,
              private adUnitMapperService: AdUnitMapperService, private assetLinkService: AssetLinkService,
              private assetDialogService: AssetDialogService, private productDialogService: ProductDialogService,
              private tableCellService: TableCellService) {
  }

  /**
   * Retrieves the resolution cell for the given asset.
   * @param asset The asset.
   * @returns The table cell representing the resolution of the asset.
   */
  private static getResolution(asset: IAsset): ITableCell {
    if (asset.ratio === RESPONSIVE) {
      return {value: RESPONSIVE};
    } else {
      return {value: asset.resolution?.width + ' x ' + asset.resolution?.height + ' px'};
    }
  }

  /**
   * Retrieves the creatives cell for the given asset.
   * @param creatives The assigned creatives.
   * @returns A promise resolving to the table cell representing the assigned creatives.
   */
  private async getCreativesCell(creatives: string[]): Promise<ITableCell> {
    if (creatives?.length > 0) {
      return {
        icons: [{type: IconOriginType.EXTERNAL_LIBRARY, iconRef: faEye, class: 'color-primary'}],
        value: await this.getCreativeLabel(creatives.length),
        tooltip: this.tableCellService.getTooltipComplex(creatives, Placement.LEFT)
      };
    } else {
      return {value: await firstValueFrom(this.translateService.get('creative.noCreatives'))};
    }
  }

  /**
   * Retrieves the label for the assigned creatives.
   * @param creativesLength The number of assigned creatives.
   * @returns A promise resolving to the label for the assigned creatives.
   */
  private async getCreativeLabel(creativesLength: number): Promise<string> {
    if (creativesLength === 1) {
      return '1 ' + await firstValueFrom(this.translateService.get('creative.creative'));
    } else if (creativesLength > 1) {
      return creativesLength.toString() + ' ' + await firstValueFrom(this.translateService.get('creative.creativePlural'));
    }
  }

  public async mapAssetsSelectToRows(assets: IAsset[]): Promise<AssetSelectRow[]> {
    return (await this.mapAssetsToRows(assets)).map(row => ({
      ...row,
      actions: {
        component: ActionsCellComponent, actions: [
          {name: TableAction.link, iconFa: faArrowUpRightFromSquare}
        ]
      },
      buttons: {
        component: CellButtonsComponent,
        buttons: [this.getSelectButton()]
      }
    }));
  }

  public async mapAssetsToRows(assets: IAsset[]): Promise<AssetRow[]> {
    return Promise.all(assets.map(async (asset) => {
      const row: AssetRow = {
        name: {
          value: asset.originalName, realValue: asset.name, cssClass: 'column-asset-name', tooltip:
            {
              text: asset.originalName,
              placement: Placement.TOP,
              cursorAllowedInsideTooltip: true,
              type: TooltipType.TEXT
            }
        },
        createdAt: {component: CellDateComponent, date: asset.createdAt},
        format: {value: asset.format, realValue: asset.format},
        resolution: AssetMapperService.getResolution(asset) as CellBasic,
        duration: {value: asset.duration + ' s', cssClassTd: ['display-none-sm']},
        ratio: {value: asset.ratio, cssClassTd: ['asset-ratio-column']},
        size: {value: this.fileSizePipe.transform(asset.size), cssClassTd: ['display-none-sm']},
        adUnitFormat: await this.adUnitMapperService.getAdUnitFormatCell(asset.adUnitFormatIds) as CellBasic,
        creatives: {
          ...await this.getCreativesCell(asset.assignedCreatives) as CellBasic,
          cssClassTd: ['display-none-xs']
        },
        productName: {
          value: asset.product?.name || '-',
          cssClass: 'cursor-pointer'
        },
        actions: {
          component: ActionsCellComponent, actions: [
            {name: TableAction.show, iconFa: faEye},
            {name: TableAction.link, iconFa: faArrowUpRightFromSquare},
            {name: TableAction.edit, iconFa: faPenToSquare},
            {name: TableAction.delete, iconFa: faTrash},
          ]
        },
        trackById: asset.id,
        asset
      };
      return row;
    }));
  }

  public getSelectButton(): Button {
    return {name: 'Select', iconFa: faArrowPointer, type: 'mat-flat-button'};
  }
}
