import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SortType} from '../../../shared/enum/ui/table/SortType';
import {TableAction} from '../../../shared/enum/TableAction';
import {CampaignDialogService} from '../../service/campaign-dialog/campaign-dialog.service';
import {CampaignListService} from '../../service/campaign-list/campaign-list.service';
import {CampaignMapperService} from '../../service/campaign-mapper/campaign-mapper.service';
import {firstValueFrom, Subject, Subscription} from 'rxjs';
import {AdUnitFiltersService} from '../../../ad-unit/service/ad-unit-filters.service';
import {IProduct} from '../../../product/interface/IProduct';
import {ExpandedDialogService} from '../../../shared/service/dialog/expanded-dialog/expanded-dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {CampaignsService} from '../../service/campaigns.service';
import {TranslationPrefix} from '../../../shared/enum/ui/dialog/TranslationPrefix';
import {TranslateWrapperService} from '../../../shared/service/translate/translate-wrapper/translate-wrapper.service';
import {CampaignEndpoints, ProductEndpoints} from '../../../shared/enum/Endpoints';
import {ProductsService} from '../../../product/service/product/products.service';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {ProductMapperService} from '../../../product/service/product-mapper/product-mapper.service';
import {CampaignList} from '../../interface/CampaignList';
import {Filter, FilterBasic} from '../../../shared/interface/ui/my-table/filter.model';
import {TableQuery} from '../../../shared/interface/ui/my-table/table-query.model';
import {CampaignSelectRow} from '../../interface/CampaignSelectRow';
import {Header} from '../../../shared/interface/ui/my-table/header.model';
import {CampaignTableManagerService} from '../../service/campaign-table-manager/campaign-table-manager.service';
import {ProductDialogService} from '../../../product/service/product-dialog/product-dialog.service';
import {
  FilterDateRangeComponent
} from '../../../shared/component/common/filter-date-range/filter-date-range.component';
import {FilterSelectComponent} from '../../../shared/component/common/filter-select/filter-select.component';
import {CellActionsEvent, CellEvent} from '../../../shared/interface/ui/my-table/cell-event.model';

@Component({
  selector: 'app-campaigns-per-product-list',
  templateUrl: './campaigns-per-product-list.component.html',
  styleUrls: ['./campaigns-per-product-list.component.scss']
})
export class CampaignsPerProductListComponent implements OnInit, OnDestroy {
  @Input() productId: string;
  @Input() selectedCampaignId: string;
  @Output() campaignSelectionChanged = new EventEmitter<{ name: string, id: string }>();

  public product: IProduct;
  public searchValue = '';
  public autocompleteItems: string[] = [];
  public subscriptions: Subscription[] = [];

  public isLoading;
  private query: TableQuery;
  public rows: CampaignSelectRow[] = [];
  public itemsCount;
  public headers: Header<CampaignSelectRow>[] = [
    {name: 'name', headerName: '', sortType: SortType.alphabetic},
    {name: 'productName', headerName: '', sortType: SortType.alphabetic},
    {name: 'startDate', headerName: '', sortType: SortType.numeric, cssClassTh: ['display-none-xs']},
    {name: 'endDate', headerName: '', sortType: SortType.numeric, cssClassTh: ['display-none-xs']},
    {name: 'adUnits', headerName: ''},
    {name: 'actions', headerName: ''},
    {name: 'buttons', headerName: ''}
  ];
  public filters: Filter[] = [];
  public noMatchFound = false;
  public changeQuery$: Subject<Partial<TableQuery>> = new Subject<Partial<TableQuery>>();
  public spinnerForInfinityScroll = false;

  constructor(private campaignDialogService: CampaignDialogService, public campaignListService: CampaignListService,
              private changeDetectorRef: ChangeDetectorRef, private campaignMapperService: CampaignMapperService,
              private adUnitFiltersService: AdUnitFiltersService, private expandedDialog: ExpandedDialogService,
              private translateService: TranslateService, private campaignService: CampaignsService,
              private translateWrapperService: TranslateWrapperService, private productService: ProductsService,
              private loadingService: LoadingService, private campaignTableManager: CampaignTableManagerService,
              private productDialogService: ProductDialogService) {
  }

  private _loadingEndpointNames(): string[] {
    return [CampaignEndpoints.getCampaigns(), ProductEndpoints.getProductByInternalId(this.productId)];
  }

  public ngOnInit(): void {
    this.initData();
  }

  private async initData(): Promise<void> {
    this.observeLoading();
    await this.initProduct();
    this.initTranslations();
    this.initFilters();
    this.initAutocompleteItems();
  }

  private async initProduct(): Promise<void> {
    const product = await this.productService.getProductByInternalId(this.productId);
    this.product = ProductMapperService.getProductFromDetailed(product);
  }

  private async initTranslations(): Promise<void> {
    this.translateWrapperService.translateTableHeader(TranslationPrefix.CAMPAIGN, this.headers);
  }

  private async initFilters(): Promise<void> {
    const adUnitFilters = await this.adUnitFiltersService.getAdUnitFilters();
    this.filters = [
      {
        isDateRange: true, name: 'dateRange',
        label: await firstValueFrom(this.translateService.get('campaign.dateRange')),
        items: null,
        selectedItems: null,
        component: FilterDateRangeComponent
      } as FilterBasic,
      {
        label: adUnitFilters.label,
        name: adUnitFilters.name,
        selectedItems: [],
        items: adUnitFilters.items,
        component: FilterSelectComponent
      } as FilterBasic
    ];
  }

  private async initAutocompleteItems(): Promise<void> {
    this.autocompleteItems = await this.campaignListService.getAutocomplete();
  }

  private observeLoading(): void {
    const sub = this.loadingService.loadingEndpointsObs().subscribe((loadingEndpoints) => {
      this.isLoading = this.loadingService.areEndpointsLoading(this._loadingEndpointNames(), loadingEndpoints);
    });
    this.subscriptions.push(sub);
  }

  public previewCampaign(campaignRow: CampaignSelectRow): void {
    this.campaignDialogService.openCampaignPreviewDialog(campaignRow.campaign.id);
  }

  private previewProduct(campaignRow: CampaignSelectRow): void {
    this.productDialogService.openProductPreview(campaignRow.campaign.product.id);
  }

  public onChooseCampaignClicked(campaignRow: CampaignSelectRow): void {
    this.campaignSelectionChanged.emit({id: campaignRow.campaign.id, name: campaignRow.campaign.name});
    this.expandedDialog.close();
  }

  public async onQueryUpdated(query: TableQuery): Promise<void> {
    const shouldAppendRows = this.shouldAppendRows(query);
    this.query = query;
    this.initiateSpinnerLoading(shouldAppendRows);
    let campaignsList: CampaignList;
    try {
      campaignsList = await this.getCampaignList(query);
    } catch (error) {
      this.stopSpinnerLoading();
      return;
    }
    this.itemsCount = campaignsList.metaData.totalItems;
    this.noMatchFound = this.itemsCount === 0;
    const newRows = await this.campaignMapperService.mapCampaignsSelectToRows(campaignsList.data, this.selectedCampaignId);
    this.handleNewRows(newRows, shouldAppendRows);
    this.stopSpinnerLoading();
  }

  public onCellEvent(event: CellEvent): void {
    const columnName: keyof CampaignSelectRow = event.columnName as keyof CampaignSelectRow;
    switch (columnName) {
      case 'actions':
        this.onActionsClicked(event as CellActionsEvent);
        break;
      case 'name':
        this.previewCampaign(event.item as CampaignSelectRow);
        break;
      case 'productName':
        this.previewProduct(event.item as CampaignSelectRow);
        break;
      case 'buttons':
        this.onChooseCampaignClicked(event.item as CampaignSelectRow);
        break;
    }
  }

  public onActionsClicked(event: CellActionsEvent): void {
    switch (event.action.name) {
      case TableAction.show:
        this.previewCampaign(event.item as CampaignSelectRow);
        break;
    }
  }

  private initiateSpinnerLoading(shouldAppendRows: boolean): void {
    if (shouldAppendRows) {
      this.spinnerForInfinityScroll = true;
    } else {
      this.isLoading = true;
    }
  }

  private stopSpinnerLoading(): void {
    this.isLoading = false;
    this.spinnerForInfinityScroll = false;
  }

  private handleNewRows(newRows: CampaignSelectRow[], shouldAppendRows: boolean): void {
    if (shouldAppendRows) {
      this.rows = [...this.rows, ...newRows];
    } else {
      this.rows = newRows;
    }
  }

  /** Checks if new rows should be appended to the existing ones.
   * @returns `true` if new rows should be appended, `false` otherwise.
   */
  private shouldAppendRows(newQuery: TableQuery): boolean {
    return this.query?.page?.index !== newQuery?.page?.index && newQuery?.page?.index !== 1;
  }

  private async getCampaignList(query: TableQuery): Promise<CampaignList> {
    return await this.campaignTableManager.getCampaigns({
      page: {pageIndex: query.page.index - 1, pageSize: query.page.size},
      filters: query.filters as FilterBasic[],
      sort: {direction: query.sort?.direction, name: query.sort?.name as any},
      searchPhrase: this.searchValue
    }, [this.product]);
  }

  public onSearchValueChanged(value: string): void {
    if (this.searchValue !== value) {
      this.searchValue = value;
      this.changeQuery$.next({page: {index: 1}});
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription?.unsubscribe());
  }

}
