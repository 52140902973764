export enum ProductEndpointsEnum  {
  GET_PRODUCTS = 'Products',
  EDIT_PRODUCT = 'Products',
  DELETE_PRODUCT = 'Products',
  GET_PRODUCT_BY_BOOMBIT_ID = 'Products/GetByBoombitId/',
  GET_PRODUCT_BY_INTERNAL_ID = 'Products/GetByInternalId/'
}

export class ProductEndpoints {
  public static getProducts(): string {
    return 'Products';
  }

  public static editProduct(): string {
    return 'Products';
  }

  public static deleteProduct(id?: string): string {
    return id ? `Products/${id}` :'Products';
  }

  public static getProductByBoombitId(id: string): string {
    return `Products/GetByBoombitId/${encodeURIComponent(id)}`;
  }

  public static getProductByInternalId(id: string): string {
    return `Products/GetByInternalId/${id}`;
  }
}

export enum AssetEndpointsEnum {
  GET_ASSET_BY_INTERNAL_ID = 'Assets/GetById/'
}

export class AssetEndpoints {

  public static getAssets(): string {
    return 'Assets/PaginatedAndFiltered';
  }
}

export enum CreativeEndpointsEnum {
  GET_CREATIVES_FOR_PRODUCT = 'Creatives/GetPreview/',
  GET_CREATIVE_BY_INTERNAL_ID = 'Creatives/GetByInternalId/'

}

export class CreativeEndpoints {
  public static getCreativesPaginated(): string {
    return 'Creatives/PaginatedAndFiltered';
  }
}

export enum CampaignEndpointsEnum {
  GET_CAMPAIGN_BY_INTERNAL_ID = 'Campaigns/GetByInternalId/'
}

export class CampaignEndpoints {
  public static getCampaigns(): string {
    return 'Campaigns/PaginatedAndFiltered';
  }

  public static getCampaignByInternalId(id?: string): string {
    return id ? `Campaigns/GetByInternalId/${id}` : 'Campaigns/GetByInternalId';
  }

  public static getCampaignsByProduct(id: string): string {
    return `Campaigns/GetByProductId/${id}`;
  }
}

export enum WaterfallEndpointsEnum {
  GET_WATERFALL_BY_INTERNAL_ID = 'Waterfalls/GetByInternalId/'
}

export class WaterfallEndpoints {
  public static getWaterfalls(): string {
    return `/Waterfalls/PaginatedAndFiltered`;
  }

  public static getWaterfallByInternalId(id: string): string {
    return `/Waterfalls/GetByInternalId/${id}`;
  }

  public static getWaterfallsByProduct(id: string): string {
    return `/Waterfalls/GetByProduct/${id}`;
  }

  public static editWaterfall(): string {
    return 'Waterfalls';
  }

  public static deleteWaterfall(): string {
    return 'Waterfalls';
  }

  public static validateCsv(): string {
    return 'CSV/Validate';
  }
}

export class MoreGamesEndpoints {
  public static getMoreGamesByInternalId(id: string): string {
    return `MoreGames/GetByInternalId/${id}`;
  }
}
