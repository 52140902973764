<app-base-center-dialog [dialogRef]="dialogRef">
  <div class="confirm-dialog">
    <ng-container *ngIf="data.headerIcon?.type">
    <ng-container [ngSwitch]="data.headerIcon.type">
      <ng-container *ngSwitchCase="IconOriginType.CUSTOM">
        <img alt="icon" [src]="'assets/icons/' + data.headerIcon.iconName + '.svg'">
      </ng-container>
      <ng-container *ngSwitchCase="IconOriginType.EXTERNAL_LIBRARY">
        <fa-icon class="confirm-dialog-icon" [icon]="data.headerIcon.iconRef"></fa-icon>
      </ng-container>
    </ng-container>
    </ng-container>

    <h1 *ngIf="data.header" class="margin-0">{{data.header}}</h1>
    <div [innerHTML]="data.text"></div>
    <div *ngIf="data.warning">
      <p class="warning">WARNING!</p>
      <div [innerHTML]="data.warning"></div>
    </div>

    <div class="actions-buttons">
      <button (click)="onCancel()" mat-stroked-button color="primary">
        <fa-icon *ngIf="data.closeButton.icon" [icon]="data.closeButton.icon.iconRef"></fa-icon>
        {{data.closeButton.name}}
      </button>
      <button (click)="onConfirm()" mat-flat-button color="primary">
        <fa-icon *ngIf="data.acceptButton.icon" [icon]="data.acceptButton.icon.iconRef"></fa-icon>
        {{data.acceptButton.name}}
      </button>
    </div>
  </div>
</app-base-center-dialog>
