import { Injectable } from '@angular/core';
import { IAsset } from 'src/app/asset/interface/IAsset';
import { ApiAssetService } from './api-asset.service';
import {IAdUnitFormat} from '../../ad-unit/interface/IAdUnitFormat';
import {firstValueFrom, Subject} from 'rxjs';
import {IAssetRaw} from '../interface/IAssetRaw';
import {IAssetDetails} from '../interface/IAssetDetails';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from '../../shared/service/snackbar/snackbar.service';
import {ApiErrorService} from '../../shared/service/api/api-error.service';
import {CreativeQueryRaw} from '../../creative/interface/creative-query-raw';
import {AssetList} from '../interface/AssetList';
import {AssetCreateBulkPayload} from '../interface/AssetCreateBulkPayload';
import {AssetCreateBulkRaw} from '../interface/AssetCreateBulkRaw';
import {AdUnitFormatMatchRes} from '../interface/AdUnitFormatMatchRes';
import {PresignedUrlResponse} from '../interface/PresignedUrlResponse';
import {PresignedUrlPayload} from '../interface/PresignedUrlPayload';
import {IAssetChanges} from '../interface/IAssetChanges';

/**
 * Injectable service for managing assets.
 * This service provides methods for interacting with assets, such as creating, updating, and deleting them.
 */
@Injectable({
  providedIn: 'root'
})
export class AssetService {
  /** Subject to notify subscribers when assets have changed. */
  public assetChangedSubject: Subject<void | IAssetChanges> = new Subject<void>();

  /**
   * Constructor for AssetService.
   * @param apiAssetService The service for interacting with the asset API.
   * @param translateService Service for language translation.
   * @param snackBarService The service for displaying snack bar messages.
   * @param apiErrorService The service for handling API errors.
   */
  constructor(
      private apiAssetService: ApiAssetService, private translateService: TranslateService, private snackBarService: SnackbarService,
      private apiErrorService: ApiErrorService
  ) {
  }

  /**
   * Converts an IAsset object to its raw representation (IAssetRaw).
   * @param asset The asset to convert.
   * @returns The raw representation of the asset.
   */
  private static getAssetRaw(asset: IAsset): IAssetRaw {
    return {
      name: asset.name,
      originalName: asset.originalName,
      format: asset.format,
      size: asset.size,
      url: asset.url,
      duration: asset.duration,
      resolution: {
        width: asset.resolution.width,
        height: asset.resolution.height,
      },
      ratio: asset.ratio,
      tags: [],
      htmlString: asset.htmlString,
    };
  }

  /**
   * Retrieves a list of asset names for autocompletion.
   * @returns A promise that resolves to an array of asset names.
   */
  public async getAssetsAutocomplete(): Promise<string[]> {
    try {
      return await firstValueFrom(this.apiAssetService.getAssetNames());
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'asset.searchNamesAssetsError');
      throw error;
    }

  }

  /**
   * Generates a random asset name.
   * @param nameLength The length of the random name to generate.
   * @returns A promise that resolves to a random asset name.
   */
  public async getRandomAssetName(nameLength: number): Promise<string> {
    try {
      return await firstValueFrom(this.apiAssetService.getRandomAssetName(nameLength));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'asset.getRandomAssetNameError');
      throw error;
    }
  }

  public async getRandomAssetNames(length: number, numberOfStrings: number): Promise<string[]> {
    try {
      return await firstValueFrom(this.apiAssetService.getRandomAssetNames(length, numberOfStrings));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'asset.getRandomAssetNameError');
      throw error;
    }
  }

  public async createAssets(assetsBulk: AssetCreateBulkPayload[]): Promise<IAsset> {
    try {
      const asserBulkRaw: AssetCreateBulkRaw[] = assetsBulk.map((assetBulk) => {
        const assets = assetBulk.assets.map((asset) => AssetService.getAssetRaw(asset));
        return {productId: assetBulk.productId, assets};
      });
      const res = await firstValueFrom(this.apiAssetService.createAssets(asserBulkRaw));
      this.assetChangedSubject.next();
      return res;
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'asset.assetCreationErrorMessage');
      throw error;
    }
  }

  /**
   * Creates a new asset.
   * @param productId The ID of the product associated with the asset.
   * @param asset The asset data.
   * @returns A promise that resolves to the created asset.
   */
  public async createAsset(productId: string, asset: IAsset): Promise<IAsset> {
    try {
      const assetRaw: IAssetRaw = AssetService.getAssetRaw(asset);
      const res = await firstValueFrom(this.apiAssetService.createAsset(productId, assetRaw));
      this.assetChangedSubject.next();
      return res;
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'asset.assetCreationErrorMessage');
      throw error;
    }
  }

  /**
   * Updates an existing asset.
   * @param asset The updated asset data.
   * @param productId The ID of the product associated with the asset.
   * @returns A promise that resolves when the asset is successfully updated.
   */
  public async updateAsset(asset: IAsset, productId: string): Promise<void> {
    try {
      const assetRaw: IAssetRaw = AssetService.getAssetRaw(asset);
      assetRaw.id = asset.id;
      assetRaw.productId = productId;
      await firstValueFrom(this.apiAssetService.updateAsset(assetRaw));
      this.assetChangedSubject.next({assetEdited: assetRaw});
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'asset.assetEditErrorMessage');
      throw error;
    }
  }

  /**
   * Uploads a file to the server.
   * @param file The file to upload.
   * @param boomBitId The ID associated with the file.
   * @returns A promise that resolves when the file is successfully uploaded.
   */
  public async uploadFile(file: File, boomBitId: string): Promise<void> {
    try {
      return await firstValueFrom(this.apiAssetService.uploadFile(file, boomBitId));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'asset.uploadFileError');
      throw error;
    }
  }

  /**
   * Retrieves a paginated list of assets based on the provided query parameters.
   * @param query The query parameters for filtering and pagination.
   * @returns A promise that resolves to the paginated list of assets.
   */
  public async getAssetsPaginated(query: CreativeQueryRaw): Promise<AssetList> {
    try {
      return await firstValueFrom(this.apiAssetService.getAssetsPaginated(query));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'asset.getAssetsError');
      throw error;
    }
  }

  /**
   * Retrieves details of an asset by its ID.
   * @param assetId The ID of the asset to retrieve.
   * @returns A promise that resolves to the details of the asset.
   */
  public async getAssetById(assetId: string): Promise<IAssetDetails> {
    try {
      return await firstValueFrom(this.apiAssetService.getAssetById(assetId));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'asset.getAssetError');
      throw error;
    }
  }

  /**
   * Deletes an asset by its ID.
   * @param assetId The ID of the asset to delete.
   * @returns A promise that resolves when the asset is successfully deleted.
   */
  public async deleteAsset(assetId: string): Promise<void> {
    try {
      await firstValueFrom(this.apiAssetService.deleteAsset(assetId));
      this.assetChangedSubject.next();
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'asset.deleteAssetError');
      throw error;
    }
  }

  /**
   * Retrieves ad unit formats matched with the given asset.
   * @param asset The asset for which to retrieve ad unit formats.
   * @returns A promise that resolves to the ad unit formats matched with the asset.
   */
  public async getAssetAdUnitFormats(asset: IAsset): Promise<IAdUnitFormat[]> {
    try {
      return await firstValueFrom(this.apiAssetService.getAssetAdUnitFormats(asset));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'asset.adUnitFormatsMatchErrorMessage');
      throw error;
    }
  }

  /**
   * Checks if the provided asset name is original.
   * @param name The name to check for originality.
   * @returns A promise that resolves to `true` if the name is original, `false` otherwise.
   */
  public async checkOriginalName(name: string): Promise<boolean> {
    try {
      return await firstValueFrom(this.apiAssetService.checkOriginalName(name));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'asset.checkOriginalNameError');
      throw error;
    }
  }

  public async checkOriginalNames(names: string[]): Promise<boolean[]> {
    try {
      const res = await firstValueFrom(this.apiAssetService.checkOriginalNames(names));
      return names.map(name => !!res[name]);
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'asset.checkOriginalNamesError');
      throw error;
    }
  }

  public async getAssetsAdUnitFormats(assets: IAsset[]): Promise<AdUnitFormatMatchRes[]> {
    try {
      return await firstValueFrom(this.apiAssetService.getAssetsAdUnitFormats(assets));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'asset.adUnitFormatsMatchErrorMessage');
      throw error;
    }
  }

  public async generatePresignedUrls(payload: PresignedUrlPayload[]): Promise<{[key:string]: PresignedUrlResponse}> {
    try {
      return await firstValueFrom(this.apiAssetService.generatePresignedUrls(payload));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'asset.generatePresignedUrlsError');
      throw error;
    }
  }
}
