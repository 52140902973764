<ng-container *ngIf="isMultiSelect">
  <app-base-dropdown-select (saveClicked)="onSaveClicked()" (cleanClicked)="onCleanClicked()">
    <div class="dropdown-scroll">
      <div *ngIf="showAddProductButton" class="dropdown-select-row">
        <button (click)="openNewProductDialog()" class="button-narrow" mat-flat-button color="primary">
          <fa-icon [icon]="faBasketShoppingSimple"></fa-icon>
          {{'product.addNewProductButton' | translate}}
        </button>
      </div>
      <hr *ngIf="showAddProductButton">
      <div class="dropdown-select-row">
        <div class="dropdown-search-control">
          <app-search-control [focusObservable]="dropdownOpenedSubject.asObservable()"
                              (valueChanges)="onSearchValueChanges($event.value)" [isNarrow]="true"></app-search-control>
        </div>
      </div>
      <hr *ngIf="selectedItems.length">
      <div [hidden]="selectedItems.length === 0" class="options-row" #multiItemsContainer>
        <div (click)="onItemDeselected(selectedItem)" class="mat-caption option text-14-txt-2-semi-bold"
             *ngFor="let selectedItem of selectedItems">
          {{selectedItem.name}}
          <fa-icon class="check-icon" [icon]="faCheck"></fa-icon>
        </div>
      </div>
      <hr>
      <div class="options-row options-row--search-items">
        <div (click)="onItemSelected(displayedItem)" class="mat-caption option"
             *ngFor="let displayedItem of displayedItems">{{displayedItem.name}}</div>
        <div class="align-center-gap-5 error-message" *ngIf="shouldShowNoResultsError()">
          <span class="color-error x-mark-icon">&#10006;</span>
          <p class="margin-0 text-14-txt-2-medium"> {{'table.noResultsFound' | translate}}</p>
        </div>
        <app-spinner *ngIf="isLoading"></app-spinner>
      </div>
    </div>
  </app-base-dropdown-select>
</ng-container>

<ng-container *ngIf="!isMultiSelect">
  <div *ngIf="showAddProductButton" class="dropdown-select-row">
    <button (click)="openNewProductDialog()" class="button-narrow" mat-flat-button color="primary">
      <fa-icon [icon]="faBasketShoppingSimple"></fa-icon>
      {{'product.addNewProductButton' | translate}}
    </button>
  </div>
  <hr *ngIf="showAddProductButton">
  <div class="dropdown-select-row">
    <div class="dropdown-search-control">
      <app-search-control [focusObservable]="dropdownOpenedSubject.asObservable()"
                          (valueChanges)="onSearchValueChanges($event.value)" [isNarrow]="true"></app-search-control>
    </div>
  </div>
  <hr *ngIf="selectedItem">
  <div *ngIf="selectedItem" class="options-row">
    <div (click)="onItemDeselected(selectedItem)" class="mat-caption option text-14-txt-2-semi-bold">
      {{selectedItem.name}}
      <fa-icon class="check-icon" [icon]="faCheck"></fa-icon>
    </div>
  </div>
  <hr>
  <div class="options-row options-row--search-items">
    <div (click)="onItemSelected(displayedItem)" class="mat-caption option"
         *ngFor="let displayedItem of displayedItems">{{displayedItem.name}}</div>
    <div class="align-center-gap-5 error-message" *ngIf="shouldShowNoResultsError()">
      <span class="color-error x-mark-icon">&#10006;</span>
      <p class="margin-0 text-14-txt-2-medium"> {{'table.noResultsFound' | translate}}</p>
    </div>
    <app-spinner *ngIf="isLoading"></app-spinner>
  </div>
</ng-container>
