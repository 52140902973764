<div class="side-dialog">
  <div [ngClass]="{'side-dialog-buttons--space-between': showBackButton}" class="side-dialog-buttons side-dialog--padding-horizontal">
    <button *ngIf="showBackButton" (click)="onBackClicked()" class="button-narrow" mat-stroked-button color="primary">
      <fa-icon [icon]="faArrowLeft"></fa-icon>
      {{'button.back' | translate}}</button>
    <button (click)="onClose()" class="button-narrow" mat-flat-button color="primary">
      <fa-icon class="close-icon-big" [icon]="faXMark"></fa-icon>
      {{'button.close' | translate}}</button>
  </div>
  <div [style.padding-top.px]="showBackButton ? 20 : 0" class="side-dialog-content">
    <ng-content></ng-content>
  </div>
</div>
