import {Component, Input, ViewChild} from '@angular/core';
import {DropdownComponent} from '../../common/dropdown/dropdown.component';
import {IBasicSelectOption} from '../../../interface/ui/form/IBasicSelectOption';
import {AbstractControl, ControlValueAccessor, NgControl} from '@angular/forms';
import * as _ from 'lodash';
import {faAngleDown, faAngleUp, faCheck } from '@fortawesome/pro-light-svg-icons';
import {DropdownTriggerForDirective} from '../../../directive/dropdown/dropdown-trigger-for.directive';
import {hasAnyError} from 'src/app/shared/util/form/form-utils';

@Component({
  selector: 'app-single-select-input',
  templateUrl: './single-select-input.component.html',
  styleUrls: ['./single-select-input.component.scss']
})
export class SingleSelectInputComponent implements ControlValueAccessor {
  @ViewChild('dropdown') dropdownComponent: DropdownComponent;
  @ViewChild(DropdownTriggerForDirective) dropdownDirective: DropdownTriggerForDirective;
  @Input() preventClick = false;
  @Input() label: string;
  @Input() items: IBasicSelectOption[] = [];
  @Input() showError = false;
  public isDropdownOpen = false;
  public selectedItem: IBasicSelectOption;

  public faCheck = faCheck;
  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public isDisabled = false;

  public hasAnyError: (control: AbstractControl | NgControl) => boolean = hasAnyError;

  public onChange: any = () => {
  }
  public onTouched: any = () => {
  }


  constructor(public control: NgControl) {
    this.control.valueAccessor = this;
  }

  onItemClicked(item: IBasicSelectOption): void {
    this.onChange(this.selectedItem?.name === item.name ? null : item);
    this.onTouched();
    this.dropdownComponent.closed.emit();
  }

  public onDropdownToggled(isOpen: boolean): void {
    if (!isOpen) {
      this.onTouched();
    }
    this.isDropdownOpen = isOpen;
    this.selectedItem = _.clone(this.control.value);
  }

  public openDropdown(): void {
    this.dropdownDirective.openDropdown();
  }

  /* ControlValueAccessor */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: any): void {
    this.selectedItem = _.clone(obj);
  }
  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
