import {
  Directive,
  ElementRef, EventEmitter,
  Input,
  OnDestroy, Output,
  ViewContainerRef
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { merge, Observable, Subscription } from 'rxjs';
import {IDropdownPanel} from '../../interface/ui/form/select/IDropdownPanel';
import {Placement} from '../../enum/ui/tooltip/Placement';
import {getPosition} from '../../util/overlay/overlay.util';

@Directive({ // eslint-disable-next-line
  selector: '[dropdownTriggerFor]', // eslint-disable-next-line
  host: { // eslint-disable-next-line
    '(click)': 'toggleDropdown()'
  }
})
export class DropdownTriggerForDirective implements OnDestroy {
  private isDropdownOpen = false;
  private overlayRef: OverlayRef;
  private dropdownClosingActionsSub = Subscription.EMPTY;
  private subscriptions: Subscription[] = [];

  @Input('dropdownTriggerFor') public dropdownPanel: IDropdownPanel; // eslint-disable-next-line
  @Input('placement') placement: Placement = Placement.BOTTOM; // eslint-disable-next-line
  @Input('preventClick') public preventClick = false; // eslint-disable-next-line
  @Input('preventClose') public preventClose = false; // eslint-disable-next-line
  @Input('hasBackdrop') hasBackdrop = true; // eslint-disable-next-line
  @Input('offsetY') offsetY = 13; // eslint-disable-next-line

  @Output() isOpen: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef
  ) {}

  public toggleDropdown(): void {
    if (this.isDropdownOpen && !this.preventClose) {
      this.destroyDropdown();
    } else if (!this.preventClick) {
      this.openDropdown();
    }
  }

  public openDropdown(): void {
    this.isDropdownOpen = true;
    this.isOpen.emit(true);

    this.overlayRef = this.createOverlayRef();
    const templatePortal = new TemplatePortal(this.dropdownPanel.templateRef, this.viewContainerRef);
    this.overlayRef.attach(templatePortal);
    this.updateDropdownPanelOpen();

    this.dropdownClosingActionsSub = this.dropdownClosingActions().subscribe(() => this.destroyDropdown());
  }

  private updateDropdownPanelOpen(): void {
    this.dropdownPanel.onOpen();
    const sub = this.dropdownPanel.resized.subscribe(() => this.overlayRef.updatePosition());
    this.subscriptions.push(sub);
  }

  private createOverlayRef(): OverlayRef {
    return this.overlay.create({
      hasBackdrop: this.hasBackdrop,
      panelClass: 'dropdown-cdk-panel',
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions(getPosition(this.placement, {offsetY: this.offsetY, offsetX: null}))
    });
  }

  private dropdownClosingActions(): Observable<MouseEvent | void> {
    const backdropClick$ = this.overlayRef.backdropClick();
    const detachment$ = this.overlayRef.detachments();
    const dropdownClosed = this.dropdownPanel.closed;

    return merge(backdropClick$, detachment$, dropdownClosed);
  }

  public destroyDropdown(): void {
    if (!this.overlayRef || !this.isDropdownOpen) {
      return;
    }

    this.dropdownClosingActionsSub.unsubscribe();
    this.isDropdownOpen = false;
    this.isOpen.emit(false);
    this.overlayRef.detach();
  }

  public ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
