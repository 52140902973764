<div class="date-range-control-wrapper">
<app-base-select-button [disabled]="isDisabled" [isTableFilter]="isTableFilter"
                        [isFilterSelected]="isTableFilter && hasValue()" [isArrowGrayedOut]="isControlGreyedOut()"
                        [errorDisplayed]="hasAnyError(control)" (clicked)="onDropdownToggled(true)"
                        [isDropdownOpen]="isDropdownOpen" [preventDropdownOpen]="true">
  <app-base-select-button-content [isTableFilter]="isTableFilter" [placeholder]="placeholder"
                                  [showContent]="hasValue()">
    <span [ngClass]="isControlGreyedOut() ? 'color-light-grey-primary' : ''">
      {{control.value?.start | date:'dd/MM/YYYY'}} - {{control.value?.end | date:'dd/MM/YYYY'}}
    </span>
  </app-base-select-button-content>
</app-base-select-button>


<mat-form-field class="mat-form-field-hidden" appearance="fill">
  <mat-date-range-input [formGroup]="dateRangeForm" [rangePicker]="picker">
    <input name="startDate" autocomplete="off" formControlName="start" matStartDate placeholder="Start date">
    <input name="endDate" autocomplete="off" formControlName="end" matEndDate placeholder="End date">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker  [yPosition]="'below'" [xPosition]="'end'" [calendarHeaderComponent]="calendarHeader" #picker>
        <mat-date-range-picker-actions>
          <div class="datepicker-actions-wrapper">
            <button (click)="onClearClicked()" class="mat-stroked-button mat-primary button-narrow" mat-stroked-button>
              <fa-icon [icon]="faTrash"></fa-icon>
              {{'button.clear' | translate}}</button>
            <button class="mat-flat-button mat-primary button-narrow margin-0" mat-flat-button matDateRangePickerApply>
              <fa-icon [icon]="faDiskArrowRight"></fa-icon>
              {{'button.choose' | translate}}</button>
          </div>
        </mat-date-range-picker-actions>
      </mat-date-range-picker>
</mat-form-field>
</div>
