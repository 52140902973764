<ng-container [formGroup]="formGroup">
  <div formArrayName="platforms">
    <div *ngFor="let item of platforms.controls; let i = index">
      <div [formGroupName]="i" class="form-controls-group">
        <ng-container [ngSwitch]="getPlatformTypeFromIdx(i)">
          <ng-container *ngSwitchCase="PlatformType.IOS">
            <ng-container *ngTemplateOutlet="iosPlatform; context: {index: i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="PlatformType.ANDROID">
            <ng-container *ngTemplateOutlet="androidPlatform; context: {index: i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="PlatformType.WEB ">
            <ng-container *ngTemplateOutlet="webPlatform; context: {index: i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="PlatformType.SAMSUNG ">
            <ng-container *ngTemplateOutlet="samsungPlatform; context: {index: i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="PlatformType.HUAWEI ">
            <ng-container *ngTemplateOutlet="huaweiPlatform; context: {index: i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="PlatformType.AMAZON ">
            <ng-container *ngTemplateOutlet="amazonPlatform; context: {index: i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="emptyPlatform; context: {index: i}"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<button type="button" class="move-right" *ngIf="platforms.length < 6" (click)="addPlatform()" mat-flat-button
        color="primary">
  <fa-icon [icon]="faPlus"></fa-icon>
  {{'platform.addNextPlatformButton' | translate}}
</button>


<ng-template let-index="index" #iosPlatform>
  <ng-container [formGroup]="formGroup">
    <ng-container formArrayName="platforms">
      <ng-container [formGroupName]="index">
        <div class="platform-grid">
          <div class="form-controls-row">
            <ng-container
              *ngTemplateOutlet="platformTypeControl; context: {index: index, platformName: ('platformType.ios' | translate)}">
            </ng-container>
              <ng-container
                *ngTemplateOutlet="storeIdFormControl;
                context: {index: index, tooltip: ('product.productForm.iosStoreIdTooltip' | translate), isBundleId: false}">
              </ng-container>
          </div>
          <div>
            <ng-container *ngTemplateOutlet="deletePlatformButton; context:
           {index: index, platformName: ('platformType.ios' | translate),
           buttonText: ('product.productForm.deleteIOSPlatformButton' | translate)}"></ng-container>
          </div>
        </div>
        <div *ngIf="isEditMode" class="form-controls-row form-row">
          <ng-container *ngTemplateOutlet="enabledPlatform;
           context: {index: index}"></ng-container>
        </div>

        <hr>
        <div class="form-controls-row url-row">
          <ng-container
            *ngTemplateOutlet="urlFormControl; context: {index: index, tooltip: ('product.productForm.iosURLTooltip' | translate)}"></ng-container>
        </div>

          <div class="app-store-checkbox-row mat-caption">
            <div class="align-center-gap-10">
              {{'product.productForm.appStore' | translate}}:
              <div class="align-center-gap-10 cursor-pointer" (click)="useDefaultProductPageChanged(index)">
                <app-checkbox [preventChange]="true" formControlName="useDefaultProductPage">
                </app-checkbox>
                {{'product.productForm.useDefaultPage' | translate}}
              </div>
            </div>
          </div>

          <div class="product-page-ids-container" formArrayName="productPageIds">
            <div class="product-page-id" *ngFor="let item of getProductPageIds(index).controls;let pageIdIndex = index">
              <div class="form-control">
                <div class="label align-center-gap-5"><span>{{'product.productForm.productPageIds' | translate}}</span>
                  <fa-icon class="color-primary" [icon]="faCircleInfo"
                           [appTooltip]="'product.productForm.productPageIdTooltip' | translate">
                  </fa-icon>
                </div>
                <input name="productPage" autocomplete="off" [formControlName]="pageIdIndex" type="text"
                       [placeholder]="'product.productForm.productPageIds' | translate">
                <span *ngIf="hasRequiredError(getProductPageIdControl(index, pageIdIndex))"
                      class="error-message">{{'form.fieldRequired' | translate}}</span>
                <span *ngIf="hasError(getProductPageIdControl(index, pageIdIndex), 'uuid')"
                      class="error-message">{{'product.productForm.uuidError' | translate}}</span>
              </div>
              <button type="button" *ngIf="getProductPageIds(index).length === 1"
                      (click)="clearProductPageID(index, pageIdIndex)"
                      class="button-square-icon" mat-stroked-button color="primary">
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
              <button type="button" *ngIf="!(pageIdIndex === 0)" (click)="removeProductPageID(index, pageIdIndex)"
                      class="button-square-icon" mat-stroked-button color="primary">
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
              <button type="button" (click)="addProductPageID(index)"
                      *ngIf="pageIdIndex === getProductPageIds(index).controls.length - 1 && getProductPageIds(index).length < 35"
                      class="button-square-icon" mat-flat-button color="primary">
                <fa-icon [icon]="faPlus"></fa-icon>
              </button>
            </div>
          </div>
        <ng-container *ngTemplateOutlet="trackingLinks; context: {index: index}"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template let-index="index" #androidPlatform>
  <ng-container [formGroup]="formGroup">
    <ng-container formArrayName="platforms">
      <ng-container [formGroupName]="index">
        <div class="platform-grid">
          <div class="form-controls-row">
            <ng-container
              *ngTemplateOutlet="platformTypeControl; context: {index: index, platformName: ('platformType.android' | translate)}">
            </ng-container>
              <ng-container
                *ngTemplateOutlet="storeIdFormControl;
                context: {index: index, tooltip: ('product.productForm.androidBundleIdTooltip' | translate), isBundleId: true}">
              </ng-container>
          </div>
          <div>
            <ng-container *ngTemplateOutlet="deletePlatformButton;
           context: {index: index, platformName: ('platformType.android' | translate),
           buttonText: ('product.productForm.deleteAndroidPlatformButton' | translate)}"></ng-container>
          </div>
        </div>
        <div *ngIf="isEditMode"  class="form-controls-row form-row">
          <ng-container *ngTemplateOutlet="enabledPlatform;
           context: {index: index}"></ng-container>
        </div>

        <div class="form-controls-row url-row form-row">
          <ng-container
            *ngTemplateOutlet="urlFormControl; context: {index: index, tooltip: ('product.productForm.androidURLTooltip' | translate)}"></ng-container>
        </div>

        <div class="form-controls-row form-row">
          <div class="form-control">
            <div class="label align-center-gap-5">
              <span>{{'product.productForm.utmCampaign' | translate}}</span>
              <fa-icon class="color-primary" [icon]="faCircleInfo"
                       [appTooltip]="'product.productForm.utmCampaignTooltip' | translate">
              </fa-icon>
            </div>
            <input name="utmCampaign" autocomplete="off" formControlName="utmCampaign" type="text"
                   [placeholder]="'product.productForm.utmCampaign' | translate">
            <span *ngIf="hasError(getControl(index, 'utmCampaign'), 'utmCampaign')"
                  class="error-message">{{'product.productForm.utmCampaignError' | translate}}</span>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="trackingLinks; context: {index: index}"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template let-index="index" #webPlatform>
  <ng-container [formGroup]="formGroup">
    <ng-container formArrayName="platforms">
      <ng-container [formGroupName]="index">
        <div class="platform-grid">
          <div class="form-controls-row">
            <ng-container
              *ngTemplateOutlet="platformTypeControl; context: {index: index, platformName: ('platformType.web' | translate)}"></ng-container>
            <ng-container
              *ngTemplateOutlet="storeIdFormControl;
              context: {index: index, tooltip: ('product.productForm.webStoreIdTooltip' | translate), isBundleId: false}">
            </ng-container>
          </div>
          <div>
            <ng-container *ngTemplateOutlet="deletePlatformButton; context: {index: index, platformName: ('platformType.web' | translate),
            buttonText: ('product.productForm.deleteWebPlatformButton' | translate)}">
            </ng-container>
          </div>
        </div>
        <div *ngIf="isEditMode"  class="form-controls-row form-row">
          <ng-container *ngTemplateOutlet="enabledPlatform;
           context: {index: index}"></ng-container>
        </div>
        <div class="form-controls-row url-row form-row">
          <ng-container *ngTemplateOutlet="urlFormControl; context: {index: index, isRequired: true}"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="trackingLinks; context: {index: index}"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>


<ng-template let-index="index" #samsungPlatform>
  <ng-container [formGroup]="formGroup">
    <ng-container formArrayName="platforms">
      <ng-container [formGroupName]="index">
        <div class="platform-grid">
          <div class="form-controls-row">
            <ng-container
              *ngTemplateOutlet="platformTypeControl; context: {index: index, platformName: ('platformType.samsung' | translate)}"></ng-container>
              <ng-container
                *ngTemplateOutlet="storeIdFormControl;
                context: {index: index, tooltip: ('product.productForm.samsungBundleIdTooltip' | translate), isBundleId: true}">
              </ng-container>

          </div>
          <div>
            <ng-container *ngTemplateOutlet="deletePlatformButton; context: {index: index, platformName: ('platformType.samsung' | translate),
          buttonText: ('product.productForm.deleteSamsungPlatformButton' | translate)}">
            </ng-container>
          </div>
        </div>
        <div *ngIf="isEditMode"  class="form-controls-row form-row">
          <ng-container *ngTemplateOutlet="enabledPlatform;
           context: {index: index}"></ng-container>
        </div>

        <div class="form-controls-row url-row form-row">
          <ng-container
            *ngTemplateOutlet="urlFormControl; context: {index: index, tooltip: ('product.productForm.samsungURLTooltip' | translate)}"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="trackingLinks; context: {index: index}"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>


<ng-template let-index="index" #huaweiPlatform>
  <ng-container [formGroup]="formGroup">
    <ng-container formArrayName="platforms">
      <ng-container [formGroupName]="index">
        <div class="platform-grid">
          <div class="form-controls-row">
            <ng-container
              *ngTemplateOutlet="platformTypeControl; context: {index: index, platformName: ('platformType.huawei' | translate)}"></ng-container>
              <ng-container
                *ngTemplateOutlet="storeIdFormControl;
                context: {index: index, tooltip: ('product.productForm.huaweiBundleIdTooltip' | translate), isBundleId: true}">
              </ng-container>

          </div>
          <div>
            <ng-container *ngTemplateOutlet="deletePlatformButton; context: {index: index, platformName: ('platformType.huawei' | translate),
          buttonText: ('product.productForm.deleteHuaweiPlatformButton' | translate)}">
            </ng-container>
          </div>
        </div>
        <div *ngIf="isEditMode"  class="form-controls-row form-row">
          <ng-container *ngTemplateOutlet="enabledPlatform;
           context: {index: index}"></ng-container>
        </div>

        <div class="form-controls-row url-row form-row">
          <ng-container
            *ngTemplateOutlet="urlFormControl; context: {index: index, tooltip: ('product.productForm.huaweiURLTooltip' | translate)}"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="trackingLinks; context: {index: index}"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template let-index="index" #amazonPlatform>
  <ng-container [formGroup]="formGroup">
    <ng-container formArrayName="platforms">
      <ng-container [formGroupName]="index">
        <div class="platform-grid">
          <div class="form-controls-row">
            <ng-container
              *ngTemplateOutlet="platformTypeControl; context: {index: index, platformName: ('platformType.amazon' | translate)}"></ng-container>
              <ng-container
                *ngTemplateOutlet="storeIdFormControl;
                context: {index: index, tooltip: ('product.productForm.amazonBundleIdTooltip' | translate), isBundleId: true}">
              </ng-container>
          </div>
          <div>
            <ng-container *ngTemplateOutlet="deletePlatformButton; context: {index: index, platformName: ('platformType.amazon' | translate),
          buttonText: ('product.productForm.deleteAmazonPlatformButton' | translate)}">
            </ng-container>
          </div>
        </div>
        <div *ngIf="isEditMode"  class="form-controls-row form-row">
          <ng-container *ngTemplateOutlet="enabledPlatform;
           context: {index: index}"></ng-container>
        </div>
        <div class="form-controls-row url-row form-row">
          <ng-container
            *ngTemplateOutlet="urlFormControl; context: {index: index, tooltip: ('product.productForm.amazonURLTooltip' | translate)}"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="trackingLinks; context: {index: index}"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template let-index="index" #emptyPlatform>
  <ng-container [formGroup]="formGroup">
    <ng-container formArrayName="platforms">
      <ng-container [formGroupName]="index">
        <div class="platform-grid">
          <div class="form-controls-row">
            <ng-container
              *ngTemplateOutlet="platformTypeControl; context: {index: index, platformName: ''}"></ng-container>
          </div>
          <div *ngIf="index !== 0">
            <button type="button" class="button-narrow button-delete-platform" (click)="deletePlatform(index)"
                    mat-stroked-button color="primary">
              <fa-icon [icon]="faTrash"></fa-icon>
              {{'product.productForm.deletePlatformButton' | translate}}
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>


<ng-template let-index="index" let-tooltip="tooltip" let-isBundleId="isBundleId" #storeIdFormControl>
  <ng-container [formGroup]="formGroup">
    <ng-container formArrayName="platforms">
      <ng-container [formGroupName]="index">
        <div class="form-control">
          <div class="label align-center-gap-5">
            <span>{{isBundleId ? ('product.productForm.bundleId' | translate) : ('product.productForm.storeIdentifier' | translate)}}
              <span class="color-error">*</span></span>
            <fa-icon class="color-primary" [icon]="faCircleInfo" [appTooltip]="tooltip">
            </fa-icon>
          </div>
          <input name="storeId" autocomplete="off" formControlName="platformId" type="url"
                 [placeholder]="isBundleId ? ('product.productForm.bundleId' | translate) : ('product.productForm.storeIdentifier' | translate)">

          <ng-container *ngIf="hasRequiredError(getControl(index, 'platformId')); else storeIdUniq" #storeIdRequired>
            <span class="error-message">{{'form.fieldRequired' | translate}}</span>
          </ng-container>

          <ng-template #storeIdUniq>
            <ng-container *ngIf="hasError(getControl(index, 'platformId'), 'platformIdExists'); else storeIdValid">
              <span class="error-message">{{'product.productForm.storeIdUniqMsg' | translate}}</span>
            </ng-container>
          </ng-template>
          <ng-template #storeIdValid>
            <span *ngIf="hasError(getControl(index, 'platformId'), 'reverseDomain')"
                  class="error-message">
            {{'product.productForm.reverseDomainError' | translate}}
            </span>

            <ng-container *ngIf="hasError(getControl(index, 'platformId'), 'storeIdNumericIOS'); else storeIdLengthIOS">
              <span class="error-message">{{'product.productForm.storeIdIOSNumericError' | translate}}</span>
            </ng-container>
          </ng-template>

          <ng-template #storeIdLengthIOS>
            <span *ngIf="hasError(getControl(index, 'platformId'), 'storeIdLengthIOS')"
                  class="error-message">
            {{'product.productForm.storeIdIOSLengthError' | translate}}
          </span>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>


<ng-template let-index="index" let-platformName="platformName" #platformTypeControl>
  <ng-container [formGroup]="formGroup">
    <ng-container formArrayName="platforms">
      <ng-container [formGroupName]="index">
        <div class="form-control">
          <div class="label">{{'platform.platformType' | translate}} <span class="color-error">*</span></div>
          <app-single-select-input formControlName="type" [items]="platformTypes"
                                   [label]="'product.productForm.platformTypePlaceholder' | translate"
                                   [preventClick]="hasPlatformId(index)" [appChangePlatform]="hasPlatformId(index)"
                                   [showError]="hasPlatformCountError(index)"
                                   (confirmed)="changePlatformTypeAndDelete(index)"
                                   [platformName]="platformName" [productName]="formGroup.controls.name.value">

          </app-single-select-input>
          <span class="error-message" *ngIf="hasPlatformCountError(index)">
            {{'product.productForm.platformTypeNoRepeatError' | translate}}
            </span>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>


<ng-template let-index="index" let-platformName="platformName" let-buttonText="buttonText" #deletePlatformButton>
  <button type="button" [appDeletePlatform]="hasPlatformId(index)" [platformName]="platformName"
          [productName]="formGroup.controls.name.value"
          (confirmed)="deletePlatformConfirmed(index)" class="button-narrow button-delete-platform"
          (click)="onDeletePlatformClicked(index)" mat-stroked-button color="primary">
    <fa-icon [icon]="faTrash"></fa-icon>
    {{buttonText}}
  </button>
</ng-template>

<ng-template let-index="index" #enabledPlatform>
  <ng-container [formGroup]="formGroup">
    <ng-container formArrayName="platforms">
      <ng-container [formGroupName]="index">
        <div class="d-flex align-center-gap-5">
          <app-toggle-slider formControlName="enabled">
          </app-toggle-slider>
          <div class="align-center-gap-5 mat-caption">
            {{'product.enabledStatus' | translate}}
          <fa-icon class="color-primary" [icon]="faCircleInfo"
                   [appTooltip]="'product.productForm.disabledEnabledTooltip' | translate">
                  </fa-icon>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template let-index="index" let-isRequired="isRequired" let-tooltip="tooltip" #urlFormControl>
  <ng-container [formGroup]="formGroup">
    <ng-container formArrayName="platforms">
      <ng-container [formGroupName]="index">
        <div class="form-control">
          <div class="label align-center-gap-5">
            <span>{{'product.productForm.url' | translate}} <span *ngIf="isRequired" class="color-error">*</span>
            <span *ngIf="!isRequired"> (optional)</span>
            </span>
            <fa-icon *ngIf="tooltip" class="color-primary" [icon]="faCircleInfo" [appTooltip]="tooltip">
            </fa-icon>
          </div>
          <input name="defaultURL" autocomplete="off" formControlName="defaultURL" type="url"
                 [placeholder]="'product.productForm.url' | translate">
          <span *ngIf="hasRequiredError(getControl(index, 'defaultURL'))"
                class="error-message">
            {{'form.fieldRequired' | translate}}
            </span>
          <span *ngIf="hasError(getControl(index, 'defaultURL'), 'urlPattern')"
                class="error-message">
            <ng-container *ngIf="!hasError(getControl(index, 'defaultURL'), 'httpsMissing')">
              {{'product.productForm.urlInvalidMsg' | translate}}
            </ng-container>
            <ng-container *ngIf="hasError(getControl(index, 'defaultURL'), 'httpsMissing')">
              {{('product.productForm.urlInvalidMsg' | translate) + ('product.productForm.urlHttpsMissingPostfix' | translate)}}
            </ng-container>
            </span>
          <span *ngIf="hasError(getControl(index, 'defaultURL'), 'androidUrlPattern')"
                class="error-message">
            {{'product.productForm.androidUrlMsg' | translate}}
            </span>
          <span *ngIf="hasError(getControl(index, 'defaultURL'), 'samsungUrlPattern')"
                class="error-message">
            {{'product.productForm.samsungUrlMsg' | translate}}
            </span>
          <span *ngIf="hasError(getControl(index, 'defaultURL'), 'amazonUrlPattern')"
                class="error-message">
            {{'product.productForm.amazonUrlMsg' | translate}}
            </span>
          <span *ngIf="hasError(getControl(index, 'defaultURL'), 'huaweiUrlPattern')"
                class="error-message">
            {{'product.productForm.huaweiUrlMsg' | translate}}
            </span>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template let-index="index" #trackingLinks>
  <ng-container [formGroup]="formGroup">
    <ng-container formArrayName="platforms">
      <ng-container [formGroupName]="index">
        <div class="form-controls-row form-controls-row--align-center mat-caption">
          <div class="align-center-gap-40">
            <p>{{'product.externalTracking' | translate}}:</p>
            <div formArrayName="externalTrackingSelect" class="align-center-gap-20">
              <div
                class="align-center-gap-5 mat-caption"
                [formGroupName]="i"
                *ngFor="let trackingControl of getPlatform(index).controls.externalTrackingSelect.controls; let i = index">
                <app-toggle-slider formControlName="isSelected">
                </app-toggle-slider>
                {{trackingControl.controls.name.value}}
              </div>
            </div>
            <div (click)="onOptimizedQueueChanged(index)"
                 class="form-controls align-center-gap-5 mat-caption cursor-pointer">
              <app-checkbox [preventChange]="true" formControlName="useOptimizedQueue">
              </app-checkbox>
              {{'product.productForm.optimizedQueue' | translate}}
            </div>
          </div>
        </div>
        <ng-container *ngIf="getPlatform(index).controls.externalTracking.length > 0">
          <ng-container formArrayName="externalTracking">
            <div [formGroupName]="i"
                 *ngFor="let trackingGroup of getPlatform(index).controls.externalTracking.controls; let i = index"
                 class="form-controls-row">
              <ng-container formArrayName="trackingElement">
                <div [formGroupName]="innerIdx"
                     *ngFor="let innerGroup of trackingGroup.controls.trackingElement.controls; let innerIdx = index">
                  <div class="form-control">
                    <div class="label align-center-gap-5">
                      <span>{{innerGroup.controls.trackingType.value + ' URL' + '(' + innerGroup.controls.name.value + ')'}}</span>
                    </div>
                    <input name="tracking_url" autocomplete="off" formControlName="url" type="text"
                           [placeholder]="innerGroup.controls.trackingType.value + ' URL' +  '(' + innerGroup.controls.name.value + ')'">
                    <span *ngIf="hasError(innerGroup.controls.url, 'trackingLinkFormatError')" class="error-message">
                      {{'product.productForm.trackingLinkFormatError' | translate}}
                    </span>
                    <span *ngIf="hasError(innerGroup.controls.url, 'trackingLinkHttpsError')" class="error-message">
                      {{'product.productForm.trackingLinkHttpsError' | translate}}
                    </span>
                    <span #incorrectMacrosMsg
                          *ngIf="hasError(innerGroup.controls.url, 'trackingLinkIncorrectMacros')"
                          class="error-message error-message-ellipsis"
                          [tooltipCss]="'tooltip-error-small'"
                          [cursorAllowedInsideTooltip]="true"
                          [isActive]="isEllipsisActive(incorrectMacrosMsg)"
                          [appTooltip]="('product.productForm.trackingLinkIncorrectMacros' | translate)
                            + getIncorrectMacros(innerGroup.controls.url)">
                      {{('product.productForm.trackingLinkIncorrectMacros' | translate) + getIncorrectMacros(innerGroup.controls.url)}}
                    </span>
                    <span *ngIf="hasError(innerGroup.controls.url, 'trackingLinkNoMacro')" class="error-message">
                      {{'product.productForm.trackingLinkNoMacroError' | translate}}
                    </span>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
