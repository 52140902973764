import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appFileDnd]'
})
export class FileDndDirective {
  @HostBinding('class.file-over') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<FileList>();
  private counter = 0;

  @HostListener('dragover', ['$event']) onDragOver(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.counter++;
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.counter--;
    if (this.counter === 0) {
      this.fileOver = false;
    }
  }

  @HostListener('drop', ['$event']) public ondrop(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }

}
