import { Injectable } from '@angular/core';
import {IBasicSelectOption} from '../../interface/ui/form/IBasicSelectOption';
import {TranslateService} from '@ngx-translate/core';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnumTranslateService {

  constructor(private translateService: TranslateService) { }

  public async getSelectOptionsFromEnum(enumName: { [x: string]: string }, prefix: string): Promise<IBasicSelectOption[]> {
    return Promise.all(Object.values(enumName)
      .map((value) => this.getSelectOption(value, prefix)));
  }

  public async getTranslation(prefix: string, value: string): Promise<string> {
    return await firstValueFrom(this.translateService.get(prefix + value));
  }

  private async getSelectOption(value: string, prefix: string): Promise<IBasicSelectOption> {
    return {
      label: await firstValueFrom(this.translateService.get(prefix + value)),
      name: value
    };
  }
}
