import {ComponentRef, Injectable, Injector, Type, ViewContainerRef} from '@angular/core';
import {BACK_ACTION} from '../dialog/dialog-tokens';
import {IDialogConfig} from '../../../interface/ui/dialog/IDialogConfig';
import {IBackAction} from '../../../interface/ui/dialog/IBackAction';
import {DialogGeneralService} from '../dialog-general/dialog-general.service';

@Injectable({
  providedIn: 'root'
})
export class ExpandedDialogService {
  private rootViewContainerRef: ViewContainerRef;

  constructor(private injector: Injector, private dialogGeneralService: DialogGeneralService) {
  }

  public setRootViewContainerRef(rootViewContainerRef: ViewContainerRef): void {
    this.rootViewContainerRef = rootViewContainerRef;
  }

  public open<C>(componentType: Type<C>, dialogConfig?: IDialogConfig): ComponentRef<C> {
    const backAction: IBackAction = dialogConfig?.backActionDialog ?
      {backActionDialogConfig: dialogConfig.backActionDialog} as IBackAction : null;
    const injector: Injector = Injector.create({
      parent: this.injector,
      providers: [
        {provide: BACK_ACTION, useValue: backAction}
      ],
    });
    return this.rootViewContainerRef.createComponent(componentType, {injector});
  }

  public close(backAction?: IBackAction): void {
    this.rootViewContainerRef.remove(this.rootViewContainerRef.length - 1);
    if (backAction) {
      this.dialogGeneralService.open(backAction.backActionDialogConfig);
    }
  }

  public closeAll(): void {
    this.rootViewContainerRef.clear();
  }
}
