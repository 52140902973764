import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModuleImporterService {
  // this service allows for dynamically importing lazy loaded modules
  // main purpose of dynamic imports is dynamically creating components inside modals

  constructor() { }

  public async importProductModule(): Promise<void> {
    await import('../../../product/product.module');
  }

  public async importCreativeModule(): Promise<void> {
    await import('../../../creative/creative.module');
  }

  public async importAssetModule(): Promise<void> {
    await import('../../../asset/asset.module');
  }

  public async importCampaignModule(): Promise<void> {
    await import('../../../campaign/campaign.module');
  }

  public async importWaterfallModule(): Promise<void> {
    await import('../../../waterfall/waterfall.module');
  }

  public async importMoreGamesModule(): Promise<void> {
    await import('../../../more-games/more-games.module');
  }
}
