<app-base-side-dialog [dialogRef]="dialogRef" [showBackButton]="!!backAction?.backActionDialogConfig"
                      [backAction]="backAction">
  <app-spinner *ngIf="isLoading"></app-spinner>
  <div class="preview-content">
    <div class="text-light side-dialog--padding-horizontal preview-header">
      <p class="mat-body-2 color-dark-grey-fourth">{{'creative.creativePreview.creativesPreview' | translate}}</p>

      <div class="side-dialog-header-row ">
        <div class="center-gap-10">
          <h1>{{data.product.name}}</h1>
        </div>
      </div>
      <div class="side-dialog-header-row">
        <p>
          {{'product.productForm.productId' | translate}}: {{data.product.boombitId}}
        </p>

        <div style="gap: 10px" class="side-dialog-header-row">
          <p class="align-center-gap-5" style="margin:0">
            {{'adUnit.adUnit' | translate}}:<span *ngIf="data.product.adUnitTypes.length === 0">{{'creative.noCreatives' | translate}}</span>
          </p>
          <app-ad-unit-icons [adUnitTypes]="data.product.adUnitTypes"></app-ad-unit-icons>
        </div>
      </div>
    </div>

    <hr class="separator separator--no-margin"/>
    <div class="side-dialog--padding-horizontal preview-body--scrollable">
      <app-tabs [isNarrow]="true" (tabChanged)="onTabChanged($event)" [activeTab]="selectedTab"
                [tabs]="tabs"></app-tabs>
      <div class="padding-t-20">
      <app-creatives-details (deleted)="onCreativeDeleted($event)" [creatives]="creatives" [dialogRef]="dialogRef" [backDialogConfig]="backDialogConfig">
      </app-creatives-details>
      </div>
      <div class="preview-row--separated" *ngIf="creatives.length === 0">
        <p>{{'creative.noCreativesFound' | translate}}</p>
      </div>


      <div class="side-dialog-action-buttons">
        <button (click)="onCloseClicked()" mat-stroked-button color="primary">
          <fa-icon [icon]="faXMark" class="close-icon-big"></fa-icon>
          {{'button.close' | translate}}
        </button>
      </div>
    </div>
  </div>
</app-base-side-dialog>
