export function setBackdropOnlyForOverlayOnTop(mainOverlayWrapper: HTMLElement) {
  const elements: HTMLCollectionOf<Element> = mainOverlayWrapper.getElementsByClassName('cdk-overlay-backdrop');
  const length = elements.length;
  Array.from(elements).forEach((el, idx) => {
    if (idx === length - 1){
      updateClassesForOverlayOnTop(el);
    } else {
      updateClassesForOverlayInTheBack(el)
    }
  })
}

function updateClassesForOverlayOnTop(el) {
  el.classList.add('overlay-backdrop');
  el.classList.remove('overlay-backdrop-transparent');
}

function updateClassesForOverlayInTheBack(el) {
  el.classList.add('overlay-backdrop-transparent');
  el.classList.remove('overlay-backdrop');
}
