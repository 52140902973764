import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  ViewContainerRef
} from '@angular/core';
import {TooltipComponent} from '../../component/common/tooltip/tooltip.component';
import {TooltipType} from '../../enum/ui/tooltip/TooltipType';
import {ConnectedPosition, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Placement} from '../../enum/ui/tooltip/Placement';
import {TOOLTIP_DATA} from './tooltip-token';
import {ITooltip, ITooltipContentCmp} from '../../interface/ui/tooltip/ITooltip';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnDestroy{

  constructor(private elementRef: ElementRef,
              private viewContainerRef: ViewContainerRef,
              private overlay: Overlay, private injector: Injector) {
  }

  @Input('appTooltip') public text = ''; // eslint-disable-next-line
  @Input('contentCmp') public contentComponent: ITooltipContentCmp; // eslint-disable-next-line
  @Input('tooltipType') public tooltipType: TooltipType = TooltipType.TEXT; // eslint-disable-next-line
  @Input('placement') placement: Placement = Placement.TOP; // eslint-disable-next-line
  @Input('isActive') isActive = true; // eslint-disable-next-line
  @Input('tooltipCss') tooltipCss: string; // eslint-disable-next-line
  @Input('cursorAllowedInsideTooltip') cursorAllowedInsideTooltip = false; // eslint-disable-next-line

  private overlayRef: OverlayRef;
  private subscriptions: Subscription[] = [];

  private tooltipRef: ComponentRef<TooltipComponent>;
  private isMouseInsideHost = false;

  private static getRightPosition(): ConnectedPosition[] {
    return [{originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center', offsetX: 13}];
  }

  private static getTopPosition(offset?: number): ConnectedPosition[] {
    return [{originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom', offsetY: offset ? offset : -13}];
  }

  private static getLeftPosition(): ConnectedPosition[] {
    return [{originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: -13}];
  }

  public static getPosition(position: Placement, offset?: number): ConnectedPosition[] {
    switch (position) {
      case Placement.RIGHT:
        return TooltipDirective.getRightPosition();
      case Placement.TOP:
        return TooltipDirective.getTopPosition(offset);
      case Placement.LEFT:
        return TooltipDirective.getLeftPosition();
      default:
        return TooltipDirective.getTopPosition(offset);
    }
  }

  @HostListener('mouseenter')
  private show(): void {
    this.isMouseInsideHost = true;
    if (!this.overlayRef && this.isActive) {
      this.createTooltip();
    }
  }

  @HostListener('mouseleave')
  private hide(): void {
    this.isMouseInsideHost = false;

    if (this.cursorAllowedInsideTooltip) {
      this.hideIfCursorAllowed();
    } else {
      this.removeTooltip();
    }
  }

  private hideIfCursorAllowed(): void {
    setTimeout(() => {
      if (this.overlayRef && this.tooltipRef && !this.tooltipRef?.instance.isMouseInside && !this.isMouseInsideHost) {
        this.removeTooltip();
      }
    }, 100);
  }

  private createTooltip(): void {
    this.overlayRef = this.overlay.create({
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions(TooltipDirective.getPosition(this.placement))
    });

    this.observeDetachments();
    this.observeOutsidePointerEvent();
    const portal = new ComponentPortal(TooltipComponent, null, this.getTooltipInjector());
    this.tooltipRef = this.overlayRef.attach(portal);
    this.observeTooltipComponentEvents();
  }

  private observeDetachments(): void {
    const sub = this.overlayRef.detachments().subscribe(() => {
      // clear data after dialog closes by "scrollStrategies.close()"
      this.clearAfterTooltipRemoval();
    });
    this.subscriptions.push(sub);
  }

  private observeOutsidePointerEvent(): void {
    // close tooltip after element changes position
    const sub = this.overlayRef.outsidePointerEvents().subscribe(() => {
      if (this.cursorAllowedInsideTooltip && !this.tooltipRef.instance.isMouseInside) {
        this.hide();
      } else if(!this.cursorAllowedInsideTooltip) {
        this.removeTooltip();
      }
    });
    this.subscriptions.push(sub);
  }

  private observeTooltipComponentEvents(): void {
    if (this.cursorAllowedInsideTooltip) {
      this.observeMouseOutOfTooltip();
      this.observeCloseClickedEvent();
    }
  }

  private observeMouseOutOfTooltip(): void {
    const sub = this.tooltipRef.instance.mouseOut.subscribe(() => {
      this.hide();
    });
    this.subscriptions.push(sub);
  }

  private observeCloseClickedEvent(): void {
    const sub = this.tooltipRef.instance.closeClicked?.subscribe(() => {
      this.removeTooltip();
    });
    this.subscriptions.push(sub);
  }

  private removeTooltip(): void {
    if (!this.overlayRef) {
      return;
    }
    this.clearAfterTooltipRemoval();
  }

  private clearAfterTooltipRemoval(): void {
    this.overlayRef?.detach();
    this.overlayRef = null;
    this.tooltipRef?.destroy();
    this.tooltipRef = null;

    this.subscriptions?.forEach(sub => sub?.unsubscribe());
    this.subscriptions = [];
  }

  private getTooltipInjector(): Injector {
    const tooltipData: ITooltip = {
      text: this.text,
      placement: this.placement,
      type: this.tooltipType,
      contentComponent: this.contentComponent,
      cssClass: this.tooltipCss
    };

    return Injector.create({
      parent: this.injector,
      providers: [{provide: TOOLTIP_DATA, useValue: tooltipData},
      ],
    });
  }

  public ngOnDestroy(): void {
    this.removeTooltip();
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }
}
