export function compareAlphabetical(a: string, b: string): number {
  if(!a || !b) {
    return compareAlphabeticalEmpty(a,b);
  }
  if (a.toLowerCase() < b.toLowerCase()) {
    return -1;
  }
  if (a.toLowerCase() > b.toLowerCase()) {
    return 1;
  }
  return 0;
}

function compareAlphabeticalEmpty(a: string, b: string): number {
  if (!a && b) {
    return 1;
  } else if (!b && a) {
    return -1;
  } else if (!b && !a) {
    return 0;
  }
}

export function compareNumerical(a: number, b: number): number {
  return a - b;
}
