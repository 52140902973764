import { Component, Inject } from '@angular/core';

import { TOOLTIP_CONTENT_DATA } from '../../../directive/tooltip/tooltip-content-token';
import { IPanelTooltip } from '../../../interface/ui/tooltip/IPanelTooltip';

/**
 * Component responsible for displaying tooltip panel content.
 * It receives data through dependency injection and formats the details for display.
 */
@Component({
  selector: 'app-tooltip-panel',
  templateUrl: './tooltip-panel.component.html',
  styleUrls: ['./tooltip-panel.component.scss']
})
export class TooltipPanelComponent {

  /**
   * Contructor for TooltipPanelComponent.
   * Injects tooltip content data provided by the parent component.
   * @param data The data to be displayed in the tooltip panel.
   */
  constructor(@Inject(TOOLTIP_CONTENT_DATA) public data: IPanelTooltip) { }
}
