import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard/component/dashboard/dashboard.component';
import {LoginComponent} from './shared/component/login/login/login.component';
import {AuthGuard} from './shared/guard/auth.guard';
import {DummyComponent} from './shared/component/common/dummy/dummy.component';


const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {
    path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'products', loadChildren: () => import('./product/product.module').then(m => m.ProductModule)
  },
  {
    path: 'assets', loadChildren: () => import('./asset/asset.module').then(m => m.AssetModule)
  },
  {
    path: 'campaign', loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule)
  },
  {
    path: 'creative', loadChildren: () => import('./creative/creative.module').then(m => m.CreativeModule)
  },
  {
    path: 'waterfall', loadChildren: () => import('./waterfall/waterfall.module').then(m => m.WaterfallModule)
  },
  {
    path: 'more-games', loadChildren: () => import('./more-games/more-games.module').then(m => m.MoreGamesModule)
  },
  {
    path: 'whitelist', loadChildren: () => import('./whitelist/whitelist.module').then(m => m.WhitelistModule)
  },
  {
    path: 'dummy-route',
    component: DummyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/dashboard/_/details',
    pathMatch: 'full'
  },
  {path: '**', redirectTo: '/dashboard/_/details'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
