import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ExpandedDialogService} from '../../../service/dialog/expanded-dialog/expanded-dialog.service';
import {faXmark} from '@fortawesome/pro-light-svg-icons';
import {IBackAction} from '../../../interface/ui/dialog/IBackAction';
import {Observable, Subscription} from 'rxjs';
import {FormCloseService} from '../../../service/form-close/form-close.service';

@Component({
  selector: 'app-base-expanded-dialog',
  templateUrl: './base-expanded-dialog.component.html',
  styleUrls: ['./base-expanded-dialog.component.scss']
})
export class BaseExpandedDialogComponent implements OnInit, OnDestroy{
  @Input() backAction: IBackAction;
  @Input() closeDialogObservable: Observable<any>;
  @Input() showConfirmPopup: boolean;

  public faXMark = faXmark;

  public closeSubscription: Subscription;

  constructor(private expandedDialogService: ExpandedDialogService, private formCloseService: FormCloseService) { }

  public onCloseDialog(): void {
      this.handleClose();
  }

  public ngOnInit(): void {
    this.closeSubscription = this.closeDialogObservable?.subscribe((val) => {
      this.handleClose(!!val);
    });
  }

  private async handleClose(omitConfirmation: boolean = false): Promise<void> {
    if (await this.canClose(omitConfirmation)) {
      this.close();
    }
  }

  private close(): void {
    if (this.backAction?.backActionDialogConfig) {
      this.expandedDialogService.close(this.backAction);
    } else {
    this.expandedDialogService.close();
    }
  }

  public async canClose(omitConfirmation: boolean = false): Promise<boolean> {
    if (this.showConfirmPopup && !omitConfirmation) {
      return await this.formCloseService.getConfirmation();
    } else {
      return true;
    }
  }

  public ngOnDestroy(): void {
   this.closeSubscription?.unsubscribe();
  }
}
