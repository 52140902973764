import {NgModule} from '@angular/core';
import {CommonModule, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FileSizePipe} from './pipe/file-size.pipe';
import {MultiSelectInputComponent} from './component/form/multi-select-input/multi-select-input.component';
import {DropdownComponent} from './component/common/dropdown/dropdown.component';
import {TooltipComponent} from './component/common/tooltip/tooltip.component';
import {TooltipDirective} from './directive/tooltip/tooltip.directive';
import {IconTooltipDirective} from './directive/icon-tooltip/icon-tooltip.directive';
import {BaseDropdownSelectComponent} from './component/common/base-dropdown-select/base-dropdown-select.component';
import {BaseSelectButtonComponent} from './component/common/base-select-button/base-select-button.component';
import {DropdownTriggerForDirective} from './directive/dropdown/dropdown-trigger-for.directive';
import {TableCellComponent} from './component/common/table/table-cell/table-cell.component';
import {DateSelectControlComponent} from './component/form/date-select-control/date-select-control.component';
import {
  DateRangeControlComponent
} from './component/form/date-select-control/date-range-control/date-range-control.component';
import {
  CheckboxFilterComponent
} from './component/common/table/table-filter/checkbox-filter/checkbox-filter.component';
import {
  BaseSelectButtonContentComponent
} from './component/common/base-select-button/base-select-button-content/base-select-button-content.component';
import {CheckboxComponent} from './component/form/checkbox/checkbox.component';
import {SearchControlComponent} from './component/form/search/search-control/search-control.component';
import {TagComponent} from './component/common/tag/tag.component';
import {CustomIconComponent} from './component/common/custom-icon/custom-icon.component';
import {BaseExpandedDialogComponent} from './component/common/base-expanded-dialog/base-expanded-dialog.component';
import {BaseSideDialogComponent} from './component/common/base-side-dialog/base-side-dialog.component';
import {BaseCenterDialogComponent} from './component/common/base-center-dialog/base-center-dialog.component';
import {InnerNavbarComponent} from './component/common/navigation/inner-navbar/inner-navbar.component';
import {NavigationListComponent} from './component/common/navigation/navigation-list/navigation-list.component';
import {SearchInputComponent} from './component/form/search-input/search-input.component';
import {
  SearchAutocompleteComponent
} from './component/common/search/search-autocomplete/search-autocomplete.component';
import {
  SelectProductDropdownComponent
} from '../product/component/select-product-dropdown/select-product-dropdown.component';
import {
  SelectProductControlComponent
} from '../product/component/select-product-control/select-product-control.component';
import {SpinnerComponent} from './component/common/spinner/spinner.component';
import {AdUnitIconsComponent} from '../ad-unit/component/ad-unit-icons/ad-unit-icons.component';
import {AdUnitTooltipDirective} from '../ad-unit/directive/ad-unit-tooltip/ad-unit-tooltip.directive';
import {StepsComponent} from './component/common/steps/steps.component';
import {SingleSelectInputComponent} from './component/form/single-select-input/single-select-input.component';
import {ToggleSliderComponent} from './component/form/toggle-slider/toggle-slider.component';
import {ConfirmDialogComponent} from './component/common/confirm-dialog/confirm-dialog.component';
import {TabsComponent} from './component/common/tabs/tabs.component';
import {AssetFileInputComponent} from '../asset/component/asset-form/asset-file-input/asset-file-input.component';
import {FileDndDirective} from './directive/file/file-dnd.directive';
import {SnackbarComponent} from './component/common/snackbar/snackbar.component';
import {
  CalendarHeaderComponent
} from './component/form/date-select-control/calendar-header/calendar-header.component';
import {DragAndDropListComponent} from './component/common/drag-and-drop-list/drag-and-drop-list.component';
import {ProductAvatarComponent} from '../product/component/product-avatar/product-avatar.component';
import {PlatformDetailsComponent} from '../product/component/platform-details/platform-details.component';
import {CreativePreviewRowComponent} from '../creative/component/creative-preview-row/creative-preview-row.component';
import {CreativesDetailsComponent} from '../creative/component/creatives-details/creatives-details.component';
import {AssetDetailsComponent} from '../asset/component/asset-details/asset-details.component';
import {PlatformsIconsComponent} from '../product/component/platforms-icons/platforms-icons.component';
import {
  WaterfallListDraggableComponent
} from '../waterfall/component/waterfall-list-draggable/waterfall-list-draggable.component';
import {RouterModule} from '@angular/router';
import {AdUnitsFormSelectComponent} from '../ad-unit/component/ad-units-form-select/ad-units-form-select.component';
import {TooltipListComponent} from './component/common/tooltip-list/tooltip-list.component';
import {TooltipBasicComponent} from './component/common/tooltip-basic/tooltip-basic.component';
import {CellComponent} from './component/common/cell/cell.component';
import {CellBasicComponent} from './component/common/cell-basic/cell-basic.component';
import {PaginationComponent} from './component/common/pagination/pagination.component';
import {TableFiltersComponent} from './component/common/table-filters/table-filters.component';
import {TableHeaderComponent} from './component/common/table-header/table-header.component';
import {TableComponent} from './component/common/my-table/table.component';
import {ActionsCellComponent} from './component/common/actions-cell/actions-cell.component';
import {FilterSelectComponent} from './component/common/filter-select/filter-select.component';
import {TableHeaderBasicComponent} from './component/common/table-header-basic/table-header-basic.component';
import { TooltipPanelComponent } from './component/common/tooltip-panel/tooltip-panel.component';
import {FilterDateRangeComponent} from './component/common/filter-date-range/filter-date-range.component';
import {CellButtonsComponent} from './component/common/cell-buttons/cell-buttons.component';
import {SourceSelectComponent} from './component/common/source-select/source-select.component';
import {CellDateComponent} from './component/common/cell-date/cell-date.component';


@NgModule({
  declarations: [
    FileSizePipe,
    MultiSelectInputComponent,
    DropdownComponent,
    TooltipComponent,
    TooltipDirective,
    IconTooltipDirective,
    BaseDropdownSelectComponent,
    BaseSelectButtonComponent,
    DropdownTriggerForDirective,
    TableCellComponent,
    DateSelectControlComponent,
    DateRangeControlComponent,
    CheckboxFilterComponent,
    BaseSelectButtonContentComponent,
    CheckboxComponent,
    SearchControlComponent,
    TagComponent,
    CustomIconComponent,
    BaseExpandedDialogComponent,
    BaseSideDialogComponent,
    BaseCenterDialogComponent,
    InnerNavbarComponent,
    NavigationListComponent,
    SearchInputComponent,
    SearchAutocompleteComponent,
    SelectProductDropdownComponent,
    SelectProductControlComponent,
    SpinnerComponent,
    AdUnitIconsComponent,
    AdUnitTooltipDirective,
    StepsComponent,
    SingleSelectInputComponent,
    ToggleSliderComponent,
    ConfirmDialogComponent,
    TabsComponent,
    AssetFileInputComponent,
    FileDndDirective,
    SnackbarComponent,
    CalendarHeaderComponent,
    DragAndDropListComponent,
    ProductAvatarComponent,
    PlatformDetailsComponent,
    CreativePreviewRowComponent,
    CreativesDetailsComponent,
    AssetDetailsComponent,
    PlatformsIconsComponent,
    WaterfallListDraggableComponent,
    AdUnitsFormSelectComponent,
    TooltipListComponent,
    TooltipBasicComponent,
    CellComponent,
    CellBasicComponent,
    PaginationComponent,
    TableFiltersComponent,
    TableHeaderComponent,
    TableComponent,
    ActionsCellComponent,
    FilterSelectComponent,
    TableHeaderBasicComponent,
    TooltipPanelComponent,
    FilterDateRangeComponent,
    CellButtonsComponent,
    SourceSelectComponent,
    CellDateComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    FontAwesomeModule,
    MatSelectModule,
    MatSnackBarModule,
    DragDropModule,
    ScrollingModule,
    TranslateModule,
    MatExpansionModule
  ],
  exports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    FontAwesomeModule,
    MatSelectModule,
    MatSnackBarModule,
    DragDropModule,
    ScrollingModule,
    TranslateModule,
    MatExpansionModule,
    FileSizePipe,
    MultiSelectInputComponent,
    DropdownComponent,
    TooltipComponent,
    TooltipDirective,
    IconTooltipDirective,
    BaseDropdownSelectComponent,
    BaseSelectButtonComponent,
    DropdownTriggerForDirective,
    TableCellComponent,
    DateSelectControlComponent,
    DateRangeControlComponent,
    CheckboxFilterComponent,
    BaseSelectButtonContentComponent,
    CheckboxComponent,
    SearchControlComponent,
    TagComponent,
    CustomIconComponent,
    BaseExpandedDialogComponent,
    BaseSideDialogComponent,
    BaseCenterDialogComponent,
    InnerNavbarComponent,
    NavigationListComponent,
    SearchInputComponent,
    SearchAutocompleteComponent,
    SelectProductDropdownComponent,
    SelectProductControlComponent,
    SpinnerComponent,
    AdUnitIconsComponent,
    AdUnitTooltipDirective,
    StepsComponent,
    SingleSelectInputComponent,
    ToggleSliderComponent,
    ConfirmDialogComponent,
    TabsComponent,
    AssetFileInputComponent,
    FileDndDirective,
    SnackbarComponent,
    CalendarHeaderComponent,
    DragAndDropListComponent,
    ProductAvatarComponent,
    PlatformDetailsComponent,
    CreativePreviewRowComponent,
    CreativesDetailsComponent,
    AssetDetailsComponent,
    PlatformsIconsComponent,
    WaterfallListDraggableComponent,
    AdUnitsFormSelectComponent,
    TooltipListComponent,
    TooltipBasicComponent,
    TableComponent,
    SourceSelectComponent,
    CellDateComponent
  ],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
})
export class SharedModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Date.now());
}

