import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarComponent} from '../../component/common/snackbar/snackbar.component';
import {Status} from '../../enum/Status';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) { }

  public openErrorSnackbar(message: string, duration?: number, closeButton?: boolean): void {
    const snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
        data: {type: Status.ERROR, innerHTML: message, preClose: closeButton ? () => snackBarRef.dismiss() : undefined},
        panelClass: 'snackbar-error-panel',
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: closeButton ? undefined : (duration ? duration : 2500)
    });
  }

  public openSuccessSnackbar(message: string): void {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {type: Status.SUCCESS, innerHTML: message},
      panelClass: 'snackbar-success-panel',
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 2500
    });
  }

  public openWarningSnackbar(message: string, duration?: number, closeButton?: boolean): void {
    const snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
      data: {type: Status.WARNING, innerHTML: message, preClose: closeButton ? () => snackBarRef.dismiss() : undefined},
      panelClass: 'snackbar-warning-panel',
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: closeButton ? undefined : (duration ? duration : 2500)
    });
  }
}
