import {Injectable} from '@angular/core';
import {RequestType} from 'src/app/shared/enum/RequestType';
import {ICampaign} from 'src/app/campaign/interface/ICampaign';
import {ICampaignRaw} from 'src/app/campaign/interface/ICampaignRaw';
import {ApiGeneralService} from '../../shared/service/api/api-general.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ICampaignRepresentation} from '../interface/ICampaignRepresentation';
import {ICampaignDetails} from '../interface/ICampaignDetails';
import _ from 'lodash';
import {CampaignList} from '../interface/CampaignList';
import {QueryService} from '../../shared/service/query.service';

@Injectable({
  providedIn: 'root'
})
export class ApiCampaignsService {

  constructor(
    private apiGeneralService: ApiGeneralService, private queryService: QueryService
  ) {
  }

  public deleteCampaign(id: string): Observable<string> {
    const url = `Campaigns/${id}`;

    return this.apiGeneralService.sendRequest({
      method: RequestType.delete,
      url,
    });
  }

  public createCampaign(campaign: ICampaignRaw): Observable<void> {
    const url = 'Campaigns';

    return this.apiGeneralService.sendRequest({
      method: RequestType.post,
      url,
      data: campaign
    });
  }

  public editCampaign(campaign: ICampaignRaw): Observable<void> {
    const url = 'Campaigns';

    return this.apiGeneralService.sendRequest({
      method: RequestType.put,
      url,
      data: campaign
    });
  }

  public getCampaign(campaignId: string): Observable<ICampaignDetails> {
    const url = `Campaigns/GetByInternalId/${campaignId}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    }).pipe(
      map((campaign: ICampaignRepresentation) => {
        return ({
          ...campaign, startDate: campaign.startDate === 0 ? null : new Date(campaign.startDate),
          endDate: campaign.endDate === 0 ? null : new Date(campaign.endDate)
        });
      }));
  }

  public getDefaultCampaign(productId: string): Observable<ICampaign> {
    const url = `Campaigns/GetDefault/${productId}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }

  public getCampaignsPaginated(query: any): Observable<CampaignList> {
    const params = this.queryService.getHttpParams(query);
    const url = 'Campaigns/PaginatedAndFiltered';
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
      params
    }).pipe(
      map((res: CampaignList) => {
        const campaigns = res.data.map(campaign =>
          ({...campaign, startDate: new Date(campaign.startDate), endDate: new Date(campaign.endDate)}));
        return {...res, data: campaigns};
      }));
  }

  public getCampaignAutocomplete(): Observable<string[]> {
    const url = 'Campaigns/Names';
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }

  public getCampaignsByProduct(productId: string): Observable<ICampaignDetails[]> {
    const url = `Campaigns/GetByProductId/${productId}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    }).pipe(
      map((campaigns: ICampaignDetails[]) => {
        return campaigns.map(campaign => ({
          ...campaign, startDate: new Date(campaign.startDate),
          endDate: new Date(campaign.endDate)
        }));
      }));
  }

  public matchCampaignToProductOrientation(productId: string, campaignIds: string[]): Observable<string[]> {
    const url = `Campaigns/MatchToProductOrientation/${productId}`;

    return this.apiGeneralService.sendRequest({
      method: RequestType.post,
      url,
      data: _.uniq(campaignIds)
    });
  }


}
