import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BACK_ACTION, DIALOG_DATA} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {IBackAction} from '../../../shared/interface/ui/dialog/IBackAction';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {CampaignsService} from '../../service/campaigns.service';
import {CustomIconName} from '../../../shared/enum/ui/icons/CustomIconName';
import {getSideDialogConfig} from '../../../shared/util/dialog/dialog-back-action.util';
import {CreativeDialogService} from '../../../creative/service/creative-dialog/creative-dialog.service';
import {faEdit, faEye, faTrash, faXmark} from '@fortawesome/pro-light-svg-icons';
import {CampaignDialogService} from '../../service/campaign-dialog/campaign-dialog.service';
import {CampaignEndpointsEnum} from '../../../shared/enum/Endpoints';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {Subscription} from 'rxjs';
import {IDialogConfig} from '../../../shared/interface/ui/dialog/IDialogConfig';
import {ICampaignDetails} from '../../interface/ICampaignDetails';

@Component({
  selector: 'app-campaign-preview',
  templateUrl: './campaign-preview.component.html',
  styleUrls: ['./campaign-preview.component.scss']
})
export class CampaignPreviewComponent implements OnInit, OnDestroy {
  public campaign: ICampaignDetails;
  public adUnitTypes: string[];

  public faEye = faEye;
  public faTrash = faTrash;
  public faEdit = faEdit;
  public faXMark = faXmark;

  public isLoading = false;
  private loadingEndpointNames: string[] = [CampaignEndpointsEnum.GET_CAMPAIGN_BY_INTERNAL_ID];
  private subscriptions: Subscription[] = [];

  constructor(public dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: { campaignId: string },
              @Inject(BACK_ACTION) public backAction: IBackAction, private campaignService: CampaignsService,
              private creativeDialogService: CreativeDialogService, private campaignDialogService: CampaignDialogService,
              private loadingService: LoadingService) {
  }

  public ngOnInit(): void {
    this.initCampaign();
    this.observeLoading();
  }

  public async initCampaign(): Promise<void> {
    this.campaign = await this.campaignService.getCampaignByInternalId(this.data.campaignId);
    this.adUnitTypes = this.campaign.adUnits?.map(adUnit => adUnit.type) || [];
  }

  private observeLoading(): void {
    const sub = this.loadingService.loadingEndpointsObs().subscribe((loadingEndpoints) => {
      this.isLoading = this.loadingService.areEndpointsLoading(this.loadingEndpointNames, loadingEndpoints);
    });
    this.subscriptions.push(sub);
  }

  public async onCreativeDeleted(creativeId: string): Promise<void> {
    this.campaign.creatives = this.campaign.creatives.filter(initialCreative => initialCreative.id !== creativeId);
  }

  public getBackDialogConfig(): IDialogConfig {
    return getSideDialogConfig(CampaignPreviewComponent, this.backAction, this.data);
  }

  public async onDeleteCampaignClicked(): Promise<void> {
    const dialogRef: DialogRef = await this.campaignDialogService.openCampaignDeleteDialog(this.campaign.name, this.campaign.id);
    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.dialogRef.close();
      }
    });
  }

  public async onEditCampaignClicked(): Promise<void> {
    await this.campaignDialogService.openCampaignEditDialog(this.campaign.id,
      getSideDialogConfig(CampaignPreviewComponent, this.backAction, this.data));
    this.dialogRef.close();
  }

  public onCloseClicked(): void {
    this.dialogRef.close();
  }

  public getCustomIconName(name: string): CustomIconName {
    return name as CustomIconName;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }

}
