<ng-container *ngIf="isDialog">
  <app-base-expanded-dialog [showConfirmPopup]="true" [backAction]="backAction" [closeDialogObservable]="closeDialogSubject.asObservable()">
    <ng-container *ngTemplateOutlet="productForm"></ng-container>
  </app-base-expanded-dialog>
</ng-container>

<ng-container *ngIf="!isDialog">
  <ng-container *ngTemplateOutlet="productForm"></ng-container>
</ng-container>

<ng-template #productForm>
  <ng-container *ngIf="!formInitialized">
    <app-spinner></app-spinner>
  </ng-container>
  <ng-container *ngIf="formInitialized">
<hr *ngIf="!isDialog" class="nav-separator">
<div class="form-page">
<div [ngClass]="isDialog ? 'no-padding-top' : ''" class="add-form-header">
  <h1 *ngIf="!isEditMode">{{'product.productForm.addNewProductHeader' | translate}}</h1>
  <h1 *ngIf="isEditMode">{{'product.productForm.editProductHeader' | translate}}</h1>
  <p *ngIf="!isEditMode">{{'product.productForm.addNewProductInfo' | translate}}</p>
  <p *ngIf="isEditMode">{{'product.productForm.editProductInfo' | translate}}</p>
</div>
<form [ngClass]="{'add-product-form-dialog' : isDialog, 'form-shadow-bottom--hidden': !hasScroll}" class="add-product-form form-shadow-bottom" [formGroup]="formGroup" #formScrollable>
  <div class="form-controls-group">
    <div class="form-controls-row">

      <div class="form-control">
        <div class="label">{{'product.productForm.productName' | translate}} <span class="color-error">*</span></div>
        <input name="productName" autocomplete="off" formControlName="name" type="text"
               [placeholder]="'product.productForm.productName' | translate">
        <span *ngIf="hasRequiredError(formGroup.controls.name)" class="error-message">
          {{'form.fieldRequired' | translate}}
        </span>
        <span *ngIf="hasError(formGroup.controls.name, 'productNameExists')" class="error-message">
          {{'product.productForm.productNameUniqMsg' | translate}}
        </span>
      </div>

      <div class="form-control">
        <div class="label align-center-gap-5">
          <span>{{'product.productForm.productId' | translate}} <span class="color-error">*</span></span>
          <fa-icon class="color-primary" [icon]="faCircleInfo" [appTooltip]="'product.productForm.productIdTooltip' | translate">
        </fa-icon></div>
        <input name="product" autocomplete="off" formControlName="boombitId" type="text"
               [placeholder]="'product.productForm.productId' | translate">
        <span *ngIf="hasRequiredError(formGroup.controls.boombitId)" class="error-message">
          {{'form.fieldRequired' | translate}}
        </span>
        <span *ngIf="hasError(formGroup.controls.boombitId, 'productBoombitIdExists')"
              class="error-message">{{'product.productForm.productIdUniqMsg' | translate}}
        </span>
      </div>

      <div class="form-control select">
        <div class="label">{{'product.productForm.tags' | translate}}</div>
        <app-multi-select-input formControlName="tags" [items]="tags"
                                 [label]="'product.productForm.tagsPlaceholder' | translate">

        </app-multi-select-input>
      </div>

      <input name="avatarFile" (change)="onAvatarSelected($event, logoInput)" #logoInput type="file" accept="image/png, image/gif, image/jpeg"/>
      <ng-container *ngIf="avatar.source === ''">
        <button type="button" (click)="logoInput.click()" mat-stroked-button color="primary">
          <fa-icon [icon]="faArrowDownToLine"></fa-icon>
          {{'product.productForm.addAvatarButton' | translate}}
        </button>
      </ng-container>
      <ng-container *ngIf="avatar.source !== ''">
        <img class="logo-image" alt="logo" [src]="avatar.source">
        <button type="button" (click)="logoInput.click()" mat-stroked-button color="primary">
          <fa-icon [icon]="faArrowRightArrowLeft"></fa-icon>
          {{'product.productForm.changeAvatarButton' | translate}}
        </button>
        <button type="button" (click)="deleteAvatar(logoInput)" mat-stroked-button color="primary">
          <fa-icon [icon]="faTrash"></fa-icon>
          {{'product.productForm.deleteAvatarButton' | translate}}
        </button>
      </ng-container>
    </div>
    <div class="form-controls-row mat-caption">
      <p><span class="color-error">*</span>{{'form.fieldRequiredDescription' | translate}}</p>
    </div>
  </div>
  <div class="form-controls-group">
    <div class="form-controls-row form-controls-row--align-center mat-caption">
      <p>{{'product.screenOrientation' | translate}}</p>
      <div class="align-center-gap-5">
        <app-toggle-slider formControlName="isLandscape">
        </app-toggle-slider>
        <span>{{'product.landscape' | translate}}</span>
      </div>
      <div class="align-center-gap-5">
        <app-toggle-slider formControlName="isPortrait">
        </app-toggle-slider>
        <span>{{'product.portrait' | translate}}</span>
      </div>
    </div>
  </div>

  <app-platforms-form [initialValue]="initialValue" [isEditMode]="isEditMode"></app-platforms-form>
</form>

<hr class="move-right">
<div class="move-right add-form-action-buttons">
  <button (click)="onCancelClicked()" mat-stroked-button color="primary">
    {{'button.cancel' | translate}}
  </button>
  <button [disabled]="formGroup.invalid || formGroup.pending" (click)="onSubmitClicked()" mat-flat-button color="primary"
  [appSaveChanges]="isEditMode" [productName]="formGroup.controls.name.value" (confirmed)="submitForm()">
    <fa-icon *ngIf="!formGroup.pending" [icon]="faFloppyDiskCircleArrowRight"></fa-icon>
    <ng-container *ngIf="!formGroup.pending">{{'product.productForm.saveProductButton' | translate}}</ng-container>
    <mat-spinner *ngIf="formGroup.pending" [diameter]="20" color="accent"></mat-spinner>
    <span *ngIf="formGroup.pending">{{'product.productForm.pendingButton' | translate}}</span>
  </button>
  <mat-spinner *ngIf="isLoading" [diameter]="30"></mat-spinner>
</div>
</div>
  </ng-container>
</ng-template>
