import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import {AbstractControl, FormControl, Validators} from '@angular/forms';
import {TableService} from '../../../service/table/table.service';
import {NgClasses} from '../../../type/NgClasses';
import {Filter} from '../../../interface/ui/my-table/filter.model';
import {Cell} from '../../../interface/ui/my-table/cell.model';
import {Row} from '../../../interface/ui/my-table/row.model';
import {Sort} from '../../../interface/ui/my-table/sort.model';
import {Header} from '../../../interface/ui/my-table/header.model';
import {TableQuery} from '../../../interface/ui/my-table/table-query.model';
import {SortService} from '../../../service/sort/sort.service';
import {PaginationService} from '../../../service/pagination/pagination.service';
import {HeaderEvent} from '../../../interface/ui/my-table/header-event.model';
import {CellEvent} from '../../../interface/ui/my-table/cell-event.model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [DatePipe]
})

export class TableComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('tableContent') public tableContent: ElementRef;

  // filters and pageIndex have to be ready before TableComponent creation
  @Input({required: true}) public rows: Row[];
  @Input() public rowsCount = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public sort: Sort<any>;
  @Input() public filters: Filter[] = [];
  @Input() public withFilters = true;
  @Input() public withPagination = true;
  @Input() public pageIndex = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input({required: true}) public headers: Header<any>[] = [];
  @Input() public tableCssClass: string[] = [];
  @Input() public customRowHeight: number;
  @Input() public dynamicRowHeight = false;
  @Input() public customHeaderHeight: number;
  @Input() public isLoading = false;
  @Input() public spinnerForInfinityScroll = false;
  @Input() public loadingText: string;
  @Input() public noMatchFound = false;
  @Input() public changeQuery$: Observable<Partial<TableQuery>>;
  @Input() public infinityScroll = false;

  @Output() public queryUpdated: EventEmitter<TableQuery> = new EventEmitter<TableQuery>();
  @Output() public cellEvent: EventEmitter<CellEvent> = new EventEmitter<CellEvent>();
  @Output() public headerEvent: EventEmitter<HeaderEvent> = new EventEmitter<HeaderEvent>();
  public rowHeight = 50;
  public headerHeight = 56;

  public headerNames: string[] = [];
  private tableHeight: number;
  public pageSize = 8;
  public pageControl: FormControl<number> = new FormControl<number>(1, [
    Validators.required,
    Validators.min(1),
    (control: AbstractControl) =>
      Validators.max(this.paginationService.getPagesCount(this.rowsCount, this.pageSize))(control)
  ]);
  public subscriptions: Subscription[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private tableService: TableService, private paginationService: PaginationService,
              private sortService: SortService) {
  }

  public ngOnInit(): void {
    this.initialize();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.tableService.shouldForceHeadersRerender(changes)) {
      this.forceHeadersRerender();
    }
  }

  private forceHeadersRerender(): void {
    this.headerNames = [];
    this.changeDetectorRef.detectChanges();
    this.headerNames = this.headers.map(column => column.name);
    this.changeDetectorRef.detectChanges();
  }

  private onResize(): void {
    if (window.innerWidth < 1800 && !this.customRowHeight) {
      this.rowHeight = 48;
      this.changeDetectorRef.detectChanges();
    }

    if (window.innerWidth < 1800 && !this.customHeaderHeight) {
      this.headerHeight = 56;
      this.changeDetectorRef.detectChanges();
    }
    this.handleTableResized();
  }

  private initialize(): void {
    this.headerNames = this.headers.map(column => column.name);
    if (this.pageIndex) {
      this.setValidPageIdx(this.pageIndex);
    }
    this.observePage();
  }

  private observePage(): void {
    this.pageControl.valueChanges.subscribe(() => this.pageChanged());
  }

  public ngAfterViewInit(): void {
    this.rowHeight = this.customRowHeight ? this.customRowHeight : this.rowHeight;
    this.headerHeight = this.customHeaderHeight ? this.customHeaderHeight : this.headerHeight;
    this.changeDetectorRef.detectChanges();
    this.onResize();
    this.observeQueryChange();
    if (this.tableContent && this.infinityScroll) {
      this.tableContent.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    }

  }

  private onScroll(): void {
    const element = this.tableContent?.nativeElement;
    if (this.scrolledToBottom(element)) {
      this.handleScrolledBottom();
    }
  }

  /**
   * Checks if the user has scrolled to the bottom of the list, considering potential floating-point imprecisions.
   * Tolerance is used to handle cases where scroll-related values (scrollTop, scrollHeight, clientHeight) may not be integers.
   * This allows for small differences in values to be considered as effectively equal.
   * @param element - The element to check scroll position.
   * @returns boolean - Returns true if scrolled to the bottom with tolerance, false otherwise.
   */
  private scrolledToBottom(element: HTMLElement): boolean {
    const tolerance = 1;
    return element.scrollTop !== 0 &&
      Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < tolerance &&
      !this.spinnerForInfinityScroll;
  }

  private handleScrolledBottom(): void {
    const maxPages = this.paginationService.getPagesCount(this.rowsCount, this.pageSize);
    if (this.pageControl.value + 1 <= maxPages) {
      this.pageControl.setValue(this.pageControl.value + 1, {emitEvent: false});
      this.emitQuery();
    }
  }

  private handleTableResized(): void {
    setTimeout(() => {
      this.tableHeight = this.tableContent.nativeElement.offsetHeight;
      this.setPageSize();
    }, 10);
  }

  private observeQueryChange(): void {
    const sub = this.changeQuery$?.subscribe((query: Partial<TableQuery>) => {
      this.handleQueryChange(query);
    });
    this.subscriptions.push(sub);
  }

  private handleQueryChange(query: Partial<TableQuery>): void {
    if (this.isPageChanged(query)) {
      this.pageChangedParent(query.page.index);
    }
    if (query.filters) {
      this.filters = query.filters;
    }
    if (this.isSortChanged(query)) {
      this.setSort(query.sort);
    }
    this.emitQuery();
    this.changeDetectorRef.detectChanges();
  }

  private isPageChanged(query: Partial<TableQuery>): boolean {
    return query.page?.index !== undefined && query.page?.index !== null;
  }

  private isSortChanged(query: Partial<TableQuery>): boolean {
    return !!query.sort || query.sort === null;
  }

  private setPageSize(): void {
    if (this.infinityScroll) {
      this.pageSize = this.tableService.getPageSizeForScroll();
    } else {
      this.pageSize = this.tableService.getPageSize(this.tableContent.nativeElement.offsetHeight, this.rowHeight);
    }
    this.emitQuery();
  }

  private emitQuery(): void {
    this.queryUpdated.emit({
      page: {index: this.pageControl.value, size: this.pageSize}, filters: this.filters, sort: this.sort
    });
  }

  /** Set page to 1 and emit query. For infinity scroll, scroll to top */
  private firstPageAndEmitQuery(): void {
    this.pageControl.setValue(1, {emitEvent: false});
    if (this.infinityScroll) {
      this.scrollTop();

    }
    this.emitQuery();
  }

  private pageChanged(): void {
    if (this.pageControl.invalid) {
      this.setValidPageIdx(this.pageControl.value);
    }
    this.emitQuery();
  }

  /** Set page to parent value and scroll to top if needed */
  private pageChangedParent(value: number): void {
    if(this.scrollTopOnParentQueryChange(value)) {
      this.scrollTop();
    }
    this.setValidPageIdx(value);
  }

  private scrollTopOnParentQueryChange(pageNumber: number): boolean {
    return pageNumber === 1 && this.infinityScroll;
  }

  private scrollTop(): void {
    this.tableContent.nativeElement.scrollTop = 0;
  }

  private setValidPageIdx(value: number): void {
    const validValue = this.paginationService.getValidPageIndex(
      {index: value, size: this.pageSize}, this.rowsCount);
    this.pageControl.setValue(validValue, {emitEvent: false});
  }

  public isNextDisabled(): boolean {
    return this.paginationService.nextDisabled({index: this.pageControl.value, size: this.pageSize}, this.rowsCount);
  }

  public isPreviousDisabled(): boolean {
    return this.paginationService.previousDisabled(this.pageControl.value);
  }

  public getPageCount(): number {
    return this.paginationService.getPagesCount(this.rowsCount, this.pageSize);
  }

  public onCellEvent(event: CellEvent, columnName: string, row: Row, rowIdx: number): void {
    this.cellEvent.emit({...event, columnName, item: row, rowIdx});
  }

  public onFiltersUpdated(filters: Filter[]): void {
    this.filters = filters;
    this.firstPageAndEmitQuery();
  }

  public getCssClassTd(cell: Cell): NgClasses {
    return this.tableService.getCssClassTd(cell);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getCssClassTh(header: Header<any>): NgClasses {
    return this.tableService.getCssClassTh(header);
  }

  public getCssClassTr(row: Row): NgClasses {
    return this.tableService.getCssClassTr(row);
  }

  public onHeaderEvent(event: HeaderEvent): void {
    if (event.type === 'sort') {
      this.onSortUpdated(event);
    } else {
      this.headerEvent.emit(event);
    }
  }

  private onSortUpdated(event: HeaderEvent): void {
    this.sort = this.sortService.getSort(this.sort, event);
    this.sortService.updateSortHeaders(this.headers, this.sort);
    this.firstPageAndEmitQuery();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setSort(sort: Sort<any>): void {
    this.sort = sort;
    this.sortService.updateSortHeaders(this.headers, this.sort);
  }

  public getTableCssClass(): NgClasses {
    const cssClass: NgClasses = {};
    this.tableCssClass.forEach(el => cssClass[el] = true);
    return cssClass;
  }

  public trackBy(index: number, item: Row): string {
    return item.trackById;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub?.unsubscribe());

    if (this.tableContent) {
      this.tableContent.nativeElement.removeEventListener('scroll', this.onScroll.bind(this));
    }
  }
}
