import {IResolution} from '../interface/IResolution';
import {RESPONSIVE} from './ratio.util';

/**
 * Function to get the label for a resolution.
 * This function generates a label for the resolution of an asset, including the width and height in pixels.
 * If the resolution is responsive, it returns `responsive` instead of the width and height.
 * @param resolution The resolution of the asset.
 * @param ratio The aspect ratio of the asset.
 * @returns The label for the resolution.
 */
export function getResolutionLabel(resolution: IResolution, ratio: string): string {
  if (!resolution) {
    return '';
  }
  return ratio === RESPONSIVE ? RESPONSIVE : resolution?.width + 'x' + resolution?.height + ' px';
}
