import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {faXmark} from '@fortawesome/pro-light-svg-icons';
import {Subscription} from 'rxjs';
import {Filter} from '../../../interface/ui/my-table/filter.model';
import {FilterHandler} from '../../../interface/ui/my-table/filter-handler.interface';

@Component({
  selector: 'app-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss']
})
export class TableFiltersComponent implements OnDestroy, AfterViewInit, OnChanges {
  @Input({required: true}) filters: Filter[] = [];
  @Output() public filtersUpdated: EventEmitter<Filter[]> = new EventEmitter<Filter[]>();
  @ViewChild('filters', {read: ViewContainerRef}) container: ViewContainerRef;
  public faXMark = faXmark;
  private sub: Subscription[] = [];

  private filterComponents: ComponentRef<FilterHandler>[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  public ngAfterViewInit(): void {
    this.createFilters();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters) {
      const currentValue = changes.filters.currentValue;
      const previousValue = changes.filters.previousValue;
      if (currentValue !== previousValue && currentValue?.length > 0) {
        if (this.container) { // this if is needed in case ngOnChanges runs before ngAfterViewInit
          this.container.clear();
          this.filterComponents = [];
          this.createFilters();
        }
      }
    }
  }

  public createFilters(): void {
    this.filters.forEach((filter: Filter) => {
      if (filter?.component) {
        this.createComponent(filter.component, filter);
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createComponent(type: Type<any>, filter: Filter): void {
    const componentRef: ComponentRef<FilterHandler> = this.container.createComponent(type);
    componentRef.instance.filter = filter;
    this.sub.push(componentRef.instance.filterChanged?.subscribe(() =>{
      this.filtersUpdated.emit(this.filters);}));
    this.filterComponents.push(componentRef);
  }

  public onClearClicked(): void {
    this.filterComponents.forEach((filterComponent) => filterComponent.instance.clear());
    this.changeDetectorRef.detectChanges();
    this.filtersUpdated.emit(this.filters);
  }

  public ngOnDestroy(): void {
    this.container.clear();
    this.sub?.forEach((sub: Subscription) => sub?.unsubscribe());
  }

  protected readonly faXmark = faXmark;
}
