<ng-container *ngIf="isDialog">
  <app-base-expanded-dialog [showConfirmPopup]="true" [backAction]="backAction" [closeDialogObservable]="closeDialogSubject.asObservable()">
    <ng-container *ngTemplateOutlet="campaignForm"></ng-container>
  </app-base-expanded-dialog>
</ng-container>

<ng-container *ngIf="!isDialog">
  <ng-container *ngTemplateOutlet="campaignForm"></ng-container>
</ng-container>

<ng-template #campaignForm>
  <div class="form-page">
    <div [ngClass]="isDialog ? 'no-padding-top' : ''" class="add-form-header">
      <h1 class="h1-semibold" *ngIf="!isEditMode">{{'campaign.campaignForm.newCampaignFormHeader' | translate}}</h1>
      <h1 *ngIf="isEditMode">{{'campaign.campaignForm.editCampaignFormHeader' | translate}}</h1>
      <p class="translate-wrapper--strong" *ngIf="isEditMode" [innerHTML]="'campaign.campaignForm.editCampaignFormHeaderProductInfo' |
      translate: {name: this.formGroupAccessor.productValue.name}"></p>
    </div>
    <app-spinner *ngIf="isFormLoading"></app-spinner>
    <div [hidden]="!showFullForm" class="form-page-content">
      <div class="d-flex flex-column h-100 overflow-y-hidden" >
        <div [ngClass]="{'tabs-wrapper--background': !isDialog}">
        <app-steps (stepChanged)="onStepSelected($event)" [activeStep]="activeStep"
                   [steps]="steps"></app-steps>
        </div>
        <hr class="margin-0">
        <div class="flex-grow-1 overflow-y-auto">
          <form class="h-100" [formGroup]="formGroup">
            <app-source-select [label]="'product.choosePromotedProduct' | translate"
                                          [selectedProduct]="formGroupAccessor.productValue"
                                          [hidden]="!(activeStep?.name === CampaignFormSteps.CHOOSE_PROMOTED_PRODUCT)"
                                          (productSelected)="onProductSelected($event)"></app-source-select>
            <app-campaign-info-form [isEditMode]="isEditMode" [defaultCampaign]="defaultCampaign"
                                    [isDefaultRequired]="isDefaultCampaignRequiredTrue()" [initialValue]="initialValue"
                                    [hidden]="!(activeStep?.name === CampaignFormSteps.CREATE_CAMPAIGN)"></app-campaign-info-form>
            <app-campaign-creatives-list
              [hidden]="!(activeStep?.name === CampaignFormSteps.CHOOSE_CREATIVES)"></app-campaign-creatives-list>
            <app-campaign-summary (goToStepClicked)="onGoToStepClicked($event)"
                                  [hidden]="!(activeStep?.name === CampaignFormSteps.SUMMARY)"></app-campaign-summary>
          </form>
        </div>
      </div>
    </div>
    <div [hidden]="showFullForm" class="form-page-content">
      <form [formGroup]="formGroup">
        <app-campaign-summary [showHeading]="false" (goToStepClicked)="onGoToStepClicked($event)"></app-campaign-summary>
      </form>
    </div>
    <div class="add-form-action-buttons add-form-action-buttons--background">
      <button *ngIf="!isCancelButtonVisible()" (click)="onPreviousStepClicked()" mat-stroked-button color="primary">
        <fa-icon [icon]="faArrowLeft"></fa-icon>
        {{'button.previousStep' | translate}}
      </button>
      <button *ngIf="isCancelButtonVisible()" (click)="onCancelClicked()" mat-stroked-button color="primary">
        {{'button.cancel' | translate}}
      </button>
      <button *ngIf="isNextStepButtonVisible()" [disabled]="!isStepValid()" (click)="onNextStepClicked()"
              mat-flat-button
              color="primary">
        <fa-icon [icon]="faFloppyDiskCircleArrowRight"></fa-icon>
        {{'button.saveAndNextStep' | translate}}
      </button>
      <button *ngIf="isSubmitButtonVisible()" [disabled]="!isFormValid()" (click)="onSubmitClicked()" mat-flat-button
              color="primary">
        <fa-icon [icon]="faFloppyDiskCircleArrowRight"></fa-icon>
        {{'campaign.campaignForm.saveCampaign' | translate}}
      </button>
      <button *ngIf="isSubmitEditButtonVisible()" [disabled]="!isFormValid()" (click)="onSubmitEditClicked()"
              mat-flat-button
              color="primary">
        <fa-icon [icon]="faFloppyDiskCircleArrowRight"></fa-icon>
        {{'button.saveChanges' | translate}}
      </button>

          <mat-spinner *ngIf="isLoading" [diameter]="30"></mat-spinner>
    </div>
  </div>
</ng-template>
