import {ConnectedPosition} from '@angular/cdk/overlay';
import {Placement} from '../../enum/ui/tooltip/Placement';

function getRightPosition(): ConnectedPosition[] {
  return [{originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center', offsetX: 13}];
}

function getTopPosition(): ConnectedPosition[] {
  return [{originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom', offsetY: -20}];
}

function getBottomPosition(offset?: {offsetX: number, offsetY: number}): ConnectedPosition[] {
  return [{originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top', offsetY: offset.offsetY ? offset.offsetY : 13}];
}

export function getPosition(position: Placement, offset?: {offsetX: number, offsetY: number}): ConnectedPosition[] {
  switch (position) {
    case Placement.RIGHT:
      return getRightPosition();
    case Placement.TOP:
      return getTopPosition();
    case Placement.BOTTOM:
      return getBottomPosition(offset);
    default:
      return getTopPosition();
  }
}
