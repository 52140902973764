import {Injectable, SimpleChanges} from '@angular/core';
import {ScreenSizeService} from '../screen-size/screen-size.service';
import {ScreenWidth} from '../../enum/ScreenWidth';
import {NgClasses} from '../../type/NgClasses';
import {Row} from '../../interface/ui/my-table/row.model';
import {Header} from '../../interface/ui/my-table/header.model';
import {Cell} from '../../interface/ui/my-table/cell.model';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  private readonly minPageSize = 3;
  private readonly scrollPageSize = 20;

  // below values must match corresponding values in scss files
  private readonly rowHeight: {type: ScreenWidth, height: number}[] = [
    {
      type: ScreenWidth.DEFAULT,
      height: 50
    },
    {
      type: ScreenWidth.LAPTOP,
      height: 48
    }
  ];
  private readonly tableHeader = 56;
  private readonly tablePadding = 20;

  constructor(private screenSizeService: ScreenSizeService) { }

  public getPageSize(tableHeight: number, customHeight?: number): number {
    const tableContentHeight = tableHeight - this.tableHeader - this.tablePadding;
    const rowHeight = customHeight ? customHeight : this.getRowHeight();
    const pageSize = Math.floor(tableContentHeight / rowHeight);
    return Math.max(this.minPageSize, pageSize);
  }

  public getPageSizeForScroll(): number {
    return this.scrollPageSize;
  }

  public getRowHeight(): number {
    const rowHeight = this.rowHeight.find(el => el.type === this.screenSizeService.getScreenWidthType());
    return rowHeight.height;
  }

  public getCssClassTd(cell: Cell): NgClasses {
    if (cell?.cssClassTd) {
      const array = cell.cssClassTd;
      return array.reduce((obj: NgClasses, item) => {
        obj[item] = true;
        return obj;
      }, {});
    } else {
      return {};
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getCssClassTh(header: Header<any>): NgClasses {
    if (header?.cssClassTh) {
      const array = header.cssClassTh;
      return array.reduce((obj: NgClasses, item) => {
        obj[item] = true;
        return obj;
      }, {});
    } else {
      return {};
    }
  }

  public getCssClassTr(row: Row): NgClasses {
    if (row?.cssClassTr) {
      const array = row.cssClassTr;
      return array.reduce((obj: NgClasses, item) => {
        obj[item] = true;
        return obj;
      }, {});
    } else {
      return {};
    }
  }

  public shouldForceHeadersRerender(changes: SimpleChanges ): boolean {
    if(!changes.headers) {
      return false;
    }
    const currentValue = changes.headers.currentValue;
    const previousValue = changes.headers.previousValue;
    return !changes.headers.isFirstChange() && currentValue !== previousValue && currentValue?.length > 0;
  }
}
