import {IAssetFormControl} from '../interface/ICreativeForm';
import {IAdUnitFormat} from '../../ad-unit/interface/IAdUnitFormat';
import {Status} from '../../shared/enum/Status';
import {ICreativeDetails} from '../interface/ICreativeDetails';

export function matchesAllRequirements(assetControl: IAssetFormControl): boolean {
  return matchesFileFormat(assetControl) && matchesResolution(assetControl) && matchesRatio(assetControl)
    && matchesMaxSize(assetControl) && matchesMaxDuration(assetControl);
}

export function matchesResolution(assetControl: IAssetFormControl): boolean {
  if (!assetControl.asset) {
    return false;
  } else {
    return matchesResolutionAndRatio(assetControl);
  }
}

function matchesResolutionAndRatio(assetControl: IAssetFormControl): boolean {
  if (assetControl.adUnitFormat.ratio) {
      return assetControl.adUnitFormat.ratio === assetControl.asset.ratio &&
        assetControl.adUnitFormat.resolution?.width >= assetControl.asset.resolution?.width &&
        assetControl.adUnitFormat.resolution?.height >= assetControl.asset.resolution?.height;
  } else {
    return assetControl.adUnitFormat.resolution?.width >= assetControl.asset.resolution?.width &&
    assetControl.adUnitFormat.resolution?.height >= assetControl.asset.resolution?.height;
  }
}

export function matchesRatio(assetControl: IAssetFormControl): boolean {
  return assetControl.adUnitFormat.ratio ? assetControl.adUnitFormat.ratio === assetControl.asset?.ratio : true;
}

export function hasAssetRequiredError(assetControl: IAssetFormControl): boolean {
  return assetControl.adUnitFormat.required && !assetControl.asset;
}

export function matchesFileFormat(assetControl: IAssetFormControl): boolean {
  if (!assetControl.asset) {
    return false;
  }
  return assetControl.adUnitFormat.formats.includes(assetControl.asset.format);
}

export function matchesMaxSize(assetControl: IAssetFormControl): boolean {
  if (!assetControl.asset) {
    return false;
  }
  return assetControl.asset.size <= assetControl.adUnitFormat.maxSize;
}

export function matchesMaxDuration(assetControl: IAssetFormControl): boolean {
  if (!assetControl.asset) {
    return false;
  }
  return assetControl.asset.duration <= assetControl.adUnitFormat.maxDuration;
}


export function getAssetControl(adUnitFormat: IAdUnitFormat, adUnitId: string): IAssetFormControl {
  return {status: null, adUnitFormat, asset: null, adUnitId};
}

export function getAssetControlForEdit(adUnitFormat: IAdUnitFormat, adUnitId: string, creative: ICreativeDetails): IAssetFormControl {
  for (const assetFormatPair of creative.matchedAssetsToFormats) {
    if (assetFormatPair.adUnitFormatId === adUnitFormat.id) {
      const asset = creative.assets.find(el => el.id === assetFormatPair.assetId);
      return {status: Status.SUCCESS, adUnitFormat, asset, adUnitId};
    }
  }
  return {status: null, adUnitFormat, asset: null, adUnitId};
}

export function setAssetStatus(assetControl: IAssetFormControl): void {
  assetControl.status = matchesAllRequirements(assetControl)
  && !assetControl.nameExistsError && !assetControl.adUnitFormatMatchError ? Status.SUCCESS : Status.ERROR;
}

export function isAssetListValid(assetsControls: IAssetFormControl[]): boolean {
  return assetsControls.length > 0 && assetsControls.some(el => el.asset) &&
    !assetsControls.some(el => el.status === Status.ERROR) &&
    !assetsControls.some(el => el.nameExistsError) &&
    !assetsControls.some(el => el.adUnitFormatMatchError) &&
    allRequiredAssetsPresent(assetsControls);
}

function allRequiredAssetsPresent(assetsControls: IAssetFormControl[]): boolean {
  return assetsControls.filter(assetControl => assetControl.adUnitFormat.required)
    .every(assetControl => !hasAssetRequiredError(assetControl));
}
