<ng-container *ngIf="isDialog">
  <app-base-expanded-dialog [showConfirmPopup]="showFullForm" [backAction]="backAction" [closeDialogObservable]="closeDialogSubject.asObservable()">
    <ng-container *ngTemplateOutlet="waterfallForm"></ng-container>
  </app-base-expanded-dialog>
</ng-container>

<ng-container *ngIf="!isDialog">
  <ng-container *ngTemplateOutlet="waterfallForm"></ng-container>
</ng-container>


<ng-template #waterfallForm>
<div class="form-page">
  <div [ngClass]="isDialog ? 'no-padding-top' : ''" class="add-form-header">
    <h1 *ngIf="!isEditMode" [ngClass]="{'h1-semibold': !isDialog}">{{'waterfall.waterfallForm.newWaterfallFormHeader' | translate}}</h1>
    <h1 *ngIf="isEditMode">{{'waterfall.waterfallForm.editWaterfallFormHeader' | translate}}</h1>
    <p class="translate-wrapper--strong" *ngIf="isEditMode && formGroup" [innerHTML]="'waterfall.waterfallForm.editWaterfallFormHeaderProductInfo' |
      translate: {name: this.formGroup.value.promotingProduct?.name}" ></p>
  </div>
  <app-spinner *ngIf="isFormLoading"></app-spinner>
  <ng-container *ngIf="formGroup">
  <div [hidden]="!showFullForm"  class="form-page-content">
    <div class="d-flex flex-column h-100 overflow-y-hidden">
      <div [ngClass]="{'tabs-wrapper--background': !isDialog}">
    <app-steps (stepChanged)="onStepSelected($event)" [activeStep]="activeStep" [steps]="steps"></app-steps>
      </div>
    <hr class="margin-0">
    <div class="flex-grow-1 overflow-y-auto">
      <form [formGroup]="formGroup" class="h-100">
        <app-source-select [label]="'waterfall.waterfallForm.tabs.choosePromotingProduct' | translate"
                                      [selectedProduct]="formGroup?.value?.promotingProduct"
                                      [hidden]="!(activeStep?.name === WaterfallFormSteps.CHOOSE_PROMOTING_PRODUCT)"
                                      (productSelected)="onPromotingProductSelected($event)"></app-source-select>
        <app-waterfall-info-form [initialValue]="initialValue" [hidden]="!(activeStep?.name === WaterfallFormSteps.CREATE_WATERFALL)">
        </app-waterfall-info-form>
        <app-waterfall-choose-products [initialValue]="initialValue" [selectedProduct]="formGroup.value.promotingProduct"
                                       [refreshProductsCampaigns]="productsCampaignsRefresh"
                                       (productsCampaignsChanged)="onPromotedProductsCampaignsChange($event)"
                                       [hidden]="!(activeStep?.name === WaterfallFormSteps.CHOOSE_PRODUCTS)">
        </app-waterfall-choose-products>
        <app-waterfall-summary (goToStepClicked)="onGoToStepClicked($event)" [hidden]="!(activeStep?.name === WaterfallFormSteps.SUMMARY)">
        </app-waterfall-summary>
      </form>
    </div>
  </div>
  </div>
  <div [hidden]="showFullForm" class="form-page-content">
    <form [formGroup]="formGroup">
      <app-waterfall-summary [isInlineEditActive]="false" [showHeading]="false"  (goToStepClicked)="onGoToStepClicked($event)">
      </app-waterfall-summary>
    </form>
  </div>
  <div *ngIf="showFullForm" class="add-form-action-buttons add-form-action-buttons--background">
    <ng-container *ngIf="isEditMode">
      <button (click)="onCancelClicked()" mat-stroked-button color="primary">
        {{'button.cancel' | translate}}
      </button>
      <button *ngIf="!isFirstStep()" (click)="onPreviousStepClicked()" mat-stroked-button color="primary">
        <fa-icon [icon]="faArrowLeft"></fa-icon>
        {{'button.previousStep' | translate}}
      </button>
      <button *ngIf="isNextStepButtonVisible()" [disabled]="!isStepValid()" (click)="onNextStepClicked()" mat-stroked-button
              color="primary">
        <fa-icon [icon]="faFloppyDiskCircleArrowRight"></fa-icon>
        {{'button.saveAndNextStep' | translate}}
      </button>
      <button [disabled]="!isFormValid()" (click)="onSubmitEditClicked()" mat-flat-button
              color="primary">
        <fa-icon [icon]="faFloppyDiskCircleArrowRight"></fa-icon>
        {{'button.finishAndSave' | translate}}
      </button>
    </ng-container>

    <ng-container *ngIf="!isEditMode">
      <button (click)="onCancelClicked()" mat-stroked-button color="primary">
        {{'button.cancel' | translate}}
      </button>
      <button *ngIf="!isFirstStep()" (click)="onPreviousStepClicked()" mat-stroked-button color="primary">
        <fa-icon [icon]="faArrowLeft"></fa-icon>
        {{'button.previousStep' | translate}}
      </button>
      <button *ngIf="isNextStepButtonVisible()" [disabled]="!isStepValid()" (click)="onNextStepClicked()" mat-flat-button
              color="primary">
        <fa-icon [icon]="faFloppyDiskCircleArrowRight"></fa-icon>
        {{'button.saveAndNextStep' | translate}}
      </button>
      <button *ngIf="isSubmitButtonVisible()" [disabled]="!isFormValid()" (click)="onSubmitClicked()" mat-flat-button
              color="primary">
        <fa-icon [icon]="faFloppyDiskCircleArrowRight"></fa-icon>
        {{'waterfall.waterfallForm.saveWaterfall' | translate}}
      </button>
    </ng-container>

    <mat-spinner *ngIf="isLoading" [diameter]="30"></mat-spinner>
  </div>
  </ng-container>
</div>
</ng-template>
