<div class="table-actions">
  <fa-icon
    [appIconTooltip]="action.iconFa"
    *ngFor="let action of cell.actions"
    (click)="onIconClicked(action)"
    class="table-action-icon"
    [icon]="action.showSpinner ? faCircleNotch : action.iconFa"
    [spin]="action.isRegenerating || action.showSpinner"
    [ngClass]="{ 'disabled': action.isDisabled }">
  </fa-icon>
</div>
