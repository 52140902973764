import {Component, EventEmitter, Input, Output} from '@angular/core';
import {faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import {NgClasses} from '../../../type/NgClasses';

@Component({
  selector: 'app-base-select-button',
  templateUrl: './base-select-button.component.html',
  styleUrls: ['./base-select-button.component.scss']
})
export class BaseSelectButtonComponent {
  @Input() isDropdownOpen: boolean;
  @Input() isArrowGrayedOut = false;
  @Input() dropdown;
  @Input() preventDropdownOpen = false;
  @Input() isTableFilter = false;
  @Input() isFilterSelected = false;
  @Input() disabled = false;
  @Input() errorDisplayed = false;
  @Input() cssClasses: string[] = [];

  @Output() dropdownToggled = new EventEmitter();
  @Output() clicked = new EventEmitter();

  public faAngleUp = faAngleUp;
  public faAngleDown = faAngleDown;

  constructor() { }

  public onDropdownToggled(event: boolean): void {
    this.dropdownToggled.emit(event);
  }

  public getCssClasses(): NgClasses {
    const classes: NgClasses =  {
      focus: this.isDropdownOpen,
      invalid: this.errorDisplayed,
      'table-filter-button': this.isTableFilter,
      'table-filter-button--selected': this.isFilterSelected,
      'select-form-button ': !this.isTableFilter
    };
    if (this.cssClasses?.length > 0) {
      this.cssClasses.forEach((cssClass: string) => {
        classes[cssClass] = true;
      });
    }
    return classes;
  }

}
