import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {IProduct} from '../../product/interface/IProduct';
import {ICampaignFormControlsConfig} from '../interface/ICampaignFormControlsConfig';
import {ICreative} from '../../creative/interface/ICreative';

export class CampaignFormAccessor {

  constructor(private _formGroup: UntypedFormGroup) {
  }

  public get formGroup(): UntypedFormGroup {
    return this._formGroup;
  }

  public get name(): AbstractControl {
    return this.getControl('name');
  }

  public get startDate(): AbstractControl {
    return this.getControl('startDate');
  }

  public get endDate(): AbstractControl {
    return this.getControl('endDate');
  }

  public get product(): AbstractControl {
    return this.getControl('product');
  }

  public get productValue(): IProduct {
    return this.product.value as IProduct;
  }

  public get creatives(): AbstractControl {
    return this.getControl('creatives');
  }

  public get creativesValues(): ICreative[] {
    return this.creatives.value as ICreative[];
  }

  public get isDefault(): AbstractControl {
    return this.getControl('isDefault');
  }

  public getControl(key: keyof ICampaignFormControlsConfig): AbstractControl {
    return this._formGroup.get(key);
  }
}
