import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ITableCol} from '../../../interface/ui/table/ITableCol';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslateWrapperService {

  constructor(private translateService: TranslateService) { }

  public async translateTableHeader(translationObjectPrefix: string, columns: ITableCol<any>[]): Promise<void>{
    for (const column of columns) {
      column.headerName = await firstValueFrom(this.translateService.get(translationObjectPrefix + '.' +
        translationObjectPrefix + 'TableHeaders.' + column.name));
    }
  }
}
