<button type="button" [ngClass]="{'focus': isDropdownOpen, 'invalid': hasAnyError(control) || showError}"
        (isOpen)="onDropdownToggled($event)" mat-stroked-button class="select-button button-narrow select-form-button"
        [dropdownTriggerFor]="dropdown" [preventClick]="preventClick" [disabled]="isDisabled">
    <span *ngIf="!control.value" class=" mat-caption select-placeholder">
    {{label}}
    </span>
  <span class="mat-caption" *ngIf="control.value">
    {{control.value.label}}
  </span>
  <fa-icon [ngClass]="!control.value && !isDropdownOpen ?
               'dropdown-icon-light' : ''" class="dropdown-icon"
           [icon]="isDropdownOpen ? faAngleUp : faAngleDown"></fa-icon>
</button>

<app-dropdown #dropdown>
  <div class="options-wrapper">
    <div [ngClass]="item.name === selectedItem?.name ? 'text-14-txt-2-semi-bold' : ''" (click)="onItemClicked(item)"
         class="mat-caption option" *ngFor="let item of items">
      {{item.label}}
      <fa-icon *ngIf="item.name === selectedItem?.name" class="check-icon" [icon]="faCheck"></fa-icon>
    </div>
  </div>
</app-dropdown>
