import {Injectable} from '@angular/core';
import {ICreative} from 'src/app/creative/interface/ICreative';
import {ICreativeRaw} from 'src/app/creative/interface/ICreativeRaw';
import {ApiCreativeService} from './api-creative.service';
import {firstValueFrom, Subject} from 'rxjs';
import {ProductsService} from '../../product/service/product/products.service';
import {AssetService} from '../../asset/service/asset.service';
import {CampaignsService} from '../../campaign/service/campaigns.service';
import {ICreativeDetails} from '../interface/ICreativeDetails';
import {ApiErrorService} from '../../shared/service/api/api-error.service';
import {CreativeList} from '../interface/CreativeList';
import {CreativeQueryRaw} from '../interface/creative-query-raw';
import { ICreativeChanges } from '../interface/CreativeChange';


@Injectable({
  providedIn: 'root'
})
export class CreativeService {
  public creativeChangedSubject: Subject<void | ICreativeChanges> = new Subject();

  constructor(
    private apiCreativeService: ApiCreativeService, private productService: ProductsService, private assetsService: AssetService,
    private campaignService: CampaignsService,
    private apiErrorService: ApiErrorService
  ) {
  }

  public async getProductCreatives(productId: string): Promise<ICreativeDetails[]> {
    try {
      return await firstValueFrom(this.apiCreativeService.getProductCreatives(productId));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'creative.getProductCreativesError');
      throw error;
    }
  }

  public async getCreativesAutocomplete(): Promise<string[]> {
    try {
      return await firstValueFrom(this.apiCreativeService.getCreativeNames());
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'creative.getProductCreativesError');
      throw error;
    }
  }

  public async getCreativesPaginated(query: CreativeQueryRaw): Promise<CreativeList> {
    try {
      return await firstValueFrom(this.apiCreativeService.getCreativesPaginated(query));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'creative.getCreativesError');
      throw error;
    }
  }

  public async getCreative(creativeId: string): Promise<ICreativeDetails> {
    try {
      return await firstValueFrom(this.apiCreativeService.getCreative(creativeId));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'creative.getCreativeError');
      throw error;
    }
  }

  public async addCreative(creative: ICreativeRaw): Promise<void> {
    try {
      await firstValueFrom(this.apiCreativeService.createCreative(creative));
      this.creativeChangedSubject.next();
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'creative.creativeCreationErrorMessage');
      throw error;
    }
  }

  public async editCreative(creative: ICreativeRaw): Promise<void> {
    try {
      await firstValueFrom(this.apiCreativeService.editCreative(creative));
      this.creativeChangedSubject.next({creativeEdited: creative});
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'creative.creativeEditErrorMessage');
      throw error;
    }
  }

  public async deleteCreative(id: string): Promise<void> {
    try {
      await firstValueFrom(this.apiCreativeService.deleteCreative(id));
      this.creativeChangedSubject.next();
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'creative.deleteCreativeError');
      throw error;
    }
  }

  public async validateCreativeName(name: string): Promise<boolean> {
    try {
      return await firstValueFrom(this.apiCreativeService.validateCreativeName(name));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'creative.validateCreativeNameError');
      throw error;
    }
  }
}
