import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MetaDataService} from '../../../shared/service/file-utils/meta-data.service';
import {IAsset} from '../../interface/IAsset';
import {firstValueFrom} from 'rxjs';
import {HtmlFormatSimple} from '../../enum/HtmlFormatSimple';
import {FileFormatSimple} from '../../type/FileFormatSimple';
import {ImageFormatSimple} from '../../enum/ImageFormatSimple';
import {VideoFormatSimple} from '../../enum/VideoFormatSimple';
import {SnackbarService} from '../../../shared/service/snackbar/snackbar.service';
import {TranslateService} from '@ngx-translate/core';

/**
 * Service for managing asset links.
 * This service provides methods to open asset links in new tabs, including handling different asset formats such as images, videos, and HTML files.
 */
@Injectable({
  providedIn: 'root'
})
export class AssetLinkService {

  /**
   * Constructor for AssetLinkService.
   * @param metaDataService A service for handling metadata related to files.
   * @param httpClient A service for making HTTP requests.
   * @param snackBarService A service for displaying snackbars.
   * @param translateService Service for language translation.
   */
  constructor(private metaDataService: MetaDataService, private httpClient: HttpClient,
              private snackBarService: SnackbarService, private translateService: TranslateService) {
  }

  /**
   * Generates HTML content for an image asset.
   * @param src The source URL of the image.
   * @returns The HTML content for displaying the image.
   */
  private static getContentForImage(src: string): string {
    return `<img src="${src}" alt="asset_preview">`;
  }

  /**
   * Generates HTML content for a video asset.
   * @param src The source URL of the video.
   * @param format The format of the video.
   * @returns The HTML content for displaying the video.
   */
  private static getContentForVideo(src: string, format: string): string {
    return `<video controls>
                <source src="${src}" type="video/${format}">
                Your browser does not support the video tag.
            </video>`;
  }

  /**
   * Opens multimedia content in a new tab.
   * @param content The HTML content to display in the new tab.
   */
  private static openMultimediaInNewTab(content: string): void {
    const newTab = window.open('', '_blank');
    newTab.document.write(`
      <html lang="en">
        <head>
        <title>Preview</title>
        </head>
        <body style="display: flex;justify-content: center;align-items: center">` +
      content +
      `</body>
      </html>`);
  }

  /**
   * Opens an asset in a new tab.
   * @param asset The asset to open.
   */
  public async openAssetInNewTab(asset: { name: string, format: FileFormatSimple, url?: string }): Promise<void> {
    try {
      window.open(asset.url, '_blank').focus();
    } catch (e) {
      await this.showUnexpectedErrorMsg();
    }
  }

  /** Displays an unexpected error message in a snackbar. */
  private async showUnexpectedErrorMsg(): Promise<void> {
    const msg = await firstValueFrom(this.translateService.get('error.unexpectedErrorMsg'));
    this.snackBarService.openErrorSnackbar(msg);
  }

  /**
   * Opens an asset file from a file input in a new tab.
   * @param asset The asset metadata.
   * @param file The file to open.
   */
  public async openAssetFromFileInNewTab(asset: IAsset, file: File): Promise<void> {
    if (asset.format === HtmlFormatSimple.html) {
      this.openHtmlFileInNewTab(file);
    } else if (this.isImageOrVideo(asset.format)) {
      const src = await this.metaDataService.getSrcStringFromImage(file);
      this.openImageOrVideo(asset.format, src);
    }
  }

  /**
   * Checks if a file format is an image.
   * @param format The file format.
   * @returns A boolean indicating if the format is an image.
   */
  private isImage(format: FileFormatSimple): boolean {
    return this.metaDataService.isEnum(format, ImageFormatSimple);
  }

  /**
   * Checks if a file format is a video.
   * @param format The file format.
   * @returns A boolean indicating if the format is a video.
   */
  private isVideo(format: FileFormatSimple): boolean {
    return this.metaDataService.isEnum(format, VideoFormatSimple);
  }

  /**
   * Checks if a file format is an image or video.
   * @param format The file format.
   * @returns A boolean indicating if the format is an image or video.
   */
  private isImageOrVideo(format: FileFormatSimple): boolean {
    return this.isImage(format) || this.isVideo(format);
  }

  /**
   * Opens an image or video asset in a new tab.
   * @param format The file format.
   * @param src The source URL of the asset.
   */
  private openImageOrVideo(format: FileFormatSimple, src: string): void {
    const content = this.isImage(format) ? AssetLinkService.getContentForImage(src) :
      AssetLinkService.getContentForVideo(src, format);
    AssetLinkService.openMultimediaInNewTab(content);
  }

  /**
   * Opens an HTML file in a new tab.
   * @param file The HTML file to open.
   */
  private async openHtmlFileInNewTab(file: File): Promise<void> {
    const tab = window.open('', '_blank');
    const textContent = await this.metaDataService.getTextContentFromFile(file);
    tab.document.write(textContent);
  }
}
