import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {IResolution} from '../../../asset/interface/IResolution';

@Injectable({
  providedIn: 'root'
})
export class ImageDataService {

  constructor() {
  }

  public getFileSrcUrl(fileName: string, boombitId: string): string {
    return fileName ? `${environment.filesUrl}/${encodeURIComponent(boombitId)}/${encodeURIComponent(fileName)}` : '';
  }

  public getImageResolution(file: File): Promise<IResolution> {
    return new Promise((resolve) => {
      const reader = new FileReader();


      reader.readAsDataURL(file);
      reader.onload = (e) => {

        const image = new Image();
        image.src = e.target.result as string;

        image.onload = () => {
          const height = image.height;
          const width = image.width;

          resolve({width, height});
        };
      };
    });
  }


}
