import {ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Optional} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Tab} from '../../../shared/interface/ui/tabs/Tab';
import {TranslateService} from '@ngx-translate/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {faArrowLeft, faFloppyDiskCircleArrowRight} from '@fortawesome/pro-light-svg-icons';
import {CampaignFormSteps} from '../../enum/CampaignFormSteps';
import {ICampaignFormControlsConfig} from '../../interface/ICampaignFormControlsConfig';
import {IProduct} from '../../../product/interface/IProduct';
import {noWhitespaceValidator} from '../../../shared/util/form/validators/no-whitespace.validator';
import {ICampaignRaw} from '../../interface/ICampaignRaw';
import {CampaignsService} from '../../service/campaigns.service';
import {
  creativesRequired,
  endDateValidator,
  startDateValidator
} from '../../util/campaign-form-validators.util';
import {CampaignFormAccessor} from '../../util/campaign-form-accessor';
import {firstValueFrom, Subject, Subscription} from 'rxjs';
import {ICampaign} from '../../interface/ICampaign';
import {BACK_ACTION} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {IBackAction} from '../../../shared/interface/ui/dialog/IBackAction';
import {EnumTranslateService} from '../../../shared/service/translate/enum-translate.service';
import {isStepValid, updateStepsSubLabels} from 'src/app/campaign/util/campaign-form.util';
import {ProductsService} from '../../../product/service/product/products.service';
import {IFormStepsComponent} from '../../../shared/interface/form-tabs/IFormStepsComponent';
import {IFormStepsHandler} from '../../../shared/interface/form-tabs/IFormStepsHandler';
import {FormStepsHandler} from '../../../shared/class/form-steps-handler';
import {ICampaignFormChangeHandler} from '../../interface/ICampaignFormChangeHandler';
import {CampaignFormChangeHandler} from '../../class/campaign-form-change-handler';
import {ICanDeactivateFormCmp} from '../../../shared/interface/ui/form/ICanDeactivateFormCmp';
import {ICampaignDetails} from '../../interface/ICampaignDetails';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {ProductEndpoints} from '../../../shared/enum/Endpoints';
import {SnackbarService} from '../../../shared/service/snackbar/snackbar.service';

@Component({
  selector: 'app-campaign-form',
  templateUrl: './campaign-form.component.html',
  styleUrls: ['./campaign-form.component.scss']
})
export class CampaignFormComponent implements OnInit, IFormStepsComponent, ICanDeactivateFormCmp, OnDestroy {
  @Input() public isEditMode = false;
  @Input() public isDialog = false;
  @Input() public initialValue: ICampaignDetails;

  public canDeactivateForm = false;

  public initialProductId: string;
  public defaultCampaign: ICampaign;

  public showFullForm = true;

  public faFloppyDiskCircleArrowRight = faFloppyDiskCircleArrowRight;
  public faArrowLeft = faArrowLeft;

  public steps: Tab[] = [];
  public activeStep: Tab;

  public formGroup: UntypedFormGroup;
  public formGroupAccessor: CampaignFormAccessor;

  public CampaignFormSteps = CampaignFormSteps;

  public closeDialogSubject = new Subject<boolean>();

  public formStepsHandler: IFormStepsHandler;
  public changeHandler: ICampaignFormChangeHandler;

  public isLoading = false;
  public isFormLoading = false;
  public subscriptions: Subscription[] = [];

  constructor(private router: Router, private translateService: TranslateService,
              private formBuilder: UntypedFormBuilder, private campaignService: CampaignsService,
              private enumTranslateService: EnumTranslateService,
              @Optional() @Inject(BACK_ACTION) public backAction: IBackAction, private productsService: ProductsService,
              public changeDetectorRef: ChangeDetectorRef, private route: ActivatedRoute, private loadingService: LoadingService,
              private snackbarService: SnackbarService) {
    this.initialProductId = this.route.snapshot.queryParamMap.get('productId');
    this.formStepsHandler = new FormStepsHandler(this);
  }

  private _loadingEndpointNames(): string[] {
    return [ProductEndpoints.getProductByInternalId(this.initialProductId)];
  }

  public ngOnInit(): void {
    this.showFullForm = !this.isEditMode;
    this.initData();
    this.observeLoading();
    this.updateDefaultCampaign();
    this.observeProductChanges();
    this.formStepsHandler.updateStepsAfterFormChanges();
    this.changeHandler = new CampaignFormChangeHandler(this.formGroupAccessor);
  }

  private async initData(): Promise<void> {
    this.initForm();
    await this.initSteps();
    this.initProductById();
  }

  private observeLoading(): void {
    const subscription = this.loadingService.loadingEndpointsObs()
      .subscribe((loadingState) => {
        this.isFormLoading = this.loadingService.areEndpointsLoading(this._loadingEndpointNames(), loadingState);
      });
    this.subscriptions.push(subscription);
  }

  private initForm(): void {
    const controlsConfig: ICampaignFormControlsConfig = {
      product: [this.initialValue?.product || '', [Validators.required]],
      name: [this.initialValue?.name || '', [Validators.required, noWhitespaceValidator]],
      isDefault: [this.initialValue?.default === true, [this.defaultCampaignRequired.bind(this)]],
      startDate: [this.initialValue?.startDate || null, [startDateValidator]],
      endDate: [this.initialValue?.endDate || null, endDateValidator],
      creatives: [this.initialValue?.creatives || [], [creativesRequired]]
    };
    this.formGroup = this.formBuilder.group(controlsConfig);
    this.formGroupAccessor = new CampaignFormAccessor(this.formGroup);
    this.setDatesDisabledState();
    this.showErrorsInSummary();
  }

  private showErrorsInSummary(): void {
    if (this.isEditMode) {
      this.formGroup.markAllAsTouched();
    }
  }

  private async initProductById(): Promise<void> {
    if (this.initialProductId) {
      const product = await this.productsService.getProductByInternalId(this.initialProductId) as IProduct;
      this.initialValue = {product, name: null, startDate: null, endDate: null, creatives: null};
      this.formGroupAccessor.product.setValue(product);
      this.onNextStepClicked();
    }
  }

  private setDatesDisabledState(): void {
    if (this.initialValue && this.initialValue.default === true) {
      this.formGroupAccessor.startDate.disable();
      this.formGroupAccessor.endDate.disable();
    }
  }

  private async updateDefaultCampaign(): Promise<void> {
    if (this.formGroupAccessor.productValue.id) {
      this.defaultCampaign = await this.campaignService
        .getDefaultCampaignForProduct(this.formGroupAccessor.productValue.id);
    }
  }

  private observeProductChanges(): void {
    this.formGroupAccessor.product.valueChanges.subscribe(() => {
      this.formGroupAccessor.creatives.setValue([]);
      this.updateDefaultCampaign().then(() => {
        this.formGroupAccessor.isDefault.updateValueAndValidity();
      });
    });
  }

  private async initSteps(): Promise<void> {
    this.steps = await this.getInitialSteps();
    this.activeStep = this.steps[0];
    this.steps.forEach(step => step.isDisabledFn = this.isStepDisabled.bind(this));
  }

  private async getInitialSteps(): Promise<Tab[]> {
    const steps = await this.enumTranslateService.getSelectOptionsFromEnum(CampaignFormSteps, 'campaign.campaignForm.tabs.');
    return this.isEditMode ? steps.slice(1) : steps;
  }

  public onCancelClicked(): void {
    this.closeForm(false);
  }

  private closeForm(omitConfirmation: boolean): void {
    if (!this.isDialog) {
      this.router.navigate(['/campaign/list']);
    } else {
      this.closeDialogSubject.next(omitConfirmation);
    }
  }

  public isStepValid(step: Tab = this.activeStep): boolean {
    return isStepValid(step, this.formGroupAccessor);
  }

  public onNextStepClicked(): void {
    this.goToNextStep();
  }

  private goToNextStep(): void {
    this.goToStep(this.activeStepIndex + 1);
  }

  public isSubmitButtonVisible(): boolean {
    return this.activeStep?.name === CampaignFormSteps.SUMMARY && !this.isEditMode;
  }

  public isSubmitEditButtonVisible(): boolean {
    return !this.showFullForm || (this.showFullForm && this.activeStep?.name === CampaignFormSteps.SUMMARY && this.isEditMode);
  }

  public isNextStepButtonVisible(): boolean {
    return this.activeStep?.name !== CampaignFormSteps.CHOOSE_PROMOTED_PRODUCT &&
      this.activeStep?.name !== this.steps[this.steps.length - 1]?.name && this.showFullForm;
  }

  public isCancelButtonVisible(): boolean {
    return this.activeStep?.name === this.steps[0]?.name;
  }

  public onProductSelected(product: IProduct): void {
    this.changeHandler.canProductValueChange(product).then(result => {
      if (result) {
        this.formGroupAccessor.product.setValue(product);
        this.goToNextStep();
      }
    });
  }

  public isFormValid(): boolean {
    return this.formGroup.valid;
  }

  public onPreviousStepClicked(): void {
    this.goToPreviousStep();
  }

  public goToPreviousStep(): void {
    this.goToStep(this.activeStepIndex - 1);
  }

  public get activeStepIndex(): number {
    return this.steps.findIndex(step => step.name === this.activeStep.name);
  }

  public onGoToStepClicked(stepName: CampaignFormSteps): void {
    this.showEditFormIfHidden();
    const idx = this.steps.findIndex(step => step.name === stepName);
    this.goToStep(idx);
  }

  private showEditFormIfHidden(): void {
    this.showFullForm = true;
  }

  private goToStep(idx: number): void {
    this.activeStep = this.steps[idx];
    updateStepsSubLabels(this.steps, this.formGroupAccessor);
  }

  /* Selecting steps */
  public onStepSelected(step: Tab): void {
    if (!step.isDisabledFn(step)) {
      this.goToStep(this.formStepsHandler.getStepIdx(step));
    }
  }

  public isStepDisabled(step: Tab): boolean {
    return this.formStepsHandler.isStepDisabled(step);
  }

  /* Submit */
  public onSubmitClicked(): void {
    this.isLoading = true;
    const campaignRaw: ICampaignRaw = this.getCampaignRaw();
    this.campaignService.createCampaign(campaignRaw).then(async () => {
      const message = await firstValueFrom(this.translateService.get('campaign.campaignCreatedMessage'));
      this.snackbarService.openSuccessSnackbar(message);
      this.canDeactivateForm = true;
      this.closeForm(true);
    }).finally(() => this.isLoading = false);
  }

  public onSubmitEditClicked(): void {
    this.isLoading = true;
    const campaignRaw: ICampaignRaw = this.getCampaignRaw();
    campaignRaw.id = this.initialValue.id;

    this.campaignService.editCampaign(campaignRaw).then(async () => {
      const message = await firstValueFrom(this.translateService.get('campaign.campaignEditedMessage'));
      this.snackbarService.openSuccessSnackbar(message);
      this.closeForm(true);
    })
      .finally(() => this.isLoading = false);
  }

  public getCampaignRaw(): ICampaignRaw {
    const formValue: ICampaignFormControlsConfig = this.formGroup.value;
    return {
      name: formValue.name,
      default: formValue.isDefault,
      startDate: formValue.startDate?.getTime() || 0,
      endDate: formValue.endDate?.getTime() || 0,
      productId: formValue.product.id,
      creativeIds: this.formGroupAccessor.creativesValues.map(creative => creative.id),
      adUnitIds: this.formGroupAccessor.creativesValues.map(creative => creative.adUnit.id)
    };
  }

  /* Campaign required*/
  private defaultCampaignRequired(control: AbstractControl): ValidationErrors | null {
    if (!this.formGroupAccessor?.productValue) {
      return null;
    }

    if (this.isDefaultCampaignRequiredTrue()) {
      return control.value === true ? null : {required: true};
    } else {
      return null;
    }
  }

  private isInitialCampaignDefault(): boolean {
    return this.initialValue.id === this.defaultCampaign?.id;
  }

  public isDefaultCampaignRequiredTrue(): boolean {
    return (!this.isEditMode && !this.defaultCampaign) ||
      (this.isEditMode && this.isInitialCampaignDefault());
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }
}


