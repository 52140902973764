import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  faArrowDownToLine,
  faArrowRightArrowLeft,
  faArrowUpRightFromSquare,
  faBookCircleArrowUp,
  faCircleCheck,
  faCircleExclamation,
  faTrash
} from '@fortawesome/pro-light-svg-icons';
import {MetaDataService} from '../../../../shared/service/file-utils/meta-data.service';
import {IAsset} from '../../../interface/IAsset';
import {getRatio, RESPONSIVE} from '../../../util/ratio.util';
import {FileFormatType} from '../../../type/FileFormatType';
import {HtmlFormat} from '../../../enum/HtmlFormat';
import {IUserExif} from '../../../interface/IUserExif';
import {Status} from '../../../../shared/enum/Status';
import {AssetLinkService} from '../../../service/asset-link/asset-link.service';
import {IAssetFileOutput} from '../../../interface/IAssetFileOutput';
import {FileFormatSimple} from '../../../type/FileFormatSimple';
import {HtmlFormatSimple} from '../../../enum/HtmlFormatSimple';

/** Component for handling file input, displaying file details, and triggering related actions. */
@Component({
  selector: 'app-asset-file-input',
  templateUrl: './asset-file-input.component.html',
  styleUrls: ['./asset-file-input.component.scss']
})
export class AssetFileInputComponent {
  /** Reference to the file drop element. */
  @ViewChild('fileDropRef') fileElementRef: ElementRef;

  /** Input property representing the status of the asset file input (e.g., active, inactive). */
  @Input() status: Status;

  /** Input property representing the associated asset. */
  @Input() asset: IAsset;

  /** Input property indicating whether the component is inside a creative form. */
  @Input() insideCreativeForm = false;

  /** Event emitted when the "Add From Library" is clicked. */
  @Output() addFromLibraryClicked = new EventEmitter();

  /** Event emitted when a file is selected. */
  @Output() fileSelected = new EventEmitter<IAssetFileOutput>();

  /** Event emitted when the file is removed. */
  @Output() fileRemoved = new EventEmitter<void>();

  /** FontAwesome icons used in the component. */
  public faArrowDownToLine = faArrowDownToLine;
  public faArrowRightArrowLeft = faArrowRightArrowLeft;
  public faCircleExclamation = faCircleExclamation;
  public faCircleCheck = faCircleCheck;
  public faBookCircleArrowUp = faBookCircleArrowUp;
  public faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  public faTrash = faTrash;

  /** The selected file. */
  public file: File;

  /** Enum representing the status types. */
  public Status = Status;

  /**
   * Constructor for AssetFileInputComponent.
   * @param metaDataService Service providing utility functions for extracting metadata from files.
   * @param assetLinkService Service for handling asset-related navigation links.
   */
  constructor(private metaDataService: MetaDataService, private assetLinkService: AssetLinkService) {
  }

  /**
   * Helper method to get the ratio based on the file's resolution and format.
   * @param width Width of the file.
   * @param height Height of the file.
   * @param format File format type.
   * @returns The ratio as a string.
   */
  private static getRatio(width: number, height: number, format: FileFormatType): string {
    if (format === HtmlFormat.html) {
      return RESPONSIVE;
    } else if (!width || !height) {
      return '';
    } else {
      return getRatio(width, height);
    }
  }

  /**
   * Retrieves asset details from the given file's EXIF data.
   * @param fileExif User EXIF data extracted from the file.
   * @param file The file for which to get asset details.
   * @returns An IAsset object with details populated from the file.
   */
  private async getAssetFromFileExif(fileExif: IUserExif, file: File): Promise<IAsset> {
    const fileFormat: FileFormatSimple = this.metaDataService.getFileFormatSimple(fileExif.type as FileFormatType);
    const htmlString = fileFormat === HtmlFormatSimple.html ? await this.metaDataService.getTextContentFromFile(file) : '';
    return {
      name: fileExif.name,
      originalName: fileExif.name,
      duration: Math.floor(fileExif.duration || 0),
      url: 'none',
      resolution: {
        width: (fileExif.resolution?.width || 0),
        height: (fileExif.resolution?.height || 0)
      },
      format: fileFormat,
      size: Math.ceil(fileExif.size),
      tags: [],
      ratio: AssetFileInputComponent.getRatio(fileExif.resolution?.width,
        fileExif.resolution?.height, fileExif.type as FileFormatType),
      htmlString,
      createdAt: 0
    };
  }

  /**
   * Event handler for the file selected event.
   * @param event The file selected event.
   */
  public onFileSelected(event: any): void {
    if (event.target.files[0]) {
      this.file = event.target.files[0];
      this.handleFileSelected();
    }
  }

  /**
   * Event handler for the file dropped event.
   * @param files The list of dropped files.
   */
  public onFileDropped(files: FileList): void {
    if (files[0]) {
      this.file = files[0];
      this.handleFileSelected();
    }
  }

  /** Handles the file selected event by extracting EXIF data and emitting the fileSelected event. */
  public async handleFileSelected(): Promise<void> {
    const fileExif = await this.metaDataService.getUserExif(this.file);
    this.asset = await this.getAssetFromFileExif(fileExif, this.file);
    this.fileSelected.emit({asset: this.asset, file: this.file});
  }

  /**
   * Gets the resolution as a string (either responsive or in the format width x height).
   * @returns The resolution as a string.
   */
  public getResolution(): string {
    return this.asset.ratio === RESPONSIVE ? RESPONSIVE :
      this.asset.resolution?.width + 'x' + this.asset.resolution?.height + ' px';
  }

  /** Opens the asset preview in a new browser tab. */
  public async onOpenPreview(): Promise<void> {
    if (this.asset?.id) {
      this.assetLinkService.openAssetInNewTab(this.asset);
    } else {
      this.assetLinkService.openAssetFromFileInNewTab(this.asset, this.file);
    }
  }

  /** Event handler for the delete button click. */
  public async onDeleteClicked(): Promise<void> {
    this.file = null;
    this.fileElementRef.nativeElement.value = '';
    this.fileRemoved.emit();
  }
}
