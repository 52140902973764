import { Injectable } from '@angular/core';
import { Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private navbarToggleSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private isToggled = false;

  constructor() {
    this.navbarToggleSubject.next(false);
  }

  public toggleSidebar(): void {
    this.isToggled = !this.isToggled;
    this.navbarToggleSubject.next(this.isToggled);
  }

  public get sidebarToggle(): Observable<boolean> {
    return this.navbarToggleSubject.asObservable();
  }
}
