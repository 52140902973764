import {UntypedFormControl, ValidationErrors} from '@angular/forms';

export function reverseDomainValidator(control: UntypedFormControl): ValidationErrors | null {
  if(!control.value?.trim()) {
    return null;
  }
  const isValid = /^([A-Za-z]{2,63})(\.[A-Za-z0-9-]{1,63})+$/.test(control.value);
  return isValid ? null : { reverseDomain: true };
}

