import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CellActions, MyTableAction} from '../../../interface/ui/my-table/cell.model';
import {CellActionsEvent} from '../../../interface/ui/my-table/cell-event.model';
import {CellHandler} from '../../../interface/ui/my-table/cell-handler.interface';
import {faCircleNotch} from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-actions-cell',
  templateUrl: './actions-cell.component.html',
  styleUrl: './actions-cell.component.scss'
})
export class ActionsCellComponent implements CellHandler {
  @Input({required: true}) public cell: CellActions;
  @Output() public cellEvent: EventEmitter<CellActionsEvent> = new EventEmitter<CellActionsEvent>();
  protected readonly faCircleNotch = faCircleNotch;

  public onIconClicked(action: MyTableAction): void {
    this.cellEvent.emit({type: 'click', action});
  }
}
