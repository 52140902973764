import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {faCircleCheck, faTriangleExclamation, faXmarkCircle} from '@fortawesome/pro-light-svg-icons';
import {Status} from '../../../enum/Status';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {
  public faCircleCheck = faCircleCheck;
  public faXMarkCircle = faXmarkCircle;

  public Status = Status;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: {innerHTML: string, type: Status, preClose: () => void}) { }

  public onCloseClicked(): void {
    this.data.preClose();
  }

  public getIcon(): IconDefinition {
    if (this.data.type === Status.SUCCESS) {
      return faCircleCheck;
    } else if (this.data.type === Status.ERROR) {
      return faXmarkCircle;
    } else if (this.data.type === Status.WARNING) {
      return faTriangleExclamation;
    }
  }

}
