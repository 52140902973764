import {AbstractControl, UntypedFormGroup, FormGroupDirective, NgControl} from '@angular/forms';
import {ChangeDetectorRef} from '@angular/core';

export function showAllValidationErrors(form: UntypedFormGroup, changeDetector: ChangeDetectorRef): void{
  form.markAllAsTouched();
  changeDetector.detectChanges();
}

export function resetFormAndMaterialValidations(form: UntypedFormGroup, formDirective: FormGroupDirective, newState?: any): void {
  if (newState) {
    form.reset(newState);
    formDirective.resetForm(newState);
  } else {
    form.reset();
    formDirective.resetForm();
  }
}

export function hasRequiredError(control: AbstractControl | NgControl): boolean {
  return (control.hasError('required') || control.hasError('whitespace')) && control.touched;
}

export function hasError(control: AbstractControl, errorCode: string): boolean {
  return control.hasError(errorCode) && control.touched;
}

export function hasAnyError(control: AbstractControl | NgControl): boolean {
  return control.invalid && control.touched;
}

export function isValueEmpty(value: string): boolean {
  return (value || '').trim().length === 0;
}
