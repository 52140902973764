<div class="dialog-container">
  <div class="backdrop" (click)="onCloseDialog()"></div>
  <div class="dialog">
    <button (click)="onCloseDialog()" class="expanded-dialog-close-button" mat-flat-button color="primary">
      <fa-icon class="close-icon" [icon]="faXMark"></fa-icon>
      {{'button.close' | translate}}
    </button>
    <ng-content></ng-content>
  </div>
</div>
