import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {BACK_ACTION, DIALOG_DATA} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {IBackAction} from '../../../shared/interface/ui/dialog/IBackAction';
import {WaterfallService} from '../../service/waterfall.service';
import {faEdit, faEye, faTrash, faXmark} from '@fortawesome/pro-light-svg-icons';
import {WaterfallDialogService} from '../../service/waterfall-dialog/waterfall-dialog.service';
import {getSideDialogConfig} from '../../../shared/util/dialog/dialog-back-action.util';
import {getRegionsNameShort} from '../../../shared/util/region/region.util';
import {IPromotedProductCampaign} from '../../../product/interface/IPromotedProductCampaign';
import {WaterfallEndpointsEnum} from '../../../shared/enum/Endpoints';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {Subscription} from 'rxjs';
import {IWaterfallDetails} from '../../interface/IWaterfallDetails';
import {ProductDialogService} from '../../../product/service/product-dialog/product-dialog.service';
import {CampaignDialogService} from '../../../campaign/service/campaign-dialog/campaign-dialog.service';

@Component({
  selector: 'app-waterfall-preview',
  templateUrl: './waterfall-preview.component.html',
  styleUrls: ['./waterfall-preview.component.scss']
})
export class WaterfallPreviewComponent implements OnInit, OnDestroy {
  public waterfall: IWaterfallDetails;
  public adUnitTypes: string[] = [];

  public faXMark = faXmark;
  public faTrash = faTrash;
  public faEdit = faEdit;
  public faEye = faEye;

  public getRegionsNameShort = getRegionsNameShort;

  public productCampaignsIterator = [];

  public productCampaigns: IPromotedProductCampaign[];

  public isLoading = false;
  private loadingEndpointNames: string[] = [WaterfallEndpointsEnum.GET_WATERFALL_BY_INTERNAL_ID];
  private subscriptions: Subscription[] = [];

  constructor(public dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: { waterfallId: string },
              @Inject(BACK_ACTION) public backAction: IBackAction, private waterfallService: WaterfallService,
              private waterfallDialogService: WaterfallDialogService, private loadingService: LoadingService,
              private productDialogService: ProductDialogService, private campaignDialogService: CampaignDialogService) {
  }

  ngOnInit(): void {
    this.initWaterfall().then(() => this.setProductsCampaigns());
    this.observeLoading();
  }

  private async initWaterfall(): Promise<void> {
    this.waterfall = await this.waterfallService.getWaterfallByInternalId(this.data.waterfallId);
    this.adUnitTypes = this.waterfall.adUnits?.map(adUnit => adUnit.type) || [];
  }

  private observeLoading(): void {
    const sub = this.loadingService.loadingEndpointsObs().subscribe((loadingEndpoints) => {
      this.isLoading = this.loadingService.areEndpointsLoading(this.loadingEndpointNames, loadingEndpoints);
    });
    this.subscriptions.push(sub);
  }

  public async onDeleteWaterfallClicked(): Promise<void> {
    const dialogRef = await this.waterfallDialogService.openWaterfallDeleteDialog(this.waterfall.name, this.waterfall.id);
    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.dialogRef.close();
      }
    });
  }

  public onCloseClicked(): void {
    this.dialogRef.close();
  }

  public onEditWaterfallClicked(): void {
    this.waterfallDialogService.openWaterfallEditDialog(this.waterfall.id,
      getSideDialogConfig(WaterfallPreviewComponent, this.backAction, this.data));
    this.dialogRef.close();
  }

  public setProductsCampaigns(): void {
    const count = Math.max(this.waterfall.promotedProducts?.length, this.waterfall.campaigns?.length);
    this.productCampaignsIterator = isNaN(count) ? [] : Array(count);
  }

  public onProductPreviewClicked(id: string): void {
    this.productDialogService.openProductPreview(id,
      getSideDialogConfig(WaterfallPreviewComponent, this.backAction, this.data));
    this.onCloseClicked();
  }

  public onCampaignPreviewClicked(id: string): void {
    this.campaignDialogService.openCampaignPreviewDialog(id,
      getSideDialogConfig(WaterfallPreviewComponent, this.backAction, this.data));
    this.onCloseClicked();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }
}
