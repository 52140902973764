import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {BehaviorSubject, catchError, Observable, take, tap, throwError} from 'rxjs';
import {AuthService} from '../../service/auth/auth/auth.service';
import {filter, switchMap} from 'rxjs/operators';
import {RefreshTokenResponse} from '../../interface/auth/RefreshTokenResponse';
import {environment} from '../../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   * A flag used to prevent multiple requests to /Auth/refresh
   */
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private authService: AuthService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = this.authService.userValue;
    const isLoggedIn = user && user.token;
    if (isLoggedIn && request.url.startsWith(environment.apiUrl)) {
      request = this.getNewRequestWithToken(request, user.token);
    }

    return next.handle(request)
      .pipe(
        catchError((error) => {
          if (this.is401Error(error)) {
            return this.handle401Error(request, next, error);
          }
          return throwError(error);
        })
      );
  }

  private getNewRequestWithToken(request: HttpRequest<any>, newToken: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${newToken}`
      }
    });
  }

  private is401Error(error: any): boolean {
    return error instanceof HttpErrorResponse && error.status === 401;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, error: any): Observable<HttpEvent<any>> {
    if (this.isRefreshTokenEndpoint(request)) {
      return this.handle401ForRefreshTokenEndpoint(error);
    } else {
      return this.handle401ForRegularEndpoint(request, next, error);
    }
  }

  private isRefreshTokenEndpoint(request: HttpRequest<any>): boolean {
    return request.url.endsWith('/Auth/refresh');
  }

  private handle401ForRefreshTokenEndpoint(error: any): Observable<HttpEvent<any>> {
    this.authService.logout(true);
    return throwError(error);
  }

  /** Handler for handling 401 status code for regular endpoints
   *   Note about the first check:
   *   if(!this.authService.isLoggedIn()) {
   *      return throwError(error);
   *   }
   *   This check is used in the following case:
   *   1. user visits a screen in which there are multiple requests sent (for example campaigns list)
   *   2. his access token is expired and so is his refresh token
   *   3. the /Auth/refresh returns 401
   *   4. user is logged out
   *   5. Due to the fact that some requests may be sent a little later, it may happen that by the time they finish with 401 status,
   *   the user is already logged out. In that case the original error is returned,
   *   and we do not proceed with sending the /Auth/refresh request again
   */
  private handle401ForRegularEndpoint(request: HttpRequest<any>, next: HttpHandler, error: any): Observable<HttpEvent<any>> {
    if(!this.authService.isLoggedIn()) {
      return throwError(error);
    }
    return this.handleRefreshingToken(request, next);

  }

  /** Handler for refreshing the token */
  private handleRefreshingToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      return this.sendRefreshTokenRequest(request, next);
    } else {
      return this.waitUntilTokenRefreshed(request, next);
    }
  }

  private sendRefreshTokenRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.isRefreshing = true;
    this.refreshTokenSubject.next(null);

    return this.authService.refreshToken().pipe(
      tap((refreshResp: RefreshTokenResponse) => {
        this.authService.handleRefreshTokenSuccess(refreshResp);
        this.isRefreshing = false;
        this.refreshTokenSubject.next(refreshResp.accessToken);
      }),
      catchError((error) => {
        this.isRefreshing = false;
        this.authService.handleRefreshTokenError();
        return throwError(error);
      }),
      switchMap((response: RefreshTokenResponse) => {
        const newRequest = this.getNewRequestWithToken(request, response.accessToken);
        return next.handle(newRequest);
      }),
    );
  }

  private waitUntilTokenRefreshed(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.getNewRequestWithToken(request, token)))
    );
  }
}
