import {ICampaignFormChangeHandler} from '../interface/ICampaignFormChangeHandler';
import {IProduct} from '../../product/interface/IProduct';
import {CampaignFormAccessor} from '../util/campaign-form-accessor';
import {ICampaignFormMsgHandler} from '../interface/ICampaignFormMsgHandler';
import {CampaignFormMsgHandler} from './campaign-form-msg-handler';

export class CampaignFormChangeHandler implements ICampaignFormChangeHandler {
  private msgHandler: ICampaignFormMsgHandler;

  constructor(private campaignFormAccessor: CampaignFormAccessor) {
    this.msgHandler = new CampaignFormMsgHandler();
  }

  private static isProductChanged(oldProduct: IProduct, newProduct: IProduct): boolean {
    return oldProduct?.id && newProduct?.id && oldProduct.id !== newProduct.id;
  }

  public async canProductValueChange(newProduct: IProduct): Promise<boolean> {
    const oldProduct = this.campaignFormAccessor.productValue;
    if (CampaignFormChangeHandler.isProductChanged(oldProduct, newProduct)) {
      return await this.msgHandler.confirmProductChange(oldProduct.name, newProduct.name);
    } else {
      return true;
    }
  }
}
