<div [ngClass]="{
      'tooltip-padding-small': this.data.cssClass === 'tooltip-narrow',
      'tooltip-right-outer-padding': this.data.placement === Placement.RIGHT,
      'tooltip-top-outer-padding': this.data.placement === Placement.TOP,
      'tooltip-left-outer-padding': this.data.placement === Placement.LEFT}">
<div [ngClass]="getTooltipCssClasses()"
     class="tooltip-wrapper">
  <ng-container *ngIf="data.type === TooltipType.TEXT">
    <div [innerHTML]="data.text" class="tooltip-text mat-caption">
    </div>
  </ng-container>

  <ng-container *ngIf="data.type === TooltipType.CONTENT_TEMPLATE">
    <div class="tooltip-text mat-caption">
      <ng-template #content>
      </ng-template>
    </div>
  </ng-container>
</div>
</div>
