import {IFormStepsComponent} from '../interface/form-tabs/IFormStepsComponent';
import {Tab} from '../interface/ui/tabs/Tab';
import {TooltipType} from '../enum/ui/tooltip/TooltipType';
import {Placement} from '../enum/ui/tooltip/Placement';
import {TranslateService} from '@ngx-translate/core';
import {AppInjector} from '../util/app-injector';
import {IFormStepsHandler} from '../interface/form-tabs/IFormStepsHandler';

export class FormStepsHandler implements IFormStepsHandler {
  private translateService: TranslateService;

  constructor(private formComponent: IFormStepsComponent) {
    this.translateService = AppInjector.get(TranslateService);
  }

  private static setDisabled(step: Tab, isDisabled: boolean): void {
    step.isDisabled = isDisabled;
  }

  private static isDisabled(step: Tab): boolean {
    return step.isDisabled === true;
  }

  public getActiveStepIndex(): number {
    return this.formComponent.steps.findIndex(step => step.name === this.formComponent.activeStep.name);
  }

  public get activeStepIndex(): number {
    return this.formComponent.steps.findIndex(step => step.name === this.formComponent.activeStep.name);
  }

  public getStepIdx(step: Tab): number {
    return this.formComponent.steps.findIndex(stepFromList => stepFromList.name === step.name);
  }

  public isStepDisabled(step: Tab): boolean {
    const isDisabled = !this.shouldBeEnabled(step);
    FormStepsHandler.setDisabled(step, isDisabled);
    this.updateTooltipVisibility(step, isDisabled);
    return isDisabled;
  }

  public updateStepsAfterFormChanges(): void {
    this.formComponent.formGroup.valueChanges.subscribe(() => this.formComponent.changeDetectorRef.detectChanges());
  }

  private updateTooltipVisibility(step: Tab, shouldShowTooltip: boolean): void {
    if (shouldShowTooltip && !step.tooltip) {
      this.addTooltipToStep(step);
    } else if (!shouldShowTooltip && step.tooltip) {
      delete step.tooltip;
    }
  }

  private shouldBeEnabled(step: Tab): boolean {
    return this.isBeforeActiveStep(step) || this.isActiveStep(step) ||
      (this.isAfterActiveStep(step) && this.isPreviousStepValid(step) && this.isPreviousStepEnabled(step));
  }

  private addTooltipToStep(step: Tab): void {
    this.translateService.get('form.stepDisabledMessage').subscribe(result => {
      step.tooltip = {text: result, type: TooltipType.TEXT, placement: Placement.TOP};
      this.formComponent.changeDetectorRef.detectChanges();
    });
  }

  private isBeforeActiveStep(step: Tab): boolean {
    return this.getStepIdx(step) < this.activeStepIndex;
  }

  private isActiveStep(step: Tab): boolean {
    return this.getStepIdx(step) === this.activeStepIndex;
  }

  private isAfterActiveStep(step: Tab): boolean {
    return this.getStepIdx(step) > this.activeStepIndex;
  }

  private isPreviousStepValid(step: Tab): boolean {
    const previousStep = this.getPreviousStep(step);
    return this.formComponent.isStepValid(previousStep);
  }

  private isPreviousStepEnabled(step: Tab): boolean {
    const previousStep = this.getPreviousStep(step);
    return !FormStepsHandler.isDisabled(previousStep);
  }

  private getPreviousStep(step: Tab): Tab {
    const stepIdx = this.getStepIdx(step);
    if (stepIdx <= 0) {
      throw new Error('Cannot access tab - first tab has no predecessor');
    }
    return this.formComponent.steps[stepIdx - 1];
  }
}
