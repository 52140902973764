<div class="login-page-wrapper">
  <div class="login-page">
    <div class="login-card">
      <div class="boombit-logo"><img src="../../../../../assets/boombit-logo.png" alt=""></div>
      <h1 class="mat-headline-5">✋🏻 {{'login.loginHeader' | translate}}</h1>
      <p class="color-dark-grey-third" [innerHTML]="'login.loginText' | translate"></p>
      <asl-google-signin-button theme="filled_blue" size="large" type="standard"></asl-google-signin-button>
      <app-spinner *ngIf="isLoading"></app-spinner>
    </div>

    <div *ngIf="hasError" class="error-message mat-caption align-center-gap-10">
      <fa-icon [icon]="faCircleExclamation"></fa-icon>
      {{'login.loginError' | translate}}
    </div>

    <div *ngIf="sessionExpiredMsg && !hasError" class="info-message mat-caption align-center-gap-10">
      <fa-icon [icon]="faCircleExclamation"></fa-icon>
      {{'login.sessionExpired' | translate}}
    </div>

    <div class="info-message mat-caption align-center-gap-10">
      <fa-icon [icon]="faCircleExclamation"></fa-icon>
      {{'login.turnAdBlockOff' | translate}}
    </div>
  </div>
</div>

