import {Injectable} from '@angular/core';
import {AdUnitService} from './ad-unit';
import {IAdUnit} from '../interface/IAdUnit';
import {ITableFilterInput} from '../../shared/interface/ui/table/ITableFilterInput';
import {AdUnitFilter} from '../enum/AdUnitFilter';
import {TranslateService} from '@ngx-translate/core';
import {firstValueFrom} from 'rxjs';
import {IBasicSelectOption} from '../../shared/interface/ui/form/IBasicSelectOption';
import {IAdUnitFormat} from '../interface/IAdUnitFormat';
import {AdUnitFormatFilter} from '../enum/AdUnitFormatFilter';

/** Constant representing any ad unit. */
export const ANY_AD_UNIT = 'any';

/**
 * Service for managing and retrieving ad unit filters for tables/lists.
 */
@Injectable({
  providedIn: 'root'
})
export class AdUnitFiltersService {

  /**
   * Constructor for AdUnitFiltersService.
   * @param adUnitService AdUnitService for fetching Ad Units data.
   * @param translateService TranslateService for language translation.
   */
  constructor(private adUnitService: AdUnitService, private translateService: TranslateService) {
  }

  /**
   * Retrieves ad unit filters for table filtering.
   * @returns A promise with ad unit filters as ITableFilterInput.
   */
  public async getAdUnitFilters(): Promise<ITableFilterInput> {
    const adUnits: IAdUnit[] = await this.adUnitService.getAdUnitsFromSubject();
    const items: IBasicSelectOption[] = adUnits.map(adUnit => ({label: adUnit.name, name: adUnit.type}));
    items.push({label: await firstValueFrom(this.translateService.get('adUnit.anyAdUnit')), name: ANY_AD_UNIT});
    return {
      label: await firstValueFrom(this.translateService.get('adUnit.adUnit')), name: AdUnitFilter.AD_UNIT, selectedItems: [],
      items
    };
  }

  /**
   * Retrieves ad unit format filters for table filtering.
   * @returns A promise with ad unit format filters as ITableFilterInput.
   */
  public async getAdUnitFormatFilters(): Promise<ITableFilterInput> {
    const adUnitFormats: IAdUnitFormat[] = await this.adUnitService.getAdUnitFormatsFromSubject();
    const items: IBasicSelectOption[] = adUnitFormats.map(adUnitFormat =>
      ({label: adUnitFormat.name, name: adUnitFormat.name, id: adUnitFormat.id}));
    return {
      label: await firstValueFrom(this.translateService.get('adUnit.adUnitFormat')),
      name: AdUnitFormatFilter.AD_UNIT_FORMAT, selectedItems: [],
      items
    };
  }
}
