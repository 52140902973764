import { Injectable } from '@angular/core';
import {Placement} from '../../enum/ui/tooltip/Placement';
import {ITableCell} from '../../interface/ui/table/ITableCell';
import {ITooltip} from '../../interface/ui/tooltip/ITooltip';
import _ from 'lodash';
import {TooltipType} from '../../enum/ui/tooltip/TooltipType';
import {ITooltipButton} from '../../interface/ui/tooltip/ITooltipButton';
import {TooltipListComponent} from '../../component/common/tooltip-list/tooltip-list.component';
import {ITooltipList} from '../../interface/ui/tooltip/ITooltipList';
import { TooltipPanelComponent } from '../../component/common/tooltip-panel/tooltip-panel.component';
import { IPanelTooltip, ITooltipSection } from '../../interface/ui/tooltip/IPanelTooltip';

@Injectable({
  providedIn: 'root'
})
export class TableCellService {

  constructor() { }

  public getComplexCell(items: string[], placement: Placement = Placement.RIGHT, numbered: boolean = true): ITableCell {
    return {
      value: numbered ? this.getComplexCellValue(items) : this.getNotNumberedComplexCellValue(items),
      tooltip: this.getTooltipComplex(items, placement)
    };
  }

  public getComplexPanelCell(items: IPanelTooltip, placement: Placement = Placement.RIGHT): ITableCell {
    const shouldAddTooltip = items.sections.some((section) => section.items?.length > 0);
    return {
      value: this.generatePanelCellLabel(items),
      tooltip: shouldAddTooltip ? this.getTooltipPanelComplex(items, placement) : undefined
    };
  }

  public generatePanelCellLabel(data: IPanelTooltip): string {
    let label = '';
    data.sections?.forEach((section, idx) => {
      if (section.items?.length > 0) {
        label = label + section.name;
        section.items?.forEach((item, index) => {
          label = label + `${index + 1}. ${item}`;
        });

        if (idx < data.sections.length - 1) {
          label = label + ', ';
        }
      }
    });
    return label;
  }

  public getTooltipComplex(items: string[], placement: Placement = Placement.RIGHT): ITooltip {
    if (_.isEmpty(items)) {
      return undefined;
    }

    return {
      cursorAllowedInsideTooltip: true, // needed when tooltip has scroll
      type: TooltipType.CONTENT_TEMPLATE, placement,
      contentComponent: {
        type: TooltipListComponent, data: {
          items: items.map((item) => ({value: item}))
        } as ITooltipList<ITooltipButton<any,any>>
      }
    };
  }

  public getTooltipPanelComplex(data: IPanelTooltip, placement: Placement = Placement.RIGHT): ITooltip | undefined {
    if (_.isEmpty(data)) {
      return undefined;
    }

    return {
      cursorAllowedInsideTooltip: true,
      type: TooltipType.CONTENT_TEMPLATE,
      placement,
      contentComponent: {
        type: TooltipPanelComponent,
        data: data as IPanelTooltip
      }
    };
  }

  public getComplexCellValue(items: string[]): string {
    return items.map((dest, idx) => (idx + 1) + '. ' + dest).join(', ');
  }

  public getNotNumberedComplexCellValue(items: string[]): string {
    return items.join(', ');
  }

  public getFormattedPanelData(items: string[], panelType: string): ITooltipSection {
    return { name: panelType, items: items };
  }
}
