<app-base-center-dialog [dialogRef]="dialogRef">
  <div class="confirm-dialog">
          <fa-icon class="confirm-dialog-icon" [icon]="faTrash"></fa-icon>

    <h1 class="margin-0">{{'product.deleteDialog.header' | translate}}</h1>
    <div [innerHTML]="'product.deleteDialog.text' | translate: {name: data.name}"></div>
    <div >
      <p class="warning">WARNING!</p>
      <div [innerHTML]="'product.deleteDialog.warning' | translate"></div>
    </div>
    <hr class="w-100 separator-dark-grey">
    <p [innerHTML]="'product.deleteDialog.verificationInfo' | translate: {name: data.name}"></p>

    <div class="d-flex text-left">
    <div style="margin-bottom: 10px;" class="form-control">
      <div class="label">{{'product.productForm.productName' | translate}}</div>
      <input name="productName" autocomplete="off" [formControl]="nameControl" type="text"
             [placeholder]="'product.productForm.productName' | translate">
      <span *ngIf="hasError(nameControl, 'nameNotCorrect')" class="error-message">
          {{'product.deleteDialog.nameError' | translate}}
        </span>
    </div>
    </div>

    <div class="actions-buttons">
      <button (click)="onCancel()" mat-stroked-button color="primary">
        {{'button.cancel' | translate}}
      </button>
      <button [disabled]="!nameControl.valid" (click)="onConfirm()" mat-flat-button color="primary">
        <fa-icon [icon]="faTrash"></fa-icon>
        {{'button.delete' | translate}}
      </button>
    </div>
  </div>
</app-base-center-dialog>
