import {Injectable} from '@angular/core';
import {IVideoMetaData} from 'src/app/asset/interface/IVideoMetaData';

@Injectable({
  providedIn: 'root'
})
export class VideoDataService {

  constructor() { }

  public getVideoDuration(file: File): Promise<IVideoMetaData>  {
    return new Promise((resolve) => {
      const video = document.createElement('video');
      video.src = URL.createObjectURL(file);

      video.ondurationchange = () => {
        const videoMetaData: IVideoMetaData = {
          duration: video.duration,
          width: video.videoWidth,
          height: video.videoHeight
        };
        resolve(videoMetaData);
      };
    });
  }
}
