import {FormArray, FormGroup, ValidationErrors} from '@angular/forms';
import * as _ from 'lodash';
import { PlatformCountValidationErrors } from 'src/app/product/interface/platform/IPlatformCountValidationErrors';
import {PlatformType} from '../enum/PlatformType';
import {IPlatformFormGroup} from '../interface/platform/IPlatformFormGroup';

export function platformTypeUniqValidator(formArray: FormArray<FormGroup<IPlatformFormGroup>>): ValidationErrors | null {
  if (!formArray.length) {
    return null;
  }
  const validationError: PlatformCountValidationErrors = {};
  updatePlatformTypeValidity(validationError, formArray);
  return _.isEmpty(validationError) ? null : validationError;
}

function updatePlatformTypeValidity(validationError: PlatformCountValidationErrors,
                                    formArray: FormArray<FormGroup<IPlatformFormGroup>>): void {
  Object.values(PlatformType).forEach(platformType => {
    const count = getPlatformTypeCount(platformType, formArray);
    if (count > 1) {
      validationError[getCountValidatorForPlatform(platformType)] = true;
    }
  });
}

function getPlatformTypeCount(platformType: PlatformType, formArray: FormArray<FormGroup<IPlatformFormGroup>>): number {
  return formArray.controls.filter((group: FormGroup<IPlatformFormGroup>) => group.controls.type.value?.name === platformType).length;
}

export function getCountValidatorForPlatform(platformType: PlatformType): keyof PlatformCountValidationErrors {
  switch (platformType) {
    case PlatformType.WEB:
      return 'webPlatformCount';
    case PlatformType.IOS:
      return 'iosPlatformCount';
    case PlatformType.ANDROID:
      return 'androidPlatformCount';
    case PlatformType.SAMSUNG:
      return 'samsungPlatformCount';
    case PlatformType.HUAWEI:
      return 'huaweiPlatformCount';
    case PlatformType.AMAZON:
      return 'amazonPlatformCount';
  }
}
