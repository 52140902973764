import {Injectable} from '@angular/core';
import {DialogService} from '../dialog/dialog/dialog.service';
import {DialogType} from '../../enum/ui/dialog/Dialog';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private dialogService: DialogService) {
  }

  public displayDialog(component: any, data?: any): void {
    this.dialogService.open(component, {type: DialogType.center, data});
  }
}
