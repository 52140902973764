import {Component, Input} from '@angular/core';
import {DialogRef} from '../../../service/dialog/dialog/dialog-ref';
import {faArrowLeft, faXmark} from '@fortawesome/pro-light-svg-icons';
import {IBackAction} from '../../../interface/ui/dialog/IBackAction';

@Component({
  selector: 'app-base-side-dialog',
  templateUrl: './base-side-dialog.component.html',
  styleUrls: ['./base-side-dialog.component.scss']
})
export class BaseSideDialogComponent {
  faXMark = faXmark;
  faArrowLeft = faArrowLeft;

  @Input() dialogRef: DialogRef;
  @Input() showBackButton = false;
  @Input() backAction: IBackAction;

  constructor() {}

  public onClose(): void {
    this.dialogRef.close();
  }

  public onBackClicked(): void {
    if (this.backAction?.backActionDialogConfig) {
        this.dialogRef.close(null, true);
    }
  }
}
