import {Injectable} from '@angular/core';
import {ICreativeDetails} from '../interface/ICreativeDetails';
import {IAdUnit} from '../../ad-unit/interface/IAdUnit';
import {IAdUnitFormat} from '../../ad-unit/interface/IAdUnitFormat';
import {HtmlFormatSimple} from '../../asset/enum/HtmlFormatSimple';
import {IAssetFormControl} from '../interface/ICreativeForm';
import _ from 'lodash';
import {getAssetControl, getAssetControlForEdit} from '../util/creative-form.util';
import {ICreativeRaw} from '../interface/ICreativeRaw';
import {FormGroup} from '@angular/forms';
import {ICreativeFormGroup} from '../interface/ICreativeFormGroup';
import {IAdUnitFormatAssetPair} from '../interface/IAdUnitFormatAssetPair';
import {AdUnitType} from '../../shared/enum/AdUnitType';

@Injectable({
  providedIn: 'root'
})
export class CreativeFormService {
  readonly adUnitFormatsOrder = {
    interactiveIcons: ['smallInteractiveIconsFormat', 'bigInteractiveIconsFormat'],
    bannerAdUnit: ['sdBannerFormat', 'ipadBannerFormat', 'hdBannerFormat'],
    embeddedAdUnit: ['sdEmbeddedFormat', 'hdEmbeddedFormat'],
    moreGamesAdUnit: ['moreGamesSquareFormat', 'moreGamesBannerFormat', 'moreGamesVideoFormat',
      'moreGamesVideoLandscapeFormat', 'moreGamesPlayableFormat'],
    interstitialAdUnit: ['videoInterstitialFormat', 'htmlInterstitialFormat',
      'videoInterstitialFormatLandscape', 'htmlInterstitialFormatLandscape', 'staticInterstitialFormat'],
    rewardedAdUnit: ['videoRewardedFormat','htmlRewardedFormat',
      'videoRewardedFormatLandscape' , 'htmlRewardedFormatLandscape', 'staticRewardedFormat']
  };

  readonly elementsPerRow = {
    interactiveIcons: 2,
    bannerAdUnit: 3,
    embeddedAdUnit: 2,
    moreGamesAdUnit: 2,
    interstitialAdUnit: 2,
    rewardedAdUnit: 2
  };

  private initialValue: ICreativeDetails;

  constructor() {
  }

  public init(initialValue: ICreativeDetails): void {
    this.initialValue = initialValue;
  }

  public isInitialCreativeStatic(initialValue: ICreativeDetails, adUnits: IAdUnit[]): boolean {
    const adUnitFormatIds = initialValue.matchedAssetsToFormats.map(matched => matched.adUnitFormatId);
    const allAdUnitFormats = [];
    adUnits.forEach(adUnit => {
      allAdUnitFormats.push(...adUnit.adUnitFormats);
    });
    const adUnitFormats = allAdUnitFormats.filter(adUnitFormat => adUnitFormatIds.includes(adUnitFormat.id));
    return adUnitFormats.some(adUnitFormat => adUnitFormat.isStatic);
  }

  public getAdFormatsForStatic(adUnit: IAdUnit, useDefaultHtml?: boolean): IAdUnitFormat[] {
    if (useDefaultHtml) {
      return adUnit.adUnitFormats.filter(adUnitFormat => adUnitFormat.isStatic);
    } else {
      return adUnit.adUnitFormats.filter(adUnitFormat =>
        adUnitFormat.formats.includes(HtmlFormatSimple.html) || adUnitFormat.isStatic);
    }
  }

  public getAdFormatsForNonStatic(adUnit: IAdUnit): IAdUnitFormat[] {
    return adUnit.adUnitFormats.filter(adUnitFormat => !adUnitFormat.isStatic);
  }

  public handleAssetDelete(assetControl: IAssetFormControl): void {
    assetControl.asset = null;
    assetControl.file = null;
    assetControl.status = null;
    assetControl.nameExistsError = false;
    assetControl.adUnitFormatMatchError = false;
  }

  public getFormatsSorted(adUnitFormats: IAdUnitFormat[], adUnitName: string): IAdUnitFormat[] {
    if (_.isEmpty(adUnitFormats)) {
      return [];
    }
    const suggestedOrder = this.adUnitFormatsOrder[adUnitName];
    if (!suggestedOrder) {
      return adUnitFormats;
    }

    const orderMap = new Map<string, number>(suggestedOrder.map((item, index) => [item, index]));

    return [...adUnitFormats].sort((a, b) => {
      const indexA: number = orderMap.has(a.name) ? orderMap.get(a.name) : Infinity;
      const indexB: number = orderMap.has(b.name) ? orderMap.get(b.name) : Infinity;

      if (indexA !== indexB) {
        return indexA - indexB;
      }
      return adUnitFormats.indexOf(a) - adUnitFormats.indexOf(b);
    });
  }

  public getColumnsCount(adUnitName: string): number {
    return this.elementsPerRow[adUnitName] || 2;
  }

  public getAssetControls(adUnitFormats: IAdUnitFormat[], adUnitId: string, adUnitName: string, editInit?: boolean): IAssetFormControl[] {
    const adUnitFormatsSorted = this.getFormatsSorted(adUnitFormats, adUnitName);
    if (editInit) {
      const creative = this.initialValue;
      return adUnitFormatsSorted.map(adUnitFormat =>
        getAssetControlForEdit(adUnitFormat, adUnitId, creative));
    } else {
      return adUnitFormatsSorted.map(adUnitFormat =>
        getAssetControl(adUnitFormat, adUnitId));
    }
  }

  public getCreativeRaw(formGroup: FormGroup<ICreativeFormGroup>,
                        newAssets: IAdUnitFormatAssetPair[], existingAssets: IAdUnitFormatAssetPair[]): ICreativeRaw {
    const formValue = formGroup.value;
    return {
      category: formValue.adUnit.type,
      name: formValue.name,
      productId: formValue.product.id,
      adUnitId: formValue.adUnit.id,
      assetIds: existingAssets.concat(newAssets).map(el => el.assetId),
      matchedAssetsToFormats: existingAssets.concat(newAssets),
      description: formValue.description,
      incentiveText: formValue.incentiveText,
      useStaticForDefaultHtml: this.getUseDefaultStaticUrl(formGroup),
    };
  }

  private getUseDefaultStaticUrl(formGroup: FormGroup<ICreativeFormGroup>): boolean {
    const formValue = formGroup.value;
    return this.isRewardedOrInterstitial(formValue.adUnit) && formValue.isStatic &&
      formValue.useStaticForDefaultHtml;
  }

  private isRewardedOrInterstitial(adUnit: IAdUnit): boolean {
    const adUnitType = adUnit?.type;
    return adUnitType === AdUnitType.REWARDED || adUnitType === AdUnitType.INTERSTITIAL;
  }

  public destroy(): void {
    this.initialValue = null;
  }
}
