import { Injectable } from '@angular/core';
import {ApiGeneralService} from '../../api/api-general.service';
import {IdToken} from '../../../interface/auth/IdToken';
import {Observable} from 'rxjs';
import {RequestType} from '../../../enum/RequestType';
import {User} from '../../../interface/auth/User';
import {RefreshToken} from '../../../interface/auth/RefreshToken';
import {RefreshTokenResponse} from '../../../interface/auth/RefreshTokenResponse';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(private apiGeneralService: ApiGeneralService) { }

  public login(token: IdToken): Observable<User> {
    const url = 'Auth';

    return this.apiGeneralService.sendRequest({
      method: RequestType.post,
      url,
      data: token
    });
  }

  public refreshToken(refreshToken: RefreshToken): Observable<RefreshTokenResponse> {
    const url = 'Auth/refresh';

    return this.apiGeneralService.sendRequest({
      method: RequestType.post,
      url,
      data: refreshToken
    });
  }


}
