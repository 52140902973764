import {IFormScrollableHandler} from '../interface/ui/form/IFormScrollableHandler';
import {IFormScrollable} from '../interface/ui/form/IFormCmp';

export class FormScrollableHandler implements IFormScrollableHandler {

  constructor(private formCpm: IFormScrollable) {
  }

  public setScrollStatus(): void {
    if (this.hasScroll() !== this.formCpm.hasScroll) {
      this.formCpm.hasScroll = this.hasScroll();
      this.formCpm.changeDetectorRef.detectChanges();
    }
  }

  private hasScroll(): boolean {
    return this.formCpm.formScrollable?.nativeElement?.scrollHeight > this.formCpm.formScrollable?.nativeElement?.clientHeight;
  }
}
